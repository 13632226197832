import mutations from './search-resources.mutations'
import actions from './search-resources.actions'

const state = () => ({
    resources: [],
    active_search: false,
    reachable_areas_polygons: [],
    loading: false
})

const getters = {
    getResources: (state) => state.resources,
    isLoading: (state) => state.loading,
    isActiveSearch: (state) => state.active_search
}

export default {
    state,
    getters,
    mutations,
    actions
}
