import { render, staticRenderFns } from "./ActivityPanel.vue?vue&type=template&id=71d54a6f&scoped=true"
import script from "./ActivityPanel.vue?vue&type=script&lang=js"
export * from "./ActivityPanel.vue?vue&type=script&lang=js"
import style0 from "./ActivityPanel.vue?vue&type=style&index=0&id=71d54a6f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d54a6f",
  null
  
)

export default component.exports