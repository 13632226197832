<template>
    <side-panel class="side-panel" id="bluetooth-devices-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('bluetooth_devices') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>

                <div
                    class="d-flex align-items-center"
                    style="gap: 10px"
                    v-if="bluetooth_devices.length > 0"
                >
                    <div class="flex-grow-1">
                        <input-search
                            v-model="searchDevice"
                            ref="search_input"
                            @resetSearch="resetSearch"
                        />
                    </div>
                    <div class="flex-grow-0">
                        <!-- FILTERS BUTTON -->
                        <golia-button
                            variant="btn-circle btn-fill dark-blue"
                            icon="fa-fw fa-filter"
                            style="margin-bottom: 10px; padding: 5px 7px"
                            v-b-modal.modal-search-devices
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div class="mt-2">
                <b-modal id="modal-search-devices" :title="__('filter')" hide-footer>
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <div class="filter-field w-100 me-3">
                            <label for="">{{ __('order_by') }}</label>

                            <v-select
                                v-model="orderBy"
                                :options="orderByOptions"
                                label="text"
                                :placeholder="__('select')"
                                :clearable="false"
                                :reduce="(option) => option.value"
                                :searchable="false"
                            />
                        </div>

                        <div class="filter-field w-100">
                            <label for="">{{ __('order') }}</label>

                            <v-select
                                v-model="order"
                                :options="orderOptions"
                                label="text"
                                :placeholder="__('select')"
                                :clearable="false"
                                :reduce="(option) => option.value"
                            />
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between">
                        <div class="filter-field w-100 me-3">
                            <label for="">{{ __('filter_by') }}</label>

                            <v-select
                                v-model="filterBy"
                                :options="filterByOptions"
                                label="text"
                                :placeholder="__('select')"
                                :clearable="false"
                                :reduce="(option) => option.value"
                            />
                        </div>

                        <div class="filter-field w-100">
                            <label for="">{{ __('value') }}</label>

                            <v-select
                                v-model="filterValue"
                                :options="computedFilterValueOptions"
                                label="text"
                                :placeholder="__('select')"
                                :clearable="false"
                                :reduce="(option) => option.value"
                            />
                        </div>
                    </div>
                </b-modal>

                <div class="bluetooth-devices" v-if="bluetooth_devices.length > 0">
                    <div
                        class="single-device rounded-3 bg-main-gray p-2 mb-2"
                        :style="device.status === 0 ? 'opacity: 0.6' : ''"
                        v-for="(device, index) in filteredBluetoothDevices"
                        :key="index"
                        @click="selectVehicle(device)"
                    >
                        <div class="d-flex align-items-start">
                            <div class="device-info">
                                <p class="device-name mb-1">
                                    <b>{{ device.mac }}</b>
                                </p>
                                <small
                                    class="d-block text-muted text-tiny mb-0"
                                    v-b-tooltip="parseDate(device.timestamp)"
                                >
                                    <span v-if="parseModel(device)">{{ parseModel(device) }} </span>
                                    <span style="color: var(--dark-blue)">
                                        - {{ parseDate(device.timestamp) }}</span
                                    ></small
                                >
                            </div>
                            <div class="d-flex flex-column align-items-end ms-auto text-right">
                                <p class="device-vehicle text-tiny">
                                    {{ device.vehicle.plate }}
                                </p>
                                <i
                                    class="d-block fa-regular fa-sm fa-wifi-slash text-danger"
                                    v-if="device.status === 0"
                                ></i>
                            </div>
                        </div>

                        <div class="device-status d-flex align-items-center mt-2">
                            <i
                                class="fa-regular fa-rotate-270 fa-sm me-1"
                                :class="{
                                    'fa-battery-full': device.battery_percentage > 40,
                                    'fa-battery-three-quarters':
                                        device.battery_percentage <= 40 &&
                                        device.battery_percentage > 20,
                                    'fa-battery-quarter': device.battery_percentage <= 20
                                }"
                            ></i>
                            <div class="progress" style="height: 5px; width: 100%">
                                <div
                                    class="progress-bar"
                                    :class="{
                                        'bg-success': device.battery_percentage > 40,
                                        'bg-warning':
                                            device.battery_percentage <= 40 &&
                                            device.battery_percentage > 20,
                                        'bg-danger': device.battery_percentage <= 20
                                    }"
                                    role="progressbar"
                                    :style="{ width: `${device.battery_percentage}%` }"
                                    :aria-valuenow="device.battery_percentage"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <p class="device-vehicle text-tiny m-0 ms-2">
                                {{ device.battery_percentage }}%
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="rounded-3 d-flex align-items-center justify-content-center h-100 bg-main-gray"
                    v-if="bluetooth_devices.length === 0"
                >
                    <div class="text-center mt-3">
                        <i class="fa-solid fa-info-circle fa-2x text-muted text-dark-blue"></i>
                        <p class="mt-3 text-muted">{{ __('no_bluetooth_devices') }}</p>
                    </div>
                </div>
            </div>
        </template>
    </side-panel>
</template>

<script>
import moment from 'moment'
import SidePanel from './SidePanel.vue'
import InputSearch from '@/views/geofleet/components/MainPanel/InputSearch.vue'
import cloneDeep from 'lodash/cloneDeep'

import { mapState } from 'vuex'

export default {
    name: 'BluetoothDevicesPanel',
    components: {
        SidePanel,
        InputSearch
    },
    data() {
        return {
            searchDevice: '',
            orderBy: null,
            orderByOptions: [
                { value: null, text: this.__('none') },
                { value: 'timestamp.$date', text: this.__('plafonds.last_update') },
                { value: 'battery_percentage', text: this.__('battery') }
            ],
            order: null,
            orderOptions: [
                { value: null, text: this.__('none') },
                { value: 'asc', text: this.__('ascending') },
                { value: 'desc', text: this.__('descending') }
            ],
            filterBy: null,
            filterByOptions: [
                { value: null, text: this.__('none') },
                { value: 'status', text: this.__('status') }
            ],
            filterValue: null,
            filterValueOptions: [
                { value: null, text: this.__('none') },
                { value: 0, text: this.__('disconnected'), field: 'status', type: 'number' },
                { value: 1, text: this.__('connected'), field: 'status', type: 'number' }
            ]
        } //michele michelino camminava nel bosco, a un certo punto si imbatté in un magico michael selvaggio che cerco con ogni mezzo possibile di rubargli i suoi mars del potere che gli avrebbero permesso di trasformarsi nel grande eroe di cui le leggende parlano, topo gigio. Michele riusci a proteggersi da questo attacco con le armi in suo possesso, un lancia porchi e il potere della 104, a riflettere l'attacco verso un minion dall'aspetto particolare, il poveretto morì... Ma almeno Michele riusì a portarsi a casa i mars e a gustarli con il suo amico d'infanzia, Emma frodita, che dopo il suo viaggio in bangcock fece un cambio di sesso per via delle troppe critiche(non si sa quali). Michael nel fratempo nascosto nel buio pianificava la sua vendetta contro il potente titano, bimbo di Cayde-6. Purtroppo non riuscì a pensare a niente perché Andrea gli ha lanciato un porco in faccia e morì. Fine. Tralalero tralalà
    },
    methods: {
        resetSearch() {
            this.searchDevice = ''
        },
        selectVehicle(device) {
            const device_vehicle = device.vehicle.plate

            const truck = this.storeTrucks.find((truck) => truck.plate === device_vehicle)

            if (truck) {
                this.$store.commit('setFocusTruck', { truck: truck, on_focus: true })

                this.$emit('focus', truck)
            }
        },
        parseDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm')
        },
        parseModel(device) {
            let fullModel = null
            let model = device.model
            let type = device.type

            if (type == 6) {
                if (model == 4) {
                    return this.__('bluetooth_types.door_probe')
                }
                if (model == 5) {
                    return this.__('bluetooth_types.temperature_probe')
                }
            }

            return fullModel
        }
    },
    computed: {
        filteredBluetoothDevices() {
            let results = cloneDeep(this.bluetooth_devices)

            if (this.orderBy && this.order) {
                const orderMultiplier = this.order === 'asc' ? 1 : -1
                results.sort((a, b) => {
                    return (
                        orderMultiplier *
                        (this.resolvePath(this.orderBy, a) - this.resolvePath(this.orderBy, b))
                    )
                })
            }

            if (this.filterBy && this.filterValue) {
                const valueFromFilter = parseInt(this.filterValue)
                results = results.filter((device) => {
                    return device[this.filterBy] === valueFromFilter
                })
            }

            if (this.searchDevice) {
                results = results.filter((device) => {
                    return device.mac.toLowerCase().includes(this.searchDevice.toLowerCase())
                })
            }

            return results
        },
        computedFilterValueOptions() {
            if (!this.filterBy) return [{ value: null, text: this.__('select_filter') }]

            return [{ value: null, text: this.__('none') }].concat(
                this.filterValueOptions.filter((option) => {
                    return option.field === this.filterBy
                })
            )
        },
        ...mapState({
            storeTrucks: (state) => state.geoFleet.trucks,
            bluetooth_devices: (state) => state.geoFleet.bluetooth_devices
        })
    }
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';

#bluetooth-devices-panel {
    ::v-deep(.head),
    ::v-deep(.body) {
        --side-panels-security-width: 420px;
    }
}

.progress {
    background-color: #ddd;
}
.single-device {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: var(--main-gray--darker) !important;
    }
}
</style>
