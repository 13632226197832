<template>
    <div class="planner-portal">
        <PlannerPanelContents />
    </div>
</template>

<script>
export default {
    name: 'plannerPortal',

    data() {
        return {}
    },
    watch: {},
    created() {},
    mounted() {},
    computed: {},
    methods: {}
}
</script>

<style scoped lang="scss">
.planner-portal {
    color: #515059;
    overflow-y: hidden;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative; /* For loader */

    ::v-deep(.body) {
        height: 100%;
    }
}
</style>
