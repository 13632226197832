<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <b-alert v-if="errorMsg" show dismissible variant="danger">{{ errorMsg }}</b-alert>

            <b-form-group label="Username" label-for="username" class="mb-3">
                <b-form-input id="username" v-model="username" required></b-form-input>
            </b-form-group>

            <b-form-group label="Password" label-for="password" class="mb-3">
                <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    required
                ></b-form-input>
            </b-form-group>

            <golia-button
                type="submit"
                :label="__('login_button')"
                variant="btn-success me-3"
                :loading="loading"
            />
            <golia-button
                type="button"
                :label="__('cancel')"
                variant="btn-danger"
                @click="$emit('login-cancel')"
            />
        </b-form>
    </div>
</template>

<script>
export default {
    props: {
        inModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            username: '',
            password: '',
            errorMsg: null
        }
    },
    methods: {
        submitForm() {
            this.loading = true
            this.errorMsg = null

            this.$fetch('https://run.mocky.io/v3/9fb6e695-715c-4f35-9656-624575bdcca2', {
                method: 'POST',
                body: btoa(
                    JSON.stringify({
                        username: this.username,
                        password: this.password
                    })
                )
            })
                .then((res) => res.json())
                .then((res) => {
                    // Login successful, close the modal
                    if (res.success) {
                        // Qui dovrebbe partire il salvataggio del fatto che ora il login è stato effettuato
                        this.$store.commit('addUserProperty', {
                            property: 'loggedToAlpega',
                            value: true
                        })
                        this.$store.dispatch('saveConfig', {
                            loggedToAlpega: true,
                            vueInstance: this
                        })

                        this.$emit('login-success')
                    } else {
                        this.errorMsg = res.errors[0].message
                    }
                    this.loading = false
                })
                .catch((error) => {
                    // Login failed, display error message
                    this.errorMsg = error.errors[0].message
                    this.loading = false
                })
        }
    }
}
</script>

<style scoped></style>
