<template>
    <div
        class="golia-list-item d-flex flex-column flex-md-row justify-content-between"
        @click="$emit('click')"
    >
        <div class="item-contents w-100">
            <h3 class="item-title" v-show="title">{{ title }}</h3>
            <p class="item-subtitle" v-show="subtitle">{{ subtitle }}</p>

            <slot name="extra-contents"></slot>
        </div>

        <div class="item-actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoliaListItem',
    props: {
        title: String,
        subtitle: String
    }
}
</script>
