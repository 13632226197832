const mutations = {
    setResources(state, resources) {
        state.resources = resources
    },
    toggleActiveSearch(state, active_search) {
        state.active_search = active_search
    },
    focusResource(state, resource) {
        let resourceToFocus = state.resources.find((item) => {
            return item.id == resource.id
        })

        state.resources.map((item) => {
            item.on_focus = false
        })

        if (resourceToFocus) {
            resourceToFocus.on_focus = true
        }

        let cn = document.querySelector('.body.custom-scrollbar')
        let el = document.getElementById(resource.id)

        setTimeout(() => {
            cn.scrollTo({
                top: el.offsetTop - 100,
                behavior: 'smooth'
            })
        }, 300)
    },
    toggleFocusResource(state, resource) {
        let resourceToFocus = state.resources.find((item) => {
            return item.id == resource.id
        })

        if (resourceToFocus) {
            if (!resourceToFocus.on_focus) {
                resourceToFocus.on_focus = !resourceToFocus.on_focus

                state.resources.map((item) => {
                    if (item.id != resourceToFocus.id) {
                        item.on_focus = false
                    }
                })
            } else {
                resourceToFocus.on_focus = false
            }
        }
    },
    resetResources(state) {
        state.resources = []

        state.active_search = false
    },
    setReachableAreasPolygons(state, polygons) {
        state.reachable_areas_polygons = polygons
    },
    resetReachableAreasPolygons(state) {
        state.reachable_areas_polygons = []
    },
    setLoading(state, loading) {
        state.loading = loading
    }
}

export default mutations
