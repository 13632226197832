<template>
    <div>
        <h6 class="font-bold">{{ __('analisys.select_telemetries') }}</h6>
        <p class="mb-4 text-smaller">
            {{ __('analisys.select_telemetries_description') }}
        </p>

        <div class="mb-4" v-for="(fields, group) in groupedFields" :key="group">
            <h6 class="section-title mt-4 mb-2 font-bold">
                {{ __('analisys.chart_groups.' + group) }}
            </h6>

            <div
                class="bg-main-inner-bg p-3 rounded-3 form-group d-flex align-items-center flex-wrap gap-3"
            >
                <div
                    class="form-check"
                    style="flex-basis: 45%;flex-wrap: wrap;"
                    v-for="(field_value, field_name) in fields"
                    :key="field_name"
                >
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="field_value.active"
                        :disabled="shouldCheckboxDisabled(field_value, field_name)"
                        :id="'chart-' + field_name"
                        @change="saveChart(field_value)"
                    />
                    <label class="form-check-label" :for="'chart-' + field_name">
                        {{ __(field_value.title || field_name) }}
                    </label>

                    <div
                        v-if="field_name == 'activity'"
                        style="width: 100%;padding-top: 6px;"
                    >
                        <toggle-button
                            v-model="field_value.extension.computed"
                            :sync="true"
                            :disabled="!field_value.active"
                            class="me-3"
                            @change="saveChart(field_value)"
                        />
                        <label
                            class="form-check-label"
                            :style="{opacity: field_value.active ? 1 : 0.7}"
                        >
                            {{ field_value.extension.computed ? __('driver_activity') : __('telemetry') }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { useDefaultChart } from '@/composables/useDefaultChart'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
    name: 'ActivityChartSettings',
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    save: false,
                    reset: false
                }
            }
        }
    },
    components: {
        ToggleButton
    },
    setup() {
        const { defaultChart, localChart } = useDefaultChart()

        return {
            defaultChart, localChart
        }
    },
    data() {
        return {
            chart: {}
        }
    },
    methods: {
        saveChart(field) {
            this.$store.dispatch('saveConfig', {
                ['chart']: Object.keys(this.chart).reduce((c,k) => {
                    c[k] = { active: this.chart[k].active, extension: this.chart[k].extension || {} }
                    return c
                }, {}),
                vueInstance: this
            })
        },
        shouldCheckboxDisabled(field, value) {
            if (!field.count) return false

            let counter = this.activeFields.filter((field) => field.count).length

            return (
                (counter >= 4 && !this.activeFields.includes(value)) ||
                (counter == 1 && this.activeFields.includes(value))
            )
        },
        resetChart() {
            this.chart = this.defaultChart
            this.saveChart()
        }
    },
    created() {
        this.chart = this.localChart
    },
    watch: {
        value: {
            handler() {
                if (this.value.save) {
                    this.saveChart()
                }
                if (this.value.reset) {
                    this.resetChart()
                    this.$emit('input', { save: false, reset: false })
                }
            },
            deep: true
        }
    },
    computed: {
        activeFields() {
            // return array of active fields
            return Object.keys(this.chart).filter((key) => this.chart[key].active)
        },
        groupedFields() {
            // create objects of grouped fields (ex. movement_data: { speed: {...}, altitude: {...} })
            return Object.keys(this.chart).reduce((acc, key) => {
                let field = this.chart[key]

                if (!field.group) return acc

                if (!acc[field.group]) {
                    acc[field.group] = {}
                }

                acc[field.group] = { ...acc[field.group], [key]: field }
                return acc
            }, {})
        },
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>
