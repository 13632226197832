<template>
    <div class="panel-box">
        <!-- SETTINGS PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <settings-panel
                v-if="utility_panel == 'settings'"
                class="tiny-panel left-aligned settings-panel"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="tags_panel"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- BLUETOOTH DEVICES PANEL -->
        <transition name="slide-fade">
            <bluetooth-devices-panel
                v-if="utility_panel == 'bluetooth-devices'"
                class="tiny-panel left-aligned bluetooth-devices"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="bluetooth_devices"
                @focus="(truck) => $emit('focus', truck)"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- POI PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <poi-manager-panel
                v-if="utility_panel == 'manage-poi'"
                class="extended-panel activity-panel poi-manager-panel"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="tags_panel"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- VEHICLES PASSAGE PANEL -->
        <transition name="slide-fade">
            <vehicles-passage
                v-if="utility_panel == 'vehicles-passage'"
                class="tiny-panel left-aligned vehicles-passage-panel"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="vehicles_passage_panel"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- TAGS PANEL -->
        <transition name="slide-fade">
            <tags-panel
                v-if="utility_panel == 'tags'"
                class="tiny-panel left-aligned tags-panel"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="tags_panel"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- SHARED LINKS PANEL -->
        <transition name="slide-fade">
            <links-panel
                v-if="utility_panel == 'shared-links'"
                class="tiny-panel left-aligned links-panel"
                :trucks="storeTrucks"
                :class="!main_menu_collapsed ? 'with-menu-expanded' : ''"
                ref="links_panel"
                @toggleUtilityPanel="(panel) => $emit('toggleUtilityPanel', panel)"
            />
        </transition>

        <!-- SEARCH RESOURCES PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <search-resources-panel
                v-if="searchResourcesOpen && focus_truck"
                class="tiny-panel search-resources-panel"
                ref="search_resources_panel"
            />
        </transition>

        <!-- PLANNER PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <planner-panel
                v-if="activity_panel == 'planner'"
                class="extended-panel activity-panel"
                :class="{ 'focus-active': focus_truck }"
                :truck="activity_truck || undefined"
                @lostfocus="(truck) => $emit('lostfocus', truck)"
            />
        </transition>

        <!-- ACTIVITY PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <activity-panel
                v-if="activity_panel == 'activity' || activityOpen"
                class="extended-panel activity-panel"
                :class="{ 'focus-active': focus_truck }"
                :truck="activity_truck"
                @lostfocus="(truck) => $emit('lostfocus', truck)"
            />
        </transition>

        <!-- PLAFOND DRIVER PANEL -->
        <transition
            name="slide-fade"
            @after-enter="$emit('afterEnter')"
            @after-leave="$emit('afterLeave')"
        >
            <plafond-panel
                v-if="activity_panel == 'plafond' || plafondOpen"
                class="extended-panel plafond-panel"
                :class="{ 'focus-active': focus_truck }"
                :truck="focus_truck || undefined"
                @lostfocus="(truck) => $emit('lostfocus', truck)"
            />
        </transition>

        <!-- EXTRA PANELS SLOT -->
        <slot></slot>
    </div>
</template>

<script>
import SettingsPanel from '@/components/panels/SettingsPanel.vue'
import ActivityPanel from '@/components/panels/ActivityPanel.vue'
import PlannerPanel from '@/components/panels/PlannerPanel.vue'
import TagsPanel from '@/components/panels/TagsPanel.vue'
import LinksPanel from '@/components/panels/LinksPanel.vue'
import VehiclesPassage from '@/components/panels/VehiclesCross.vue'
import SearchResourcesPanel from '@/components/panels/SearchResourcesPanel.vue'
import PoiManagerPanel from '@/components/panels/PoiManagerPanel.vue'
import BluetoothDevicesPanel from '@/components/panels/BluetoothDevicesPanel.vue'
import PlafondPanel from '@/components/panels/PlafondPanel.vue'

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AppPanels',
    props: {
        utility_panel: {
            type: String,
            default: null
        },
        activity_panel: {
            type: String,
            default: null
        },
        main_menu_collapsed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SettingsPanel,
        TagsPanel,
        LinksPanel,
        SearchResourcesPanel,
        PoiManagerPanel,
        VehiclesPassage,
        BluetoothDevicesPanel,
        ActivityPanel,
        PlannerPanel,
        PlafondPanel
    },
    computed: {
        ...mapState({
            storeTrucks: (state) => state.geoFleet.trucks
        }),
        ...mapGetters({
            focus_truck: 'getFocusTruck',
            activity_truck: 'getActivityTruck',
            searchResourcesOpen: 'searchResourcesOpen',
            mainCollapsed: 'mainCollapsed',
            activityOpen: 'activityOpen',
            plafondOpen: 'plafondOpen'
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_panels.scss';
</style>
