var render = function render(){var _vm=this,_c=_vm._self._c;return _c('side-panel',{class:{ collapsed: _vm.collapsedClass },attrs:{"id":"detail-panel"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('div',{staticClass:"collapse-button",on:{"click":function($event){_vm.localCollapsed = !_vm.localCollapsed}}},[_c('div',{staticClass:"d-block d-lg-none"},[_c('span',[_vm._v(_vm._s(!_vm.localCollapsed ? _vm.__('close') : _vm.__('open')))]),(_vm.localCollapsed)?_c('i',{staticClass:"fa-solid fa-arrow-up-from-line"}):_c('i',{staticClass:"fa-solid fa-arrow-down-from-line"})]),_c('div',{staticClass:"d-none d-lg-block"},[(_vm.localCollapsed)?_c('i',{staticClass:"fa-solid fa-arrow-left-from-line"}):_c('i',{staticClass:"fa-solid fa-arrow-right-from-line"})])]),_c('transition',{attrs:{"name":"collapse-fade"},on:{"after-leave":function($event){_vm.collapsedClass = true},"before-enter":function($event){_vm.collapsedClass = false}}},[(!_vm.localCollapsed)?_c('details-head',{attrs:{"truck":_vm.truck,"collapsed":_vm.localCollapsed,"closable":_vm.closable},on:{"close":function($event){return _vm.$emit('lostfocus', _vm.truck)}}}):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [_c('transition',{attrs:{"name":"collapse-fade-reverse"}},[(_vm.localCollapsed)?_c('div',{staticClass:"collapse-info"},[_c('div',{staticClass:"collapsed-header"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c('div',{staticClass:"circle-icon",style:({
                                'background-color': _vm.colorStatus,
                                'background-image': 'none'
                            })},[_c('truck-icon',{staticStyle:{"padding":"5px","color":"#fff"},attrs:{"category":_vm.truck.category}})],1),_c('span',{staticClass:"targa-c"},[_vm._v(_vm._s(_vm.truck.plate))])]),_c('div',{staticClass:"lastpoint-info"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.truck.lastpoint.date,'UTC'))+" ")]),(
                                _vm.truck.lastpoint.provenience && _vm.truck.lastpoint.provenience.web
                            )?_c('div',{staticClass:"p-provdescr"},[(
                                    _vm.truck.lastpoint.provenience &&
                                    _vm.truck.lastpoint.provenience.icon
                                )?_c('i',{class:['fa-solid', _vm.truck.lastpoint.provenience.icon]}):_vm._e(),_vm._v(" "+_vm._s(_vm.truck.lastpoint.provenience.web)+" ")]):_vm._e()]),_c('div',{staticClass:"localizzazione-f"},[_c('reverse-geocoding',{attrs:{"full":true,"truncated":80,"latLon":[_vm.truck.lastpoint.lat, _vm.truck.lastpoint.lng]}})],1)])]):_vm._e(),(!_vm.localCollapsed)?_c('div',{staticClass:"container-fluid mt-2 mt-sm-3"},[_c('golia-date-picker',{attrs:{"alignCalendar":"right","monthPanels":1,"specificTime":false},model:{value:(_vm.localRangeDate),callback:function ($$v) {_vm.localRangeDate=$$v},expression:"localRangeDate"}}),_c('div',{staticClass:"show-route"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localTruck.showingRoute),expression:"localTruck.showingRoute"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"checkShowRoute"},domProps:{"checked":Array.isArray(_vm.localTruck.showingRoute)?_vm._i(_vm.localTruck.showingRoute,null)>-1:(_vm.localTruck.showingRoute)},on:{"click":function($event){_vm.localTruck.showingRoute = !_vm.localTruck.showingRoute},"change":function($event){var $$a=_vm.localTruck.showingRoute,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.localTruck, "showingRoute", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.localTruck, "showingRoute", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.localTruck, "showingRoute", $$c)}}}}),_c('label',{staticClass:"form-check-label w-100",attrs:{"for":"checkShowRoute"}},[_vm._v(" "+_vm._s(_vm.__('viewsharedlink.show_route'))+" ")])])])],1):_vm._e()]),_c('transition',{attrs:{"name":"collapse-fade"}},[(!_vm.localCollapsed)?_c('details-body',{ref:"details_body",staticClass:"d-body",attrs:{"truck":_vm.truck,"collapsed":_vm.localCollapsed}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }