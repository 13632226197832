<template>
    <side-panel class="side-panel" id="trucks-alerts-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('trucks_with_alerts') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div v-if="getTrucksWithAlerts.length > 0">
                <truck-list-item
                    class="truck-list-item"
                    v-for="truck of getTrucksWithAlerts"
                    :key="truck.id"
                    :truck="truck"
                    @click="(truck) => $emit('focus', truck)"
                    @analyze="(truck) => $emit('activity', truck)"
                    @plan="(truck) => $emit('plan', truck)"
                />
            </div>
            <div v-else>
                <div
                    class="rounded-3 d-flex align-items-center justify-content-center h-100 bg-main-gray"
                >
                    <div class="text-center mt-3">
                        <i class="fa-solid fa-info-circle fa-2x text-muted text-dark-blue"></i>
                        <p class="mt-3 text-muted">{{ __('no_warning') }}</p>
                    </div>
                </div>
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import TruckListItem from '@/views/geofleet/components/MainPanel/TruckListItem.vue'

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'TrucksWithAlertsPanel',
    components: {
        SidePanel,
        TruckListItem
    },
    computed: {
        ...mapState({
            companyTags: (state) => state.geoFleet.companyTags
        }),
        ...mapGetters({
            getTrucksWithAlerts: 'getTrucksWithAlerts'
        })
    }
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';

.no-vehicles {
    border-radius: 25px;
    font-size: 14px;
}
</style>
