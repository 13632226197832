<template>
    <button class="btn" :class="btnClass" :disabled="disabled" @click="$emit('click', $event)">
        <template v-if="loading">
            <i class="fa-duotone fa-spin fa-loader"></i> {{ label }}
        </template>
        <template v-else>
            <i :class="iconWeight + ' ' + icon" v-if="icon && iconPosition == 'left'"></i>
            {{ label }}
            <i :class="iconWeight + ' ' + icon" v-if="icon && iconPosition == 'right'"></i>
            <span class="badge rounded-pill ms-1" v-if="badge">{{ badge }}</span>
            <span
                class="indicator position-absolute top-0 start-100 translate-middle rounded-pill"
                v-if="indicator"
                >{{ indicator }}</span
            >
        </template>
    </button>
</template>

<script>
export default {
    name: 'GoliaButton',
    props: {
        label: {
            type: String,
            default: ''
        },
        variant: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        iconWeight: {
            type: String,
            default: 'fa-solid'
        },
        iconPosition: {
            type: String,
            default: 'left'
        },
        badge: {
            type: [String, Number]
        },
        indicator: {
            type: [String, Number]
        }
    },
    data() {
        return {}
    },
    methods: {},
    computed: {
        btnClass: function () {
            return (
                this.variant +
                (this.size ? ' btn-' + this.size : '') +
                (this.loading ? ' ' + this.loading : '') +
                (this.indicator ? ' position-relative' : '')
            )
        }
    },
    created() {},
    mounted() {}
}
</script>

<style scoped lang="scss">
button {
    border-radius: 25px;

    &.btn-sm {
        min-width: auto;
    }
    &.fenced {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            width: 50px;
            height: 1px;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -60px;
            width: 50px;
            height: 1px;
        }
    }
    .badge {
        font-family: 'Stolzl-Regular';
    }
    .indicator {
        background: var(--success);
        width: 20px;
        display: inline-block;
        height: 20px;
    }
    &.default {
        .badge {
            background-color: #333;
            color: #fff;
        }
    }
}
@media (max-width: 768px) {
    button {
        min-width: 100%;
    }
}
</style>
