<template>
    <i
        :class="[
            category && category.fa ? JSON.parse(category.fa).icon : 'fak fa-trattore-stradale'
        ]"
    ></i>
</template>

<script>
export default {
    name: 'TruckIcon',
    props: {
        category: {
            type: Object,
            default: null
        }
    },
    components: {},
    data() {
        return {}
    }
}
</script>

<style scoped>
i {
    font-size: 22px;
}
</style>
