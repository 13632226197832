const mutations = {
    addWaypoint(state, waypoint) {
        // check the bigger id
        let maxId = Math.max.apply(
            Math,
            state.waypoints.map(function (o) {
                return o.id
            })
        )
        waypoint.id = maxId + 1

        // Add a waypoint to the end of the array with reactivity
        state.waypoints.push(waypoint)
    },
    injectWaypoint(state, payload) {
        let { waypoint, index } = payload
        // check the bigger id
        let maxId = Math.max.apply(
            Math,
            state.waypoints.map(function (o) {
                return o.id
            })
        )
        waypoint.id = maxId + 1

        // Add a waypoint to the end of the array with reactivity
        state.waypoints.splice(index, 0, waypoint)
    },
    updateWaypoints(state, waypoints) {
        // update the entire state.waypoints array with reactivity
        state.waypoints.splice(0, state.waypoints.length, ...waypoints)
    },
    updateWaypoint(state, payload) {
        let { waypoint, index } = payload

        // here if not find then last
        if (!index) index = state.waypoints.findIndex((w) => w.id === waypoint.id) || 0

        state.waypoints.splice(index, 1, waypoint)
    },
    updateWaypointProperty(state, payload) {
        const { property, value } = payload
        let index = state.waypoints.findIndex((t) => t.id === payload.waypoint.id)

        const keys = property.split('.')

        if (keys.length > 1) {
            // Update nested property
            let prop = state.waypoints[index]
            for (let i = 0; i < keys.length - 1; i++) {
                prop = prop[keys[i]]
            }
            prop[keys[keys.length - 1]] = value
        } else {
            state.waypoints[index][property] = value
        }
    },
    removeWaypoint(state, waypoint) {
        let index = waypoint.id ? state.waypoints.findIndex((t) => t.id === waypoint.id) : waypoint

        if (index !== -1) {
            state.waypoints.splice(index, 1)
        }
    },
    resetWaypoint(state, waypoint) {
        let index = state.waypoints.findIndex((t) => t.id === waypoint.id)
        let maxId = Math.max.apply(
            Math,
            state.waypoints.map(function (o) {
                return o.id
            })
        )

        state.waypoints.splice(index, 1, { id: maxId + 1 })
    },
    resetWaypoints(state) {
        state.waypoints.splice(0, state.waypoints.length, { id: 1 })
    }
}

export default mutations
