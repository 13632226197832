<template>
    <div style="height: 100%">
        <div class="d-flex flex-row menu">
            <div
                :class="{ 'collapsible-title': true, 'is-active': page == 'maneuver' }"
                @click="page = 'maneuver'"
            >
                <i class="fa-solid fa-route"></i>
            </div>
            <div
                :class="{ 'collapsible-title': true, 'is-active': page == 'emission' }"
                @click="page = 'emission'"
            >
                <i class="fa-solid fa-smog"></i>
            </div>
            <div
                :class="{ 'collapsible-title': true, 'is-active': page == 'segments' }"
                @click="page = 'segments'"
            >
                <i class="fa-solid fa-road"></i>
            </div>
            <div
                :class="{ 'collapsible-title': true, 'is-active': page == 'toll' }"
                @click="page = 'toll'"
                v-if="toll.sections"
            >
                <i class="fa-solid fa-route-highway"></i>
            </div>
        </div>

        <div class="content custom-scrollbar">
            <div v-if="page == 'maneuver'" style="margin-bottom: 50px">
                <div
                    v-for="(event, key) in events"
                    :key="key"
                    class="box"
                    :class="page"
                    :data-index="key"
                >
                    <div
                        class="event-wrapper"
                        v-if="!(event.tourEventTypes || []).includes('SERVICE')"
                        :class="
                            event.$type == 'TourEvent' &&
                            (event.tourEventTypes.includes('BREAK') ||
                                event.tourEventTypes.includes('DAILY_REST'))
                                ? 'break-info'
                                : ''
                        "
                    >
                        <div
                            class="title"
                            v-if="event.$type != 'ManeuverEvent' && event.$type != 'TourEvent'"
                        >
                            {{ eventTypeLabel(event.$type)
                            }}<span v-if="event.waypointIndex >= 0"
                                >&nbsp;-&nbsp;{{
                                    waypointLabelOnCoordinates(event.waypointIndex)
                                }}</span
                            >
                        </div>
                        <div class="title" v-else-if="event.$type == 'TourEvent'">
                            {{
                                event.tourEventTypes.includes('BREAK')
                                    ? __('break')
                                    : __('daily_rest')
                            }}
                        </div>

                        <div class="description">
                            <div v-if="event.country">{{ event.country }}</div>
                            <div v-if="event.directionDescription">
                                <i :class="maneuverIcon(event.maneuverType)"></i>&nbsp;{{
                                    event.directionDescription
                                }}
                            </div>
                            <div v-if="event.displayName">
                                {{ event.displayName }}
                                <span v-if="event.accessType"
                                    >({{
                                        event.accessType == 'ENTER' ? __('input') : __('output')
                                    }})</span
                                >
                            </div>
                        </div>

                        <div v-if="event.$type == 'WaypointEvent'" class="road-stamp">
                            <div>
                                <b>{{ __('finish_point') }}</b>
                                {{ event.startsAt | formatDate('UTC') }}
                            </div>

                            <div v-if="event.travelTimeFromStart > 0">
                                <b>{{ __('duration') }}</b>
                                {{ event.travelTimeFromStart | secondsToTime }}
                            </div>
                            <div v-if="event.distanceFromStart > 0">
                                <b>{{ __('distance_total') }}</b>
                                {{ event.distanceFromStart | metersToKm }}
                            </div>
                        </div>

                        <div
                            v-if="
                                event.$type == 'TourEvent' &&
                                (event.tourEventTypes.includes('BREAK') ||
                                    event.tourEventTypes.includes('DAILY_REST'))
                            "
                            class="road-stamp"
                        >
                            <div>
                                <b>{{ __('to_begin_within') }}</b>
                                {{ event.startsAt | formatDate('UTC') }}
                            </div>

                            <div class="d-none" v-if="event.travelTimeFromStart > 0">
                                <b>{{ __('after_total_time_travel') }}</b>
                                {{ event.travelTimeFromStart | secondsToTime }}
                            </div>
                            <div class="d-none" v-if="event.distanceFromStart > 0">
                                <b>{{ __('after_total_distance_travel') }}</b>
                                {{ event.distanceFromStart | metersToKm }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="page == 'emission'"
                :style="emissions.length > 1 ? 'margin-bottom: 50px' : ''"
            >
                <div v-for="(emission, key) in emissions" :key="key" class="box" :class="page">
                    <div class="title">
                        {{ __(emission.standardType) }}
                    </div>
                    <div class="description">
                        <div class="row">
                            <div
                                class="col-md-6 mb-1"
                                v-for="(title, key) in Object.keys(emission).filter(
                                    (t) =>
                                        t != '$type' &&
                                        t != 'standardType' &&
                                        t != 'valueScenarioType'
                                )"
                                :key="key"
                            >
                                <div
                                    class="d-flex justify-content-between h-100 pb-1"
                                    style="border-bottom: 1px solid #ddd"
                                >
                                    <b style="opacity: 0.9">{{ __(consumiLabel(title)) }}</b
                                    ><span>{{
                                        title == 'numberOfParticles'
                                            ? (emission[title] / 1000000000000000).toFixed(3) +
                                              ' quadr.'
                                            : emission[title].toFixed(3)
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="page == 'segments'"
                :style="legs.length > 3 ? 'margin-bottom: 50px' : ''"
            >
                <div v-for="(leg, key) in legs" :key="key" class="box" :class="page">
                    <div class="title">{{ __('segment') }} {{ key + 1 }}</div>
                    <div class="description">
                        <i
                            >{{ waypoints[key].coordinate.label }} -
                            {{ waypoints[key + 1].coordinate.label }}</i
                        >

                        <div class="row mt-2">
                            <div
                                class="col-md-6 mb-1"
                                v-for="(title, key) in Object.keys(leg)"
                                :key="key"
                            >
                                <div
                                    class="d-flex justify-content-between h-100 pb-1"
                                    style="border-bottom: 1px solid #ddd"
                                >
                                    <b style="opacity: 0.9">{{ __(segmentiLabel(title)) }}</b
                                    ><span>{{ segmentiValue(title, leg[title]) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="page == 'toll'"
                :style="
                    toll.summary.countries.length > 3 || (toll.sections || {}).length > 3
                        ? 'margin-bottom: 50px'
                        : ''
                "
            >
                <div
                    v-for="(country, key) in toll.summary.countries"
                    :key="key"
                    class="box"
                    :class="page"
                >
                    <div class="title">{{ __('state') }} {{ country.country }}</div>
                    <div class="description">
                        <table>
                            <tr
                                v-for="(toll, key) in toll.sections.filter(
                                    (t) => t.country == country.country
                                )"
                                :key="key"
                            >
                                <td>{{ toll.displayName }}</td>
                                <td class="text-end">{{ toll.cost.amount | currency }}</td>
                            </tr>
                        </table>
                        <hr class="my-2" />
                        <table>
                            <tr>
                                <td>{{ __('cost') }}</td>
                                <td class="text-end">{{ country.cost.amount }} €</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventViewer',
    props: {
        waypoints: {
            type: Array,
            default: () => []
        },
        events: {
            type: Array,
            required: true
        },
        emissions: {
            type: Array,
            required: true
        },
        legs: {
            type: Array,
            required: true
        },
        toll: {
            type: Object,
            required: true
        }
    },
    components: {},
    data() {
        return {
            page: 'maneuver'
        }
    },
    methods: {
        waypointLabelOnCoordinates(index) {
            var real_waypoints = this.waypoints.filter((w) => w.coordinate.action != 'break')

            return real_waypoints[index].coordinate.label
        },
        eventTypeLabel(type) {
            switch (type) {
                case 'WaypointEvent':
                    return this.__('planner.waypointevent')
                case 'LegEvent':
                    return this.__('segment')
                case 'CountryEvent':
                    return this.__('planner.countryevent')
                case 'TollEvent':
                    return this.__('planner.tollevent')
                case 'TourEvent':
                    return this.__('planner.event')
            }
        },
        consumiLabel(label) {
            switch (label) {
                case 'fuelConsumption':
                    return this.__('planner.emissions.fuelconsumption')
                case 'hydrocarbons':
                    return this.__('planner.emissions.hydrocarbons')
                case 'methane':
                    return this.__('planner.emissions.methane')
                case 'hydrocarbonsExceptMethane':
                    return this.__('planner.emissions.hydrocarbonsexceptmethane')
                case 'carbonMonoxide':
                    return this.__('planner.emissions.carbonmonoxide')
                case 'carbonDioxide':
                    return this.__('planner.emissions.carbondioxide')
                case 'fossilCarbonDioxide':
                    return this.__('planner.emissions.fossilcarbondioxide')
                case 'sulphurDioxide':
                    return this.__('planner.emissions.sulphurdioxide')
                case 'nitrogenOxides':
                    return this.__('planner.emissions.nitrogenoxides')
                case 'nitrogenDioxide':
                    return this.__('planner.emissions.nitrogendioxide')
                case 'nitrousOxide':
                    return this.__('planner.emissions.nitrousoxide')
                case 'ammonia':
                    return this.__('planner.emissions.ammonia')
                case 'benzene':
                    return this.__('planner.emissions.benzene')
                case 'particles':
                    return this.__('planner.emissions.particles')
                case 'numberOfParticles':
                    return this.__('planner.emissions.numberofparticles')
                default:
                    return label
            }
        },
        segmentiLabel(label) {
            switch (label) {
                case 'distance':
                    return this.__('distance')
                case 'travelTime':
                    return this.__('duration')
                case 'trafficDelay':
                    return this.__('traffic_delay')
                case 'violated':
                    return this.__('violated_plafond')
                default:
                    return label
            }
        },
        segmentiValue(label, value) {
            switch (label) {
                case 'distance':
                    return this.$options.filters.metersToKm(value)
                case 'travelTime':
                    return this.$options.filters.secondsToTime(value)
                case 'trafficDelay':
                    return this.$options.filters.secondsToTime(value)
                case 'violated':
                    return value ? this.__('yes') : this.__('no')
                default:
                    return value
            }
        },
        maneuverIcon(type) {
            switch (type) {
                case 'START':
                    return 'fa-solid fa-flag-checkered'
                case 'START_LEFT':
                    return 'fak fa-decisa-sx'
                case 'START_RIGHT':
                    return 'fak fa-decisa'
                case 'ARRIVE':
                    return 'fa-solid fa-flag-checkered'
                case 'ARRIVE_LEFT':
                    return 'fak fa-decisa-sx'
                case 'ARRIVE_RIGHT':
                    return 'fak fa-decisa'
                case 'CONTINUE':
                case 'KEEP_STRAIGHT':
                    return 'fa-solid fa-arrow-up'
                case 'KEEP_LEFT':
                    return 'fak fa-lieve-sx'
                case 'KEEP_RIGHT':
                    return 'fak fa-lieve'
                case 'TURN_HALF_LEFT':
                    return 'fak fa-lieve-sx'
                case 'TURN_LEFT':
                    return 'fak fa-decisa-sx'
                case 'TURN_SHARP_LEFT':
                    return 'fak fa-lieve-sx'
                case 'TURN_HALF_RIGHT':
                    return 'fak fa-lieve'
                case 'TURN_RIGHT':
                    return 'fak fa-decisa'
                case 'TURN_SHARP_RIGHT':
                    return 'fak fa-lieve'
                case 'MAKE_U_TURN':
                    return 'fak fa-inversione'
                case 'TAKE_ROUNDABOUT_LEFT':
                case 'TAKE_ROUNDABOUT_RIGHT':
                    return 'fak fa-rotonda'
                case 'TAKE_COMBINED_TRANSPORT':
                    return 'fa-solid fa-ship'
                case 'ENTER':
                    return 'fa-solid fa-arrow-up'
                case 'ENTER_LEFT':
                    return 'fak fa-lieve-sx'
                case 'ENTER_RIGHT':
                    return 'fak fa-lieve'
                case 'EXIT':
                    return 'fa-solid fa-arrow-up'
                case 'EXIT_LEFT':
                    return 'fak fa-lieve-sx'
                case 'EXIT_RIGHT':
                    return 'fak fa-lieve'
                case 'CHANGE':
                case 'CHANGE_LEFT':
                case 'CHANGE_RIGHT':
                    return 'fa-solid fa-arrow-up'
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>
.content {
    --scrollbar-thumb-border: var(--main-inner-bg-color);
    background: var(--main-inner-bg-color);
    border-radius: 0 0 15px 15px;
    margin: 0;
    padding: 15px 15px 5px 15px;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
}
.box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #495568;
}
.event-wrapper {
    margin: 3px 0px;
    padding: 3px 8px;
    background: #fff;
    border-radius: 6px;
}
.title {
    display: flex;
    justify-content: flex-start;

    font-weight: 700;
    font-size: 12px;
    border-radius: 6px;
}
.emission .title,
.segments .title,
.toll .title {
    font-size: 14px;
}
.road-stamp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 4px 0px;
}
.menu {
    align-items: stretch;
    justify-items: center;
    gap: 4px;
}
.collapsible-title {
    border: none;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    background-color: #687a8e;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 0px 0px 0px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.collapsible-title.is-active,
.collapsible-title:hover {
    background-color: #e5e6eb;
}
.collapsible-title.is-active,
.collapsible-title:hover i {
    color: #6d6b7a;
}
.collapsible-title i {
    color: #fff;
}
.collapsible-title.is-active i {
    color: #6d6b7a;
}
</style>
