<template>
    <side-panel class="side-panel" id="vehicles-passage-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('vehicles_cross.title') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3 mb-4">
                <golia-button
                    variant="btn-danger"
                    icon="fa-fw fa-times"
                    style="margin-right: 5px"
                    :label="__('cancel_search')"
                    v-if="activeSearch && resultVehicles.length > 0"
                    @click="cancelSearch"
                />
                <golia-button
                    variant="btn-fill dark-blue"
                    icon="fa-fw fa-arrow-left"
                    :label="mode == 'results' ? __('edit_search') : __('back_to_results')"
                    style="margin-right: 5px"
                    v-if="activeSearch && resultVehicles.length > 0"
                    @click="mode == 'search' ? (mode = 'results') : (mode = 'search')"
                />
            </div>
        </template>

        <template #body>
            <div class="container-fluid">
                <template v-if="mode == 'search'">
                    <!-- FORM + EMPTY STATE -->
                    <vehicles-cross-form
                        v-model="vehiclesCross"
                        :isMoreThan24Hours="isMoreThan24Hours"
                        :loading="loading"
                        @searchVehicleCross="searchVehicleCross"
                    />

                    <!-- Empty results -->
                    <template v-if="activeSearch && resultVehicles.length === 0">
                        <div
                            class="no-vehicles d-flex align-items-center justify-content-center bg-main-gray mt-4"
                        >
                            <div class="text-center mt-3">
                                <i
                                    class="fa-solid fa-circle-exclamation fa-xl text-muted text-dark-blue"
                                ></i>
                                <p class="mt-3 text-muted">{{ __('no_vehicles_founded') }}</p>
                            </div>
                        </div>
                    </template>

                    <!-- Is loading message -->
                    <transition name="common-fade">
                        <template v-if="long_loading">
                            <div
                                class="no-vehicles d-flex align-items-center justify-content-center bg-main-gray mt-4"
                            >
                                <div class="text-center mt-3">
                                    <i
                                        class="fa-solid fa-circle-exclamation fa-xl text-muted text-dark-blue"
                                    ></i>
                                    <p class="mt-3 text-muted">
                                        {{ __('vehicles_cross.messages.long_loading') }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </transition>
                </template>
                <template v-else>
                    <!-- SEARCH RESULTS -->
                    <vehicles-cross-results :vehicles="resultVehicles" />
                </template>
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import VehiclesCrossForm from './VehiclesCross/VehiclesCrossForm.vue'
import VehiclesCrossResults from './VehiclesCross/VehiclesCrossResults.vue'

import { mapState, mapMutations } from 'vuex'

export default {
    name: 'VehiclesCross',
    components: {
        SidePanel,
        VehiclesCrossForm,
        VehiclesCrossResults
    },
    data() {
        return {
            loading: false,
            long_loading: false,
            vehiclesCross: {
                address: null,
                lat: null,
                lng: null,
                radius: 0.1,
                timeInterval: {
                    start: this.moment().startOf('day'),
                    end: this.moment().endOf('day')
                }
            },
            activeSearch: false,
            resultVehicles: [],
            drawCirclesTimeout: null,
            mode: 'search'
        }
    },
    created() {
        if (Object.values(this.vehiclesCrossSearch).length > 0) {
            this.activeSearch = true
            this.vehiclesCross = this.vehiclesCrossSearch
        }

        // If this.vehiclesCrossSearch.address and this.vehiclesCrossSearch.radius are defined, draw the circle
        if (this.vehiclesCrossSearch.address && this.vehiclesCrossSearch.radius) {
            let coordinates = [
                this.vehiclesCrossSearch.address.referenceCoordinate.y,
                this.vehiclesCrossSearch.address.referenceCoordinate.x
            ]
            this.$store.dispatch('drawCircle', {
                ref: 'vehicles-cross',
                center: coordinates,
                radius: this.vehiclesCrossSearch.radius * 1000
            })
        }

        if (this.vehiclesCrossResults.length > 0) this.resultVehicles = this.vehiclesCrossResults
    },
    methods: {
        searchVehicleCross(prepared_search_params) {
            if (this.isMoreThan24Hours) {
                this.$snotify.error(this.__('vehicles_cross.fields.time_interval_description'), {
                    position: 'centerTop'
                })
                return
            }

            if (this.loading) return

            this.loading = true

            setTimeout(() => {
                if (this.loading) this.long_loading = true
            }, 10000)

            let search_url = this.$env.GOLIA_API + '/geofleet/vehiclepassage'

            let search_params = prepared_search_params || {
                start_date: this.startTimeInterval,
                end_date: this.endTimeInterval,
                lat: this.vehiclesCross.address.referenceCoordinate.y,
                lon: this.vehiclesCross.address.referenceCoordinate.x,
                radius: this.vehiclesCross.radius
            }

            // Save the search on the store
            this.setVehiclesCrossSearch(this.vehiclesCross)

            let search_url_params = new URLSearchParams(search_params).toString()

            this.activeSearch = false

            this.$fetch(search_url + '?' + search_url_params)
                .then((response) => response.json())
                .then((data) => {
                    if (data.errors) {
                        this.$snotify.error(this.__('vehicles_cross.messages.error_searching'), {
                            position: 'centerTop'
                        })
                        return
                    }

                    this.resultVehicles = data.data
                    this.activeSearch = true

                    this.setVehiclesCrossResults(data.data)
                    this.loading = false
                    this.long_loading = false
                })
                .catch(() => {
                    this.$snotify.error(this.__('vehicles_cross.messages.error_searching'), {
                        position: 'centerTop'
                    })
                    this.loading = false
                    this.long_loading = false
                })
        },
        editSearch() {
            this.mode = 'search'
        },
        cancelSearch() {
            this.resultVehicles = []
            this.vehiclesCross = {
                address: null,
                radius: 0.1,
                timeInterval: {
                    start: this.moment().startOf('day'),
                    end: this.moment().endOf('day')
                }
            }

            this.resetVehiclesCrossSearch()
            this.resetVehiclesCrossResults()

            this.activeSearch = false
            this.mode = 'search'

            this.$store.dispatch('removeCircle', { ref: 'vehicles-cross' })
            this.$store.dispatch('removeMarker', { ref: 'vehicles-cross' })
        },
        ...mapMutations({
            setVehiclesCrossSearch: 'setVehiclesCrossSearch',
            setVehiclesCrossResults: 'setVehiclesCrossResults',
            resetVehiclesCrossSearch: 'resetVehiclesCrossSearch',
            resetVehiclesCrossResults: 'resetVehiclesCrossResults'
        })
    },
    watch: {
        resultVehicles(val) {
            this.mode = val.length > 0 ? 'results' : 'search'
        },
        vehiclesCross: {
            handler(val) {
                if (!val) return

                if (!val.address) {
                    this.$store.dispatch('removeMarker', { ref: 'vehicles-cross' })
                    this.$store.dispatch('removeCircle', { ref: 'vehicles-cross' })

                    this.$store.commit('setMainMapStopAutocenter', false)
                    return
                }

                // Address handler
                if (
                    val?.address?.referenceCoordinate?.x === null ||
                    val?.address?.referenceCoordinate?.y === null
                ) {
                    this.$store.dispatch('removeCircle', { ref: 'vehicles-cross' })

                    this.$store.commit('setMainMapStopAutocenter', false)
                    return
                }

                let coordinates = [
                    val.address.referenceCoordinate.y,
                    val.address.referenceCoordinate.x
                ]
                this.$store.dispatch('drawMarker', {
                    ref: 'vehicles-cross',
                    center: coordinates,
                    title: val.address.label
                })
                this.$store.dispatch('flyToMap', { center: coordinates, zoom: 15 })

                this.$store.commit('setMainMapStopAutocenter', true)

                // Radius handler
                if (!val.radius) return

                // Debouce the circle drawing
                if (this.drawCirclesTimeout) clearTimeout(this.drawCirclesTimeout)

                this.drawCirclesTimeout = setTimeout(() => {
                    this.$store.dispatch('drawCircle', {
                        ref: 'vehicles-cross',
                        center: coordinates,
                        radius: val.radius * 1000
                    })
                }, 300)
            },
            deep: true
        }
    },
    computed: {
        startTimeInterval() {
            return this.vehiclesCross?.timeInterval?.start?.clone().utc().format()
        },
        endTimeInterval() {
            return this.vehiclesCross?.timeInterval?.end?.clone().utc().format()
        },
        isMoreThan24Hours() {
            if (!this.vehiclesCross?.timeInterval?.start || !this.vehiclesCross?.timeInterval?.end)
                return false

            return (
                this.vehiclesCross.timeInterval.end.diff(
                    this.vehiclesCross.timeInterval.start,
                    'hours'
                ) > 24
            )
        },
        ...mapState({
            vehiclesCrossSearch: (state) => state.geoFleet.vehiclesCrossSearch,
            vehiclesCrossResults: (state) => state.geoFleet.vehiclesCrossResults
        })
    },
    beforeDestroy() {
        this.$store.dispatch('removeCircle', { ref: 'vehicles-cross' })
        this.$store.dispatch('removeMarker', { ref: 'vehicles-cross' })

        this.$store.commit('setMainMapStopAutocenter', false)
    }
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';

#vehicles-passage-panel ::v-deep(.head),
#vehicles-passage-panel ::v-deep(.body) {
    --side-panels-security-width: 450px;
    width: var(--side-panels-security-width);
}
.no-vehicles {
    border-radius: 25px;
    font-size: 14px;
}
</style>
