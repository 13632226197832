<template>
    <div>
        <b-modal id="modal-saved-trips" scrollable size="lg" body-class="custom-scrollbar">
            <template #modal-title>
                <i class="fa-solid fa-heart"></i> {{ __('planner.savedroutes.saved_routes') }}
            </template>

            <div class="saved-trips" role="tablist">
                <div class="spinner-wrapper" v-if="localSavedRoutes.savedTripsLoading">
                    <Spinner></Spinner>
                </div>

                <div
                    class="single-trip"
                    v-for="(trip, index) in localSavedRoutes.savedTrips"
                    :key="index"
                    @click="localSavedRoutes.selectedTrip = trip"
                >
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            v-model="localSavedRoutes.selectedTrip"
                            :value="trip"
                        />
                    </div>

                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle="'trip-' + index" variant="default w-100">
                                <div>
                                    <p class="text-start mb-1">
                                        <i class="fa-solid fa-fw fa-route"></i> {{ trip.title }}
                                    </p>

                                    <p
                                        class="text-start mb-0 font-normal"
                                        v-if="trip.waypoints.length > 0"
                                    >
                                        <i class="fa-solid fa-fw fa-location-dot ms-5"></i>
                                        <span
                                            class="truncated-text"
                                            v-b-tooltip.hover
                                            :title="trip.waypoints[0].coordinate.label"
                                            >{{
                                                trip.waypoints[0].coordinate.label | truncate(16)
                                            }}...</span
                                        >
                                        -
                                        <span
                                            class="truncated-text"
                                            v-b-tooltip.hover
                                            :title="trip.waypoints.at(-1).coordinate.label"
                                            >{{
                                                trip.waypoints.at(-1).coordinate.label
                                                    | truncate(16)
                                            }}...</span
                                        >
                                        | <i class="fa-solid fa-fw fa-route"></i>
                                        <b class="font-bold">{{
                                            trip.result.distance | metersToKm
                                        }}</b>
                                        | <i class="fa-solid fa-fw fa-timer"></i>
                                        <b class="font-bold">{{
                                            trip.result.travelTime | secondsToTime
                                        }}</b>
                                    </p>
                                </div>

                                <i class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            :id="'trip-' + index"
                            accordion="trips-accordion"
                            role="tabpanel"
                        >
                            <div class="trip-contents mt-3 px-5">
                                <ul
                                    class="trip-waypoints list-unstyled"
                                    v-if="trip.waypoints.length > 0"
                                >
                                    <li v-for="(waypoint, index) in trip.waypoints" :key="index">
                                        {{ waypoint.coordinate.label }}
                                    </li>
                                </ul>
                                <hr />
                                <p
                                    class="d-flex justify-content-between align-items-center"
                                    v-if="trip.result"
                                >
                                    {{ __('planner.extimated_cost') }}
                                    {{ trip.result.monetaryCostsReport.totalCost }} €

                                    <golia-button
                                        @click="deleteSavedTrip(trip)"
                                        :label="__('delete')"
                                        icon="fa-solid fa-trash"
                                        variant="btn-sm btn-ghost danger"
                                    />
                                </p>
                            </div>
                        </b-collapse>
                    </b-card>
                </div>

                <div class="no-trips mt-3" v-if="localSavedRoutes.savedTrips.length === 0">
                    <p class="text-center text-muted">
                        {{ __('planner.savedroutes.no_saved_routes') }}
                    </p>
                </div>

                <b-pagination
                    class="mt-3"
                    v-model="localSavedRoutes.savedTripsPagination.currentPage"
                    :total-rows="localSavedRoutes.savedTripsPagination.totalItems"
                    :per-page="localSavedRoutes.savedTripsPagination.perPage"
                    size="sm"
                    align="right"
                    pills
                    v-if="
                        localSavedRoutes.savedTrips &&
                        localSavedRoutes.savedTripsPagination.totalItems >
                            localSavedRoutes.savedTripsPagination.perPage
                    "
                ></b-pagination>
            </div>

            <template #modal-footer="{ cancel }">
                <golia-button
                    variant="btn-fill dark-blue"
                    @click="cancel()"
                    :label="__('cancel')"
                />

                <golia-button
                    variant="btn-success"
                    @click="$emit('savedRoute', localSavedRoutes.selectedTrip)"
                    :label="__('planner.set_route')"
                    :disabled="localSavedRoutes.selectedTrip === null"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
    name: 'SavedRoutes',
    props: ['value'],
    components: {
        Spinner
    },
    data() {
        return {
            localSavedRoutes: this.value
        }
    },
    methods: {
        deleteSavedTrip(trip) {
            this.$emit('deleteSavedTrip', trip)
        }
    },
    watch: {
        localSavedRoutes() {
            this.$emit('input', this.localSavedRoutes)
        }
    }
}
</script>

<style scoped lang="scss">
.single-trip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .card {
        border: 0px;
        border-radius: 18px;
        background-color: #eaeaea;
        margin-left: 40px;
        flex-grow: 1;
        font-size: 14px;
    }
    .card-header {
        background-color: transparent;
        border: 0px;

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &.not-collapsed i.fa.fa-chevron-down,
            &.collapsed i.fa.fa-chevron-up {
                display: none;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
    .truncated-text {
        text-decoration: underline;
        color: #184172;
    }
}
.trip-waypoints {
    border-left: 1px solid #ccc;
    margin-left: 25px;
    padding-left: 16px;

    li {
        position: relative;
        line-height: 1;
        margin-bottom: 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -22px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #ccc;
        }
    }
}
.saved-trips ::v-deep(.page-item .page-link) {
    color: var(--dark-blue);
}
.saved-trips ::v-deep(.page-item.active .page-link) {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: #fff;
}
</style>
