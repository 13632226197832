import _ from "lodash";
let jws = require("jws");

function loginRedirect() {
    window.location.href = this.auth_url;
}

class AuthProcess {
    Vue = null;

    in_process = false;

    bounce = 0;
    reload_timeout = 0;
    auth_url = null;

    store = null;

    triggerLoginProcess = _.once(loginRedirect);

    constructor(Vue, store, router) {
        this.Vue = Vue;

        this.auth_url = Vue.prototype.$env.AUTH_20_LINK;

        this.store = store;

        store.commit("initialiseLoggedUserStore");

        this.startlisteningRoute(router);
    }

    startlisteningRoute(router) {
        router.addRoute({
            path: "/auth/:token",
            name: "auth.index",
            beforeEnter: async (to, from, next) => {
                const token = to.params.token;

                if (token)
                this.store.commit("updateLoggedUserToken", {
                    logged_user: jws.decode(token),
                    sso_token: token,
                });

                await this.checkAuth();

                // Effettua il redirect al path '/'
                next({ name: "geofleet.index" });
            },
        });

        router.beforeEach(async (to, from, next) => {
            if (to.name.startsWith("error-")) {
                return next();
            }

            if (["auth.index", "viewvehicle.index"].indexOf(to.name) < 0) await this.checkAuth();

            return next();
        });
    }

    async loadRules() {
        let res = await this.Vue.prototype.$fetch(this.Vue.prototype.$env.GOLIA_API + "/licenses");

        let data = await res.json();

        let parsed_data = Object.values(data.data || {});

        let licenses = Object.assign({}, ...parsed_data);

        let rules = Object.keys(licenses).reduce((c, k) => {
        c.push({
            action: licenses[k] ? "use" : "denied",
            subject: k,
        });

        return c;
        }, []);

        this.store.commit("updateLoggedUserRules", rules);
    }

    async checkAuth() {
        if (!this.getSsoToken()) return this.triggerLoginProcess();

        if (!this.store.getters.doIHaveAllUserInfo) {
            let res = await fetch(this.Vue.prototype.$env.GOLIA_API + "/users/me", {
                headers: { Authorization: "Bearer " + this.getSsoToken() },
            });

            let data = await res.json();

            this.store.commit("updateLoggedUserData", {
                tenant_token: data.data.info.signed_tenant_token,
                params: {
                    Azienda: data.data.info.current_company,
                    Lingua: data.data.info.language,
                    tz: data.data.info.iana,
                    timezone: data.data.info.timezone,
                    infringment_nation: data.data.info.infringment_nation,
                },
            });
        }

        if (!this.store.state.loggedUser.rules_loaded) {
            await this.loadRules();

            this.store.state.loggedUser.rules_loaded = true;
        }

        return true;
    }

    getUser() {
        return this.store.state.loggedUser.logged_user;
    }

    getSsoToken() {
        return this.store.state.loggedUser.sso_token;
    }

    getSignedTenantToken() {
        return this.store.state.loggedUser.tenant_token;
    }

    getParams(key) {
        let params = this.store.state.loggedUser.params || {};

        return key ? params[key] : params;
    }
}

export default {
    install: function (Vue, store, router) {
        let authProcess = new AuthProcess(Vue, store, router);

        Vue.prototype.$authProcess = authProcess;

        Vue.prototype.$user = authProcess.getUser.bind(authProcess);
    },
};
