var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"menu"},[(!_vm.sharedView && (_vm.$mq == 'xs' || _vm.$mq == 'sm'))?_c('div',{class:{
                    'collapsible-title': true,
                    'is-active': _vm.page == 'map'
                },on:{"click":function($event){_vm.page = 'map'}}},[_c('i',{staticClass:"fa-solid fa-map"})]):_vm._e(),(!_vm.sharedView)?_c('div',{class:{
                    'collapsible-title': true,
                    'is-active': _vm.page == 'truck'
                },on:{"click":function($event){_vm.page = 'truck'}}},[_c('i',{staticClass:"fa-solid fa-truck"}),(_vm.vehicleAlertsList.length > 0 && (this.$mq == 'xs' || this.$mq == 'sm'))?_c('b-badge',{staticClass:"bg-danger",attrs:{"variant":"danger"}},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation text-white"}),_vm._v(" "+_vm._s(_vm.vehicleAlertsList.length)+" ")]):_vm._e()],1):_vm._e(),(!_vm.sharedView)?_c('div',{class:{
                    'collapsible-title': true,
                    'is-active': _vm.page == 'driver'
                },on:{"click":function($event){_vm.page = 'driver'}}},[_c('i',{staticClass:"fa fa-user"})]):_vm._e()]),_c('div',{staticClass:"content custom-scrollbar",class:{
                'clear-content': _vm.sharedView,
                'first-tab-active':
                    ((_vm.$mq == 'xs' || _vm.$mq == 'sm') && _vm.page == 'map') ||
                    (_vm.$mq != 'xs' && _vm.$mq != 'sm' && _vm.page == 'truck'),
                'center-tab-active': _vm.$mq != 'xs' && _vm.$mq != 'sm' && _vm.page == 'driver'
            }},[(!_vm.sharedView && (_vm.$mq == 'xs' || _vm.$mq == 'sm'))?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.page == 'map'),expression:"page == 'map'"}]},[_c('activity-wrapper',{staticClass:"activity-wrapper",attrs:{"truck":_vm.truck}})],1):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.page == 'truck'),expression:"page == 'truck'"}]},[(_vm.vehicleAlertsList.length > 0 && (_vm.$mq == 'xs' || _vm.$mq == 'sm'))?_c('div',{staticClass:"vehicle-alerts bg-dark-blue mt-3 mb-4 p-2 rounded"},_vm._l((_vm.vehicleAlertsList),function(alert,index){return _c('div',{key:index,staticClass:"d-flex align-items-center gap-3",attrs:{"show":""}},[_c('i',{staticClass:"text-white",class:alert.icon}),_c('span',{staticClass:"text-white text-smaller ms-2"},[_vm._v(" "+_vm._s(_vm.__(alert.problem))+" ")])])}),0):_vm._e(),_c('small',{staticClass:"d-block mt-2 mb-1 ps-1 text-muted"},[_vm._v(_vm._s(_vm.__('geofleet.detailspanel.bind_vehicle')))]),_c('golia-multiselect',{staticClass:"mb-2",attrs:{"label":"name","track-by":"id","emptyLabel":_vm.__('empty_search'),"show-labels":false,"outsideTagsSelected":true,"customValueLabel":{
                        singular: _vm.__('geofleet.detailspanel.binded_tag'),
                        plural: _vm.__('geofleet.detailspanel.binded_tags')
                    },"tag-placeholder":_vm.__('geofleet.detailspanel.add_new_tag'),"placeholder":_vm.__('geofleet.detailspanel.select_new_tag') + '...',"selectLabel":_vm.__('select'),"selectedLabel":_vm.__('selected'),"deselectLabel":_vm.__('deselect'),"options":_vm.$store.state.geoFleet.companyTags,"multiple":true,"close-on-select":false,"taggable":true,"customSelectedLabel":{
                        singular: _vm.__('geofleet.detailspanel.binded_tag'),
                        plural: _vm.__('geofleet.detailspanel.binded_tags')
                    },"id":'groups-of-' + _vm.truck.id,"refer":'groups-of' + _vm.truck.id},on:{"select":(option, id) => {
                            _vm.handleTag(option, id, 'add')
                        },"remove":(option, id) => {
                            _vm.handleTag(option, id, 'remove')
                        },"tag":_vm.addNewTag},model:{value:(_vm.localTags),callback:function ($$v) {_vm.localTags=$$v},expression:"localTags"}}),(_vm.truck.parameters && _vm.lock_command)?_c('cloud-panel',{ref:"commandsCloud",attrs:{"ignore-null-value":true,"data":_vm.commandsCloud,"highlighted":true},on:{"switchInputChange":_vm.switchInputChange,"buttonClick":_vm.commandsClick}}):_vm._e(),(_vm.is_truck_hooked(this.truck.plate))?_c('div',{staticClass:"cloud-vehicle-hooked"},[_c('div',{staticClass:"d-flex align-items-center gap-3"},[_c('i',{staticClass:"fa-regular fa-lg fa-link-horizontal"}),_c('span',[_vm._v(" "+_vm._s(_vm.get_type_vehicle_hooked(this.truck.plate) == 'truck' ? _vm.__('truck_hooked_text') : _vm.__('trailer_hooked_text'))+" "),_c('b',[_vm._v(_vm._s(_vm.get_vehicle_hooked(this.truck.plate)))])])])]):_vm._e(),(_vm.truck.lastpoint)?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('info'),"data":_vm.infoCloud}}):_vm._e(),(_vm.vehicleTemperatureProbes)?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('temperature.title'),"data":_vm.vehicleTemperatureProbes}}):_vm._e(),(
                        _vm.$can('use', 'telemetry') &&
                        _vm.deviceCloud &&
                        Object.entries(_vm.deviceCloud).length > 0 &&
                        _vm.truck?.lastpoint?.telemetry?.device_timestamp
                    )?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('device'),"data":_vm.deviceCloud}}):_vm._e(),_c('transition',{attrs:{"name":"common-fade"}},[(_vm.$can('use', 'telemetry') && !_vm.sharedView)?_c('telemetry-cloud-panel',{attrs:{"truck":_vm.truck},on:{"telemetriesLoaded":_vm.telemetriesLoaded}}):_vm._e()],1)],1),(!_vm.sharedView)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.page == 'driver'),expression:"page == 'driver'"}]},[(_vm.truck.lastpoint.driver)?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('driver') + ' 1',"data":{
                        driver: _vm.checkCardDriver,
                        card: { value: _vm.truck.lastpoint.driver.card }
                    }}},[_c('div',{staticClass:"tiny-plafond"},[_c('tiny-plafond',{attrs:{"driver":_vm.truck.lastpoint.driver}})],1)]):_vm._e(),(_vm.truck.lastpoint.codriver)?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('driver') + ' 2',"data":{
                        driver: _vm.checkCardCodriver,
                        card: { value: _vm.truck.lastpoint.codriver.card }
                    }}},[_c('div',{staticClass:"tiny-plafond"},[_c('tiny-plafond',{attrs:{"driver":_vm.truck.lastpoint.codriver}})],1)]):_vm._e(),(
                        !(_vm.truck.lastpoint.driver || _vm.truck.lastpoint.codriver) &&
                        _vm.truck.lastpoint.last_driver
                    )?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('last_driver_detected'),"data":{
                        driver: _vm.checkCardLastDriver,
                        card: { value: _vm.truck.lastpoint.last_driver.card },
                        data: {
                            value: _vm.moment(_vm.truck.lastpoint.last_driver_date, 'UTC').format(
                                'L HH:mm:ss'
                            )
                        }
                    }}},[_c('div',{staticClass:"tiny-plafond"},[_c('tiny-plafond',{attrs:{"driver":_vm.truck.lastpoint.last_driver}})],1)]):_vm._e(),(
                        !_vm.truck.lastpoint.driver &&
                        !_vm.truck.lastpoint.codriver &&
                        !_vm.truck.lastpoint.last_driver &&
                        _vm.truck.lastpoint.rfid
                    )?_c('cloud-panel',{attrs:{"ignore-null-value":true,"title":_vm.__('driver').toUpperCase(),"icon":'<i class=\'fa-solid fa-id-badge\'></i>',"rfid":!_vm.truck.lastpoint.rfid.driver,"data":{
                        driver: _vm.checkRfidCardDriver,
                        card: {
                            value:
                                _vm.truck.lastpoint.rfid?.driver?.rfid_code ??
                                _vm.truck.lastpoint.rfid?.card_id
                        },
                        timeStamp: {
                            value: _vm.moment(
                                parseInt(_vm.truck.lastpoint.rfid.timestamp.$date.$numberLong),
                                'UTC'
                            ).format('L HH:mm')
                        },
                        state: {
                            value: _vm.__(_vm.truck.lastpoint.rfid?.report_id)
                        }
                    }}},[(
                            _vm.truck.lastpoint.rfid.driver &&
                            _vm.truck.lastpoint.rfid.driver.card_type != 112
                        )?_c('div',{staticClass:"tiny-plafond"},[_c('tiny-plafond',{attrs:{"driver":_vm.truck.lastpoint.rfid.driver}})],1):_vm._e()]):_vm._e(),(
                        !_vm.truck.lastpoint.driver &&
                        !_vm.truck.lastpoint.codriver &&
                        !_vm.truck.lastpoint.last_driver &&
                        !_vm.truck.lastpoint.rfid
                    )?_c('p',{staticClass:"text-center p-5"},[_vm._v(" "+_vm._s(_vm.__('no_driver'))+" ")]):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }