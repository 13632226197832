import { defineAbility } from '@casl/ability'
import moment from 'moment'

let local_storage_store = 'logged_user_store'

function getDataFromCookies() {
    let cookies =
        document && document.cookie
            ? document.cookie.split('; ').reduce(function (c, s) {
                  var kv = s.split('=')
                  var value = null

                  if (kv[0] == 'golia2vars') {
                      value = JSON.parse(atob(decodeURIComponent(kv[1].trim())))
                  } else {
                      value = kv[1].trim()
                  }

                  c[kv[0]] = value

                  return c
              }, {})
            : {}

    if (cookies.golia2vars && cookies._GOLIA5SSO_ && cookies._GOLIASIGNEDTENANT_) {
        return {
            sso_token: cookies._GOLIA5SSO_,
            tenant_token: cookies._GOLIASIGNEDTENANT_,
            params: cookies.golia2vars
        }
    }

    return null
}

function updateLocalStorage(update) {
    let data = sessionStorage.getItem(local_storage_store)

    data = data ? JSON.parse(data) : {}

    data = Object.assign(data, update)

    data.timestamp = moment().utc().valueOf()

    sessionStorage.setItem(local_storage_store, JSON.stringify(data))
}

function updateCurrentState(state, payload) {
    state.timestamp = moment().utc().valueOf()

    updateLocalStorage(payload)
}

export default {
    state: () => ({
        ability: defineAbility((can, cannot) => {
            cannot('manage', 'all')
        }),
        logged_user: null,
        sso_token: null,
        tenant_token: null,
        params: {},
        timestamp: null,
        rules_loaded: false
    }),
    getters: {
        ability: (state) => state.ability,
        doIHaveAllUserInfo: (state) =>
            state.sso_token && state.tenant_token && Object.keys(state.params).length
    },
    mutations: {
        updateRulesLoaded: (state, rules_loaded) => {
            state.rules_loaded = rules_loaded

            updateCurrentState(state, {
                rules_loaded
            })
        },
        updateLoggedUserRules: (state, rules) => {
            state.ability.update(rules)

            updateCurrentState(state, {
                rules
            })
        },
        updateLoggedUserToken: (state, payload) => {
            state.logged_user = payload.logged_user
            state.sso_token = payload.sso_token

            updateCurrentState(state, payload)
        },
        updateLoggedUserData: (state, payload) => {
            state.tenant_token = payload.tenant_token
            state.params = payload.params

            updateCurrentState(state, payload)
        },
        updateLoggedUserParams(state, params) {
            state.params = params

            updateCurrentState(state, {
                params
            })
        },
        initialiseLoggedUserStore: function (state) {
            let data = sessionStorage.getItem(local_storage_store)

            data = data ? JSON.parse(data) : {}

            if (!(data.sso_token && data.tenant_token && data.params)) {
                data = getDataFromCookies()
            }

            if (data) {
                state.logged_user = data.logged_user
                state.sso_token = data.sso_token
                state.tenant_token = data.tenant_token
                state.params = data.params

                state.timestamp = moment().utc().valueOf()
            }
        }
    }
}
