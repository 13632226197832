<template>
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h3 class="d-none">Oops! Sembra che ti manchino dei permessi.</h3>
                <h1><span>4</span><span>0</span><span class="text-golia">3</span></h1>
            </div>
            <p>
                {{ __('error_pages.error_403.description') }}
            </p>
            <div
                class="d-flex flex-column flex-md-row px-4 px-md-0 justify-content-center gap-0 gap-md-4"
            >
                <golia-button
                    class="mt-3"
                    size="lg"
                    variant="btn-fill golia"
                    :label="__('error_pages.error_403.retry_login')"
                    @click="loginRetray"
                />
                <golia-button
                    class="mt-3"
                    size="lg"
                    variant="btn-fill dark-blue"
                    :label="__('error_pages.error_403.contact_us')"
                    @click="goToContacts"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPermissions2',
    methods: {
        loginRetray() {
            this.$authProcess.triggerLoginProcess()
        },
        goToContacts() {
            // Open https://www.goliaweb.it/contact page on a new tab
            window.open('https://www.goliaweb.it/contact', '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
#notfound {
    position: relative;
    height: 100%;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}

.notfound .notfound-404 {
    position: relative;
    height: 340px;
}

.notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
    text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
}

.notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
}

@media only screen and (max-width: 991px) {
    .notfound .notfound-404 {
        height: 200px;
    }
    .notfound .notfound-404 h1 {
        font-size: 200px;
    }
}

@media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
        height: 162px;
    }
    .notfound .notfound-404 h1 {
        font-size: 162px;
        height: 150px;
        line-height: 162px;
    }
    .notfound h2 {
        font-size: 16px;
    }
}
</style>
