var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"print-directives"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-wrapper"},[_c('Spinner')],1),_c('a',{staticClass:"golia-logo",attrs:{"target":"_blank","href":"https://goliaweb.it"}},[_c('img',{attrs:{"src":_vm.$mq == 'xs' || _vm.$mq == 'sm' ? _vm.goliaMobileLogo : _vm.goliaFullLogo,"alt":"Powered by Golia"}})]),_c('geofleet-map',{ref:"map",staticClass:"geofleet-map shared-link-map",class:{ 'geofleet-map--focus': _vm.focus_truck },attrs:{"map":"osm","advanced":false,"cluster":true,"ptvToken":'shared',"trucks":_vm.trucks,"poi":false,"menu":false,"mode":'shared-link'},on:{"focus":_vm.truckFocus}},[((_vm.focus_truck || {}).showingRoute)?_c('l-marker-cluster',{attrs:{"options":_vm.clusterOpt}},_vm._l((_vm.geojson),function(opt){return _c('l-geo-json',{key:opt.id,ref:'geojson_' + opt.id,refInFor:true,attrs:{"geojson":opt.geojson,"optionsStyle":opt.style || {},"options":opt.options || {}},on:{"ready":_vm.geoJsonLoaded}})}),1):_vm._e()],1),_c('div',{staticClass:"panel-box"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.focus_truck)?_c('details-panel',{ref:"details_panel",staticClass:"details-panel shared-link-panel",class:{
                    mobile: _vm.$mq == 'xs' || _vm.$mq == 'sm',
                    'with-trucks': _vm.trucks.length > 1,
                    'with-scrollbar': _vm.mainPanelHasScrollbar
                },attrs:{"truck":_vm.focus_truck,"rangeData":_vm.rangeData,"collapsed":_vm.$mq == 'xs' || _vm.$mq == 'sm',"closable":_vm.trucks.length > 1 ? true : false},on:{"lostfocus":_vm.lostFocus,"rangeUpdate":(date) => {
                        _vm.rangeData = date
                    }}}):_vm._e()],1)],1),(_vm.trucks.length > 1)?_c('main-panel',{ref:"main_panel",staticClass:"main-panel shared-link-panel horizontal-variant",class:_vm.mainPanelHasScrollbar ? 'has-scrollbar' : '',attrs:{"trucks":_vm.trucks,"drivers":{},"companies":{},"collapsed":true,"last_update":_vm.last_update,"direction":'horizontal'},on:{"focus":_vm.truckFocus,"pinTruck":_vm.pinMyTruck,"searching":_vm.boundVisibleTruck}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }