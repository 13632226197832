/* eslint-disable */
import PtvXserver from 'leaflet-xserver'
/* eslint-enable */


let templates = {
    'maxPermittedWeight': {
        parser: (v) => {
            return parseInt(v) / 100
        },
        um: 't'
    },
    'minPermittedWeight': {
        parser: (v) => {
            return parseInt(v) / 100
        },
        um: 't'
    },
    'zoneName': {
    },
    'unauthorizedType': {
    },
    'maxEmissionStandardEU': {
        parser: (v) => {
            return 'EURO ' + v
        }
    },
    'fuelType': {
        parser: (v) => {
            let types = ['Gasoline', 'Diesel', 'Petrol', 'ELectric', 'Hydrogen', 'Hybrid', 'Plugin Hybrid', 'LPG', 'LNG', 'CNG']

            return types[v] || v
        }
    },
    'loadType': {
        parser: (v) => {
            let types = ['Passengers', 'Goods']

            return types[v] || v
        }
    },
    'opening': {
        parser: (v) => {
            return parseInt(v) ? 'Closed' : 'Open'
        }
    },
    'maxHeight': {
        parser: (v) => {
            return parseInt(v) / 100
        },
        um: 'm'
    },
    'maxWeight': {
        parser: (v) => {
            return parseInt(v) / 1000
        },
        um: 't'
    },
    'maxWidth': {
        parser: (v) => {
            return parseInt(v) / 100
        },
        um: 'm'
    },
    'maxLength': {
        parser: (v) => {
            return parseInt(v) / 100
        },
        um: 'm'
    },
    'absoluteSpeed': {
        um: 'Km/h'
    },
    'hazardousToWaters': {
        parser: (v) => {
            return ''
        }
    },
    'hazardousGoods': {
        parser: (v) => {
            return ''
        }
    },
    'combustibles': {
        parser: (v) => {
            return ''
        }
    },
    'maxAxleLoad': {
        parser: (v) => {
            return parseInt(v) / 1000
        },
        um: 't'
    },
    'totalPermittedWeight': {
        parser: (v) => {
            return parseInt(v) / 1000
        },
        um: 't'
    },
    'tunnelRestriction': {
    },
    'kingpinToRearAxle': {
        um: 'cm'
    },
    'numberOfAxles': {
    },
    'message.text': {
        priority: true
    },
    'length': {
        um: 'm'
    },
    'delay': {
        um: 'min',
    }
}

export default function (L, fn, lang_prefix) {
    L.TileLayer.XServer.prototype.buildDescriptionText = (found) => {
        var description = [];

        for (var layer in found) {
            let c_description = []

            for (var i = 0; i < found[layer].attributes.length; i++) {
                var attribute = found[layer].attributes[i]

                let key = templates[attribute.key]

                if (!key)
                    continue

                let title = fn((lang_prefix || '') + attribute.key)

                let row = '<div><b>' + (title ? title : attribute.key) + '</b></div><div>' + (key.parser ? key.parser(attribute.value) : attribute.value) + (key.um ? ' ' + key.um : '') + '</div>'

                key.priority ? c_description.unshift(row) : c_description.push(row)
            }

            description.push(c_description.filter(d => d).join(''))
        }

        return '<div class="d-grid" style="grid-template-columns: auto auto; column-gap: 8px">' + description.join('<div style="padding-bottom: 14px;"></div><div></div>') + '</div>'
    }
}
