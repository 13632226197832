import Vue, { computed, ref, unref, watchEffect } from 'vue'
import store from '@/store'

export function useVehicleIdentification(vehicle) {
    const identification = ref('')
    // const config = ref(store.state.geoFleet.config.vehicleIdentification)
    const vehicleIdentification = computed(() => store.state.geoFleet.config?.vehicleIdentification)
    
    watchEffect(() => {
            identification.value = unref(vehicle)?.details?.custom_id || unref(vehicle).plate

            if (vehicleIdentification.value) {
                if (vehicleIdentification.value == 'customId') {
                    identification.value = unref(vehicle).details.custom_id || unref(vehicle).plate
                } else if (vehicleIdentification.value == 'driver') {
                    let validDriver = ''

                    if (unref(vehicle).lastpoint.driver && unref(vehicle).lastpoint.driver.id > 1) {
                        validDriver = unref(vehicle).lastpoint.driver
                    } else if (
                        unref(vehicle).lastpoint.codriver &&
                        unref(vehicle).lastpoint.codriver.id > 1
                    ) {
                        validDriver = unref(vehicle).lastpoint.codriver
                    } else {
                        if (unref(vehicle).type == 3)
                            validDriver = { name: unref(vehicle).plate, surname: null }
                        else validDriver = { name: null, surname: null }
                    }

                    if (!validDriver.name && !validDriver.surname) {
                        identification.value = '- ' + Vue.$__('no_card') + ' -'
                    } else {
                        identification.value =
                            truncateString(validDriver.name || '--', 10, '...') +
                            ' ' +
                            truncateString(validDriver.surname || '--', 10, '...')
                    }
                } else {
                    identification.value = unref(vehicle).plate
                }
            }
        }
    )

    return {
        identification
    }
}

function truncateString(str, num, add) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, add) + '...'
}