<template>
    <div class="truck-filters">
        <b-tabs nav-wrapper-class="golia-classic-tabs mb-3">
            <b-tab active>
                <template #title>
                    <span> <i class="fa-solid fa-steering-wheel"></i> {{ __('vehicle') }} </span>
                    <b-badge
                        v-if="filterByVehicleLength"
                        class="indicator position-absolute rounded-pill top-0 mt-2"
                    >
                        {{ filterByVehicleLength }}
                    </b-badge>
                </template>

                <!-- Search by tags -->
                <template v-if="storeCompanyTags">
                    <p>{{ __('geofleet.mainpanel.search_trucks_tags_title') }}</p>

                    <div class="bg-main-inner-bg p-3 rounded-3 mb-4">
                        <golia-multiselect
                            v-model="localActiveFilters.tags"
                            label="name"
                            track-by="id"
                            class="no-tags flex-grow-1"
                            :show-labels="false"
                            :placeholder="__('select')"
                            :options="storeCompanyTags"
                            :customSelectedLabel="true"
                            :multiple="true"
                            :closeOnSelect="false"
                            :emptyLabel="__('empty_search')"
                            refer="tagsFilters"
                        />
                    </div>
                </template>

                <p>{{ __('vehicle') }}</p>

                <div class="bg-main-inner-bg p-3 rounded-3 mb-4">
                    <!-- MOTORE -->
                    <div class="form-group align-items-center mb-3">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="engine_off"
                                        type="radio"
                                        v-model="localActiveFilters.engine_rpm"
                                        :value="1"
                                    />
                                    <label class="form-check-label" for="engine_off"
                                        ><i class="fa-solid fa-fw fa-power-off text-success"></i> -
                                        {{ __('engine_on') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('engine_rpm', 0)"
                                        v-if="localActiveFilters.engine_rpm == 1"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="engine_on"
                                        type="radio"
                                        v-model="localActiveFilters.engine_rpm"
                                        :value="2"
                                    />
                                    <label class="form-check-label" for="engine_on"
                                        ><i class="fa-solid fa-fw fa-power-off text-danger"></i> -
                                        {{ __('engine_off') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('engine_rpm', 0)"
                                        v-if="localActiveFilters.engine_rpm == 2"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <!-- QUADRO ACCESO -->
                    <div class="form-group align-items-center mb-3">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="ignition_on"
                                        type="radio"
                                        v-model="localActiveFilters.ignition"
                                        :value="1"
                                    />
                                    <label class="form-check-label" for="ignition_on"
                                        ><i class="fa-solid fa-fw fa-lightbulb-on text-success"></i>
                                        - {{ __('ignition_on') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('ignition', 0)"
                                        v-if="localActiveFilters.ignition == 1"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="ignition_off"
                                        type="radio"
                                        v-model="localActiveFilters.ignition"
                                        :value="2"
                                    />
                                    <label class="form-check-label" for="ignition_off"
                                        ><i class="fa-solid fa-fw fa-lightbulb text-danger"></i> -
                                        {{ __('ignition_off') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('ignition', 0)"
                                        v-if="localActiveFilters.ignition == 2"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <!-- IN MOVIMENTO -->
                    <div class="form-group align-items-center">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="moving"
                                        type="radio"
                                        v-model="localActiveFilters.moving"
                                        :value="1"
                                    />
                                    <label class="form-check-label" for="moving"
                                        ><i class="fa-solid fa-fw fa-gauge-max text-success"></i> -
                                        {{ __('moving') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('moving', 0)"
                                        v-if="localActiveFilters.moving == 1"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        id="stopped"
                                        type="radio"
                                        v-model="localActiveFilters.moving"
                                        :value="2"
                                    />
                                    <label class="form-check-label" for="stopped"
                                        ><i class="fa-solid fa-fw fa-gauge-min text-danger"></i> -
                                        {{ __('stopped') }}</label
                                    >

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="toggleRadio('moving', 0)"
                                        v-if="localActiveFilters.moving == 2"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab>
                <template #title>
                    <span> <i class="fa-kit fa-user-card"></i> {{ __('driver') }} </span>
                    <b-badge
                        v-if="filterByDriverLength"
                        class="indicator position-absolute rounded-pill top-0 mt-2"
                    >
                        {{ filterByDriverLength }}</b-badge
                    >
                </template>

                <p>{{ __('activity') }}</p>

                <div class="bg-main-inner-bg p-3 rounded-3 mb-4">
                    <div
                        class="form-group d-flex gap-3 align-items-center justify-content-between mb-3"
                    >
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                id="activity-inactive"
                                :value="''"
                                v-model="localActiveFilters.activity"
                            />
                            <label class="form-check-label" for="activity-inactive">{{
                                __('none')
                            }}</label>

                            <!-- Reset filter button -->
                            <button
                                class="btn btn-link btn-sm p-0"
                                @click="toggleRadio('activity', 4)"
                                v-if="localActiveFilters.activity === ''"
                            >
                                <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                            </button>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                id="activity-at-rest"
                                :value="0"
                                v-model="localActiveFilters.activity"
                            />
                            <label class="form-check-label" for="activity-at-rest"
                                ><i
                                    class="fa-solid fa-fw fa-bed"
                                    style="color: var(--rest-color)"
                                ></i>
                                {{ __('rest') }}</label
                            >

                            <!-- Reset filter button -->
                            <button
                                class="btn btn-link btn-sm p-0"
                                @click="toggleRadio('activity', 4)"
                                v-if="localActiveFilters.activity === 0"
                            >
                                <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                            </button>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                id="activity-available"
                                :value="1"
                                v-model="localActiveFilters.activity"
                            />
                            <label class="form-check-label" for="activity-available"
                                ><i
                                    class="fak fa-availability"
                                    style="color: var(--availability-color)"
                                ></i>
                                {{ __('availability') }}</label
                            >

                            <!-- Reset filter button -->
                            <button
                                class="btn btn-link btn-sm p-0"
                                @click="toggleRadio('activity', 4)"
                                v-if="localActiveFilters.activity == 1"
                            >
                                <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                            </button>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                id="activity-at-work"
                                :value="2"
                                v-model="localActiveFilters.activity"
                            />
                            <label class="form-check-label" for="activity-at-work"
                                ><i class="fak fa-work" style="color: var(--work-color)"></i>
                                {{ __('work') }}</label
                            >

                            <!-- Reset filter button -->
                            <button
                                class="btn btn-link btn-sm p-0"
                                @click="toggleRadio('activity', 4)"
                                v-if="localActiveFilters.activity == 2"
                            >
                                <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                            </button>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                id="activity-driving"
                                :value="3"
                                v-model="localActiveFilters.activity"
                            />
                            <label class="form-check-label" for="activity-driving"
                                ><i
                                    class="fa-solid fa-steering-wheel"
                                    style="color: var(--driving-color)"
                                ></i>
                                {{ __('driving') }}</label
                            >

                            <!-- Reset filter button -->
                            <button
                                class="btn btn-link btn-sm p-0"
                                @click="toggleRadio('activity', 4)"
                                v-if="localActiveFilters.activity == 3"
                            >
                                <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                            </button>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <div class="row">
                        <!-- GUIDA IN EQUIPAGGIO -->
                        <div class="col-6 d-flex align-items-center mb-2">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.codriving"
                                    id="codriving-filter"
                                />
                                <label class="form-check-label" for="codriving-filter">
                                    <i class="fa-solid fa-user-group text-success"></i>
                                    {{ __('codriving') }}
                                </label>

                                <!-- Reset filter button -->
                                <button
                                    class="btn btn-link btn-sm p-0"
                                    @click="localActiveFilters.codriving = false"
                                    v-if="localActiveFilters.codriving"
                                >
                                    <i class="fa-solid fa-lg fa-times-circle text-dark-blue"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <p>{{ __('driver_plafond') }}</p>

                <div class="bg-main-inner-bg p-3 rounded-3 mb-4">
                    <!-- IMPEGNO RESIDUO -->
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-2 d-none">
                            <div class="form-group w-100">
                                <label for="residual-commitment-filter">{{
                                    __('residual_commitment_type')
                                }}</label>
                                <golia-input
                                    type="select"
                                    v-model="localActiveFilters.residual_commitment_type"
                                    :options="[
                                        { value: 'daily', text: 'Giornaliero' },
                                        { value: 'week', text: 'Settimanale' }
                                    ]"
                                />
                            </div>
                        </div>
                        <div class="col-12 d-flex align-items-center mb-2">
                            <div class="form-group w-100">
                                <label for="residual-commitment-filter">{{
                                    __('residual_commitment')
                                }}</label>

                                <div class="d-flex gap-2">
                                    <golia-input
                                        v-model="localActiveFilters.residual_commitment"
                                        :placeholder="__('commitment_hours')"
                                        style="min-width: auto; flex-grow: 1"
                                    />

                                    <!-- Reset filter button -->
                                    <button
                                        class="btn btn-link btn-sm p-0"
                                        @click="localActiveFilters.residual_commitment = null"
                                        v-if="localActiveFilters.residual_commitment"
                                    >
                                        <i
                                            class="fa-solid fa-lg fa-times-circle text-dark-blue"
                                        ></i>
                                    </button>
                                </div>

                                <small class="form-text text-tiny">
                                    {{ __('commitment_hours_description') }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab>
                <template #title>
                    <span>
                        <i class="fa-solid fa-exclamation-triangle"></i> {{ __('alerts') }}
                    </span>
                    <b-badge
                        v-if="filterByAlertsLength"
                        class="indicator position-absolute rounded-pill top-0 mt-2"
                    >
                        {{ filterByAlertsLength }}
                    </b-badge>
                </template>

                <p>{{ __('alerts') }}</p>

                <div class="bg-main-inner-bg p-3 rounded-3 mb-4">
                    <div class="row mb-3">
                        <!-- LIVELLO CARBURANTE BASSO -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.low_fuel"
                                    id="low-fuel-filter"
                                />
                                <label class="form-check-label" for="low-fuel-filter">
                                    <i class="fa-solid fa-fw fa-gas-pump text-danger"></i>
                                    {{ __('low_fuel_level') + ' ( <20% )' }}
                                </label>
                            </div>
                        </div>

                        <!-- LIVELLO BATTERIA BASSO -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.low_battery"
                                    id="low-battery-filter"
                                />
                                <label class="form-check-label" for="low-battery-filter">
                                    <i class="fa-solid fa-fw fa-battery-quarter text-danger"></i>
                                    {{ __('low_battery_level') }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <div class="row mb-3">
                        <!-- OVERSPEED TACHIGRAFO -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.tacho_overspeed"
                                    id="tacho-overspeed-filter"
                                />
                                <label class="form-check-label" for="tacho-overspeed-filter">
                                    <i class="fa-solid fa-fw fa-gauge-max text-danger"></i>
                                    {{ __('tacho_overspeed') }}
                                </label>
                            </div>
                        </div>

                        <!-- ALIMENTAZIONE ASSENTE -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.no_power_supply"
                                    id="no-power-supply-filter"
                                />
                                <label class="form-check-label" for="no-power-supply-filter">
                                    <i
                                        class="fa-solid fa-fw fa-battery-exclamation text-danger"
                                    ></i>
                                    {{ __('no_power_supply') }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <div class="row mb-3">
                        <!-- NO SEGNALE GPS -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.no_gps"
                                    id="no-gps-filter"
                                />
                                <label class="form-check-label" for="no-gps-filter">
                                    <i class="fa-solid fa-fw fa-signal-bars-slash text-danger"></i>
                                    {{ __('no_gps') }}
                                </label>
                            </div>
                        </div>

                        <!-- NO PUNTI PER 24 ORE -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.no_points"
                                    id="no-points-filter"
                                />
                                <label class="form-check-label" for="no-points-filter">
                                    <i
                                        class="fa-solid fa-fw fa-exclamation-triangle text-danger"
                                    ></i>
                                    {{ __('no_points_title') }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="divider thin dark-blue" />

                    <div class="row">
                        <!-- ERRORE ORARIO TACHIGRAFO -->
                        <div class="col-6 d-flex align-items-center">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.tacho_clock_error"
                                    id="tacho-clock-error-filter"
                                />
                                <label class="form-check-label" for="tacho-clock-error-filter">
                                    <i class="fa-solid fa-fw fa-binary-slash text-danger"></i>
                                    {{ __('tacho_clock_error') }}
                                </label>
                            </div>
                        </div>

                        <!-- GUIDA SENZA CARTA -->
                        <div class="col-6 d-flex align-items-center mb-2">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="localActiveFilters.no_card"
                                    id="no-card-filter"
                                />
                                <label class="form-check-label" for="no-card-filter">
                                    <i class="fak fa-user-nocard text-danger"></i>
                                    {{ __('no_card_driving') }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab>
                <template #title>
                    <span> <i class="fa-solid fa-radar"></i> {{ __('other') }} </span>
                    <b-badge
                        v-if="filterByOperationsLength"
                        class="indicator position-absolute rounded-pill top-0 mt-2"
                    >
                        {{ filterByOperationsLength }}
                    </b-badge>
                </template>
                <!-- Search near vehicles -->
                <p>{{ __('geofleet.mainpanel.search_near_trucks_title') }}</p>
                <golia-waypoint-search v-model="localNearVehicles.searchLocation" />

                <div class="row">
                    <div class="col-9 col-12">
                        <golia-input
                            class="my-2"
                            v-model="localNearVehicles.areaSearch"
                            :placeholder="__('planner.resources.area_search') + ' (Km)'"
                        ></golia-input>
                    </div>
                    <div class="col-3 d-none">
                        <golia-input
                            class="my-2"
                            v-model="localNearVehicles.areaUnit"
                            type="select"
                            :options="[
                                { value: 'km', text: 'Km' },
                                { value: 'min', text: __('minutes') }
                            ]"
                        />
                    </div>
                </div>

                <hr class="d-none" />

                <!-- Includi Plafond nella ricerca -->
                <div class="form-check d-flex align-items-center gap-2 d-none">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="localNearVehicles.includePlafond"
                        @click="
                            localNearVehicles.includePlafond = !localNearVehicles.includePlafond
                        "
                        id="checkPlafondNearVehicles"
                    />
                    <label
                        class="form-check-label w-100"
                        for="checkPlafondNearVehicles"
                        style="margin-bottom: -3px"
                    >
                        {{ __('planner.include_plafond') }}

                        <i
                            class="fa-solid fa-circle-question text-primary"
                            id="popover-plafond-near-vehicles"
                        ></i>
                        <b-popover
                            target="popover-plafond-near-vehicles"
                            triggers="hover"
                            placement="top"
                            variant="info"
                        >
                            {{ __('planner.include_plafond_near_vehicles') }}
                        </b-popover>
                    </label>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    name: 'FilterTrucks',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        nearVehicles: {
            type: Object,
            default: () => ({
                active: false,
                searchLocation: null,
                searchResults: [],
                searchedVehicles: [],
                areaSearch: null,
                areaUnit: 'km'
            })
        }
    },
    data() {
        return {
            localActiveFilters: this.value,
            localNearVehicles: this.nearVehicles
        }
    },
    metaInfo() {
        return {
            script: [
                {
                    src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDHi20jpKVCTkdIfgWXUQFk56x4FEBh6D4&libraries=places`,
                    async: true,
                    defer: true,
                    callback: () => this.GoogleMapsInit()
                }
            ]
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.localActiveFilters = val
            },
            deep: true
        },
        localActiveFilters: {
            handler: function (val) {
                this.$emit('input', val)
            },
            deep: true
        },
        nearVehicles: {
            handler: function (val) {
                this.localNearVehicles = val
            },
            deep: true
        }
    },
    methods: {
        toggleRadio(field, default_value) {
            if (this.localActiveFilters[field] !== default_value) {
                this.localActiveFilters[field] = default_value
            }
        },
        searchAddress(search, loading) {
            let _this = this

            if (search) {
                loading(true)

                if (search != '') this.gMapLocate(loading, search, this)
                else {
                    this.$nextTick(() => {
                        _this.localNearVehicles.searchLocation = null
                        _this.localNearVehicles.searchResults = []
                    })
                }
            }
        },
        async optionSelectHandler(option) {
            if (option) {
                option.referenceCoordinate = await this.gMapPlace(option)
            }
        },
        GoogleMapsInit() {
            this.$store.commit('setGmaps', window.google.maps.places)
        },
        gMapLocate: _.debounce((loading, search, vm) => {
            let autocompleteService = new vm.gmaps.AutocompleteService()

            var sessionToken = new vm.gmaps.AutocompleteSessionToken()

            autocompleteService.getPlacePredictions(
                {
                    input: search,
                    sessionToken: sessionToken,
                    language: vm.$locale
                },
                (res) => {
                    loading(false)

                    vm.localNearVehicles.searchResults = res.map((r) => {
                        return {
                            placeId: r.place_id,
                            label: r.description,
                            sessionToken: sessionToken,
                            referenceCoordinate: { x: 0, y: 0 }
                        }
                    })
                }
            )
        }, 250),
        gMapPlace: function (option) {
            return new Promise((resolved, rejected) => {
                let placeService = new this.gmaps.PlacesService(document.createElement('div'))

                placeService.getDetails(
                    {
                        placeId: option.placeId,
                        sessionToken: option.sessionToken,
                        fields: ['geometry.location']
                    },
                    (res, status) => {
                        if (status == 'OK')
                            return resolved({
                                x: res.geometry.location.lng(),
                                y: res.geometry.location.lat()
                            })

                        rejected(status)
                    }
                )
            })
        }
    },
    computed: {
        filterByOperationsLength() {
            return this.nearVehicles.areaSearch && this.nearVehicles.searchLocation ? 1 : 0
        },
        filterByDriverLength() {
            let count = 0
            if (this.localActiveFilters.activity != 4) {
                count++
            }
            if (this.localActiveFilters.codriving) {
                count++
            }
            if (this.localActiveFilters.residual_commitment) {
                count++
            }
            return count
        },
        filterByVehicleLength() {
            let count = 0
            if (this.localActiveFilters.tags.length > 0) {
                count++
            }
            if (this.localActiveFilters.moving != 0) {
                count++
            }
            if (this.localActiveFilters.engine_rpm != 0) {
                count++
            }
            if (this.localActiveFilters.ignition) {
                count++
            }
            return count
        },
        filterByAlertsLength() {
            let count = 0
            if (this.localActiveFilters.low_fuel) {
                count++
            }
            if (this.localActiveFilters.low_battery) {
                count++
            }
            if (this.localActiveFilters.tacho_overspeed) {
                count++
            }
            if (this.localActiveFilters.no_power_supply) {
                count++
            }
            if (this.localActiveFilters.no_gps) {
                count++
            }
            if (this.localActiveFilters.no_points) {
                count++
            }
            if (this.localActiveFilters.tacho_clock_error) {
                count++
            }
            if (this.localActiveFilters.no_card) {
                count++
            }
            return count
        },
        ...mapState({
            storeCompanyTags: (state) => state.geoFleet.companyTags,
            gmaps: (state) => state.geoFleet.gmaps
        })
    }
}
</script>

<style scoped lang="scss">
.truck-filters {
    p {
        font-family: 'Stolzl-Medium';
    }
    label,
    .form-check {
        font-size: 14px;
    }

    &::v-deep(.v-select) {
        font-size: 14px;
    }
}
.indicator {
    background: var(--success);
    width: 20px;
    display: inline-block;
    height: 20px;
}
</style>
