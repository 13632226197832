import { ref, unref, watch } from 'vue'
import moment from 'moment'

export function useVehicleStatus(vehicle) {
    let now = moment()
    const last_date = moment(unref(vehicle)?.lastpoint?.date).utc(true)

    let last_activity = null

    const vehicle_status = ref('')
    const vehicle_color = ref('')

    watch(
        vehicle,
        () => {
            if (last_date) last_activity = now.diff(last_date, 'hours')

            if (last_activity !== null && last_activity > 1) {
                vehicle_color.value = '--default-color'
                vehicle_status.value = 'default'
            } else {
                if (vehicle.value.activity_type == 'moving') {
                    vehicle_color.value = '--moving-color'
                    vehicle_status.value = 'moving'
                } else if (vehicle.value.activity_type == 'engine_on') {
                    vehicle_color.value = '--engine-on-color'
                    vehicle_status.value = 'engine-on'
                } else if (vehicle.value.activity_type == 'ignition') {
                    vehicle_color.value = '--ignition-color'
                    vehicle_status.value = 'ignition'
                } else if (vehicle.value?.lastpoint?.ignition > 0) {
                    vehicle_color.value = '--ignition-color'
                    vehicle_status.value = 'ignition'
                } else {
                    vehicle_color.value = '--default-color'
                    vehicle_status.value = 'default'
                }
            }
        },
        { deep: true, immediate: true }
    )

    return {
        vehicle_color,
        vehicle_status
    }
}
