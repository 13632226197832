import { ref, unref, watch } from 'vue'

export function useVehicleParameters(vehicle) {
    const commands = ref([])
    const lock_command = ref(null)

    watch(
        vehicle,
        () => {
            if( !unref(vehicle).parameters ) return

            let vehicle_commands = []
            let possible_commands = [ 4, 5, 6 ]
            let active_lock_command = null

            possible_commands.map( (command) => {
                if( unref(vehicle).parameters[command] && unref(vehicle).parameters[command].valore == '1' ) {
                    vehicle_commands.push(unref(vehicle).parameters[command])
                }
            })

            if( unref(vehicle).parameters[4] && unref(vehicle).parameters[4].valore == '1' ) {
                active_lock_command = unref(vehicle).parameters[4]
            } else if( unref(vehicle).parameters[5] && unref(vehicle).parameters[5].valore == '1' ) {
                active_lock_command = unref(vehicle).parameters[5]
            }

            lock_command.value = active_lock_command

            commands.value = vehicle_commands
        },
        { deep: true, immediate: true }
    )

    return {
        commands,
        lock_command
    }
}
