<template>
    <form>
        <div class="form-group mb-3">
            <label for="description">{{ __('manage_poi.fields.name') }}</label>
            <golia-input
                id="description"
                :value="localPoi.description"
                v-model="localPoi.description"
            ></golia-input>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <label for="type">{{ __('manage_poi.fields.type') }}</label>
                    <golia-input
                        id="type"
                        type="select"
                        :options="poiTypes"
                        :value="localPoi.type"
                        v-model="localPoi.type"
                    ></golia-input>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <label for="type">{{ __('manage_poi.fields.color') }}</label>
                    <div class="field-wrapper">
                        <input
                            id="color"
                            type="color"
                            class="form-control"
                            v-model="localPoi.color"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group mb-3">
            <label for="type">{{ __('manage_poi.fields.note') }}</label>
            <textarea id="notes" class="form-control" rows="3" v-model="localPoi.note"></textarea>
        </div>

        <div class="form-group mb-3">
            <label for="address">{{ __('manage_poi.fields.address') }}</label>

            <golia-waypoint-search id="address" v-model="localPoi.address" />
            <b-alert
                class="select-on-map p-2 mt-2"
                variant="primary"
                show
                v-if="localPoi.address && (!localPoi.lat || !localPoi.lng)"
            >
                {{ __('planner.select_on_map') }}
            </b-alert>
        </div>

        <div class="form-group mb-3">
            <label for="radius">{{ __('manage_poi.fields.radius') }} (m)</label>
            <golia-input
                id="radius"
                type="number"
                :value="localPoi.radius"
                v-model.number="localPoi.radius"
            ></golia-input>

            <small class="form-text text-muted">
                {{ __('manage_poi.fields.radius_description') }}
            </small>
        </div>

        <golia-button
            v-if="localPoi.id"
            class="mt-3"
            variant="btn-success"
            type="button"
            @click="submitForm"
            :label="__('edit')"
        />

        <golia-button
            v-else
            class="mt-3"
            variant="btn-success"
            type="button"
            @click="submitForm"
            :disabled="!validPoi"
            :label="__('add_new')"
        />
    </form>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localPoi: this.value,
            poiTypes: [
                { value: 1, text: this.__('manage_poi.types_poi.headquarter') },
                { value: 15, text: this.__('manage_poi.types_poi.truck_washes') },
                { value: 12, text: this.__('manage_poi.types_poi.blacklist') },
                { value: 11, text: this.__('manage_poi.types_poi.end_of_the_line') },
                { value: 5, text: this.__('manage_poi.types_poi.customers') },
                { value: 10, text: this.__('manage_poi.types_poi.stops') },
                { value: 14, text: this.__('manage_poi.types_poi.suppliers') },
                { value: 8, text: this.__('manage_poi.types_poi.interports') },
                { value: 9, text: this.__('manage_poi.types_poi.markets') },
                { value: 13, text: this.__('manage_poi.types_poi.large_square') },
                { value: 2, text: this.__('manage_poi.types_poi.poi_distributors') },
                { value: 4, text: this.__('manage_poi.types_poi.poi_workshops') },
                { value: 6, text: this.__('manage_poi.types_poi.harbors') },
                { value: 3, text: this.__('manage_poi.types_poi.restaurants') },
                { value: 7, text: this.__('manage_poi.types_poi.stations') }
            ],
            poiTypesColors: {
                1: '#1D5F9F',
                15: '#000000',
                12: '#D70E08',
                11: '#d70e08',
                5: '#1D5F9F',
                10: '#36D700',
                14: '#D713A3',
                8: '#442BD7',
                9: '#b6b600',
                13: '#3e9d20',
                2: '#25907A',
                4: '#D74848',
                6: '#3ED7CE',
                3: '#D74002',
                7: '#3e9d20'
            },
            toggleWaypoint: false
        }
    },
    created() {
        this.localPoi.color = this.localPoi?.color || this.poiTypeColor || '#000000'
    },
    methods: {
        submitForm() {
            // Handle form submission logic here
            if (this.localPoi.id) {
                this.$emit('update', this.localPoi)
            } else {
                this.$emit('store', this.localPoi)
            }
        }
    },
    watch: {
        value: function (val) {
            this.localPoi = val
        },
        localPoi: function (val) {
            this.$emit('input', val)
        },
        'localPoi.type': function (val) {
            this.localPoi.color = this.poiTypeColor
        },
        'localPoi.address': {
            handler: function (val) {
                if (!val) return

                if (val.referenceCoordinate) {
                    this.$emit('searchAddress', [
                        val.referenceCoordinate.y,
                        val.referenceCoordinate.x
                    ])

                    this.localPoi.lat = val.referenceCoordinate.y
                    this.localPoi.lng = val.referenceCoordinate.x

                    this.$emit('input', this.localPoi)
                }
            },
            deep: true
        }
    },
    computed: {
        poiTypeColor() {
            return this.poiTypesColors[this.localPoi.type]
        },
        validPoi() {
            return (
                this.localPoi.description &&
                this.localPoi.lat &&
                this.localPoi.lng &&
                this.localPoi.radius
            )
        }
    }
}
</script>

<style lang="scss" scoped>
input[type='color'].form-control {
    padding: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 12px;
    height: calc(1.5em + 0.75rem);
}
</style>
