<template>
    <div class="picker-window" :class="{ 'show-calendar': show }" v-click-outside="closeCalendar">
        <div id="dp-selector">
            <golia-input
                type="text"
                :readonly="true"
                @click="openCalendar()"
                :value="inputValue"
                :icon="{ class: 'fa-solid fa-calendar-days', right: true }"
            ></golia-input>

            <!-- <div @click="openCalendar()" class="input">
                <div class="values">
                    <span>{{ (propValue && propValue.start) ? propValue.start.format('L HH:mm') : '' }}</span>
                    <span v-if="!specificTime">
                        <span>-</span>
                        <span>{{ (propValue && propValue.end) ? propValue.end.format('L HH:mm') : '' }}</span>
                    </span>
                </div>
                <slot>
                </slot>
            </div> -->
        </div>
        <div
            v-show="show"
            class="dp_main"
            :class="`${alignCalendar}-aligned`"
            :style="{ width: mainWidth, top: panelPosition.top, left: panelPosition.left }"
        >
            <div class="dp_calendar flex-column flex-md-row">
                <div v-for="(month, index) in months" :key="month.label" class="db_month">
                    <div class="dp_month_header">
                        <div v-if="index == 0">
                            <div @click.stop="goBack" class="slide-button box">
                                <svg width="11" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.919 0l2.748 2.667L5.333 8l5.334 5.333L7.919 16 0 8z"
                                        fill-rule="nonzero"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div v-else class="box"></div>
                        <div class="month_title">
                            <span class="month">{{ month.label }}</span>
                            <span class="year">{{ month.year }}</span>
                        </div>
                        <div v-if="index + 1 == monthPanels">
                            <div @click.stop="goAhead" class="slide-button box">
                                <svg width="11" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.748 16L0 13.333 5.333 8 0 2.667 2.748 0l7.919 8z"
                                        fill-rule="nonzero"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div v-else class="box"></div>
                    </div>
                    <div class="dp_month_titles">
                        <div v-for="day in translatedDayNames" :key="day" class="day_title box">
                            {{ day }}
                        </div>
                    </div>
                    <div class="dp_month_days">
                        <span v-for="(day, index) in month.days" :key="index">
                            <div
                                v-if="day"
                                @click="daySelected(day)"
                                :class="{
                                    'day-item box': true,
                                    'in-range': !(day.start || day.end) && inRange(day),
                                    start: day.start,
                                    end: day.end,
                                    'is-locked': day.locked,
                                    'out-range': day.out_range
                                }"
                            >
                                {{ day.index }}
                            </div>
                            <div v-else class="box"></div>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="timePicker" class="dp-time-picker">
                <input type="time" :disabled="!selection.start" v-model="time.start" id="" />
                <input
                    v-if="!specificTime"
                    type="time"
                    :disabled="!selection.end"
                    v-model="time.end"
                    id=""
                />
            </div>
            <div class="dp-apply">
                <div class="dp-result">
                    <span v-if="singleDate == false">
                        <span v-if="selection.start">
                            {{
                                selection.start.date.format('L') +
                                (timePicker ? ' ' + time.start : '')
                            }}
                        </span>
                        <span v-if="selection.start && selection.end"> - </span>
                        <span v-if="selection.end">
                            {{
                                selection.end.date.format('L') + (timePicker ? ' ' + time.end : '')
                            }}
                        </span>
                    </span>
                    <span v-else>
                        <span v-if="selection.start && specificTime">
                            {{
                                selection.start.date.format('L') +
                                (timePicker ? ' ' + time.start : '')
                            }}
                        </span>
                        <span v-else-if="selection.start && !specificTime">
                            {{
                                selection.start.date.format('L') +
                                (timePicker ? ' ' + (time.start + ' - ' + time.end) : '')
                            }}
                        </span>
                    </span>
                </div>
                <div class="toolBtn dp-cancel-btn" @click="closeCalendar">{{ __('close') }}</div>
                <div
                    class="toolBtn dp-apply-btn"
                    @click="triggerSelection"
                    :style="{
                        cursor: selection.start && selection.end ? 'pointer' : 'not-allowed'
                    }"
                >
                    {{ __('select') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoliaDatePicker',
    props: {
        monthPanels: {
            type: Number,
            default: 2
        },
        timePicker: {
            type: Boolean,
            default: true
        },
        specificTime: {
            type: Boolean,
            default: false
        },
        singleDate: {
            type: Boolean,
            default: false
        },
        lockedDays: {
            type: Array,
            default: null
        },
        availableDays: {
            type: Array,
            default: null
        },
        maxRange: {
            type: Number,
            default: null
        },
        alignCalendar: {
            type: String,
            default: null
        },
        value: {
            type: Object,
            default: () => {
                return { start: this.moment().startOf('day'), end: this.moment().endOf('day') }
            }
        }
    },
    data() {
        return {
            monthIndex: 0,
            show: false,
            panelPosition: { top: '0px', left: '0px' },
            months: [],
            days_column: this.__('days_names_short'),
            propValue: this.value,
            selection: {
                start: null,
                end: null
            },
            time: {
                start: this.value && this.value.start ? this.value.start.format('HH:mm') : '00:00',
                end: this.value && this.value.end ? this.value.end.format('HH:mm') : '23:59'
            }
        }
    },
    methods: {
        closeCalendar() {
            this.show = false
        },
        openCalendar() {
            var sel_el = document.getElementById('dp-selector')

            if (!sel_el) return false

            this.panelPosition.top = String(sel_el.offsetHeight + 4) + 'px'
            if (!this.alignCalendar)
                this.panelPosition.left =
                    String(-1 * ((266 + 10) * this.monthPanels) + sel_el.offsetWidth) + 'px'

            if (this.$mq == 'xs' || this.$mq == 'sm') this.panelPosition.left = '0px'

            this.show = true
        },
        triggerSelection() {
            if (!(this.selection.start && this.selection.end)) return null

            if (!this.singleDate) {
                this.propValue = {
                    start: this.selection.start.date.clone(),
                    end: this.selection.end.date.clone()
                }
            } else {
                this.propValue = {
                    start: this.selection.start.date.clone(),
                    end: this.selection.start.date.clone()
                }
            }

            if (this.timePicker) {
                let timeStart = this.time.start.split(':')

                this.propValue.start.set({ hour: timeStart[0], minute: timeStart[1] })

                if (!this.singleDate) {
                    let timeEnd = this.time.end.split(':')

                    this.propValue.end.set({ hour: timeEnd[0], minute: timeEnd[1] })
                }
            }

            this.show = false

            this.$emit('input', this.propValue)
        },
        monthName(month) {
            return this.__('months_names')[month]
        },
        daySelected(day) {
            if (day.locked) return false

            if (this.singleDate) {
                this.selection.end.end = false
                this.selection.start.start = false

                day.start = true
                day.end = true

                this.selection.start = day
                this.selection.end = day

                return true
            }

            if (day.out_range || (this.selection.start && this.selection.end)) {
                if (this.selection.end) {
                    this.selection.end.end = false

                    this.selection.end = null
                }

                this.selection.start.start = false

                this.selection.start = day

                day.start = true
            } else if (this.selection.start) {
                if (day.date.isBefore(this.selection.start.date)) {
                    this.selection.start.start = false

                    this.selection.start = day

                    day.start = true
                } else {
                    this.selection.end = day

                    day.end = true
                }
            } else {
                this.selection.start = day

                day.start = true
            }

            for (var m of this.months) {
                for (var d of m.days) {
                    if (!d) continue

                    d.out_range = this.isOutRange(d.date)
                }
            }
        },
        inRange(day) {
            if (day.locked || !(this.selection.start && this.selection.end)) return false

            return day.date.isBetween(this.selection.start.date, this.selection.end.date)
        },
        isOutRange(day) {
            if (!this.maxRange) return false

            return (
                this.selection.start &&
                day.diff(this.selection.start.date, 'days', true) > this.maxRange
            )
        },
        goBack() {
            let dropped_month = this.months.pop()

            let first_month = this.months.at(0) || dropped_month

            this.addMonth(first_month.index - 1, false)
        },
        goAhead() {
            let dropped_month = this.months.shift()

            let last_month = this.months.at(-1) || dropped_month

            this.addMonth(last_month.index + 1, true)
        },
        loadDates(push) {
            for (let index = 0; index < this.monthPanels; index++) {
                this.addMonth(this.monthIndex - index, push)
            }
        },
        checkDayPermission(date, dates, op) {
            for (var ad of dates) {
                if (Array.isArray(ad)) {
                    let isBetween = date.isBetween(ad[0], ad[1], undefined, '[]')

                    return op ? isBetween : !isBetween
                } else {
                    let isSame = date.isSame(ad, 'day')

                    return op ? isSame : !isSame
                }
            }
        },
        isLocked(date) {
            if (this.availableDays) return this.checkDayPermission(date, this.availableDays, false)

            if (this.lockedDays) return this.checkDayPermission(date, this.lockedDays, true)
        },
        addMonth(index, push) {
            let m = this.moment().add(index, 'months').startOf('month').startOf('day')

            let month_index = m.format('M')

            // m.format('d') restituisce l'indice del giorno della settimana (es: 0 = domenica, 1 = lunedì, ecc...)
            // Faccio un - 1 perchè noi "umani" ragioniamo con 1 come primo numero di indice (altrimenti il calendario partirebbe da un giorno in avanti)
            let day_index = parseInt(m.format('d'))

            /* 
                Siccome m.format('d') ragiona con la domenica = 0, allora se il primo giorno del mese è una domenica,
                day_index sarà uguale a 0, quindi lo imposto a 6 per farlo ragionare con la domenica = 6 (come settimo giorno se il formato data dell'utente non è anglosassone)
            */
            if (!this.isAnglosassone) day_index = day_index == 0 ? 6 : day_index - 1

            // if(this.isAnglosassone)
            //     day_index += 1

            /*
                La funzione Array.apply(null, Array(day_index)) crea un array di lunghezza pari a day_index 
                per costruire la parte grafica del calendario per la prima settimana del mese.

                Ad esempio, se il primo giorno del mese è un martedì, day_index sarà uguale a 2 (-1), quindi
                l'array sarà di lunghezza 2 (-1), e quindi verranno creati 2 (-1) elementi vuoti per la prima settimana
                del calendario.
            */
            let month = {
                index: index,
                label: m.format('MMMM'),
                year: m.format('YYYY'),
                days: Array.apply(null, Array(day_index))
            }

            let start = (
                this.selection.start ? this.selection.start.date : this.value.start || this.moment()
            ).clone()
            let end = (
                this.selection.end ? this.selection.end.date : this.value.end || this.moment()
            ).clone()

            while (month_index == m.format('M')) {
                let day = {
                    index: m.format('D'),
                    start: false,
                    end: false,
                    date: m.clone()
                }

                day.locked = this.isLocked(day.date)
                day.out_range = this.isOutRange(day.date)

                if (!day.locked && start && start.isSame(m, 'day')) {
                    day.start = true

                    this.selection.start = day
                }

                if (!day.locked && end && end.isSame(m, 'day')) {
                    day.end = true

                    this.selection.end = day
                }

                month.days.push(day)

                m.add(1, 'days')
            }

            if (push) this.months.push(month)
            else this.months.unshift(month)
        }
    },
    computed: {
        mainWidth() {
            if (this.$mq == 'xs' || this.$mq == 'sm') return '100%'

            return String((266 + 10) * this.monthPanels) + 'px'
        },
        inputValue() {
            let from_v =
                this.propValue && this.propValue.start ? this.propValue.start.format('L HH:mm') : ''
            let to_v =
                this.propValue && this.propValue.end ? this.propValue.end.format('L HH:mm') : ''

            if (this.specificTime) return from_v

            return from_v + (to_v ? ' - ' + to_v : '')
        },
        selectionComputed() {
            return Object.assign({}, this.selection)
        },
        isAnglosassone() {
            let locale = this.moment().localeData()
            return locale.firstDayOfWeek() == 0
        },
        translatedDayNames() {
            let locale = this.moment().localeData()
            return locale.weekdaysShort(true)
        }
    },
    watch: {
        value() {
            this.propValue = this.value
            this.selection.start.date = this.value.start
            this.selection.end.date = this.value.end
        },
        selectionComputed: {
            deep: true,
            handler(val, oldVal) {
                if (!(val?.start?.date._d.getDate() == oldVal?.start?.date._d.getDate())) {
                    this.time.start = '00:00'
                    this.time.end = '23:59'
                }
            }
        }
    },
    created() {
        this.loadDates(false)
    },
    mounted() {}
}
</script>

<style scoped>
.picker-window {
    position: relative;
    /*display: inline-flex;*/
}
.picker-window.show-calendar ::v-deep(.form-input-g .input) {
    background-color: var(--main-gray);
    box-shadow: none;
}
.picker-window ::v-deep(.form-input-g .input) {
    padding-right: 30px;
}
.picker-window ::v-deep(.form-input-g .icon i) {
    opacity: 0.8;
}
.dp_main {
    position: absolute;
    z-index: 50000;

    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
    font-size: 0.8em;

    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #ddd;

    padding-bottom: 5px;
}
.dp_main.right-aligned {
    left: initial !important;
    right: 0px;
}
.box {
    width: 38px;
    height: 27.5px;
}
.dp_calendar {
    display: flex;
    /*flex-direction: row;*/
}
.db_month {
    display: flex;
    flex-direction: column;
    width: 266px;
    margin: 5px;
}
.dp_month_header {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dp_month_titles {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 85%;
}
.day_title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
}
.day-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.day-item:hover {
    color: #2196f3;
    -webkit-box-shadow: inset 0 0 0 1px #2196f3;
    box-shadow: inset 0 0 0 1px #2196f3;
}
.start:hover {
    color: #fff !important;
}
.end:hover {
    color: #fff !important;
}
.is-locked {
    color: #9e9e9e !important;
}
.is-locked:hover {
    color: #9e9e9e !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: default !important;
}
.out-range {
    color: #9e9e9e !important;
}
.is-locked:hover {
    color: #9e9e9e !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.dp_month_days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}
.month_title {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.month {
    font-weight: bolder;
    text-transform: capitalize;
}
.slide-button {
    fill: #9e9e9e;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.slide-button:hover {
    fill: #2196f3;
}
.start {
    color: #fff;
    background-color: #2196f3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.end {
    color: #fff;
    background-color: #2196f3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.start.end {
    border-radius: 5px !important;
}
.in-range {
    background-color: #bbdefb;
    border-radius: 0;
}
.dp-time-picker {
    display: flex;
    align-content: flex-start;
    justify-content: space-around;
    margin-bottom: 8px;
}
.dp-apply {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 1px solid #efefef;
}
.toolBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 500;
    padding: 4px;
    cursor: pointer;
}
.dp-apply-btn {
    background-color: #2196f3;
    color: #fff;
}
.dp-cancel-btn {
    background-color: #efefef;
    color: #000;
}
.dp-result {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}
.selector {
    float: right;
}
.input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-grow: 2;
}
.input > .values {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-grow: 2;
}
#dp-selector {
    flex-grow: 2;
    display: flex;
    justify-content: flex-start;
}
</style>
