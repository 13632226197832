import Vue from './common.js'
import App from './App.vue'
import { abilitiesPlugin } from '@casl/vue'

import AuthProcess from './plugins/AuthProcess.js'

import geofleet from './routes/geofleet.js'
import geofleetMobile from './routes/geofleet-mobile.js'

import store from './store/index.js'

const router = (() => {
    return isMobile() ? geofleetMobile : geofleet
})()

Vue.use(abilitiesPlugin, store.getters.ability)
Vue.use(AuthProcess, store, router)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')

function isMobile() {
    const userAgent = window.navigator.userAgent
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ||
        window.innerWidth <= 991
    )
}
