var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('multi-select',_vm._g(_vm._b({ref:_vm.refer,on:{"select":(option, id) => {
                _vm.$emit('selectOption', option, id)
            },"remove":(option, id) => {
                _vm.$emit('removeOption', option, id)
            },"tag":(query, id) => {
                _vm.$emit('tagOption', query, id)
            }},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return (_vm.customSelectedLabel)?[(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single",on:{"click":function($event){return _vm.toggleMultiselect(isOpen, this.refer)}}},[_c('b',{staticClass:"text-success"},[_vm._v(_vm._s(values.length))]),(_vm.customSelectedLabel && !_vm.customSelectedLabel.onlyValue)?_c('span',[_vm._v(" "+_vm._s(values.length > 1 ? _vm.customSelectedLabel && _vm.customSelectedLabel.plural ? _vm.customSelectedLabel.plural : _vm.__('selected_plural') : _vm.customSelectedLabel && _vm.customSelectedLabel.singular ? _vm.customSelectedLabel.singular : _vm.__('selected'))+" ")]):_vm._e()]):_vm._e()]:undefined}}],null,true)},'multi-select',_vm.$attrs,false),_vm.$listeners),[_c('template',{slot:"noOptions"},[_c('span',[_vm._v(_vm._s(_vm.emptyLabel ? _vm.emptyLabel : _vm.__('empty_search')))])])],2),(_vm.outsideTagsSelected)?_c('div',{staticClass:"d-flex flex-wrap container-label mt-2"},[_c('div',[(_vm.attrsCloned.value.length > 0)?_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.customValueLabels)+":")]):_vm._e()]),_vm._l((_vm.attrsCloned.value),function(tag,index){return _c('div',{key:tag.id},[_c('span',{staticClass:"selected-label"},[_vm._v(" "+_vm._s(tag.label)+_vm._s(_vm.attrsCloned.value.length - 1 !== index ? ',' : '')+" ")])])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }