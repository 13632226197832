<template>
    <div>
        <div class="content custom-scrollbar clear-content">
            <div class="white-cloud">
                <!-- TITOLO SEZIONE -->
                <span class="title-wc">{{ __('vehicle_info').toUpperCase() }}</span>
                <hr style="border: 1px solid #aebec6" />

                <!-- DETTAGLI SEZIONE -->
                <div class="cloud-container">
                    <div
                        v-for="(title, key) in Object.keys(informations)"
                        :key="key"
                        class="row g-0 justify-content-between mb-1"
                    >
                        <div class="col-5 pe-2">
                            <span class="detail-wc">{{ __(title).toUpperCase() }}</span>
                        </div>
                        <div class="col-7" v-if="informations[title].value">
                            <span class="response-wc"
                                >{{ informations[title].value || '' }}
                                <span v-if="informations[title].um">{{
                                    informations[title].um
                                }}</span></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailsBody',
    props: {
        truck: {
            type: Object,
            required: true
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            informations: {
                speed: {
                    value: this.truck.lastpoint.tacho_speed || this.truck.lastpoint.speed || 0,
                    um: 'Km/h'
                },
                altitude: { value: this.truck.lastpoint.altitude, um: 'm s.l.m.' }
            }
        }
    }
}
</script>

<style scoped>
.collapsible-title {
    border: none;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    background-color: #687a8e;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 0px 0px 0px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.collapsible-title.is-active,
.collapsible-title:hover {
    background-color: #e5e6eb;
}
.collapsible-title.is-active,
.collapsible-title:hover i {
    color: #6d6b7a;
}
.collapsible-title i {
    color: #fff;
}
.collapsible-title.is-active i {
    color: #6d6b7a;
}

.content {
    background: var(--main-inner-bg-color);
    border-radius: 0 0 15px 15px;
    margin: 0;
    padding: 0px 15px 5px 15px;
    width: 100%;

    flex: 1;
    overflow-y: auto;
}
.content.clear-content {
    background-color: transparent;
    padding: 0;
}
.content.custom-scrollbar {
    --scrollbar-thumb-border: var(--main-inner-bg-color);
}

.white-cloud {
    background: rgba(var(--main-bg-color--rgb), 0.8);
    padding: 15px;
    border-radius: 15px;
    margin-top: 10px;
}
.title-wc {
    font-size: 15px;
}
.title-wc + hr {
    margin: 5px 0 8px;
}
.detail-wc {
    font-size: 11px;
    color: #90a4ae;
    text-transform: uppercase;
    vertical-align: middle;
}
.response-wc {
    font-size: 14px;
    color: #6d6c73;
    vertical-align: middle;
    font-weight: 700;
}
</style>
