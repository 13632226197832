import mutations from './activity-panel.mutations'
import actions from './activity-panel.actions'

const state = () => ({
    layers: [],
    activities: [],
    stops: [],
    geojson: [],
    loaders: {
        activities: false,
        stops: false,
        route: false
    },
    currentTruck: null
})

const getters = {
    isLoading: (state) => state.loaders.activities || state.loaders.stops || state.loaders.route,
    allResourcesLoaded: (state) => state.geojson.length === 3,

    routeLoaded: (state) => {
        let routeElement = state.geojson.some((obj) => obj.id === 'route')

        return routeElement
    },
    stopsLoaded: (state) => {
        let stopsElement = state.geojson.some((obj) => obj.id === 'stops')

        return stopsElement?.geojson?.features?.length > 0
    },
    activitiesLoaded: (state) => {
        let activitiesElement = state.geojson.some((obj) => obj.id === 'activities')

        return activitiesElement?.geojson?.features?.length > 0
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
