<template>
    <l-marker
        :visible="visible"
        :lat-lng="coordinates"
        :draggable="localDraggable"
        :ref="referId"
        @dragend="updateByDrag"
        @click="$emit('click', (item || {}).reference)"
    >
        <l-icon :icon-anchor="[20, 40]" :icon-size="[40, 40]" className="custom-icon">
            <svg
                :class="classHandler"
                id="Goccia"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-19 -3 159.5 190.4"
            >
                <path
                    id="Traccia_goccia"
                    data-name="Traccia goccia"
                    d="M164,84.8c0,33-59.7,109.2-59.7,109.2S44.5,117.8,44.5,84.8a59.8,59.8,0,0,1,119.5,0Z"
                    transform="translate(-44.5 -12.6)"
                    fill="#fff"
                />
                <path
                    id="Base_goccia"
                    data-name="Base goccia"
                    d="M157.4,87.6c0,29.3-53.1,97-53.1,97s-53.1-67.7-53.1-97a53.1,53.1,0,0,1,106.2,0Z"
                    transform="translate(-44.5 -12.6)"
                    fill="#c9f0cc"
                />
                <path
                    id="Mezza_goccia_riflesso"
                    data-name="Mezza goccia riflesso"
                    d="M157.4,87.6a53.1,53.1,0,0,0-53.1-53.1V184.6S157.4,116.9,157.4,87.6Z"
                    transform="translate(-44.5 -12.6)"
                    fill="#a4d6a8"
                />
                <g class="rotate" fill="div-rotate">
                    <path
                        id="Freccia_direzionale"
                        data-name="Freccia direzionale"
                        d="M150.1,86.4a45.9,45.9,0,0,0-30.2-43.1L104.3,12.6,88.7,43.3a45.8,45.8,0,1,0,61.4,43.1Z"
                        transform="translate(-44.5 -12.6)"
                        :class="{ 'custom-color': customizer.custom_color }"
                        :fill="customizer.custom_color ? customizer.custom_color : '#657565'"
                    />
                </g>
                <text
                    :x="markerSizeHandler.x ? markerSizeHandler.x : 28"
                    :y="markerSizeHandler.y ? markerSizeHandler.y : 92"
                    style="
                        font-family: 'Font Awesome Kit', 'FontAwesome', 'Font Awesome 6 Pro';
                        font-size: 52px;
                    "
                    fill="#fff"
                    v-if="iconUnicode"
                    v-html="iconUnicode ? `&${iconUnicode};` : '&#xe00d;'"
                ></text>
                <text
                    x="56"
                    y="92"
                    style="font-size: 52px; font-weight: 700"
                    fill="#fff"
                    text-anchor="middle"
                    v-else
                >
                    {{ markerInnerLabel + 1 }}
                </text>
            </svg>
            <div
                class="plate"
                :class="classHandler"
                v-if="markerTitle != __('planner.vehicle_actual_position')"
                v-b-tooltip.hover
                :title="markerTitle"
            >
                <span
                    class="borsa-icon"
                    v-show="
                        customizer.color_class == 'borsa.delivery' ||
                        customizer.color_class == 'borsa'
                    "
                >
                    {{ customizer.color_class == 'borsa' ? '&#xf0aa;' : '&#xf0ab;' }}
                </span>
                <span>{{ computedMarkerLabel }}</span>
            </div>
        </l-icon>
    </l-marker>
</template>

<script>
import { LMarker, LIcon } from 'vue2-leaflet'

export default {
    name: 'CustomMarker',
    props: {
        referId: String,
        item: Object,
        visible: { Boolean, default: true },
        coordinates: Array,
        markerTitle: [String, Number],
        markerInnerLabel: [String, Number],
        iconUnicode: String,
        customizer: Object,
        draggable: { Boolean, default: false },
        highlighted: { Boolean, default: false }
    },
    components: {
        LMarker,
        LIcon
    },
    data() {
        return {
            bounce: false,
            localDraggable: false
        }
    },
    methods: {
        updateByDrag: function (e) {
            let position = e.target.getLatLng()

            this.$emit('updateByDrag', [position.lat, position.lng])
        }
    },
    watch: {
        visible: function (val) {
            if (this.draggable === true) {
                this.localDraggable = val
            }
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            if (this.draggable === true) {
                this.localDraggable = this.draggable
            }
        })
    },
    computed: {
        classHandler: function () {
            let className = ''

            if ((this.item || {}).on_focus || this.highlighted) {
                className += 'bounce'
            }

            if (this.customizer.tiny) {
                className += ' tiny'
            }

            if (this.customizer.color_class) {
                className += ' ' + this.customizer.color_class
            }

            return className
        },
        markerSizeHandler: function () {
            var size = { x: 28, y: 92 }

            if (this.customizer.color_class == 'fuel') {
                size.x = 28
                size.y = 92
            } else if (this.customizer.color_class == 'parking') {
                size.x = 32
                size.y = 92
            } else if (this.customizer.color_class == 'poi') {
                size.x = 38
                size.y = 92
            } else if (
                this.customizer.color_class == 'borsa' ||
                this.customizer.color_class == 'borsa-timocom' ||
                this.customizer.color_class == 'borsa.delivery'
            ) {
                size.x = 26
                size.y = 92
            } else if (
                this.customizer.color_class == 'start' ||
                this.customizer.color_class == 'finish'
            ) {
                size.x = 36
                size.y = 92
            }

            if (this.iconUnicode == '#xf041' || this.iconUnicode == '#xf2f2') {
                size.x = 38
                size.y = 92
            }

            return this.customizer.size_x ? { x: this.customizer.size_x, y: 92 } : size
        },
        computedMarkerLabel: function () {
            if (this.markerTitle)
                return this.markerTitle.length > 20
                    ? this.$options.filters.truncate(this.markerTitle, 20, '...')
                    : this.markerTitle
            else return ''
        }
    }
}
</script>

<style scoped lang="scss">
@import './CustomMarker.scss';
</style>
