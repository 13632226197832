<template>
    <div>
        <h5>
            {{ __('sharedlinks.vehicles_link') }}
            <span class="truck-link" v-for="(truck, index) in link.trucks" :key="truck.id">
                {{ index < link.trucks.length - 1 ? truck.plate + ', ' : truck.plate }}
            </span>
        </h5>
        <p>
            {{ __('available') }} {{ __('from') }} <b>{{ unixToDate(link.start) }}</b>
            {{ __('to') }} <b>{{ unixToDate(link.exp) }}</b>
        </p>
        <div class="d-flex justify-content-between">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <router-link
                        target="_blank"
                        :to="'/view-shared-link/' + link.token"
                        class="btn btn-sm btn-circle btn-fill success"
                        ><i class="fa-solid fa-fw fa-up-right-from-square"></i
                    ></router-link>
                </li>
                <li class="list-inline-item">
                    <golia-button
                        variant="btn-sm btn-circle btn-fill danger"
                        @click="deleteLink(link)"
                        icon="fa-solid fa-fw fa-trash"
                    />
                </li>
                <li class="list-inline-item">
                    <golia-button
                        variant="btn-sm btn-circle btn-fill info"
                        @click="showUpdateModal(link)"
                        icon="fa-solid fa-fw fa-pencil"
                    />
                </li>
            </ul>
            <div class="share-link-message" v-if="computedSharedLink">
                <golia-shareNetwork :sharedLink="computedSharedLink" />
            </div>
        </div>

        <b-modal
            :id="'modal-share-link---update' + '-' + link.token"
            :title="__('sharedlinks.edit_link')"
            size="lg"
        >
            <div class="create-link">
                <div
                    class="p-2 mb-3 bg-warning"
                    style="opacity: 0.8; border-radius: 5px; --bs-bg-opacity: 0.6"
                >
                    <b>{{ __('warning') }}:</b> {{ __('sharedlinks.start_date_warning') }}:
                    <b>{{ unixToDate(link.start) }}</b
                    ><br />
                    {{ __('sharedlinks.start_date_condition') }}
                </div>
                <p class="mt-2 mb-3">{{ __('sharedlinks.expire_date_description') }}:</p>

                <div class="form-group">
                    <label for="share-link-expiration"
                        ><b>{{ __('expire_date') }}</b></label
                    >

                    <golia-date-picker
                        v-model="shareLinkExpiration"
                        :monthPanels="1"
                        :singleDate="true"
                        :specificTime="false"
                    ></golia-date-picker>
                </div>

                <p
                    class="my-2 p-2 text-white"
                    v-show="updatedLink"
                    :class="updatedLink && updatedLink.errors ? 'bg-danger' : 'bg-success'"
                >
                    {{
                        updatedLink && updatedLink.errors
                            ? __('sharedlinks.updated_link_error')
                            : __('sharedlinks.updated_link_success')
                    }}
                </p>
            </div>

            <template #modal-footer="{ cancel }">
                <golia-button
                    variant="btn-fill dark-blue"
                    @click="cancel()"
                    :label="updatedLink ? __('close') : __('cancel')"
                />

                <golia-button
                    variant="btn-fill success"
                    @click="updateLink(link)"
                    :label="__('sharedlinks.update_link_expire_date')"
                    :disabled="!shareLinkExpiration"
                    :loading="shareUpdateLoading"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'SingleLink',
    props: {
        link: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            updatedLink: null,
            shareUpdateLoading: false,
            shareLinkExpiration: {
                start: null
            }
        }
    },
    methods: {
        deleteLink(link) {
            var _this = this
            document.getElementById('link-' + link.token).classList.add('opacity')

            this.$bvModal
                .msgBoxConfirm(this.__('sharedlinks.delete_link_confirm'), {
                    okVariant: 'success',
                    okTitle: this.__('yes'),
                    cancelTitle: this.__('no')
                })
                .then((response) => {
                    if (response) {
                        _this
                            .$fetch(this.$env.GOLIA_API + '/sharelink', {
                                method: 'DELETE',
                                body: JSON.stringify({ token: link.token })
                            })
                            .then(() => {
                                this.$store.commit('removeLink', link)

                                _this.$emit('deleteLink')
                            })
                            .catch(() => {
                                document
                                    .getElementById('link-' + link.token)
                                    .classList.remove('opacity')
                            })
                    }
                })
        },
        showUpdateModal(link) {
            this.shareLinkExpiration.start = this.moment(this.unixToDate(link.exp, 'YYYY-MM-DD'))
            this.updatedLink = null
            this.$bvModal.show('modal-share-link---update' + '-' + link.token)
        },
        updateLink(link) {
            var _this = this
            this.shareUpdateLoading = true

            this.$fetch(this.$env.GOLIA_API + '/sharelink', {
                method: 'PUT',
                body: JSON.stringify({
                    token: link.token,
                    expire_at: _this.expireToSend,
                    tz: this.$authProcess.getParams('tz')
                })
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    _this.shareUpdateLoading = false

                    _this.$store.commit('updateExpireLink', { link, exp: res.data.exp })
                    _this.$bvModal.hide('modal-share-link---update' + '-' + link.token)
                })
        }
    },
    computed: {
        computedSharedLink() {
            let link = null
            try {
                link = this.$path_join(
                    window.location.origin,
                    this.$router.resolve({
                        name: 'viewvehicle.index',
                        params: { token: this.link.token }
                    }).href
                )
            } catch (error) {
                console.log(error)
            }
            return link
        },
        expireToSend() {
            return this.shareLinkExpiration.start.clone().utc().toISOString()
        }
    }
}
</script>

<style lang="scss" scoped>
.single-link {
    padding: 10px;
    background-color: var(--main-gray);
    margin-bottom: 10px;
    color: var(--dark-blue);
    border-radius: 10px;

    &.opacity {
        background-color: rgba(var(--main-gray--rgb), 0.6);
    }

    h5 {
        font-size: 16px;
    }
    p {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 0;
    }
    .list-inline {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .btn {
        padding: 4px 6px;
        font-size: 10px;
    }
}
.social-share li a {
    font-size: 12px !important;
    margin-top: 0px;
    color: #fff;
}
</style>
