<template>
    <side-panel id="detail-panel" :class="{ collapsed: collapsedClass }">
        <template #head>
            <div @click="localCollapsed = !localCollapsed" class="collapse-button">
                <div class="d-block d-lg-none">
                    <span>{{ !localCollapsed ? __('close') : __('open') }}</span>
                    <i v-if="localCollapsed" class="fa-solid fa-arrow-up-from-line"></i>
                    <i v-else class="fa-solid fa-arrow-down-from-line"></i>
                </div>
                <div class="d-none d-lg-block">
                    <i v-if="localCollapsed" class="fa-solid fa-arrow-left-from-line"></i>
                    <i v-else class="fa-solid fa-arrow-right-from-line"></i>
                </div>
            </div>
            <transition
                name="collapse-fade"
                @after-leave="collapsedClass = true"
                @before-enter="collapsedClass = false"
            >
                <details-head
                    :truck="truck"
                    :collapsed="localCollapsed"
                    :closable="closable"
                    @close="$emit('lostfocus', truck)"
                    v-if="!localCollapsed"
                />
            </transition>
        </template>

        <template #body>
            <transition name="collapse-fade-reverse">
                <div class="collapse-info" v-if="localCollapsed">
                    <div class="collapsed-header">
                        <div
                            style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                            "
                        >
                            <div
                                class="circle-icon"
                                :style="{
                                    'background-color': colorStatus,
                                    'background-image': 'none'
                                }"
                            >
                                <truck-icon
                                    style="padding: 5px; color: #fff"
                                    :category="truck.category"
                                />
                            </div>
                            <span class="targa-c">{{ truck.plate }}</span>
                        </div>

                        <div class="lastpoint-info">
                            <div>
                                {{ truck.lastpoint.date | formatDate('UTC') }}
                            </div>
                            <div
                                v-if="
                                    truck.lastpoint.provenience && truck.lastpoint.provenience.web
                                "
                                class="p-provdescr"
                            >
                                <i
                                    v-if="
                                        truck.lastpoint.provenience &&
                                        truck.lastpoint.provenience.icon
                                    "
                                    :class="['fa-solid', truck.lastpoint.provenience.icon]"
                                ></i>
                                {{ truck.lastpoint.provenience.web }}
                            </div>
                        </div>

                        <div class="localizzazione-f">
                            <reverse-geocoding
                                :full="true"
                                :truncated="80"
                                :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]"
                            />
                        </div>
                    </div>
                </div>

                <div class="container-fluid mt-2 mt-sm-3" v-if="!localCollapsed">
                    <golia-date-picker
                        v-model="localRangeDate"
                        alignCalendar="right"
                        :monthPanels="1"
                        :specificTime="false"
                    ></golia-date-picker>

                    <div class="show-route">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="localTruck.showingRoute"
                                @click="localTruck.showingRoute = !localTruck.showingRoute"
                                id="checkShowRoute"
                            />
                            <label class="form-check-label w-100" for="checkShowRoute">
                                {{ __('viewsharedlink.show_route') }}
                            </label>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="collapse-fade">
                <details-body
                    :truck="truck"
                    :collapsed="localCollapsed"
                    class="d-body"
                    ref="details_body"
                    v-if="!localCollapsed"
                />
            </transition>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from '@/components/panels/SidePanel.vue'

//Details Panel
import DetailsHead from './DetailsHead.vue'
import DetailsBody from './DetailsBody.vue'

export default {
    name: 'DetailsPanel',
    props: {
        truck: {
            type: Object,
            required: true
        },
        rangeData: {
            type: Object,
            default: () => ({})
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        }
    },
    components: {
        SidePanel,
        DetailsHead,
        DetailsBody
    },
    data() {
        return {
            localCollapsed: this.collapsed,
            collapsedClass: this.collapsed || false,

            localTruck: this.truck,

            shareLinkExpiration: null,
            shareLoading: false,
            createdLink: null,
            localRangeDate: this.rangeData
        }
    },
    watch: {
        collapsed: function (val) {
            this.localCollapsed = val
            this.collapsedClass = val
        },
        rangeData: {
            handler: function (val) {
                this.localRangeDate = val
            },
            deep: true
        },
        truck: function (val) {
            this.localTruck = val
        },
        localRangeDate: function (val) {
            this.$emit('rangeUpdate', val)
        }
    },
    computed: {
        colorStatus() {
            if (this.moment().diff(this.moment(this.truck.lastpoint.date, 'UTC'), 'hours') > 1)
                return '#ccccce'
            else {
                if (this.truck.activity_type == 'moving') return '#a4d6a8'
                else if (this.truck.activity_type == 'engine_on') return '#f73b5e'
                else if (this.truck.activity_type == 'ignition') return '#fabe67'
                else {
                    return '#ccccce'
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
#detail-panel {
    position: relative;

    ::v-deep(.white-cloud) {
        background: none !important;
        padding-top: 0px;
    }

    ::v-deep(.form-input-g .input) {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    #detail-panel.mobile {
        margin-bottom: 20px;
    }
    #detail-panel.mobile.with-trucks {
        margin-bottom: 95px;
    }
    #detail-panel.mobile.with-trucks.with-scrollbar {
        margin-bottom: 110px;
    }
}

#detail-panel ::v-deep(.targa-f) {
    padding-right: 20px;
}
.body {
    overflow-y: auto;
}
.d-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;

    flex: 1;
    margin-bottom: 15px;
}

.collapse-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

@media (max-width: 768px) {
    #detail-panel ::v-deep(.targa-f) {
        word-break: break-all;
        font-size: 20px;
    }
    #detail-panel ::v-deep(.d-head .btn-close) {
        display: none;
    }

    .d-body {
        margin-bottom: -15px;
    }
    .collapse-button {
        top: -26px;
        left: initial;
        right: 25px;
        width: auto;
        font-size: 14px;
        background-color: #fff;
        padding: 5px 10px;
        i {
            margin-left: 5px;
        }
    }
}

.panel-tools {
    gap: 8px;
}

.bg-success,
.bg-danger {
    border-radius: 5px;
}
.bg-success {
    background-color: var(--success) !important;
}
.share-link-message a {
    color: var(--dark-blue);
    display: block;
    word-break: break-all;
    padding: 10px;
    border-radius: 5px;
    background: var(--main-gray);
    margin-top: 10px;
    font-size: 14px;

    &:hover {
        color: var(--dark-blue--hover);
    }
}
.show-route {
    padding: 5px 8px;
    border-radius: 12px;
    background: var(--main-gray);
    margin-top: 10px;

    .form-check {
        margin-bottom: 0px;
    }
}
</style>

<style lang="scss">
/* TRANSITIONS */

// 3
.collapse-fade-enter-active {
    transition: all 0.2s 0.2s ease;
}
// 1
.collapse-fade-leave-active {
    transition: all 0.2s ease;
}
.collapse-fade-enter,
.collapse-fade-leave-to {
    opacity: 0;
}

// 2
.collapse-fade-reverse-enter-active {
    transition: all 0.2s 0.4s ease;
}
// 4
.collapse-fade-reverse-leave-active {
    transition: all 0.1s ease;
}
.collapse-fade-reverse-enter,
.collapse-fade-reverse-leave-to {
    opacity: 0;
}
.collapse-fade-reverse-enter-to,
.collapse-fade-reverse-leave {
    opacity: 1;
}

/* RETURN ANIMATION FROM COLLAPSED */
#detail-panel.shared-link-panel .head,
#detail-panel.shared-link-panel .body {
    --side-panels-security-width: 340px;
    width: var(--side-panels-security-width);

    transition-property: width;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
    // overflow-y: initial;
}
@media (max-width: 768px) {
    #detail-panel.shared-link-panel .head,
    #detail-panel.shared-link-panel .body {
        --side-panels-security-width: 100%;
        transition-property: height;
    }
    #detail-panel.shared-link-panel.collapsed .head {
        margin-bottom: 0px !important;
    }
    #detail-panel.shared-link-panel {
        .lastpoint-info,
        .localizzazione-f {
            font-size: 12px !important;
        }
        .white-cloud {
            .title-wc {
                display: none;
            }
        }
        .circle-icon {
            display: none !important;
        }
    }
}

/* COLLAPSED PANEL STYLES */
#detail-panel.shared-link-panel.collapsed {
    min-height: 260px;

    /* ANIMATION FROM EXPANDED TO COLLAPSED */
    .head,
    .body {
        width: 120px;

        transition-property: width;
        transition-duration: 0.4s;
        transition-timing-function: ease-in;
    }

    .head {
        margin-bottom: 10px;
    }
    .body {
        justify-content: space-between;
        overflow-y: auto;

        --scrollbar-track-bg: transparent;
        --scrollbar-thumb-border: #fff;

        &::-webkit-scrollbar {
            width: 12px; /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-bg); /* color of the tracking area */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a3a3a3; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 3px solid var(--scrollbar-thumb-border); /* creates padding around scroll thumb */
        }
    }
    .collapse-info {
        font-size: 12px;
        text-align: center;
    }
    .panel-tools {
        flex-wrap: nowrap;
        margin-top: 8px;
    }
    .plate {
        margin-top: 0px;
    }
    .p-provdescr {
        margin-top: 10px;
    }
    .btn {
        width: 52px !important;
        padding: 15px;
        border-radius: 50%;
        margin: 0 auto;
    }
    .dati-autisti,
    .lastpoint-info,
    .localizzazione-f {
        max-width: 100px;
        text-align: center;
        display: block;
        margin: 5px auto;
    }
}

@media (max-width: 768px) {
    #detail-panel.shared-link-panel.collapsed {
        min-height: 135px;
        transition-property: height;

        .head,
        .body {
            width: 100%;
        }
        .dati-autisti,
        .lastpoint-info,
        .localizzazione-f {
            max-width: 100%;
        }
    }
}
</style>
