export default {
  open: 'Deschide',
  close: 'Închide',
  apply: 'Aplică',
  cancel: 'Anulează',
  search: 'Caută',
  cancel_search: 'Anulează căutarea',
  edit_search: 'Modifică căutarea',
  erase: 'Ștergeți',
  back_to_results: 'Înapoi la rezultate',
  save: 'Salvează',
  retry: 'Încearca din nou',
  click_here: 'Clic aici',
  copy: 'Copiază',
  copied: 'Copiat',
  copied_coordinates: 'Coordonate copiate',
  select: 'Selectează',
  selected: 'Selectat',
  selected_plural: 'Selectate',
  deselect: 'Deselectează',
  select_multi_vehicles: 'Selectează unul sau mai multe vehicule',
  include: 'Include',
  exclude: 'Exclude',
  balanced: 'Echilibrat',
  input: 'Intrarea',
  output: 'Ieșire',
  verified: 'Verificat',
  empty_search: 'Niciun rezultat găsit',
  read_more: 'Citeste mai mult',
  details: 'Detalii',
  actual: 'Actual',
  residual_time: 'Timpul ramas',
  next: 'Urmatorul',
  previous: 'Anterior',
  menu_open: 'Deschide meniul',
  hide: 'Ascunde',
  show: 'Arată',
  none: 'Nimeni',
  none_alt: 'Niciuna',
  all: 'Toți',
  all_alt: 'Toate',
  default: 'Predeterminat',
  settings: 'Setări',
  reset_settings: 'Resetează setările',
  reset_settings_success: 'Setări resetate cu succes',
  multiselect_default_value: {
    singular: 'Valoarea selectat:',
    plural: 'Valorile selectate:'
  },
  available: 'Disponibil',
  unavailable: 'Nu este disponibil',
  not_available: 'Nu este disponibil',
  not_available_plural: 'Nu sunt disponibili',
  from: 'de la',
  to: 'la',
  of: 'de',
  yes: 'da',
  no: 'Nu',
  minutes: 'Minute',
  hours: 'Ore',
  hour: 'Ora',
  days: 'Zile',
  day: 'Ziuă',
  months: 'Luni',
  month: 'Lună',
  years: 'Ani',
  year: 'An',
  days_names: [
    'Duminică',
    'Luni',
    'Marți',
    'Miercuri',
    'Joi',
    'Vineri',
    'Sâmbătă'
  ],
  days_names_short: [
    'Du',
    'Lu',
    'Ma',
    'Mi',
    'Jo',
    'Vi',
    'Sb'
  ],
  months_names: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie'
  ],
  months_names_short: [
    'Ian',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Iun',
    'Iul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
  ],
  areas: 'Zone',
  area: 'Zona',
  time: 'Timp (min)',
  time_label: 'Timp',
  cost: 'Cost',
  toll_cost: 'Costul taxei rutiere',
  km_cost: 'Cost pe km',
  hourly_cost: 'Cost orar',
  working_cost: 'Cost om total',
  total_cost: 'Costul total',
  expire_date: 'Data scadenței',
  start_date: 'Data de incepere',
  recap: 'Recapitulare',
  start: 'Început',
  starting_point: 'Plecare',
  end: 'Sfârșit',
  finish_point: 'Sosire',
  duration: 'Durata',
  distance: 'Distanță',
  daily_distance: 'Km parcurși pe zi',
  distance_total: 'Distanză totală',
  gps_distance: 'Distanză(GPS)',
  distance_gps: 'Km parcurși pe zi(GPS)',
  pause: 'Pausă',
  segment: 'Traseu',
  start_time: 'Ora de îcepere',
  end_time: 'Ora de încheiere',
  service_time: 'Timpul de serviciu',
  waypoint_load: 'Încărcare la bord',
  state: 'Stat',
  violated_plafond: 'Încălcare plafond',
  traffic_delay: 'Întârziere trafic',
  company: 'Companie',
  address: 'Adresă',
  position: 'Poziție',
  route: 'Traseu',
  filter_date: 'Filtratză după dată',
  warning: 'Atenție',
  no_warning: 'Niciun avertisment',
  alerts: 'Alerte',
  trucks_with_alerts: 'Vehicule cu avertismente',
  bluetooth_devices: 'Dispozitive Bluetooth',
  no_bluetooth_devices: 'Niciun dispozitiv Bluetooh',
  connected: 'Conectat',
  disconnected: 'Deconectat',
  bluetooth_types: {
    temperature_probe: 'Temperatra sondei',
    door_probe: 'Sonda portiera si temperatura'
  },
  back_to_home: 'Înapoi la home',
  cerca: 'Caută',
  search_vehicle: 'Caută vehicul',
  filter: 'Filtrează',
  filter_label: 'Filtru',
  filters_label: 'Filtre',
  filter_by: 'Filtrează după',
  operations: 'Operațiuni',
  filter_vehicles: 'Filtrează vehicule',
  filter_applied: 'Filtru aplicat cu succes',
  filter_canceled: 'Filtru eliminat cu succes',
  order_by: 'Sortează după',
  order: 'Ordine',
  ascending: 'Ascendentă',
  descending: 'Descendentă',
  value: 'Valoare',
  select_filter: 'Selectați filtru',
  sort_vehicles: 'Oodona vehicule',
  sort_for_alphanumeric: 'Alfabetică',
  sort_for_lastpoint: 'Cronologică',
  sort_for_company: 'Companie',
  sort_for_activity: 'Activitate',
  company_select: 'Selectați compania',
  no_companies_founded: 'Nu a fost găsită nicio companie',
  multicompany: 'Filtrează după companie',
  no_vehicles_founded: 'Nu au fost găsite vehicule',
  print: 'Imprimare',
  export: 'Exporta',
  print_recap: 'Imprimați rezumalul',
  select_date: 'Selectați o dată',
  add: 'Adăugați',
  add_new: 'Adăugați nou',
  delete: 'Elimină',
  edit: 'Modifică',
  options: 'Opțiuni',
  login: 'Conectați-vă',
  logout: 'Ieși',
  logout_confirm: 'Ești sigur că vrei să ieși?',
  login_button: 'Accesează',
  connect: 'Conectați',
  logged: 'Conectat',
  manage: 'Gestionează',
  loading: 'Încărcare',
  loading_timeout: 'Eroare de rețea,vă rugăm să reîncărcați pagina sau încercați din nou mai târziu',
  go_back: 'Înapoi',
  select_coordinates: 'Selectați coordonatele',
  other: 'Altceva',
  options_general: 'Generale',
  options_language: 'Limba',
  change_interface_language: 'Selectați limba interfaței',
  change_infringment_language: 'Selectați națiune încălcările',
  change_timezone: 'Selectați fusul orar',
  save_language_settings: 'Salvați setările de limbă',
  vehicle_list_options: 'Lista vehicule',
  select_view: 'Selectați vizionată',
  select_fields_placeholder: 'Selectați câmpurile de afișat',
  selected_field: 'Câmpul selectat',
  selected_fields: 'Câmpurile selectate',
  list: 'Listă',
  table: 'Tabelă',
  view_options: 'Vizualizare opțiuni',
  fluid_table: 'Lățime dinamică',
  fluid_table_descr: 'Faceți lățimea tabelei fluidă pe baza câmpurilor selectate',
  vehicle_fields_list: 'Ce informații doriți să apară pentru fiecare vehicul? (Max 3)',
  vehicle_fields_list_warning: 'Atenție: câmpul nu va fi afișat dacă nu este disponibil pentru vehicul',
  vehicle_identification: 'Cum doriți ca vehiculul să fie identificat în lista principală?',
  apply_to_marker: 'Aplicați această setare pe vehiculele de pe hartă',
  element_icon_section: 'Pictograma  element',
  element_title_section: 'Titlul element',
  element_fields_list_section: 'Lista câmpuri  element',
  element_icon_description: 'Selectați pictograma de afișat pentru element',
  element_icon: 'Pictogramă',
  element_icon_color: 'Culoare pictogramă',
  options_map: 'Opțiuni hartă',
  vehicles_clustering: 'Regruparea  vehiculelor',
  vehicles_clustering_warning: 'Actualizarea acestei setări necesită reîncărcarea paginii',
  options_marker: 'Opţiuni marker',
  size: 'Dimensiune',
  font_size: 'Dimensiunea textului',
  font_color: 'Culoarea textului',
  background_color: 'Culoare de fundal',
  opacity: 'Transparență',
  color: 'Culoare',
  small: 'Mic',
  medium: 'Mediu',
  large: 'Mare',
  additional_info: 'Informații suplimentare',
  analysis_options: 'Analiza traseului',
  options_chart: 'Opțiuni grafic',
  options_timeline: 'Opțiuni timeline',
  hide_timeline: 'Ascunde timeline',
  map: 'Hartă',
  silica: 'Silica',
  google: 'Google',
  google_satellite: 'Google Satellitare',
  sandbox: 'Sandbox',
  silkysand: 'Silkysand',
  gravelpit: 'Gravelpit',
  open_street_maps: 'Open Street Maps',
  arcgis_satellite: 'ArcGIS Satellite',
  traffic: 'Trafic',
  heavy_vehicles_ban: 'Interdicții pentru vehiculele grele',
  driver: 'Șofer',
  no_driver: 'Niciun șofer',
  drivers: 'Șoferi',
  driver_card: 'Card șofer',
  name: 'Nume',
  surname: 'Prenume',
  driver_activity: 'Activitate șofer',
  driver_status: 'Starea șoferului',
  activity_type: 'Tipul activităţii',
  activity: 'Activitate',
  no_activity: 'Nicio activitate',
  driver_at_rest: 'Șofer în repaus',
  driver_available: 'Șofer în disponibilitate',
  driver_at_work: 'Șofer la locul de muncă',
  driver_driving: 'Șofer în conducere',
  inactive_vehicle: 'Vehicul invactiv',
  last_driver_detected: 'Ultimul șofer detectat',
  rest: 'Odihna',
  work: 'Muncă',
  driving: 'Conducerea',
  availability: 'Disponibilitate',
  codriving: 'Conducerea în echipă',
  codriver: 'Echipaj',
  break: 'Pauză de sesiune',
  daily_rest: 'Repaus zilnic',
  violation: 'Încălcarea',
  to_begin_within: 'De înceaput  până în',
  to_end_within: 'De încheiat până în',
  after_total_time_travel: 'După o călătorie totală de',
  after_total_distance_travel: 'După o distanţă totală parcursă de',
  residual_commitment_type: 'Tipul angajamentului rămas',
  residual_commitment: 'Angajamentul rămas',
  commitment_hours: 'Ore de angajament',
  commitment_hours_description: 'Găsiți toate vehiculele ale căror șoferi au un angajament rezidual mai mare decât cel indicat',
  driver_plafond: 'Plafond șofer',
  residual_driving: 'Conducere reziduală',
  residual_bonuses: 'Bonusuri reziduale',
  consider_bonuses: 'Timpul se calculează luând în considerare bonusurile rămase',
  current_activity: 'Activitatea curentă',
  next_activity: 'Activitatea următoare',
  view_plafond_detail: 'Vizualizați detaliile plafond',
  card: 'Card',
  driver_change: 'Schimbarea șoferului',
  shared_links: 'Link-uri partajate',
  gf: 'POI detectate',
  analyze: 'Analizează',
  plan: 'Planifică',
  vehicle: 'Vehicul',
  vehicles: 'Vehicule',
  vehicle_info: 'Informații vehicul',
  vehicle_type: 'Tipul vehiculului',
  vehicle_body: 'Corpul vehiculului',
  truck_status: 'Starea vehiculului',
  brand: 'Marcă',
  model: 'Model',
  no_card: 'Cardul nu este introdus',
  no_card_driving: 'Conducerea fără card',
  speed: 'Viteză',
  tacho_speed: 'Viteză (tahograf)',
  gps_speed: 'Viteză (GPS)',
  level: 'Nivel',
  battery: 'Baterie',
  low_battery_level: 'Nivel scăzut al bateriei',
  fuel: 'Combustibil',
  fuel_level: 'Nivelul combustibilului',
  low_fuel_level: 'Combustibil redus',
  consumption: 'Consum',
  consumptions: 'Consumuri',
  fuel_consumption: 'Consumul de combustibil',
  fuel_avg_consumption: 'Consumul mediu zilnic de combustibil',
  altitude: 'Altitudine',
  weight: 'Greutate',
  lastpoint_weight: 'Greutate (ultimul punct)',
  vehicle_weight: 'Greutate (t)',
  vehicle_length: 'Lungime (m)',
  vehicle_width: 'Lățime (m)',
  vehicle_height: 'Înălțime (m)',
  odometer: 'Odometru',
  odometer_tacho: 'Odometru (tahograf)',
  odometer_gps: 'Odometru (GPS)',
  gps_odometer: 'Odometru (GPS)',
  last_point: 'Ultimul punct',
  moving: 'În mișcare',
  stopped: 'Oprit',
  engine_on: 'Motorul pornit',
  ignition: 'Tabloul de bord aprins',
  engine_off: 'Motorul oprit',
  engine_on_off: 'Aprinderea motorului',
  ignition_on: 'Tabloul de bord aprins',
  ignition_off: 'Tabloul de bord oprit',
  device: 'Dispozitiv',
  plate: 'Număr de înmatriculare',
  chassis: 'Șasiu',
  status: 'Status',
  custom_id: 'Nume personalizat',
  heading: 'Direcția',
  rpm_engine: 'Rotațiile motorului',
  pto_active: 'PTO activ',
  pto_state: 'PTO starea',
  device_timestamp: 'Timestamp',
  info: 'Info',
  solar_day_report: 'Report ziua solară',
  telemetry: 'Telemetrie',
  parameters: 'Parametrii',
  engine_coolant_temp: 'Temperatura motorului',
  engine_rpm: 'Rotații motor',
  dallas_temp_1: 'Temperatura 1',
  dallas_temp_2: 'Temperatura 2',
  dallas_temp_id_1: 'ID Temperatura 1',
  dallas_temp_id_2: 'ID Temperatura 2',
  digital_input_1: 'Intrare digitală 1',
  digital_input_2: 'Intrare digitală 2',
  digital_input_3: 'Intrare digitală 3',
  digital_input_4: 'Intrare digitală 4',
  digital_input_5: 'Intrare digitală 5',
  battery_level: 'Nivelul bateriei',
  adblue_liters: 'Litri Adblue',
  adblue_level: 'Nivel Adblue',
  gnss_status: 'Starea GNSS',
  gnss_status_values: [
    'Oprit',
    'Pornită, absența GPS',
    'Pornită, absența de sateliţi',
    'Pornită, cu sateliţi',
    'Pornită, în sleep',
    'Starea de protecție'
  ],
  gnss_off: 'GNSS închis',
  gnss_on_fix: 'GNSS pornit, cu sateliți',
  gnss_on_without_fix: 'GNSS pornit, absența sateliți',
  gnss_on_sleep: 'GNSS pornit, în sleep',
  gnss_on_protection: 'GNSS pornit starea de protecție',
  gsm_signal: 'Semnal GSM',
  tacho_source: 'Sursa tahografului',
  external_voltage: 'Alimente',
  tacho_overspeed: 'Tahograf Overspeed',
  no_points_title: 'Ultima activitate pentru mai mult de 24 de ore',
  no_points: 'Acest vehicul nu a trimis puncte mai mult de 24 de ore',
  clutch: 'Ambreiaj',
  clutch_switch: 'Comutator ambreiaj',
  brake_pedal: 'Pedala  frână',
  accelerator_pedal: 'Pedala accelerație',
  pedal_released: 'Pedala eliberată',
  pedal_pressed: 'Pedala presată',
  data_mode: 'Mod de date',
  home_stop: 'Oprit în sede',
  home_moving: 'În mișcare în sede',
  roaming_stop: 'Oprit în roaming',
  roaming_moving: 'În mișcare în roaming',
  unknown_stop: 'Oprit necunoscut',
  unknown_moving: 'În mișcare necunoscut',
  engine_coolant_temperature: 'Temperatură  lichid refrigerare',
  engine_temperature: 'Temperatura motorului',
  cruise_control_active: 'Cruise control activ',
  adblue_off: 'Adblue oprit',
  adblue_red: 'Adblue Roșu',
  adblue_yellow: 'Adblue Galben',
  adblue_info: 'Adblue info',
  charger_notconnected: 'Încărcătorul nu este conectat',
  charger_connected: 'Încărcător conectat',
  tacho_unknown: 'Necunoscut',
  tacho_kline: 'K-Line',
  tacho_allcan: 'BUS-CAN',
  tacho_can: 'BUS-CAN',
  tacho_fms: 'FMS',
  vehicle_not_found: 'Vehiculul pe care încercați să îl accesați nu este disponibil',
  vehicle_with_lock_commands: 'Vehicul cu comenzi de blocare',
  lock: 'Blochează',
  unlock: 'Deblocare',
  send: 'Trimite',
  command_sent: 'Comanda trimisă cu succes',
  turn_on: 'Aprinde',
  turned_on: 'Aprins',
  turn_off: 'Oprește',
  turned_off: 'Oprit',
  confirm_send_command: 'Confirmați că veți trimite comanda?',
  temperature: {
    title: 'Sonde de temperatură',
    probe: [
      'Sonda 1',
      'Sonda 2',
      'Sonda 3',
      'Sonda 4',
      'Sonda 5',
      'Sonda 6'
    ]
  },
  no_power_supply: 'Alimentare absentă',
  no_gps: 'GPS-ul absent',
  tacho_clock_from_updated_error: 'Eroarere sincronizarea ceasului tahografului',
  tacho_clock_from_device_error: 'Eroare de sincronizare între tahograf și unitatea de control',
  device_timestamp_error: 'Eroare data unitatea de control',
  select_color_mode: 'Selectați modul de culoare',
  light_mode: 'Modulitate clară',
  dark_mode: 'Modulitate întunecat',
  color_blind_mode: 'Modalitate daltoniștii',
  color_blind_dark_mode: 'Mod întunecat daltoniștii',
  authorized: "Autorizat",
  logged_out: "Deconectat",
  geofleet: {
    mainpanel: {
      pinned_vehicles: 'Vehicule în evidență',
      search_trucks_title: 'Căutați vehicule',
      search_trucks_tags_title: 'Tag asociate',
      search_trucks_status_title: 'Activitate vehicul',
      search_trucks_driver_status_title: 'Activități șofer',
      search_near_trucks_title: 'Găsește vehicule în apropiere'
    },
    detailspanel: {
      bind_vehicle: 'Vehicul asociat',
      add_new_tag: 'Adaugă ca nou tag',
      select_new_tag: 'Selectați sau căutați tag',
      binded_tags: 'Tag asociate',
      binded_tag: 'Tag asociat'
    },
    analyzer: {
      vehicle_activity_analisys: 'Analiza activității vehiculului',
      timeline_separator: 'Regrupează pe',
      loading_error: 'A apărut o eroare la încărcarea datelor'
    },
    search_resources: {
      title: 'Căutați resurse',
      description: 'Găsiți cele mai apropiate stații de realimentare, locuri de parcare și zone accesibile pentru vehiculul'
    }
  },
  analisys: {
    title: 'Analiza traseului',
    select_telemetries: 'Selectați telemetriile care urmează să fie  vizualizate  în analiza traseului',
    select_telemetries_description: 'Este posibil să selectaţi maximum 4 telemetrii care urmează să fie afişate',
    chart_groups: {
      movement_data: 'Date de mișcare',
      engine_data: 'Date motor',
      vehicle_data: 'Date vehicul',
      sensors_data: 'Date  senzori',
      driver_data: 'Date șofer',
      fuel_data: 'Date combustibil'
    },
    no_data: 'Nu există date disponibile',
    no_data_description: 'Nu există date privind ruta de afișat pentru acest vehicul sau pentru perioada selectată',
    no_data_contact_support: 'Contactați asistența pentru clienți pentru mai multe informații'
  },
  planner: {
    user_config: 'Configuraţii utilizator',
    no_user_config: 'Nu există configurații utilizator pentru a afișa',
    freight_access: 'Accesul la bagaje de încărcare',
    vehicle_actual_position: 'Poziția actuală a vehiculului',
    plan_route: 'Planifică-ți traseul',
    select_on_map: 'Selectați un punct de pe hartă',
    select_on_map_option: 'Selectați pe hartă',
    fill_route_info: 'Completați informațiile despre traseu',
    fill_resources: 'Trasee mele',
    fill_waypoints: 'Selectați punctul de adăugat ca etapă',
    load_breaks: 'Selectați etapele',
    load_alternative_routes: 'Încărcați pauzele',
    savedroutes: {
      my_routes: 'Încărcați traseuri alternative',
      saved_routes: 'Traseuri salvate',
      no_saved_routes: 'Nu ai salvat încă niciun traseu',
      success_saved_route: 'Traseul tău a fost salvat cu succes',
      error_saved_route: 'A apărut o eroare în timpul operațiunii de salvare a traseului',
      success_deleted_route: 'Traseul a fost şters cu succes',
      error_deleted_route: 'A apărut o eroare în timpul ștergeri traseului'
    },
    resources: {
      area_search: 'Căutare pe o rază de',
      station_services: 'Inclusiv aceste servicii',
      parking_services: 'Servicii parcare',
      select_rating: 'Selectați scorul',
      select_security: 'Selectați securitatea',
      empty_search: 'Căutarea nu a găsit niciun element de afișat',
      delivery_nation: 'Țara de livrare',
      fai_featured: 'Convenţionat Lumesia',
      self_service: 'Self Service',
      served: 'Servit',
      total_parking_spots: 'Total locuri',
      rating: 'Rata',
      reviews: 'Recenzii',
      security: 'Siguranță',
      price_per_night: 'Preţ per noapte'
    },
    emissions_labels: 'Emisii',
    emissions: {
      fuelconsumption: 'Consumul de combustibil',
      hydrocarbons: 'Hidrocarburi',
      methane: 'Metan',
      hydrocarbonsexceptmethane: 'Hidrocarburi, cu excepția metanului',
      carbonmonoxide: 'Monoxid de carbon',
      carbondioxide: 'Dioxid de carbon',
      fossilcarbondioxide: 'Dioxid de carbon fosil',
      sulphurdioxide: 'Dioxid de sulf',
      nitrogenoxides: 'Oxizi de azot',
      nitrogendioxide: 'Dioxid de azot',
      nitrousoxide: 'Oxid de azot',
      ammonia: 'Amoniac',
      benzene: 'Benzen',
      particles: 'Particule',
      numberofparticles: 'Număr de particule'
    },
    plafond_configuration: {
      title: 'Configurarea plafond',
      driving_time_regulation: 'Timpul de conducere',
      last_time_the_driver_worked: 'Ultima activitate a șoferului',
      eu_driving_time_regulation_for_single_day: 'O singură zi',
      eu_driving_time_regulation_for_single_long_day: 'O singură zi (cu bonus)',
      eu_driving_time_regulation_for_multiple_day: 'Mai multe zile',
      eu_driving_time_regulation_for_team_and_multiple_day: 'Mai multe zile (cu echipaj)',
      accumulated_driving_time_since_lastbreak: 'Ore de conducere de la ultima pauză',
      accumulated_driving_time_since_lastdailyrest: 'Ore de conducere de la ultima odihnă zilnică',
      accumulated_travel_time_since_lastdailyrest: 'Ore de călătorie de la ultima odihnă zilnică'
    },
    set_route: 'Stabiliți ruta',
    select_profile: 'Selectează profilul',
    select_co2_class: 'Selectați clasa de emisii',
    class: 'Clasă',
    billed_roads: 'Drumuri cu taxă',
    waypointevent: 'Etapă',
    waypoints_label: 'Etape',
    add_waypoint: 'Adăugați etapa',
    delete_waypoint: 'Eliminați etapa',
    select_waypoint: 'Selectează etapa',
    select_resource: 'Selectați punctul de adăugat ca etapă',
    last_waypoint: 'Ultima etapă',
    waypoints_check: 'Există etape de completat',
    waypoints_required: 'Sunt necesare cel puțin 2 etape',
    countryevent: 'Stat',
    tollevent: 'Stația de taxare',
    event: 'Eveniment',
    alpega: 'Alpega',
    alpega_login: 'Login Alpega',
    alpega_searches: 'Căutarile tale',
    timocom: 'Timocom',
    pickup: 'Retragere',
    delivery: 'Livrare',
    extimated_cost: 'Cost estimat',
    calculate_route: 'Calculați traseul',
    calculate_isocrona: 'Calculați izocronul',
    complete_step_2: 'Completă etapa 2',
    reset_route: 'Traseu nou',
    route_error: 'A apărut o eroare în timpul calculării rutei',
    edit_route: 'Modifică traseul',
    save_route: 'Salvaează traseul',
    delete_route: 'Elimină traseul',
    route_name: 'Numele traseului',
    override_route: 'Suprascrivi traseul',
    save_as_new_route: 'Salvează traseul',
    calculate_route_for: 'Calculează traseul pentru',
    ptvexport: 'Exportează în PTV Navigator',
    isocrona: 'Zone accesibile',
    reset_isocrona: 'Resetează zonele accesibile',
    search_location: 'Caută localitate',
    search_station: 'Caută distribuitor',
    search_parking: 'Caută parcare',
    search_poi: 'Caută POI',
    include_plafond: 'Includeți plafond  șofer în calculul traseului',
    include_plafond_near_vehicles: 'Găsiți vehicule cu șoferi care au un angajament rezidual suficient pentru a ajunge la localitatea pe care o căutați',
    violated_plafond: 'Traseul selectat încalcă plafond al șoferului'
  },
  sharedlinks: {
    back_to_links: 'Înapoi la link-uri',
    create_link: 'Creați un link',
    update_link: 'Actualizați linkul',
    edit_link: 'Modifică linkul',
    update_link_expire_date: 'Actualizați data scadenței',
    delete_link: 'Eliminați linkul',
    delete_link_confirm: 'Eliminre linkul selectat?',
    all_your_shared_links: 'Toate link-urile partajate',
    no_shared_links: 'Nu ați partajat încă niciun vehicul',
    vehicles_link: 'Link-uri pentru vehicule',
    select_share_vehicles: 'Selectați vehicule pentru a partaja',
    select_start_expire_date: 'Selectați data de început și de sfârșit',
    created_link_error: 'Eroare în timpul creării link-ului',
    created_link_success: 'Link creat cu succes',
    updated_link_error: 'Eroare la actualizarea linkului',
    updated_link_success: 'Link actualizat cu succes',
    service_description: 'Partajați acest link pentru a afișa urmărirea vehiculelor selectate',
    link_preview: 'Previzualizare link',
    share_single_vehicle: 'Partajați locația acestui vehicul',
    track_vehicle_position: 'Urmăriți locația vehiculelor selectate',
    start_date_warning: 'Nu se poate modifica data de începere',
    start_date_condition: 'Dacă doriți să modificați data de începere, trebuie să creați un link nou',
    expire_date_description: 'Indicați o dată de expirare, după care acest link nu va mai fi disponibil',
    start_expire_date_description: 'Indicați o dată de începere, de la care va fi disponibil acest link și o dată de expirare, după care acest link nu va mai fi disponibil:'
  },
  viewsharedlink: {
    invalid_date_range: 'Intervalul de date pe care l-ați selectat nu este valabil pentru acest link',
    too_early_link: 'Link-ul pe care încercați să îl accesați nu este încă disponibil',
    too_late_link: 'Link-ul pe care încerci să îl accesezi nu mai există',
    show_route: 'Arată traseul'
  },
  tags: {
    manage_tags: 'Gestionați tags',
    add_tag: 'Adăugați tag',
    add_tag_placeholder: 'Introduceţi numele tag',
    add_tag_error: 'Eroare la adăugarea tag',
    add_tag_success: 'Tag adăugat cu succes',
    delete_tag: 'Ștergeți tag',
    delete_tag_confirm: 'Ștergeți tag selectat?',
    delete_tag_error: 'Eroare la ștergerea tag',
    delete_tag_success: 'Tag a fost eliminat cu succes',
    update_tag: 'Actualizați tag',
    update_tag_error: 'Eroare la actualizarea tag',
    update_tag_success: 'Tag actualizat cu succes',
    all_your_tags: 'Toate tag-urile tale',
    no_tags: 'Nu ați creat încă niciun tag',
    tag_name: 'Nume Tag'
  },
  manage_poi: {
    title: 'Gestionați POI',
    your_pois: 'POI-UL tău',
    add_poi: 'Adăugați POI',
    no_pois: 'Nu ați creat încă niciun POI',
    fields: {
      name: 'Nume',
      type: 'Tip',
      color: 'Culoare',
      note: 'Note',
      address: 'Caută adresa',
      latitude: 'Latitudine',
      longitude: 'Longitudine',
      radius: 'Raza de căutare',
      radius_description: 'Pentru a activa geofencing, setați o valoare mai mare decât zero'
    },
    types_poi: {
      all: 'Toți',
      headquarter: 'Sediu',
      truck_washes: 'Spălătorie auto  autotrenuri',
      blacklist: 'Blacklist',
      end_of_the_line: 'Sfârșitul liniei',
      customers: 'Clienți',
      stops: 'Opriri',
      suppliers: 'Furnizori',
      interports: 'Interporturi',
      markets: 'Piețe',
      large_square: 'Platformă',
      poi_distributors: 'POI distribuitori',
      poi_workshops: 'POI ateliere',
      harbors: 'Porturi',
      restaurants: 'Restaurante',
      stations: 'Stații'
    },
    messages: {
      add_poi_error: 'Eroare la adăugarea POI',
      add_poi_success: 'POI a adăugat cu succes',
      update_poi_error: 'Eroare la actualizarea POI',
      update_poi_success: 'POI actualizat cu succes',
      delete_poi_error: 'Eroare în timpul ștergerii POI',
      delete_poi_success: 'POI a fost eliminat cu succes'
    }
  },
  vehicles_cross: {
    title: 'Trecerea vehiculelor',
    fields: {
      address: 'Adresa',
      radius: 'Raza',
      time_interval: 'Interval de timp',
      time_interval_description: 'Selectați un interval de timp maxim de 24 de ore'
    },
    messages: {
      max_time_interval: 'Intervalul maxim de timp este de 24 de ore',
      error_searching: 'O eroare a apărut în timpul căutări vehiculelor',
      searching: 'Căutarea în curs…',
      long_loading: 'Căutarea poate dura câteva minute, vă rugăm să așteptați...',
      no_vehicles_cross: 'Nu au fost găsite vehicule'
    }
  },
  plafonds: {
    session: 'Pauză de sesiune',
    break: 'Pauză',
    drive_occurring: 'Perioada de Conducere în curs de desfășurare:',
    drive_max_label: 'Timp maxim de conducere',
    drive_max_value: '4h 30m',
    drive_done: 'Conducerea efectuată',
    session_title: 'Informațiile despre Pauza Fracționată',
    session_tooltip: 'Este posibil efectuarea unei pauze fracționată',
    session_done: 'A fost detectată o pauză de cel puțin 15 minute.',
    session_info: 'A  fost efectuată deja prima parte a unei Pauze Fracționată',
    session_descr: "Vă reamintim că pentru reglementările actuale este posibil să se facă o pauză de 15'+30' sau 15'+15'+15' (în funcție de tipul de transport efectuat).",
    session_choice: 'Este posibil să se efectueze cel puțin <b>30m</b> (transport de marfă) sau <b>15m + 15m</b> (transport pasageri) în următorele',
    brake_start: 'Începutul pauzei',
    brake_done: 'Pauză efectuată',
    daily: 'Zilnic',
    weekly: 'Săptămânal',
    driver_in_drive: 'Conduceți de',
    driver_in_rest: 'Reziduu odihnă',
    driver_in_work: 'Muncă',
    driver_in_available: 'Disponibilitate',
    driver_in_availability: 'Disponibilitate',
    current_activity: 'Activitatea curentă',
    mini_plafond: 'Mini-Plafond',
    last_update: 'Ultima actualizare',
    panel_title: 'Panoul Plafond',
    residual_commitment: 'Angajamentul rezidual',
    daily_driving: 'Conducere zilnică',
    weekly_guide: 'Conducere săptămânală',
    biweekly_guide: 'Conducere bi-săptămânală',
    last_rest: 'Ultima odihnă săptămânală efectuată',
    minimal_duration_next: 'Durata minimă următorul',
    top_start_until: 'Începeți  înainte de',
    reduced_weekly_rest: 'Repausuri săptămânale reduse care trebuie compensate',
    time_to_compensate: 'Timpul pentru a compensa',
    time_to_compensate_until: 'Pentru a fi compensată  înainte de',
    footer_message: '* timpul se calculează luând în considerare bonusul rămas',
    driver_in_block: 'Plafond șofer epuizat',
    'résidu_commitment': 'Reziduul angajamentului',
    week_guide: 'Conducere  hebdomadaire',
    'réduit_weekly_rest': 'Repos hebdomadaires réduits pour compenser'
  },
  error_pages: {
    error_403: {
      title: 'Eroare 403',
      description: 'Stimate client, vă informăm că în prezent contul dumneavoastră nu este configurat pentru a accesa acest serviciu. Pentru a primi informații despre serviciul în sine',
      retry_login: 'Vă rugăm să încercați din nou',
      contact_us: 'Contactați-ne'
    }
  },
  ptv: {
    maxpermittedweight: 'Greutate maximă',
    minpermittedweight: 'Greutate minimă',
    zonename: 'Zonă',
    unauthorizedtype: 'Codul nu este autorizat',
    maxemissionstandardeu: 'Clasa de emisii',
    fueltype: 'Tipul de combustibil',
    loadtype: 'Tipul de transport',
    opening: 'Pasaj',
    maxheight: 'Înălțimea maximă',
    maxweight: 'Greutate maximă',
    maxwidth: 'Lățimea maximă',
    maxlength: 'Lungimea maximă',
    absolutespeed: 'Viteză',
    hazardoustowaters: 'Interzicerea vehiculelor cu mărfuri periculoase pentru apă',
    hazardousgoods: 'Interzicerea vehiculelor cu mărfuri periculoase',
    combustibles: 'Interdicția asupra vehiculelor care transportă combustibil',
    maxaxleload: 'Greutatea maximă la osii',
    totalpermittedweight: 'Greutate maximă',
    tunnelrestriction: 'Cod de restricție tunel',
    kingpintorearaxle: 'Distanța de la Kingpin a axei',
    numberofaxles: 'Numărul de axe',
    length: 'Coadă',
    delay: 'Întârziere',
    message: {
      text: 'Mesaj'
    }
  },
  options_interface: 'Interfață opțiuni'
}