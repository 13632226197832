<template>
    <div class="spinner-wrapper">
        <Spinner :spacing="15" :font-size="16" :message="message" text-fg-color="#fff"> </Spinner>
        <a href="javascript:;" class="close-spinner" v-if="hideable" @click="hideLoader">{{
            __('hide')
        }}</a>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Spinner from 'vue-simple-spinner'

export default {
    name: 'GoliaLoader',
    components: {
        Spinner
    },
    props: {
        message: {
            type: String
        },
        hideable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        hideLoader() {
            this.$store.dispatch('hideLoader')
        }
    }
}
</script>
