import { render, staticRenderFns } from "./GoliaMultiselect.vue?vue&type=template&id=47ba0762&scoped=true"
import script from "./GoliaMultiselect.vue?vue&type=script&lang=js"
export * from "./GoliaMultiselect.vue?vue&type=script&lang=js"
import style0 from "./GoliaMultiselect.vue?vue&type=style&index=0&id=47ba0762&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ba0762",
  null
  
)

export default component.exports