<template>
    <l-marker :visible="poi.visible" :lat-lng="poi.latLng">
        <l-icon :icon-anchor="[15, 37]" :icon-size="[30, 37]" class-name="">
            <svg
                id="b36d38f2-a603-4ee4-9976-ecab66e947b9"
                data-name="Livello 2"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 16 20"
            >
                <defs>
                    <linearGradient
                        id="a31d34f1-0f49-4030-82e3-1990537f5564"
                        data-name="Sfumatura senza nome 6"
                        x1="15.7"
                        y1="4.04"
                        x2="1.41"
                        y2="12.29"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stop-color="#fff" />
                        <stop offset="1" stop-color="#c8c8c8" />
                    </linearGradient>
                </defs>
                <path
                    :fill="poi.color || poi.type_color"
                    d="M12.4.9H3.6C2,.9,1.2,1.7,1.2,3.5v8.1c0,1.5,2.6,2.4,3,2.8L8,19.1H8l3.8-4.7c.4-.4,3-1.3,3-2.8V3.5C14.8,1.7,14,.9,12.4.9Z"
                    stroke="#fff"
                    stroke-miterlimit="10"
                    stroke-width="0.75"
                />
                <text
                    x="0.7"
                    y="12"
                    style="font-family: 'Font Awesome Kit', 'FontAwesome'; font-size: 12px"
                    fill="#fff"
                    v-html="poi.type_icon"
                ></text>
            </svg>
        </l-icon>
        <l-popup>
            <div v-if="!editing" class="popup">
                <div class="title">
                    <i @click="editing = true" class="edit fa-solid fa-pen-to-square"></i>
                    {{ poi.description }}
                </div>
                <div class="note" v-html="poi.note"></div>
            </div>
            <div v-else class="popup">
                <div class="title">{{ poi.description }}</div>
                <span class="form">
                    {{ __('Colore') }}
                    <input
                        class="form-control form-control-sm"
                        list="presetColors"
                        type="color"
                        maxlength="10"
                        :value="poi.color || poi.type_color"
                    />
                    <datalist id="presetColors">
                        <option value="#00ffff"></option>
                        <option value="#000000"></option>
                        <option value="#0000ff"></option>
                        <option value="#ff00ff"></option>
                        <option value="#008000"></option>
                        <option value="#00ff00"></option>
                        <option value="#800000"></option>
                        <option value="#000080"></option>
                        <option value="#808000"></option>
                        <option value="#800080"></option>
                        <option value="#ff0000"></option>
                        <option value="#c0c0c0"></option>
                        <option value="#008080"></option>
                        <option value="#ffffff"></option>
                        <option value="#ffff00"></option>
                    </datalist>
                    {{ __('Note') }}
                    <textarea
                        id="NOTE"
                        name="NOTE"
                        class="form-control form-control-sm"
                        v-html="poi.note"
                    ></textarea>
                    <div style="display: flex; justify-content: flex-end">
                        <button @click="save" type="button" class="btn btn-info btn-sm">
                            {{ __('Salva') }}
                        </button>
                    </div>
                </span>
            </div>
        </l-popup>
    </l-marker>
</template>

<script>
import { LMarker, LIcon, LPopup } from 'vue2-leaflet'

export default {
    name: 'PoiMarker',
    props: ['poi'],
    components: {
        LMarker,
        LIcon,
        LPopup
    },
    data() {
        return {
            bounce: false,
            editing: false
        }
    },
    watch: {},
    methods: {
        save() {
            this.editing = false

            this.$fetch(this.$env.GOLIA_API + '/poi/' + this.poi.id, {
                method: 'PUT',
                body: JSON.stringify({
                    note: this.poi.note,
                    color: this.poi.color
                })
            }).then((res) => {
                return res.json()
            })
        }
    }
}
</script>

<style scoped>
.popup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.title {
    color: #515059;
    font-size: 0.7rem;
    font-weight: 500;
}
.note {
    color: #515059;
    font-size: 0.6rem;
    font-weight: 400;
}
.edit {
    cursor: pointer;
}
.form {
    color: #515059;
    font-size: 0.7rem;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    gap: 5px;
}
</style>
