<template>
    <div class="row">
        <div class="col-lg-6" v-for="(singleSort, key) in sort" :key="key">
            <div
                class="single-toggle d-flex justify-content-between align-items-center"
                :class="sortTogglerClass(singleSort)"
                @click="toggleSorter(key)"
            >
                <span class="badge rounded-pill bg-success">{{
                    sortOrder[singleSort.fields[0]]
                }}</span>
                {{ singleSort.label }}
                <i :class="sortIconClass(key)"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SortTrucks',
    props: {
        sort: {
            type: Object,
            required: true
        },
        sortAction: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            sortOrder: {},
            localSort: this.sort
        }
    },
    methods: {
        toggleSorter(key) {
            if (!this.localSort[key].active) {
                this.localSort[key].active = true
            } else {
                if (this.localSort[key].order == 'asc') {
                    this.localSort[key].order = 'desc'
                } else {
                    this.localSort[key].order = 'asc'
                    this.localSort[key].active = false
                }
            }

            this.$emit('sortToggle', key)
        },
        sortIconClass(key) {
            return {
                'fa-solid': true,
                'fa-arrow-down-short-wide': true,
                inverse: this.localSort[key].order == 'desc'
            }
        },
        sortTogglerClass(sortStatus) {
            return {
                active: sortStatus.active,
                desc: sortStatus.active && sortStatus.order == 'desc',
                asc: sortStatus.active && sortStatus.order == 'asc'
            }
        }
    },
    watch: {
        sort: {
            handler: function (val) {
                this.localSort = val
            },
            deep: true
        },
        sortAction: {
            handler() {
                this.sortOrder = {}
                this.sortAction.forEach((singleAction, index) => {
                    if (singleAction.key.indexOf(singleAction.key) > -1) {
                        this.sortOrder[singleAction.key] = index + 1
                    }
                })
            },
            deep: true
        }
    }
}
</script>
