<template>
    <div>
        <div class="drivers-wrapper" v-if="localDrivers.length > 0">
            <div class="drivers-section">
                <template v-for="driver in localDrivers">
                    <div
                        class="driver-info-section d-flex flex-column"
                        :class="singleDriverClass(driver)"
                        @click="switchDrivers(driver)"
                        :key="driver.position"
                        v-if="driver"
                    >
                        <div class="driver-title">
                            {{ __('driver') }} {{ driver.position }}
                            <i
                                class="fa-solid float-end"
                                :class="
                                    driver.selected
                                        ? 'fa-arrow-left-to-line'
                                        : 'fa-arrow-right-to-line'
                                "
                            ></i>
                        </div>

                        <transition name="common-fade" mode="in-out">
                            <div class="driver-selected-info" v-if="driver.selected">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="driver-info">
                                            <i class="driver-icon fa-solid fa-fw fa-user"></i>
                                            {{
                                                driver.name == 'NO CARD'
                                                    ? __('no_card')
                                                    : driver.name
                                            }}
                                            {{ driver.surname }}
                                        </div>
                                        <div class="driver-info">
                                            <i
                                                class="driver-icon fa-solid fa-fw fa-address-card"
                                            ></i>
                                            {{ driver.card }}
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <!-- DRIVER'S PLAFOND -->
                                        <text-plafond :driver="driver" />
                                    </div>
                                </div>
                            </div>

                            <div class="driver-closed-info d-flex flex-column" v-else>
                                <div class="d-flex justify-content-center align-items-center user">
                                    <i class="fa-solid fa-fw fa-user" style="font-size: 2em"></i>
                                </div>

                                <small>{{ driver.name }} {{ driver.surname }}</small>
                            </div>
                        </transition>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DriversSelection',
    props: {
        drivers: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            localDrivers: this.drivers
        }
    },
    watch: {
        drivers: function (val) {
            this.localDrivers = val
        }
    },
    methods: {
        switchDrivers(driver) {
            this.localDrivers.forEach((d) => {
                if (d)
                    if (d.position === driver.position) {
                        d.selected = !d.selected
                    } else {
                        d.selected = false
                    }
            })
        },
        singleDriverClass(driver) {
            if (driver)
                return {
                    selected: driver.selected,
                    'lone-driver': !this.localDrivers[1]
                }
        }
    },
    mounted() {
        this.localDrivers.map((driver, index) => {
            if (driver)
                if (index === 0) {
                    this.$set(driver, 'selected', true)
                } else {
                    this.$set(driver, 'selected', false)
                }
        })
    }
}
</script>

<style scoped lang="scss">
.drivers-section {
    display: flex;
    flex-direction: row;
    padding-top: 18px;
    padding-bottom: 12px;
    justify-content: flex-start;

    font-size: 14px;

    gap: 16px;
}

.driver-info-section {
    background-color: var(--main-inner-bg-color);
    border-radius: 25px;
    padding: 10px 25px;
    width: 25%;
    height: 140px;
    transition: width 0.3s;
    transition-timing-function: ease-out;

    &:hover {
        background-color: var(--main-bg-color--hover);
        cursor: pointer;
    }

    &.selected {
        width: 80%;
        transition: width 0.3s;
        transition-timing-function: ease-out;
        &.lone-driver {
            width: 100%;
        }
    }

    .driver-icon {
        min-width: 28px;
    }
    .driver-title {
        font-family: 'Stolzl-Medium';
        margin-bottom: 6px;
        border-bottom: 1px solid #666;
    }

    .driver-closed-info {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 100%;

        .user {
            flex-grow: 2;
        }
    }

    ::v-deep(current-activity) {
        display: none;
    }
}
</style>
