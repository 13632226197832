<template>
    <div>
        <div class="input-icons">
            <!-- <i v-if="!loader" class="fa fa-search"></i> -->
            <!-- <i v-else class="fas fa-spinner fa-pulse"></i> -->
            <input
                ref="input"
                v-model="q"
                @input="$emit('input', q)"
                type="text"
                :placeholder="__('cerca')"
                class="input-field"
                id="find"
                name="find-mezzo"
            />
            <i
                class="fa fa-circle-xmark right"
                style="cursor: pointer"
                v-show="q"
                @click="resetSearch"
            ></i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SearchInput',
    props: {},
    components: {},
    data() {
        return {
            q: null,
            loader: false
        }
    },
    methods: {
        resetSearch() {
            this.q = null
            this.$emit('resetSearch')
        },
        startLoader() {
            this.loader = true
        },
        stopLoader() {
            this.loader = false
        }
    },
    computed: {}
}
</script>

<style scoped>
#find {
    padding: 7px 12px 7px 12px;
    margin: 5px;
    border-radius: 25px;
    border: 1px solid #efefef;
    box-shadow: 1px 1px 2px 2px #f9f9f9;
}
.input-icons i {
    position: absolute;
    top: 0;
    padding: 17px;
    min-width: 40px;
    color: #a9a9a9;
}
.input-icons i.right {
    right: 0;
}
.input-icons {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}
.input-field {
    width: 98%;
    padding: 10px;
    text-align: left;
}
input[type='text'] {
    -webkit-transition: 1s;
    transition: 1s;
    color: #74869b;
}
input[type='text']:focus {
    color: #394a5d;
}
::placeholder {
    color: #a9a9a9;
}
</style>
