var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plafond-container mb-3",class:[_vm.loading && 'plafond-container--loading']},[(_vm.loading)?_c('div',{staticClass:"spinner-wrapper"},[_c('Spinner')],1):(_vm.driverActivities.state)?[_c('div',{staticClass:"p-date"},[_vm._v(" "+_vm._s(_vm.__('plafonds.last_update'))+": "+_vm._s(_vm._f("formatDateWithFormat")(_vm.detectionDate,'L HH:mm', 'UTC'))+" ")]),_c('div',{staticClass:"tiny-wrapper"},[_c('div',{staticClass:"details-wrap"},[_c('h6',[_vm._v(_vm._s(_vm.__('plafonds.current_activity')))]),_c('PlafondDriverActivities',{attrs:{"value":_vm.driverActivities,"restInfo":{
                        driveTypeNextRest: _vm.driveTypeNextRest,
                        driverPlafond: _vm.driverPlafond
                    },"driver":_vm.driver}})],1),_c('div',{staticClass:"details-wrap"},[_c('h6',[_vm._v(_vm._s(_vm.__('plafonds.mini_plafond')))]),_c('div',{staticClass:"d-flex table-element"},[_vm._m(0),_c('div',{staticStyle:{"min-width":"98px","flex-grow":"1"}},[_vm._v(" "+_vm._s(_vm.__('residual_commitment'))),(_vm.driveBonus)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{},[_vm._v(_vm._s(_vm.residualWorkingDay))])]),_c('div',{staticClass:"d-flex table-element"},[_vm._m(1),_c('div',{staticStyle:{"min-width":"98px","flex-grow":"1"}},[_vm._v(" "+_vm._s(_vm.__('residual_driving'))),(_vm.restBonus)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{},[_vm._v(_vm._s(_vm.residualDriveDay))])]),_c('div',{staticClass:"d-flex table-element"},[_vm._m(2),_c('div',{staticStyle:{"min-width":"98px","flex-grow":"1"}},[_vm._v(" "+_vm._s(_vm.__('residual_bonuses'))+" ")]),_c('div',{staticClass:"d-flex flex-column align-items-end"},[_c('div',[_vm._v(_vm._s(_vm.__('driving'))+": "+_vm._s(_vm.driveBonus))]),_c('div',[_vm._v(_vm._s(_vm.__('rest'))+": "+_vm._s(_vm.restBonus))])])])]),(_vm.showTinyHelper)?_c('div',{staticClass:"tiny-helper"},[_vm._v("* "+_vm._s(_vm.__('consider_bonuses')))]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[(_vm.$mq !== 'xs' && _vm.$mq !== 'sm')?_c('golia-button',{attrs:{"variant":"btn-fill dark-blue mt-3","label":_vm.__('view_plafond_detail')},on:{"click":function($event){return _vm.openPlafondPanel(_vm.driver)}}}):_vm._e()],1)])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"20px"}},[_c('i',{staticClass:"fa-solid fa-user-clock"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"20px"}},[_c('i',{staticClass:"fa-solid fa-steering-wheel"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"20px"}},[_c('i',{staticClass:"fa-solid fa-ticket"})])
}]

export { render, staticRenderFns }