<template>
    <div>
        <golia-input
            type="select"
            v-model="localRouteInfo.chosenProfile"
            :options="optProfiles"
            :placeholder="__('planner.select_profile')"
        ></golia-input>

        <golia-input
            type="select"
            class="mt-2"
            v-model="localRouteInfo.selectedCo2Class"
            :options="co2Classes"
            :placeholder="__('planner.select_co2_class')"
        ></golia-input>

        <div class="row">
            <div class="col-xxl-6 pe-0">
                <golia-input
                    type="select"
                    v-model="localRouteInfo.startMode"
                    :options="[
                        { value: 'start', text: __('start_time') },
                        { value: 'end', text: __('end_time') }
                    ]"
                ></golia-input>
            </div>
            <div class="col-xxl-6">
                <!-- modifica sia del start time che l'end time -->
                <golia-date-picker
                    v-model="localRouteInfo.startTime"
                    alignCalendar="right"
                    :monthPanels="1"
                    :singleDate="true"
                    :specificTime="true"
                ></golia-date-picker>
            </div>
        </div>

        <div class="row">
            <div class="col-xxl-6 pe-0">
                <golia-input
                    v-model="localRouteInfo.costPerKilometer"
                    :placeholder="__('km_cost')"
                ></golia-input>
            </div>
            <div class="col-xxl-6">
                <golia-input
                    v-model="localRouteInfo.workingCostPerHour"
                    :placeholder="__('hourly_cost')"
                ></golia-input>
            </div>
        </div>

        <div class="divider"></div>

        <template v-if="$can('use', 'planner_plafond')">
            <div class="d-flex align-items-center">
                <toggle-button
                    v-model="localRouteInfo.includePlafond"
                    :sync="true"
                    class="me-3"
                /><i
                    class="fa-user me-2"
                    :class="localRouteInfo.includePlafond ? 'fa-solid text-success' : 'fa-solid'"
                ></i>
                {{ __('planner.include_plafond') }}
            </div>

            <div class="divider"></div>
        </template>

        <p class="my-2">{{ __('planner.billed_roads') }}:</p>
        <div class="mb-5">
            <vue-slider
                v-model="localRouteInfo.tollPenalty"
                :min="-95"
                :max="500"
                :contained="true"
                :railStyle="{ backgroundColor: '#ddd' }"
                :processStyle="{ backgroundColor: 'var(--dark-blue)' }"
                :dotOptions="localRouteInfo.tollPenaltyDots"
                :marks="localRouteInfo.tollPenaltyMarks"
            />
        </div>

        <div class="divider"></div>

        <p class="my-2">{{ __('planner.calculate_route_for') }}:</p>
        <div class="mb-5">
            <vue-slider
                v-model="localRouteInfo.distanceTimeWeighting"
                :contained="true"
                :railStyle="{ backgroundColor: '#ddd' }"
                :processStyle="{ backgroundColor: 'var(--dark-blue)' }"
                :dotOptions="localRouteInfo.sliderOptions"
                :marks="localRouteInfo.distanceTimeWeightingMarks"
            />
        </div>
    </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
    name: 'RouteInfo',
    props: ['value', 'profiles'],
    components: {
        ToggleButton
    },
    data() {
        return {
            localRouteInfo: this.value,
            co2Classes: [
                {
                    value: 1,
                    text: this.__('planner.class') + ' ' + 1
                },
                {
                    value: 2,
                    text: this.__('planner.class') + ' ' + 2
                },
                {
                    value: 3,
                    text: this.__('planner.class') + ' ' + 3
                },
                {
                    value: 4,
                    text: this.__('planner.class') + ' ' + 4
                },
                {
                    value: 5,
                    text: this.__('planner.class') + ' ' + 5
                }
            ]
        }
    },
    computed: {
        optProfiles() {
            return this.profiles.map((p) => {
                let lang = this.$authProcess.getParams('Lingua') || 'it-IT'
                let text = p.name

                if (lang == 'en-GB') lang = 'en-EN' // Fix perchè non sappiamo metterci d'accordo sui nomi :-)

                if (p.translate) text = p.translate[lang]

                return { value: p.id, text: text }
            })
        }
    },
    watch: {
        localRouteInfo() {
            this.$emit('input', this.localRouteInfo)
        }
    }
}
</script>
