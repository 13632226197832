import Router from "../routes/geofleet";

const caller = {
  install: function (Vue) {
    Vue.prototype.$fetch = function (resource, init) {
      init = init || {};

      if (!init["headers"]) init["headers"] = {};

      let company = init["X-Company"] || Vue.prototype.$authProcess.getParams("Azienda");
      let token = init["token"] || Vue.prototype.$authProcess.getSsoToken();

      // if (!token)
      //     return Vue.prototype.$authProcess.triggerLoginProcess()

      init["headers"]["X-Company"] = company;
      init["headers"]["Authorization"] = "Bearer " + token;

      return fetch(resource, init).then((res) => {
        if (res.status == 401) return Vue.prototype.$authProcess.triggerLoginProcess();
        if (token && res.status == 403){ 
            return Router.push({
              name: "error-403",
            }).catch(() => {});
        }
 
        return res;
      });
    };
  },
};

export default caller;
