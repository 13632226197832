export default {
  open: 'Nyissa meg a  címet.',
  close: 'Zárja be a',
  apply: 'Alkalmazás',
  cancel: 'Törölje',
  search: 'Keresés',
  cancel_search: 'Keresés megszüntetése',
  edit_search: 'Keresés szerkesztése',
  erase: 'Törölje a  címet.',
  back_to_results: 'Vissza az eredményekhez',
  save: 'Mentés',
  retry: 'Próbálja újra',
  click_here: 'Kattintson ide',
  copy: 'Másolás',
  copied: 'Másolva',
  copied_coordinates: 'Másolt koordináták',
  select: 'Válassza ki a  címet.',
  selected: 'Kiválasztott',
  selected_plural: 'Kiválasztott',
  deselect: 'Válaszd le a  címet.',
  select_multi_vehicles: 'Válasszon ki egy vagy több járművet',
  include: 'Tartalmazza a  címet.',
  exclude: 'Kizárva',
  balanced: 'Kiegyensúlyozott',
  input: 'Bemenet',
  output: 'Kimenet',
  verified: 'Ellenőrzött',
  empty_search: 'Nincs eredmény',
  read_more: 'Bővebben',
  details: 'Részletek',
  actual: 'Jelenlegi',
  residual_time: 'Hátralévő idő',
  next: 'Következő',
  previous: 'Előző',
  menu_open: 'Menü megnyitása',
  hide: 'Rejtsd el',
  show: 'Mutasd meg a',
  none: 'Nincs',
  none_alt: 'Nincs',
  all: 'Minden',
  all_alt: 'Minden',
  default: 'Alapértelmezett',
  settings: 'Beállítások',
  reset_settings: 'Beállítások visszaállítása',
  reset_settings_success: 'A beállítások sikeres visszaállítása',
  multiselect_default_value: {
    singular: 'Kiválasztott érték:',
    plural: 'Kiválasztott értékek:'
  },
  available: 'Elérhető',
  unavailable: 'Nem elérhető',
  not_available: 'Nem elérhető',
  not_available_plural: 'Nem elérhető',
  from: 'a  címről',
  to: 'a  címen.',
  of: 'a',
  yes: 'Igen',
  no: 'Nem',
  minutes: 'Jegyzőkönyv',
  hours: 'Órák',
  hour: 'Most',
  days: 'Napok',
  day: 'Nap',
  months: 'Hónapok',
  month: 'Hónap',
  years: 'Évek',
  year: 'Év',
  days_names: [
    'Vasárnap',
    'Hétfő',
    'Kedd',
    'Szerda',
    'Csütörtök',
    'Péntek',
    'Szombat'
  ],
  days_names_short: [
    'Dom',
    'Mon',
    'Márc',
    'Wed',
    'Gio',
    'Péntek',
    'Sat'
  ],
  months_names: [
    'Január',
    'Február',
    'Március',
    'Április',
    'Május',
    'Június',
    'Július',
    'Augusztus',
    'Szeptember',
    'Október',
    'November',
    'December'
  ],
  months_names_short: [
    'Jan',
    'Február',
    'Márc',
    'Április',
    'Mag',
    'Jun',
    'Július',
    'Ago',
    'Állítsa be a',
    'Október',
    'Nov',
    'December'
  ],
  areas: 'Területek',
  area: 'Terület',
  time: 'Idő (perc)',
  time_label: 'Idő',
  cost: 'Költségek',
  toll_cost: 'Útdíj',
  km_cost: 'Kilométerenkénti költség',
  hourly_cost: 'Óradíj',
  working_cost: 'Teljes személyi költség',
  total_cost: 'Teljes költség',
  expire_date: 'Lejárati idő',
  start_date: 'Kezdeti időpont',
  recap: 'Összefoglaló',
  start: 'Indítsa el a  oldalt.',
  starting_point: 'Indulás',
  end: 'Vége',
  finish_point: 'Érkezés',
  duration: 'Időtartam',
  distance: 'Távolság',
  daily_distance: 'Napi megtett kilométerek',
  distance_total: 'Teljes távolság',
  gps_distance: 'Távolság (GPS)',
  distance_gps: 'Napi futásteljesítmény (GPS)',
  pause: 'Szünet',
  segment: 'Útvonal',
  start_time: 'A kezdési időpont',
  end_time: 'Végidő',
  service_time: 'Szolgáltatási idő',
  waypoint_load: 'Rakomány a fedélzeten',
  state: 'Állapot',
  violated_plafond: 'A mennyezet áttörése',
  traffic_delay: 'Forgalmi késések',
  company: 'Cég',
  address: 'Cím:',
  position: 'Helyszín',
  route: 'Útvonal',
  filter_date: 'Szűrés dátum szerint',
  warning: 'Figyelem',
  no_warning: 'Nincs figyelmeztetés',
  alerts: 'Értesítések',
  trucks_with_alerts: 'Figyelmeztető jelzéssel ellátott járművek',
  bluetooth_devices: 'Bluetooth eszközök',
  no_bluetooth_devices: 'Nem talált Bluetooth-eszköz',
  connected: 'Csatlakoztatva',
  disconnected: 'Kikapcsolt',
  bluetooth_types: {
    temperature_probe: 'Hőmérséklet szonda',
    door_probe: 'Ajtó és hőmérsékletmérő szonda'
  },
  back_to_home: 'Vissza a főoldalra',
  cerca: 'Keresés',
  search_vehicle: 'Keresés jármű',
  filter: 'Szűrő',
  filter_label: 'Szűrő',
  filters_label: 'Szűrők',
  filter_by: 'Szűrés',
  operations: 'Műveletek',
  filter_vehicles: 'Szűrő járművek',
  filter_applied: 'Szűrő sikeresen alkalmazva',
  filter_canceled: 'Szűrő sikeresen törölve',
  order_by: 'Rendezés',
  order: 'Megrendelés',
  ascending: 'Növekvő',
  descending: 'Decrescent',
  value: 'Érték',
  select_filter: 'Szűrő kiválasztása',
  sort_vehicles: 'Járművek rendelése',
  sort_for_alphanumeric: 'Betűrendes',
  sort_for_lastpoint: 'Kronológiai',
  sort_for_company: 'Cég',
  sort_for_activity: 'Tevékenységek',
  company_select: 'Vállalat kiválasztása',
  no_companies_founded: 'Nem talált vállalat',
  multicompany: 'Szűrés vállalat szerint',
  no_vehicles_founded: 'Nem találtak járműveket',
  print: 'Nyomtatás',
  export: 'Exportálás',
  print_recap: 'Összefoglaló nyomtatása',
  select_date: 'Válasszon ki egy dátumot',
  add: 'Add',
  add_new: 'Új hozzáadása',
  delete: 'Törölje a  címet.',
  edit: 'Szerkesztés',
  options: 'Opciók',
  login: 'Bejelentkezés',
  logout: 'Kilépés',
  logout_confirm: 'Biztos, hogy el akarsz menni?',
  login_button: 'Bejelentkezés',
  connect: 'Csatlakozás',
  logged: 'Csatlakoztatva',
  manage: 'Kezelje a  címet.',
  loading: 'Betöltés',
  loading_timeout: 'Hálózati hiba, kérjük, töltse újra az oldalt vagy próbálja meg később újra.',
  go_back: 'Vissza a tetejére',
  select_coordinates: 'Koordináták kiválasztása',
  other: 'További',
  options_general: 'Generali',
  options_language: 'Nyelv',
  change_interface_language: 'A kezelőfelület nyelvének kiválasztása',
  change_infringment_language: 'Válassza ki az országot érintő jogsértéseket',
  change_timezone: 'Időzóna kiválasztása',
  save_language_settings: 'Nyelvi beállítások mentése',
  vehicle_list_options: 'Járművek listája',
  select_view: 'Nézet kiválasztása',
  select_fields_placeholder: 'Válassza ki a megjelenítendő mezőket',
  selected_field: 'Kiválasztott terület',
  selected_fields: 'Kiválasztott mezők',
  list: 'Lista',
  table: 'Asztal',
  view_options: 'Lehetőségek megtekintése',
  fluid_table: 'Dinamikus szélesség',
  fluid_table_descr: 'A táblázat szélessége a kiválasztott mezőknek megfelelően változtatható',
  vehicle_fields_list: 'Milyen információkat szeretne megjeleníteni az egyes járművekre vonatkozóan? (Max 3)',
  vehicle_fields_list_warning: 'Figyelem: a mező nem jelenik meg, ha nem áll rendelkezésre a jármű számára.',
  vehicle_identification: 'Hogyan szeretné, hogy a járművet a főlistán azonosítani lehessen?',
  apply_to_marker: 'Ezt a beállítást a térképen lévő járművekre is alkalmazza.',
  element_icon_section: 'Elem ikon',
  element_title_section: 'A tétel címe',
  element_fields_list_section: 'Az elem mezőinek listája',
  element_icon_description: 'Válassza ki az elemhez megjelenítendő ikont',
  element_icon: 'ikon.',
  element_icon_color: 'ikon színe',
  options_map: 'Térkép opciók',
  vehicles_clustering: 'Járművek csoportosítása',
  vehicles_clustering_warning: 'A beállítás frissítése az oldal újratöltését igényli.',
  options_marker: 'Jelölő opciók',
  size: 'Méret',
  font_size: 'Szöveg mérete',
  font_color: 'Szöveg színe',
  background_color: 'Háttérszín',
  opacity: 'Átláthatóság',
  color: 'Színes',
  small: 'Kis',
  medium: 'Közepes',
  large: 'Nagyszerű',
  additional_info: 'További információk',
  analysis_options: 'Útvonal-elemzés',
  options_chart: 'Grafikai lehetőségek',
  options_timeline: 'Időzítési lehetőségek',
  hide_timeline: 'Idővonal elrejtése',
  map: 'Térkép',
  silica: 'Szilícium-dioxid',
  google: 'Google',
  google_satellite: 'Google Satellite',
  sandbox: 'Sandbox',
  silkysand: 'Silkysand',
  gravelpit: 'Sírgödör',
  open_street_maps: 'Open Street Maps',
  arcgis_satellite: 'ArcGIS Satellite',
  traffic: 'Időjárás',
  heavy_vehicles_ban: 'Nehézgépjárművek kitiltása',
  driver: 'Vezető',
  no_driver: 'Nincs járművezető',
  drivers: 'Vezetők',
  driver_card: 'Vezetői kártya',
  name: 'Név',
  surname: 'Vezetéknév',
  driver_activity: 'Vezetői tevékenységek',
  driver_status: 'A járművezető állapota',
  activity_type: 'Tevékenység típusa',
  activity: 'Tevékenységek',
  no_activity: 'Nincs tevékenység',
  driver_at_rest: 'Pihenő járművezető',
  driver_available: 'A járművezető elérhetősége',
  driver_at_work: 'Sofőr a munkahelyén',
  driver_driving: 'Vezető járművezető',
  inactive_vehicle: 'Inaktív jármű',
  last_driver_detected: 'Utolsó észlelt illesztőprogram',
  rest: 'Pihenés',
  work: 'Munka',
  driving: 'Útmutató',
  availability: 'Elérhetőség',
  codriving: 'A személyzet vezetése',
  codriver: 'Legénység',
  break: 'Ülésszünet',
  daily_rest: 'Napi pihenés',
  violation: 'Szabálysértés',
  to_begin_within: 'Elindítja',
  to_end_within: 'Kitöltendő',
  after_total_time_travel: 'A teljes utazás után',
  after_total_distance_travel: 'Miután a teljes megtett távolság',
  residual_commitment_type: 'A fennmaradó kötelezettségvállalás típusa',
  residual_commitment: 'Fennmaradó kötelezettségvállalás',
  commitment_hours: 'Kötelezettségvállalási órák',
  commitment_hours_description: 'Keresse meg az összes olyan járművet, amelynek vezetői a jelzettnél magasabb maradványkötelezettséggel rendelkeznek.',
  driver_plafond: 'Vezetői mennyezet',
  residual_driving: 'Maradék iránymutatás',
  residual_bonuses: 'Maradék bónuszok',
  consider_bonuses: 'Az időt a fennmaradó bónuszok figyelembevételével számítják ki',
  current_activity: 'Jelenlegi tevékenység',
  next_activity: 'Következő tevékenység',
  view_plafond_detail: 'A mennyezet részleteinek megtekintése',
  card: 'Papír',
  driver_change: 'Vezetőcsere',
  shared_links: 'Megosztott linkek',
  gf: 'POI észlelve',
  analyze: 'Elemzések',
  plan: 'Terv',
  vehicle: 'Jármű',
  vehicles: 'Járművek',
  vehicle_info: 'Járművel kapcsolatos információk',
  vehicle_type: 'Járműtípus',
  vehicle_body: 'Jármű karosszéria',
  truck_status: 'A jármű állapota',
  brand: 'Márka',
  model: 'Modell',
  no_card: 'A kártya nincs behelyezve',
  no_card_driving: 'Papírmentes vezetés',
  speed: 'Sebesség',
  tacho_speed: 'Sebesség (tachográf)',
  gps_speed: 'Sebesség (GPS)',
  level: 'Szint',
  battery: 'Akkumulátor',
  low_battery_level: 'Alacsony töltöttségi szint',
  fuel: 'Üzemanyag',
  fuel_level: 'Üzemanyagszint',
  low_fuel_level: 'Kevés üzemanyag',
  consumption: 'Fogyasztás',
  consumptions: 'Fogyasztás',
  fuel_consumption: 'Üzemanyag-fogyasztás',
  fuel_avg_consumption: 'Átlagos napi üzemanyag-fogyasztás',
  altitude: 'Magasság',
  weight: 'Súly',
  lastpoint_weight: 'Súly (utolsó pont)',
  vehicle_weight: 'Tömeg (t)',
  vehicle_length: 'Hosszúság (m)',
  vehicle_width: 'Szélesség (m)',
  vehicle_height: 'Magasság (m)',
  odometer: 'Távmérő',
  odometer_tacho: 'Távmérő (tachográf)',
  odometer_gps: 'Távmérő (GPS)',
  gps_odometer: 'Távmérő (GPS)',
  last_point: 'Utolsó pont',
  moving: 'Mozgásban',
  stopped: 'Stop',
  engine_on: 'Motor jár',
  ignition: 'Kép a',
  engine_off: 'Motor ki',
  engine_on_off: 'A motor indítása',
  ignition_on: 'Kép a',
  ignition_off: 'Kép ki',
  device: 'Eszköz',
  plate: 'Rendszámtábla',
  chassis: 'Keret',
  status: 'Állapot',
  custom_id: 'Személyre szabott név',
  heading: 'Irány',
  rpm_engine: 'Motor fordulatszám',
  pto_active: 'Aktív PTO',
  pto_state: 'PTO állapot',
  device_timestamp: 'Időbélyegző',
  info: 'Info',
  solar_day_report: 'Jelentés a napenergia napjáról',
  telemetry: 'Telemetria',
  parameters: 'Paraméterek',
  engine_coolant_temp: 'Motorhőmérséklet',
  engine_rpm: 'Motor fordulatszám',
  dallas_temp_1: 'Hőmérséklet 1',
  dallas_temp_2: 'Hőmérséklet 2',
  dallas_temp_id_1: 'ID Hőmérséklet 1',
  dallas_temp_id_2: 'ID Hőmérséklet 2',
  digital_input_1: 'Digitális bemenet 1',
  digital_input_2: 'Digitális bemenet 2',
  digital_input_3: 'Digitális bemenet 3',
  digital_input_4: 'Digitális bemenet 4',
  digital_input_5: 'Digitális bemenet 5',
  battery_level: 'Az akkumulátor szintje',
  adblue_liters: 'Liter Adblue',
  adblue_level: 'Adblue szint',
  gnss_status: 'GNSS állapot',
  gnss_status_values: [
    'Off',
    'Be, nincs GPS',
    'Be, nincs műhold',
    'Be, műholdakkal',
    'On, álomban',
    'Védelmi státusz'
  ],
  gnss_off: 'GNSS ki',
  gnss_on_fix: 'GNSS bekapcsolva, műholdakkal',
  gnss_on_without_fix: 'GNSS bekapcsolva, műholdak nélkül',
  gnss_on_sleep: 'GNSS bekapcsolva, alvó üzemmódban',
  gnss_on_protection: 'GNSS bekapcsolva, védelmi állapot',
  gsm_signal: 'GSM jel',
  tacho_source: 'A tachográf forrása',
  external_voltage: 'Tápegység',
  tacho_overspeed: 'Túlsebesség tachográf',
  no_points_title: 'Utolsó tevékenység több mint 24 órája',
  no_points: 'Ez a jármű több mint 24 órán keresztül nem küldött pontot',
  clutch: 'Kuplung',
  clutch_switch: 'Kuplung kapcsoló',
  brake_pedal: 'Fékpedál',
  accelerator_pedal: 'Gázpedál',
  pedal_released: 'Pedál kioldva',
  pedal_pressed: 'Pedál megnyomva',
  data_mode: 'Adat üzemmód',
  home_stop: 'Álljon meg a főhadiszálláson',
  home_moving: 'Mozgásban a központban',
  roaming_stop: 'Barangolás leállítása',
  roaming_moving: 'Barangolás útközben',
  unknown_stop: 'Még mindig idegenebb',
  unknown_moving: 'Ismeretlen mozgásban',
  engine_coolant_temperature: 'Hűtőfolyadék hőmérséklete',
  engine_temperature: 'Motorhőmérséklet',
  cruise_control_active: 'Aktív sebességtartó automatika',
  adblue_off: 'Adblue ki',
  adblue_red: 'Adblue piros',
  adblue_yellow: 'Adblue sárga',
  adblue_info: 'Adblue információ',
  charger_notconnected: 'A töltő nincs csatlakoztatva',
  charger_connected: 'Csatlakoztatott rakodó',
  tacho_unknown: 'Ismeretlen',
  tacho_kline: 'K-Line',
  tacho_allcan: 'BUS-CAN',
  tacho_can: 'BUS-CAN',
  tacho_fms: 'FMS',
  vehicle_not_found: 'A jármű, amelyhez hozzáférni szeretne, nem elérhető',
  vehicle_with_lock_commands: 'Jármű reteszelő kezelőszervekkel',
  lock: 'Lock',
  unlock: 'Feloldja a',
  send: 'Küldje el a',
  command_sent: 'Sikeresen elküldött parancs',
  turn_on: 'Gyújtsd be',
  turned_on: 'Hozzáférés:',
  turn_off: 'Kapcsolja ki',
  turned_off: 'Off',
  confirm_send_command: 'Megerősíti, hogy el akarja küldeni a parancsot?',
  temperature: {
    title: 'Hőmérséklet szondák',
    probe: [
      'Szonda 1',
      'Szonda 2',
      'Szonda 3',
      'Szonda 4',
      'Szonda 5',
      'Szonda 6'
    ]
  },
  no_power_supply: 'Nincs tápegység',
  no_gps: 'GPS nincs',
  tacho_clock_from_updated_error: 'A tachográf órájának szinkronizálási hibája',
  tacho_clock_from_device_error: 'Szinkronizációs hiba a tachográf és a vezérlőegység között',
  device_timestamp_error: 'Vezérlőegység dátum hiba',
  select_color_mode: 'Színes üzemmód kiválasztása',
  light_mode: 'Törlési mód',
  dark_mode: 'Sötét üzemmód',
  color_blind_mode: 'Színvak üzemmódok',
  color_blind_dark_mode: 'Sötét színvak üzemmód',
  authorized: "Engedélyezett",
  logged_out: "Kijelentkezett",
  geofleet: {
    mainpanel: {
      pinned_vehicles: 'Kiemelt járművek',
      search_trucks_title: 'Járművek keresése',
      search_trucks_tags_title: 'Kapcsolódó címkék',
      search_trucks_status_title: 'Jármű tevékenység',
      search_trucks_driver_status_title: 'Vezetői tevékenységek',
      search_near_trucks_title: 'Közeli járművek keresése'
    },
    detailspanel: {
      bind_vehicle: 'Társított jármű',
      add_new_tag: 'Új címkeként hozzáadni',
      select_new_tag: 'Címke kiválasztása vagy keresése',
      binded_tags: 'Kapcsolódó címkék',
      binded_tag: 'Kapcsolódó címke'
    },
    analyzer: {
      vehicle_activity_analisys: 'Járműtevékenység-elemzés',
      timeline_separator: 'Csoportosítás',
      loading_error: 'Hiba történt az adatok betöltése közben'
    },
    search_resources: {
      title: 'Források keresése',
      description: 'Keresse meg a járművéhez legközelebbi benzinkutakat, parkolókat és megközelíthető területeket.'
    }
  },
  analisys: {
    title: 'Útvonal-elemzés',
    select_telemetries: 'Az útvonalelemzésben megjelenítendő telemetria kiválasztása',
    select_telemetries_description: 'Legfeljebb 4 telemetria kijelzése választható ki',
    chart_groups: {
      movement_data: 'Mozgási adatok',
      engine_data: 'Motoradatok',
      vehicle_data: 'Járműadatok',
      sensors_data: 'Érzékelő adatok',
      driver_data: 'Vezetői adatok',
      fuel_data: 'Üzemanyagadatok'
    },
    no_data: 'Nincs adat',
    no_data_description: 'Nincs megjelenítendő útvonaladat erre a járműre vagy a kiválasztott időszakra vonatkozóan.',
    no_data_contact_support: 'További információért forduljon az ügyfélszolgálathoz'
  },
  planner: {
    user_config: 'Felhasználói konfigurációk',
    no_user_config: 'Nincsenek megjelenítendő felhasználói konfigurációk',
    freight_access: 'Hozzáférés a rakománytáskákhoz',
    vehicle_actual_position: 'A jármű jelenlegi helyzete',
    plan_route: 'Útvonaltervezés',
    select_on_map: 'Válasszon ki egy pontot a térképen',
    select_on_map_option: 'Válassza ki a térképen',
    fill_route_info: 'Töltse ki az útvonalra vonatkozó információkat',
    fill_resources: 'Válassza ki a szakaszként hozzáadandó pontot',
    fill_waypoints: 'Válassza ki a szakaszokat',
    load_breaks: 'Terhelési szünetek',
    load_alternative_routes: 'Alternatív útvonalak betöltése',
    savedroutes: {
      my_routes: 'Az útvonalaim',
      saved_routes: 'Mentett útvonalak',
      no_saved_routes: 'Még nem mentett el útvonalakat',
      success_saved_route: 'Az útvonalat sikeresen elmentettük',
      error_saved_route: 'Hiba történt az útvonal mentése közben',
      success_deleted_route: 'Az Ön útvonala sikeresen törlődött',
      error_deleted_route: 'Hiba történt az útvonal törlése közben'
    },
    resources: {
      area_search: 'Keresés a',
      station_services: 'Beleértve ezeket a szolgáltatásokat',
      parking_services: 'Parkolási szolgáltatások',
      select_rating: 'Válassza ki a pontszámot',
      select_security: 'Válassza ki a biztonságot',
      empty_search: 'A keresés nem talált olyan elemeket, amelyek megmutathatnák',
      delivery_nation: 'Szállító ország',
      fai_featured: 'Lumesia megállapodás',
      self_service: 'Önkiszolgálás',
      served: 'Kiszolgálva',
      total_parking_spots: 'Összes hely',
      rating: 'Elhatárolás',
      reviews: 'Vélemények',
      security: 'Biztonság',
      price_per_night: 'Ár éjszakánként'
    },
    emissions_labels: 'Kibocsátás',
    emissions: {
      fuelconsumption: 'Üzemanyag-fogyasztás',
      hydrocarbons: 'Szénhidrogének',
      methane: 'Metán',
      hydrocarbonsexceptmethane: 'Szénhidrogének a metán kivételével',
      carbonmonoxide: 'Szén-monoxid',
      carbondioxide: 'Szén-dioxid',
      fossilcarbondioxide: 'Fosszilis szén-dioxid',
      sulphurdioxide: 'Kén-dioxid',
      nitrogenoxides: 'Nitrogén-oxidok',
      nitrogendioxide: 'Nitrogén-dioxid',
      nitrousoxide: 'Nitrogén-oxid',
      ammonia: 'Ammónia',
      benzene: 'Benzol',
      particles: 'Részecskék',
      numberofparticles: 'Részecskék száma'
    },
    plafond_configuration: {
      title: 'Plafond konfiguráció',
      driving_time_regulation: 'Vezetési idő',
      last_time_the_driver_worked: 'A járművezető legutóbbi tevékenysége',
      eu_driving_time_regulation_for_single_day: 'Egyetlen nap',
      eu_driving_time_regulation_for_single_long_day: 'Egy nap (bónusszal)',
      eu_driving_time_regulation_for_multiple_day: 'Több nap',
      eu_driving_time_regulation_for_team_and_multiple_day: 'Több nap (legénységgel)',
      accumulated_driving_time_since_lastbreak: 'Vezetési órák az utolsó szünet óta',
      accumulated_driving_time_since_lastdailyrest: 'Vezetési idő az utolsó napi pihenő óta',
      accumulated_travel_time_since_lastdailyrest: 'Utazási órák az utolsó napi pihenő óta'
    },
    set_route: 'Útvonal beállítása',
    select_profile: 'Profil kiválasztása',
    select_co2_class: 'Kibocsátási osztály kiválasztása',
    class: 'Osztály',
    billed_roads: 'Díjköteles utak',
    waypointevent: 'Színpad',
    waypoints_label: 'Szakaszok',
    add_waypoint: 'Színpad hozzáadása',
    delete_waypoint: 'Szakasz törlése',
    select_waypoint: 'Válassza ki a szakaszt',
    select_resource: 'Válassza ki a szakaszként hozzáadandó pontot',
    last_waypoint: 'Utolsó szakasz',
    waypoints_check: 'Vannak teljesítendő szakaszok',
    waypoints_required: 'Legalább 2 szakaszra van szükség',
    countryevent: 'Állapot',
    tollevent: 'Útdíjfizető fülke',
    event: 'Esemény',
    alpega: 'Alpega',
    alpega_login: 'Alpega bejelentkezés',
    alpega_searches: 'Az Ön kutatása',
    timocom: 'Timocom',
    pickup: 'Visszavonás',
    delivery: 'Szállítás',
    extimated_cost: 'Becsült költség',
    calculate_route: 'Útvonal kiszámítása',
    calculate_isocrona: 'Izokronikus számítás',
    complete_step_2: 'Teljesítse a 2. lépést',
    reset_route: 'Új útvonal',
    route_error: 'Hiba történt az útvonal kiszámítása során',
    edit_route: 'Útvonal szerkesztése',
    save_route: 'Útvonal mentése',
    delete_route: 'Útvonal törlése',
    route_name: 'Útvonal neve',
    override_route: 'Útvonal felülírása',
    save_as_new_route: 'Új útvonal mentése',
    calculate_route_for: 'Számítsa ki az útvonalat a',
    ptvexport: 'Exportálás a PTV Navigatorba',
    isocrona: 'Megközelíthető területek',
    reset_isocrona: 'Elérhető területek visszaállítása',
    search_location: 'Keresés helye',
    search_station: 'Keresés forgalmazó',
    search_parking: 'Parkolási keresés',
    search_poi: 'POI keresés',
    include_plafond: 'A járművezető felső határának bevonása az útvonal kiszámításába',
    include_plafond_near_vehicles: 'Találjon olyan járműveket, amelyek vezetői elegendő maradék elkötelezettséggel rendelkeznek ahhoz, hogy eljussanak a keresett helyre.',
    violated_plafond: 'A kiválasztott útvonal sérti a járművezető felső határát.'
  },
  sharedlinks: {
    back_to_links: 'Vissza a linkekhez',
    create_link: 'Linkek létrehozása',
    update_link: 'Frissítési link',
    edit_link: 'Link szerkesztése',
    update_link_expire_date: 'Lejárati idő frissítése',
    delete_link: 'Link törlése',
    delete_link_confirm: 'Törölje a kiválasztott linket?',
    all_your_shared_links: 'Az összes megosztott linked',
    no_shared_links: 'Ön még nem osztott meg járművet',
    vehicles_link: 'Linkek a járművekhez',
    select_share_vehicles: 'Megosztható járművek kiválasztása',
    select_start_expire_date: 'Válassza ki a kezdeti és a lejárati dátumot',
    created_link_error: 'Hiba a hivatkozás létrehozása közben',
    created_link_success: 'Link sikeresen létrehozva',
    updated_link_error: 'Hiba a link frissítése közben',
    updated_link_success: 'Link sikeresen frissítve',
    service_description: 'Ossza meg ezt a linket a kiválasztott járművek nyomon követésének megjelenítéséhez',
    link_preview: 'Link előnézet',
    share_single_vehicle: 'Ossza meg a jármű helyét',
    track_vehicle_position: 'A kiválasztott járművek helyének nyomon követése',
    start_date_warning: 'A kezdési dátum megváltoztatása nem lehetséges',
    start_date_condition: 'Ha meg akarja változtatni a kezdeti dátumot, új linket kell létrehoznia.',
    expire_date_description: 'A lejárati dátumot jelzi, amely után ez a link már nem lesz elérhető.',
    start_expire_date_description: 'Megad egy kezdeti dátumot, amelytől kezdve ez a link elérhető lesz, és egy lejárati dátumot, amely után ez a link már nem lesz elérhető:'
  },
  viewsharedlink: {
    invalid_date_range: 'A kiválasztott dátumtartomány nem érvényes erre a hivatkozásra',
    too_early_link: 'Az Ön által keresett link még nem elérhető',
    too_late_link: 'A link, amelyet megpróbál elérni, már nem létezik.',
    show_route: 'Útvonal megjelenítése'
  },
  tags: {
    manage_tags: 'Címkék kezelése',
    add_tag: 'Címke hozzáadása',
    add_tag_placeholder: 'Adja meg a címke nevét',
    add_tag_error: 'Hiba a címke hozzáadása közben',
    add_tag_success: 'Címke sikeresen hozzáadva',
    delete_tag: 'Címkék törlése',
    delete_tag_confirm: 'A kiválasztott címke törlése?',
    delete_tag_error: 'Hiba a címke törlése közben',
    delete_tag_success: 'Címke sikeresen törölve',
    update_tag: 'Címkék frissítése',
    update_tag_error: 'Hiba a címke frissítése közben',
    update_tag_success: 'Címke sikeresen frissítve',
    all_your_tags: 'Az összes címkéd',
    no_tags: 'Még nem hoztál létre címkéket',
    tag_name: 'Címke neve'
  },
  manage_poi: {
    title: 'POI-k kezelése',
    your_pois: 'Az Ön POI-jai',
    add_poi: 'POI hozzáadása',
    no_pois: 'Ön még nem hozott létre POI-t',
    fields: {
      name: 'Név',
      type: 'Típus',
      color: 'Színes',
      note: 'Megjegyzések',
      address: 'Keresési cím',
      latitude: 'Szélesség',
      longitude: 'Hosszúság',
      radius: 'Keresés sugara',
      radius_description: 'A geofencing engedélyezéséhez nullánál nagyobb értéket kell beállítani.'
    },
    types_poi: {
      all: 'Minden',
      headquarter: 'Székhely',
      truck_washes: 'Autómosók',
      blacklist: 'Fekete lista',
      end_of_the_line: 'A sor vége',
      customers: 'Ügyfelek',
      stops: 'Stop',
      suppliers: 'Szállítók',
      interports: 'Interports',
      markets: 'Piacok',
      large_square: 'Négyzetek',
      poi_distributors: 'POI forgalmazók',
      poi_workshops: 'POI műhelyek',
      harbors: 'Kikötők',
      restaurants: 'Éttermek',
      stations: 'Állomások'
    },
    messages: {
      add_poi_error: 'Hiba POI hozzáadása közben',
      add_poi_success: 'POI sikeresen hozzáadva',
      update_poi_error: 'Hiba a POI frissítés során',
      update_poi_success: 'POI sikeresen frissítve',
      delete_poi_error: 'Hiba POI törlésénél',
      delete_poi_success: 'POI sikeresen megszűnt'
    }
  },
  vehicles_cross: {
    title: 'Jármű áthaladás',
    fields: {
      address: 'Cím:',
      radius: 'Radius',
      time_interval: 'Időintervallum',
      time_interval_description: 'Legfeljebb 24 órás időintervallum kiválasztása'
    },
    messages: {
      max_time_interval: 'A maximális időintervallum 24 óra',
      error_searching: 'Hiba történt a járműkeresés során',
      searching: 'Keresés folyamatban...',
      long_loading: 'A keresés néhány percig eltarthat, kérjük, várjon...',
      no_vehicles_cross: 'Nem találtak járműveket'
    }
  },
  plafonds: {
    session: 'Ülésszünet',
    break: 'Szünet',
    drive_occurring: 'A vezetési időszak folyamatban van:',
    drive_max_label: 'Maximális vezetési idő',
    drive_max_value: '4h 30m',
    drive_done: 'Útmutató készült',
    session_title: 'A töredékszünetről',
    session_tooltip: 'A töredékszünet lehetséges',
    session_done: 'Legalább 15 perces szünetet észleltek.',
    session_info: 'Már elvégeztem a tört szünet első részét',
    session_descr: "Felhívjuk figyelmét, hogy a jelenlegi előírások 15'+30' vagy 15'+15' szünetet engedélyeznek (a szállítás típusától függően).",
    session_choice: 'Legalább <b>30m</b> (áruszállítás) vagy <b>15m + 15m</b> (személyszállítás) a következő',
    brake_start: 'Szünet kezdete',
    brake_done: 'Szünet',
    daily: 'Napi',
    weekly: 'Heti',
    driver_in_drive: 'Ön a következő helyről vezet',
    driver_in_rest: 'Maradék pihenőidő',
    driver_in_work: 'Munka',
    driver_in_available: 'Elérhetőség',
    driver_in_availability: 'Elérhetőség',
    current_activity: 'Jelenlegi tevékenység',
    mini_plafond: 'Mini-plafond',
    last_update: 'Utolsó frissítés',
    panel_title: 'Plafond panel',
    residual_commitment: 'Fennmaradó kötelezettségvállalás',
    daily_driving: 'Napi útmutató',
    weekly_guide: 'Heti útmutató',
    biweekly_guide: 'Kéthetente megjelenő útmutató',
    last_rest: 'Utolsó heti pihenőidő',
    minimal_duration_next: 'Minimális időtartam következő',
    top_start_until: 'Elindítja',
    reduced_weekly_rest: 'A csökkentett heti pihenőidőt kompenzálni kell',
    time_to_compensate: 'Ideje kompenzálni',
    time_to_compensate_until: 'A kompenzációt a következők nyújtják',
    footer_message: '* az időt a maradék bónuszok figyelembevételével számítják ki',
    driver_in_block: 'A vezető mennyezete kimerült',
    'résidu_commitment': 'A kötelezettségvállalás maradványa',
    week_guide: 'Hétköznapi útmutató',
    'réduit_weekly_rest': 'Repos hebdomadaires réduits pour compenser'
  },
  error_pages: {
    error_403: {
      title: '403-as hiba',
      description: 'Kedves Ügyfél, tájékoztatjuk, hogy az Ön felhasználói fiókja jelenleg nincs beállítva a szolgáltatás eléréséhez. A szolgáltatással kapcsolatos információkért',
      retry_login: 'Hozzáférés megismétlése',
      contact_us: 'Kapcsolatfelvétel'
    }
  },
  ptv: {
    maxpermittedweight: 'Maximális súly',
    minpermittedweight: 'Minimális súly',
    zonename: 'Zóna',
    unauthorizedtype: 'Illetéktelen kód',
    maxemissionstandardeu: 'Kibocsátási osztály',
    fueltype: 'Üzemanyag típusa',
    loadtype: 'A szállítás típusa',
    opening: 'Átjáró',
    maxheight: 'Maximális magasság',
    maxweight: 'Maximális súly',
    maxwidth: 'Maximális szélesség',
    maxlength: 'Maximális hossz',
    absolutespeed: 'Sebesség',
    hazardoustowaters: 'A vízzel veszélyes árut szállító járművek tilalma',
    hazardousgoods: 'Veszélyes árut szállító járművek tilalma',
    combustibles: 'Az üzemanyagot szállító járművek tilalma',
    maxaxleload: 'Maximális tengelysúly',
    totalpermittedweight: 'Maximális súly',
    tunnelrestriction: 'Tunnell korlátozó kód',
    kingpintorearaxle: 'A tengelycsap és a tengely közötti távolság',
    numberofaxles: 'Tengelyek száma',
    length: 'Farok',
    delay: 'Késleltetés',
    message: {
      text: 'Üzenet'
    }
  },
  options_interface: 'Opciók interfész'
}