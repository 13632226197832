import Vue from 'vue'
import Vuex from 'vuex'

import delay from 'lodash/delay'

import geoFleet from './geofleet/geofleet.module'
import planner from './planner/planner.module'
import searchResource from './search-resources/search-resources.module'
import activityPanel from './activity-panel/activity-panel.module'
import loggedUser from './user/index.js'

Vue.use(Vuex)

const sharedLinksStore = {
    state: () => ({
        links: []
    }),
    getters: {},
    mutations: {
        setSharedLinks(state, links) {
            state.links = links
        },
        addLink(state, link) {
            state.links.push(link)
        },
        updateExpireLink(state, payload) {
            let index = state.links.findIndex((l) => l.token === payload.link.token)

            if (index !== -1) {
                state.links[index].exp = payload.exp
            }
        },
        removeLink(state, link) {
            state.links.splice(state.links.indexOf(link), 1)
        }
    },
    actions: {
        async getSharedLinks({ commit }, vueInstance) {
            // Disable ESLint for this line
            // eslint-disable-next-line no-unused-vars
            let links = await vueInstance
                .$fetch(vueInstance.$env.GOLIA_API + '/sharelink', {
                    method: 'GET'
                })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    var tempLinks = []
                    var incomingLinks = res.data

                    for (var company in incomingLinks) {
                        if (incomingLinks[company].length > 0) {
                            for (var link in incomingLinks[company]) {
                                tempLinks.push(incomingLinks[company][link])
                            }

                            commit('setSharedLinks', tempLinks)
                        }
                    }
                })
        }
    }
}

const store = new Vuex.Store({
    state: () => ({
        rootLoading: false,
        user: {
            permissions: {
                freight: false
            },
            loggedToAlpega: false
        },
        panels: {
            main: {
                collapsed: false
            },
            planner: {
                open: false
            },
            activity: {
                open: false,
                meta: {
                    start_date: null,
                    end_date: null
                }
            },
            searchResources: {
                open: false
            },
            tags: {
                open: false
            },
            sharedLinks: {
                open: false
            },
            plafond: {
                open: false,
                meta: {
                    driver: null
                }
            }
        }
    }),
    getters: {
        user: (state) => state.user,
        hasFreightPermission: (state) => {
            // Check if state.user has permissions property and freight property is true
            return state.user && state.user.permissions && state.user.permissions.freight
        },
        mainCollapsed: (state) => state.panels.main.collapsed,
        plannerOpen: (state) => state.panels.planner.open,
        activityOpen: (state) => state.panels.activity.open,
        activityMeta: (state) => state.panels.activity.meta,
        searchResourcesOpen: (state) => state.panels.searchResources.open,
        tagsOpen: (state) => state.panels.tags.open,
        sharedLinksOpen: (state) => state.panels.sharedLinks.open,
        plafondOpen: (state) => state.panels.plafond.open,
        plafondMeta: (state) => state.panels.plafond.meta
    },
    mutations: {
        setLoading(state, loading) {
            state.rootLoading = loading
        },
        setTimedLoading(state, time) {
            state.rootLoading = true

            delay(() => {
                state.rootLoading = false
            }, time)
        },
        setUser(state, user) {
            state.user = user
        },
        updateUser(state, user) {
            state.user = { ...state.user, ...user }
        },
        addUserProperty(state, payload) {
            const { property, value } = payload

            const keys = property.split('.')

            if (keys.length > 1) {
                // Update nested property
                let prop = state.user

                for (let i = 0; i < keys.length - 1; i++) {
                    prop = prop[keys[i]]
                }
                Vue.set(prop, keys[keys.length - 1], value)
            } else {
                state.user[property] = value
            }
        },
        openPanel(state, panel) {
            state.panels[panel].open = true
        },
        openPlafondPanel(state, driver) {
            state.panels['plafond'].open = true
            state.panels['plafond'].meta.driver = driver
        },
        setPlafondPanelDriver(state, driver) {
            state.panels['plafond'].meta.driver = driver
        },
        closePanel(state, panel) {
            state.panels[panel].open = false
        },
        closePlafondPanel(state, panel) {
            state.panels[panel].open = false
            state.panels['plafond'].meta.driver = null
        },
        collapsePanel(state, payload) {
            const { panel, with_loading } = payload

            if (with_loading) state.rootLoading = true

            state.panels[panel].collapsed = true

            if (with_loading)
                delay(() => {
                    state.rootLoading = false
                }, 500)
        },
        expandPanel(state, payload) {
            const { panel, with_loading } = payload

            if (with_loading) state.rootLoading = true

            state.panels[panel].collapsed = false

            if (with_loading)
                delay(() => {
                    state.rootLoading = false
                }, 1000)
        },
        togglePanel(state, panel) {
            state.panels[panel].open = !state.panels[panel].open
        },
        toggleCollapsePanel(state, payload) {
            const { panel, with_loading } = payload

            if (with_loading) state.rootLoading = true

            state.panels[panel].collapsed = !state.panels[panel].collapsed

            if (with_loading)
                delay(() => {
                    state.rootLoading = false
                }, 1000)
        },
        addPanelMeta(state, payload) {
            const { panel, meta } = payload

            state.panels[panel].meta = { ...state.panels[panel].meta, ...meta }
        }
    },
    actions: {
        async hideLoader() {
            document.querySelector('.spinner-wrapper').classList.add('d-none')
        },
        async logout({ state }, payload) {
            let vm = payload.vueInstance
            let user = vm.$authProcess.getUser()

            if (user) {
                let nonce = user?.payload?.nonce

                console.log('nonce', nonce)

                if (nonce) {
                    nonce = nonce.split(':')[3]

                    let url =
                        'https://auth.goliaweb.it/sessions/end?nonce=' +
                        nonce +
                        '&redirect_uri=https://geofleet.goliaweb.it'

                    vm.$bvModal
                        .msgBoxConfirm(vm.__('logout_confirm'), {
                            title: vm.__('Logout'),
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: vm.__('yes'),
                            cancelTitle: vm.__('no'),
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: false
                        })
                        .then((value) => {
                            if (value) location.href = url
                            else return
                        })
                        .catch((err) => {
                            // An error occurred
                        })
                }
            }
        },
        async scrollToElement({ state }, payload) {
            let scrollList = payload.scrollList
            let element = payload.element

            let domContainer = document.querySelector(scrollList)
            let domElement = document.getElementById(element)

            setTimeout(() => {
                domContainer.scrollTo({
                    top: domElement.offsetTop - 100,
                    behavior: 'smooth'
                })
            }, 300)
        }
    },
    modules: {
        geoFleet,
        planner,
        sharedLinks: sharedLinksStore,
        searchResource,
        loggedUser,
        activityPanel: {
            namespaced: true,
            ...activityPanel
        }
    }
})

export default store
