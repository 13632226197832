<template>
    <div id="h-chart"></div>
</template>

<script>
import { useDefaultChart } from '@/composables/useDefaultChart'
import cloneDeep from 'lodash/cloneDeep'
import Moment from 'moment'
import MomentTimeZone from 'moment-timezone'
import { toRefs } from 'vue'

import Highcharts from 'highcharts'

import XRange from 'highcharts/modules/xrange'
import Accessibility from 'highcharts/modules/accessibility'

XRange(Highcharts)
Accessibility(Highcharts)

window.moment = Moment
MomentTimeZone()

export default {
    name: 'ActivityChart',
    props: {
        layers: {
            type: Array,
            required: true
        },
        activities: {
            type: Array,
            required: true
        },
        extremes: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    setup(props) {
        const { localChart } = useDefaultChart()

        return {
            localChart
        }
    },
    data() {
        return {
            charts: [],
            chartsData: {},
            chartsColors: ['#7E5CF7', '#4c586b', '#39C4CE', '#87CB7E']
        }
    },
    watch: {
        layers: function () {
            this.draw()
        },
        extremes: function () {
            this.charts.forEach((c) => {
                if (this.extremes) {
                    c.xAxis[0].setExtremes(this.extremes[0], this.extremes[1])
                } else {
                    c.xAxis[0].setExtremes()
                }
            })
        }
        // localChart: {
        //     handler: function () {
        //         this.draw()
        //     },
        //     deep: true
        // }
    },
    methods: {
        buildYAxis(key, value) {
            let axisStandardTemplate = {
                labels: {
                    enabled: false,
                    style: {
                        color: 'var(--main-text-color)'
                    }
                },
                title: {
                    text: null
                },
                height: '75%'
            }
            let tmpAxis = cloneDeep(axisStandardTemplate)

            tmpAxis._name = key
            tmpAxis.className = 'custom-' + key + '-Axis'

            if (value?.format) tmpAxis.labels.format = value?.format

            if (value?.height) tmpAxis.height = value?.height

            if (value?.opposite) tmpAxis.opposite = value?.opposite

            if (value?.top) tmpAxis.top = value?.top

            if (value?.offset) tmpAxis.offset = value?.offset

            return tmpAxis
        },
        buildAxisData(layers) {
            let start_consumption_point = null
            let last_consumption_point = 0

            let chartData = {}

            let time = null

            const tachoSpeedPresence = this.computedYAxis.axis.find(
                (axe) => axe._name == 'tacho_speed'
            )

            for (var layer of layers) {
                time = this.moment(layer.feature.properties.date, 'UTC').valueOf()

                this.computedYAxis.axis.forEach((axe, index) => {
                    if (axe._name == 'activity') return

                    let yData = null

                    /**
                     * TODO: If tacho_speed is present in this.computedYAxis.axis use only speed, otherwise use (tacho_speed || speed)
                     */
                    if (axe._name == 'speed') {
                        if (tachoSpeedPresence) {
                            yData = layer.feature.properties.speed
                        } else {
                            yData =
                                layer.feature.properties.tacho_speed ||
                                layer.feature.properties.speed
                        }
                    }

                    if (axe._name == 'tacho_speed') yData = layer.feature.properties.tacho_speed

                    if (axe._name == 'altitude') yData = layer.feature.properties.altitude

                    if (axe._name == 'lastpoint_weight') {
                        yData = layer.feature.properties.axel_1_weight
                            ? layer.feature.properties.axel_1_weight
                            : 0

                        yData += layer.feature.properties.axel_2_weight
                            ? layer.feature.properties.axel_2_weight
                            : 0

                        yData += layer.feature.properties.axel_3_weight
                            ? layer.feature.properties.axel_3_weight
                            : 0
                    }

                    if (axe._name == 'consumption') {
                        if (start_consumption_point === null && layer.feature.properties.fuel_total)
                            start_consumption_point = layer.feature.properties.fuel_total

                        last_consumption_point =
                            layer.feature.properties.fuel_total || last_consumption_point

                        yData = parseFloat(
                            (last_consumption_point - (start_consumption_point || 0)).toFixed(2)
                        )
                    }

                    if (axe._name == 'odometer') yData = parseInt(layer.feature.properties.odometer)

                    if (axe._name == 'gps_odometer')
                        yData = parseInt(layer.feature.properties.gps_odometer)

                    if (axe._name == 'engine_rpm') yData = layer.feature.properties.engine_rpm

                    if (axe._name == 'engine_coolant_temperature')
                        yData = layer.feature.properties.engine_coolant_temperature

                    if (axe._name == 'cruise_control_active')
                        yData = layer.feature.properties.cruise_control === false ? 0 : 1

                    if (axe._name == 'pto_active')
                        yData = layer.feature.properties.pto === false ? 0 : 1

                    if (axe._name == 'codriving')
                        yData = layer.feature.properties.crew === false ? 0 : 1

                    if (!chartData[axe._name]) chartData[axe._name] = []

                    chartData[axe._name].push({
                        x: time,
                        y: yData,
                        l: layer
                    })
                })

                // if (layer.feature.properties.fuel_total) {
                //     if (current_consumption[0] && (time - current_consumption[0] >= (30 * 60 * 1000))) {
                //         let avg = Math.floor(current_consumption[2].reduce((c,v) => (c + v),0) / 10) / 100

                //         consumption.push({
                //             x: current_consumption[0],
                //             y: avg,
                //             l: layer
                //         })

                //         consumption.push({
                //             x: time,
                //             y: avg,
                //             l: layer
                //         })

                //         current_consumption = [null, null, []]
                //     } else if (current_consumption[1]) {
                //         current_consumption[2].push(
                //             layer.feature.properties.fuel_total - current_consumption[1]
                //         )

                //         current_consumption[1] = layer.feature.properties.fuel_total
                //     } else {
                //         current_consumption[0] = time
                //         current_consumption[1] = layer.feature.properties.fuel_total
                //     }
                // }

                // if (current_consumption[0]) {
                //     let avg = Math.floor(current_consumption[2].reduce((c,v) => (c + v),0) / 10) / 100

                //     consumption.push({
                //         x: current_consumption[0],
                //         y: avg,
                //         l: layer
                //     })

                //     consumption.push({
                //         x: time,
                //         y: avg,
                //         l: layer
                //     })
                // }
            }

            // For each property of the chartData object, push the value in the right array
            Object.entries(chartData).forEach(([key, value]) => {
                this.$set(this.chartsData, key, value)
            })
        },
        draw() {
            this.charts = []
            this.chartsData = {}

            let last_tooltip_layer = null
            let activities = []

            this.buildAxisData(this.layers)

            if (this.activities.length) {
                for (var activity of this.activities) {
                    if (activity.activity < 0 || activity.activity > 3) continue

                    let color = ['#87CB7E', '#96CEE9', '#FC9E42', '#E83744']
                    let label = [
                        this.__('rest'),
                        this.__('availability'),
                        this.__('work'),
                        this.__('driving')
                    ]
                    let y_value = [4, 3, 2, 1]

                    activities.push({
                        x: activity.x,
                        x2: activity.x2,
                        y: y_value[activity.activity],
                        l: activity.ref,
                        color: color[activity.activity],
                        text: label[activity.activity]
                    })
                }

                this.$set(this.chartsData, 'activities', activities)
            }

            let g_moment = this.moment
            let vm = this

            this.charts.push(
                Highcharts.chart('h-chart', {
                    chart: {
                        zoomType: 'x',
                        backgroundColor: 'var(--main-inner-bg-color)',
                        className: 'customized-h-chart',
                        style: {
                            color: 'var(--main-text-color)'
                        },
                        displayErrors: true
                    },
                    time: {
                        timezone: 'Europe/Rome'
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'top',
                        itemStyle: { color: 'var(--main-text-color)', opacity: 1 },
                        itemHiddenStyle: { opacity: 0.5 },
                        itemHoverStyle: { color: 'var(--main-text-color--hover)', opacity: 0.5 }
                    },
                    yAxis: this.computedYAxis.axis,
                    xAxis: {
                        type: 'datetime',
                        scrollbar: {
                            enabled: true
                        },
                        labels: {
                            style: {
                                color: 'var(--main-text-color)'
                            }
                        }
                        // min: this.range.start.valueOf()
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        formatter: function () {
                            if (last_tooltip_layer) {
                                last_tooltip_layer.closeTooltip
                                    ? last_tooltip_layer.closeTooltip()
                                    : null

                                last_tooltip_layer.setStyle
                                    ? last_tooltip_layer.setStyle(last_tooltip_layer.os)
                                    : null
                            }

                            last_tooltip_layer = this.points[0]?.point.l || {}

                            last_tooltip_layer.openTooltip ? last_tooltip_layer.openTooltip() : null

                            if (last_tooltip_layer.setStyle) {
                                last_tooltip_layer.os = {
                                    weight: last_tooltip_layer.options.weight,
                                    color: last_tooltip_layer.options.color
                                }

                                last_tooltip_layer.setStyle({
                                    weight: 14,
                                    color: '#35bdfc'
                                })
                            }

                            let html = '<div>'

                            let plafond_point = this.points.find((p) => p.point.x && p.point.x2)
                            // let opacity = plafond_point ? plafond_point.point?.graphic?.parentGroup?.element?.getAttribute("opacity") || 0 : 0
                            // let active = parseFloat(opacity) > 0.2

                            if (plafond_point && this.points.length == 1) {
                                let x = g_moment(plafond_point.point.x, 'UTC')
                                let x2 = g_moment(plafond_point.point.x2, 'UTC')
                                let diff = x2.diff(x, 'minutes')
                                let diff_h = Math.floor(diff / 60)

                                html +=
                                    '<div style="display: inline-block">' +
                                    (plafond_point.point.text || plafond_point.y) +
                                    ' (' +
                                    diff_h +
                                    'h ' +
                                    (diff - diff_h * 60) +
                                    'm)' +
                                    '</div><br>' +
                                    '<div style="display: inline-block">' +
                                    x.format('L HH:mm') +
                                    '</div><br>' +
                                    '<div style="display: inline-block">' +
                                    x2.format('L HH:mm') +
                                    '</div><br>'
                            } else {
                                for (var p of this.points) {
                                    html +=
                                        '<div style="display: inline-block">' +
                                        p.series.name +
                                        ': ' +
                                        (p.point.text || p.y) +
                                        ' ' +
                                        (vm.computedYAxis?.axisFormat[p.series.index]?.um || '') +
                                        '</div><br>'
                                }

                                html +=
                                    '<div style="display: inline-block">' +
                                    g_moment(this.x, 'UTC').format('L HH:mm') +
                                    '</div>'
                            }

                            html += '</div>'

                            return html
                        }
                    },
                    plotOptions: {
                        series: {
                            pointWidth: 10,
                            events: {
                                mouseOut: function () {
                                    if (last_tooltip_layer) {
                                        last_tooltip_layer.closeTooltip
                                            ? last_tooltip_layer.closeTooltip()
                                            : null

                                        last_tooltip_layer.setStyle
                                            ? last_tooltip_layer.setStyle(last_tooltip_layer.os)
                                            : null
                                    }
                                }
                            }
                        }
                    },
                    series: this.computedYAxis.axisFormat
                })
            )
        }
    },
    computed: {
        activeYAxisList() {
            let yAxis = []

            this.computedYAxis.axis.forEach((axe) => {
                yAxis.push(axe._name)
            })

            return yAxis
        },
        computedYAxis() {
            let chartAxis = this.localChart

            let yAxis = {
                axis: [],
                axisFormat: []
            }

            Object.entries(chartAxis).forEach(([key, value]) => {
                if (!value?.active) return

                let newAxe = this.buildYAxis(key, value)

                yAxis.axis.push(newAxe)

                yAxis.axisFormat.push({
                    turboThreshold: 0,
                    name: this.__(key),
                    um: value?.chartUm || '',
                    yAxis: yAxis.axis.length - 1,
                    data: this.chartsData[key],
                    color: this.chartsColors[yAxis.axis.length - 1]
                })
            })

            if (chartAxis?.activity?.active) {
                let newAxe = this.buildYAxis('activity', chartAxis.activity)

                yAxis.axis.push(newAxe)

                yAxis.axisFormat.push({
                    turboThreshold: 0,
                    type: 'xrange',
                    name: this.__('activity'),
                    yAxis: yAxis.axis.length - 1,
                    data: this.chartsData.activities
                })
            }

            return yAxis || []
        }
    }
}
</script>
