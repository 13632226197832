const Utilities = {
    install(Vue) {
        Vue.filter('metersToKm', (value) => {
            return String((value / 1000).toFixed(2)).replace('.', ',') + ' km'
        })

        // Funzione per tornare il valore di una proprietà di un oggetto (anche innestata)
        /*
            Esempio:
            let obj = {
                id: 1,
                info: {
                    name: 'Mario',
                    surname: 'Rossi'
                }
            }

            let name = resolvePath('info.name', obj); // name = 'Mario'
        */
        Vue.prototype.resolvePath = (path, obj) => {
            return path.split('.').reduce(function (prev, curr) {
                return prev ? prev[curr] : null
            }, obj || self)
        }

        // Funzione per contare quante volte in un oggetto è presente un valore di una proprietà
        Vue.prototype.countEntries = (object, property, value) => {
            var count = 0

            for (let key in object) {
                // If property is an array check every element of the array if the previuos one is not founded
                if (Array.isArray(property)) {
                    let found = false

                    for (let i = 0; i < property.length && !found; i++) {
                        let searched_value = Vue.prototype.resolvePath(property[i], object[key])

                        if (searched_value == value) {
                            count++
                            found = true
                        }
                    }
                } else {
                    if (Vue.prototype.resolvePath(property, object[key]) == value) {
                        count++
                    }
                }
            }

            return count
        }
    }
}

export default Utilities
