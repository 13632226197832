<template>
    <div>
        <div class="resource-search d-flex gap-3" v-if="!noHeaderButtons">
            <golia-button
                class="close-search d-block mb-3 flex-grow-1"
                variant="btn-rounded btn-sm btn-fill dark-blue w-100"
                :label="__('close')"
                icon="fa fa-times"
                @click="$emit('closePanel')"
            />
            <golia-button
                class="abort-search d-block mb-3 flex-grow-1"
                variant="btn-rounded btn-sm btn-fill danger w-100"
                :label="__('cancel')"
                icon="fa fa-times"
                @click="$emit('abortSearch')"
            />
            <golia-button
                class="filter-search d-block mb-3"
                :label="__('filter')"
                variant="btn-rounded btn-sm btn-fill dark-blue-1 w-100"
                icon="fa fa-filter-list"
                v-b-modal.modal-filter-resources
            />
        </div>

        <b-modal id="modal-filter-resources" :title="__('filter')" v-if="!noHeaderButtons">
            <resources-filters
                v-if="waypoint"
                :waypoint="waypoint"
                @searchResource="(localWaypoint) => $emit('searchResource', localWaypoint)"
            />

            <template #modal-footer="{ cancel }">
                <!-- Chiude modale / Torna indietro -->
                <golia-button
                    icon="fa-fw fa-circle-xmark"
                    variant="btn-fill dark-blue"
                    @click="cancel()"
                    :label="__('close')"
                />
            </template>
        </b-modal>

        <div class="resources-list custom-scrollbar" v-show="localResources">
            <ul class="resources list-group">
                <li
                    :id="resource.id"
                    class="single-resource list-group-item"
                    :class="resource.on_focus ? 'active' : ''"
                    v-for="resource in localResources"
                    :key="resource.id + '_' + resource.name"
                    @click="focusResource(resource)"
                >
                    <div class="fw-bold d-flex align-items-center">
                        <i
                            class="fa me-2 poi-icon"
                            style="
                                font-family: 'Font Awesome Kit', FontAwesome, 'Font Awesome 6 Pro';
                            "
                            v-html="resource.reference.type_icon"
                            v-if="resource.type == 'poi'"
                        ></i>
                        <i class="fa me-2" :class="resource.icon" v-else></i>

                        <span class="resource-name" v-html="resourceHeader(resource)"></span>

                        <img
                            v-if="
                                (resource.reference.services || []).findIndex(
                                    (s) => s.type == 'FAI'
                                ) > -1
                            "
                            v-b-tooltip.hover
                            :title="__('planner.resources.fai_featured')"
                            class="resource-service-logo"
                            :src="lumesiaLogo"
                        />
                    </div>
                    <small class="text-muted">{{ resourceAddress(resource) }}</small>

                    <!-- FUEL DETAILS - GROUPED FUELS -->
                    <ul
                        class="fuels-list list-unstyled"
                        v-show="resource.on_focus"
                        v-if="resource.type == 'fuel'"
                    >
                        <li
                            class="single-price"
                            v-for="(fuel_name, fuel_info) in groupedFuels(resource.reference)"
                            :key="fuel_info.id"
                        >
                            <div class="fw-bold mb-2">{{ fuel_name[0].description }}</div>

                            <div class="d-flex justify-content-between align-items-center">
                                <div
                                    class="single-price d-flex justify-content-between align-items-center"
                                    v-for="fuel in fuel_name"
                                    :key="fuel.id"
                                >
                                    <small class="price">{{ fuel.price | currency }}</small>
                                    <span
                                        class="badge bg-success rounded-pill float-right ms-1"
                                        style="font-size: 10px"
                                        v-if="fuel.self !== null"
                                        >{{
                                            fuel.self
                                                ? __('planner.resources.self_service')
                                                : __('planner.resources.served')
                                        }}</span
                                    >
                                </div>
                            </div>
                        </li>
                    </ul>

                    <!-- PARKING DETAILS -->
                    <div
                        class="parking-detail mt-3 mb-2"
                        v-show="resource.on_focus"
                        v-if="resource.type == 'parking'"
                    >
                        <ul class="list-unstyled" style="font-size: 14px">
                            <li class="list-item" v-if="resource.reference.number_of_parking_spots">
                                {{ __('planner.resources.total_parking_spots') }}:
                                {{ resource.reference.number_of_parking_spots }}
                            </li>

                            <li class="list-item" v-if="resource.reference.reviews_rating">
                                {{ __('planner.resources.rating') }} ({{
                                    resource.reference.reviews_count
                                }}
                                {{ __('planner.resources.reviews') }}):
                                <b-form-rating
                                    v-model="resource.reference.reviews_rating"
                                    inline
                                    no-border
                                    readonly
                                    class="mb-2"
                                ></b-form-rating>
                            </li>

                            <li class="list-item" v-if="resource.reference.security_rating">
                                {{ __('planner.resources.security') }}:
                                <b-form-rating
                                    v-model="resource.reference.security_rating"
                                    inline
                                    no-border
                                    readonly
                                    class="mb-2"
                                ></b-form-rating>
                            </li>

                            <li class="list-item" v-if="resource.reference.price_per_night">
                                {{ __('planner.resources.price_per_night') }}:
                                {{ resource.reference.price_per_night | currency }}
                            </li>

                            <li class="list-item" v-if="resource.reference.filters.length > 0">
                                <img
                                    v-for="(service, index) in resource.reference.filters"
                                    class="parking-service-icon"
                                    :src="parkingServiceIcon(service)"
                                    v-b-tooltip.hover
                                    :title="parkingServiceLabel(service)"
                                    :key="index"
                                />
                            </li>
                        </ul>
                    </div>

                    <!-- BORSA ALPEGA DETAILS -->
                    <div
                        class="borsa-detail mt-3 mb-2"
                        v-show="resource.on_focus"
                        v-if="resource.type == 'borsa'"
                    >
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="pickup">
                                <h5 class="mb-2">
                                    {{ __('planner.pickup') }}
                                    <i class="fa-solid fa-flag-checkered"></i>
                                </h5>

                                <div class="info">
                                    {{ pickUpAddress(resource).city }},
                                    {{ pickUpAddress(resource).zip }} ({{
                                        pickUpAddress(resource).country
                                    }})
                                </div>
                            </div>

                            <div class="arrow">
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>

                            <div class="delivery text-end">
                                <h5 class="mb-2">
                                    <i class="fa-solid fa-truck-fast"></i>
                                    {{ __('planner.delivery') }}
                                </h5>

                                <div class="info">
                                    {{ deliveryAddress(resource).city }},
                                    {{ deliveryAddress(resource).zip }} ({{
                                        deliveryAddress(resource).country
                                    }})
                                </div>
                            </div>
                        </div>

                        <a
                            class="d-block my-2"
                            :href="resource.reference.links[0].href"
                            target="_blank"
                            v-if="resource.reference.links && resource.reference.links.length > 0"
                            >{{ __('details') }}</a
                        >
                    </div>

                    <!-- BORSA TIMOCOM DETAIL -->
                    <div
                        class="borsa-detail mt-3 mb-2"
                        v-show="resource.on_focus"
                        v-if="resource.type == 'borsa-timocom'"
                    >
                        <div class="pickup">
                            <h5 class="mb-2">
                                {{ __('pickup') }} <i class="fa-solid fa-flag-checkered"></i>
                            </h5>

                            <div class="info">
                                {{ pickUpAddress(resource).city }},
                                {{ pickUpAddress(resource).zip }} ({{
                                    pickUpAddress(resource).country
                                }})
                            </div>
                        </div>

                        <div class="arrow">
                            <i class="fa-solid fa-arrow-right"></i>
                        </div>

                        <div class="delivery text-end">
                            <h5 class="mb-2">
                                <i class="fa-solid fa-truck-fast"></i> {{ __('delivery') }}
                            </h5>

                            <div class="info">
                                {{ deliveryAddress(resource).city }},
                                {{ deliveryAddress(resource).zip }} ({{
                                    deliveryAddress(resource).country
                                }})
                            </div>
                        </div>
                    </div>

                    <button
                        class="btn btn-rounded btn-sm w-100 btn-fill dark-blue mt-2 mb-1"
                        @click="selectResource(resource)"
                        v-show="resource.on_focus && !noSelectButton"
                    >
                        <i class="fa-solid fa-location-plus me-2"></i
                        >{{ __('planner.select_waypoint') }}
                    </button>
                </li>
            </ul>

            <b-pagination
                class="mt-3"
                v-model="pagination.page"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                size="sm"
                align="right"
                pills
                v-if="waypoint?.searchInfo?.pagination"
            ></b-pagination>
        </div>

        <p class="text-center my-3" v-show="!localResources || localResources.length == 0">
            {{ __('planner.resources.empty_search') }} <br />
            <a href="javascript:;" @click="$emit('abortSearch')">{{ __('retry') }}</a>
        </p>
    </div>
</template>

<script>
import _ from 'lodash'
import lumesiaLogo from './../../../assets/images/lumesia-logo.png'

export default {
    name: 'ResourcesList',
    props: {
        waypoint: {
            type: Object
        },
        resources: {
            type: Array
        },
        noHeaderButtons: {
            type: Boolean,
            default: false
        },
        noSelectButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localWaypoint: this.waypoint || {},
            localResources: [],
            filtersDictionary: {
                parkings: [],
                stations: []
            },
            resourcesFilters: {
                service: ''
            },

            pagination: {
                page: 1,
                perPage: 10,
                total: this.waypoint?.searchInfo?.pagination?.total || 0
            },
            //Images
            lumesiaLogo: lumesiaLogo
        }
    },
    watch: {
        resources: function (val) {
            this.localResources = val
        },
        waypoint: {
            handler: function (val) {
                this.localWaypoint = val
                this.pagination.total = val?.searchInfo?.pagination?.total || 0
            },
            deep: true
        },
        'pagination.page': function (page) {
            this.localWaypoint.searchInfo.pagination.page = page

            this.$emit('searchResource', this.localWaypoint)
        }
    },
    methods: {
        resourceHeader(resource) {
            if (resource.type == 'fuel') {
                let name =
                    resource.reference.name ||
                    resource.reference.flag ||
                    resource.reference.id + ' - ' + resource.reference.city
                let header = this.$options.filters.truncate(name, 20, '...')

                if (resource.reference.flag) {
                    header +=
                        ' <span class="badge bg-primary rounded-pill ms-auto" style="font-size: 10px">' +
                        resource.reference.flag +
                        '</span>'
                }

                return header
            } else if (resource.type == 'parking') {
                let name =
                    resource.reference.name ||
                    resource.reference.flag ||
                    resource.reference.id + ' - ' + resource.reference.city
                let header = this.$options.filters.truncate(name, 20, '...')

                if (resource.reference.verified) {
                    header +=
                        ' <span class="badge bg-primary rounded-pill ms-auto" style="font-size: 10px">' +
                        this.__('verified') +
                        '</span>'
                }

                return header
            } else if (resource.type == 'poi') {
                return (
                    resource.name +
                    ' <span class="badge bg-primary rounded-pill ms-auto" style="font-size: 10px">' +
                    resource.reference.type +
                    '</span>'
                )
            } else if (resource.type == 'borsa') {
                return resource.name
            } else if (resource.type == 'borsa-timocom') {
                return resource.name
            } else {
                return (
                    resource.name +
                    ' <span class="badge bg-primary rounded-pill ms-auto" style="font-size: 10px">' +
                    resource.type +
                    '</span>'
                )
            }
        },
        resourceAddress(resource) {
            if (resource.type == 'fuel') {
                let address = resource.reference.address + ', ' + resource.reference.city

                if (resource.reference.province) {
                    address += ' (' + resource.reference.province + ')'
                }
                return address
            } else if (resource.type == 'parking') {
                let address = resource.reference.address

                if (resource.reference.province) {
                    address += ' (' + resource.reference.province + ')'
                }
                return address
            } else if (resource.type == 'borsa') {
                var pickup_address = resource.reference.pickUp.location.address
                var delivery_address = resource.reference.delivery.location.address

                return (
                    pickup_address.city +
                    ', ' +
                    pickup_address.zip +
                    ' (' +
                    pickup_address.country +
                    ')' +
                    ' - ' +
                    delivery_address.city +
                    ', ' +
                    delivery_address.zip +
                    ' (' +
                    delivery_address.country +
                    ')'
                )
            } else if (resource.type == 'borsa-timocom') {
                var pickup_address_timocom = resource.reference.pickUp.location.address
                var delivery_address_timocom = resource.reference.delivery.location.address

                return (
                    pickup_address_timocom.city +
                    ', ' +
                    pickup_address_timocom.zip +
                    ' (' +
                    pickup_address_timocom.country +
                    ')' +
                    ' - ' +
                    delivery_address_timocom.city +
                    ', ' +
                    delivery_address_timocom.zip +
                    ' (' +
                    delivery_address_timocom.country +
                    ')'
                )
            } else {
                return ''
            }
        },
        pickUpAddress(resource) {
            return resource.reference.pickUp.location.address
        },
        deliveryAddress(resource) {
            return resource.reference.delivery.location.address
        },
        focusResource(resource) {
            this.$set(
                resource,
                'grouped_fuels',
                _.groupBy(resource.reference.prices, 'description')
            )

            this.$emit('focusResource', resource)
        },
        selectResource(resource) {
            this.$emit('selectResource', resource)
        },
        groupedFuels(resource) {
            return _.groupBy(resource.prices, 'description')
        },
        parkingServiceLabel(service, lang) {
            lang = lang || 'it_IT'

            if (this.$store.state.geoFleet.filtersDictionary.parkings[service])
                return this.$store.state.geoFleet.filtersDictionary.parkings[service].name[lang]
        },
        parkingServiceIcon(service) {
            if (this.$store.state.geoFleet.filtersDictionary.parkings[service]) {
                return this.$store.state.geoFleet.filtersDictionary.parkings[service]
                    .icon_available_url
            }
        }
    }
}
</script>

<style lang="scss">
#modal-filter-resources hr {
    display: none;
}
</style>

<style scoped lang="scss">
.resources-list {
    --scrollbar-thumb-border: var(--main-bg-color);
    overflow-y: scroll;
    flex-basis: 35%;
}
.single-resource {
    border: 0px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 25px !important;
    cursor: pointer;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);

    &:hover {
        background-color: var(--main-focus);
    }
    &.active {
        background-color: var(--main-focus);
        border-color: var(--main-focus);
        color: var(--main-text-color);
    }

    .resource-name {
        flex-grow: 1;
    }
    .resource-service-logo {
        height: 30px;
    }
    small {
        font-size: 11px;
        display: block;
        margin: 5px 0px;
    }
    .fuels-list {
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #ccc;

        li {
            margin-bottom: 10px;
        }

        .single-fuel {
            margin-top: 5px;
            padding-top: 5px;
            border-top: 1px solid #ccc;
        }
        .single-price {
            min-width: 40%;
        }
        .btn {
            width: 100%;
            border-radius: 25px;
            margin-top: 10px;
        }
    }
    .btn {
        font-size: 16px;
    }

    .parking-service-icon {
        display: inline-block;
        height: 35px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .b-rating {
        background-color: transparent;
        padding: 0px;
        margin-left: 10px;
    }

    .borsa-detail {
        padding-top: 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        h5 {
            font-weight: 700;
        }
    }
}
</style>
