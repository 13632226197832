import { ref, computed, watch } from 'vue'
import store from '@/store'

let localDefaultChart = {
    activity: {
        active: true,
        top: '79%',
        height: '25%',
        opposite: true,
        group: 'driver_data',
        count: false,
        extension: {
            computed: false
        }
    },
    speed: {
        active: true,
        group: 'movement_data',
        chartUm: 'km/h',
        count: true
    },
    tacho_speed: {
        active: false,
        group: 'movement_data',
        chartUm: 'km/h',
        count: true
    },
    odometer: {
        active: false,
        group: 'movement_data',
        chartUm: 'Km',
        count: true
    },
    gps_odometer: {
        active: false,
        group: 'movement_data',
        chartUm: 'Km',
        count: true
    },
    altitude: {
        active: true,
        group: 'movement_data',
        chartUm: 'm s.l.m.',
        count: true
    },
    lastpoint_weight: {
        active: false,
        group: 'movement_data',
        chartUm: 'Kg',
        count: true
    },
    engine_rpm: {
        active: false,
        group: 'engine_data',
        chartUm: 'RPM',
        count: true
    },
    engine_coolant_temperature: {
        active: false,
        group: 'engine_data',
        chartUm: '°C',
        count: true
    },
    // cruise_control_active: {
    //     active: false,
    //     group: 'engine_data'
    // },

    // digital_input_1: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // digital_input_2: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // digital_input_3: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // digital_input_4: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // dallas_temp_1: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // dallas_temp_2: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // codriving: {
    //     active: false,
    //     group: 'engine_data'
    // },
    // pto_active: {
    //     active: false,
    //     group: 'engine_data'
    // },
    consumption: {
        active: true,
        format: '{value} l',
        group: 'engine_data',
        chartUm: 'l'
    }
}

export function useDefaultChart() {
    const defaultChart = ref(localDefaultChart)
    const configFromStore = computed(() => store.state.geoFleet.config?.chart || {})

    const localChart = ref({})

    const updateLocalChart = () => {
        const updatedChart = Object.keys(localDefaultChart).reduce((c, k) => {
            const defaultSettings = localDefaultChart[k]
            const storeSettings = configFromStore.value[k] || {}
            const combinedSettings = { ...defaultSettings, ...storeSettings }

            c[k] = combinedSettings
            return c
        }, {})
        localChart.value = updatedChart
    }

    watch(configFromStore, updateLocalChart, { immediate: true, deep: true })

    return {
        defaultChart,
        localChart
    }
}