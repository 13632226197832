import moment from 'moment'
import store from '@/store'

const actions = {
    // MAIN MAP
    async flyToMap({ state }, payload) {
        let map = state.mainMapInstance.mapObject

        if (map) {
            map.flyTo(payload.center, payload.zoom, { duration: 1 })
        }
    },
    async drawMarker({ commit }, payload) {
        let center = payload.center
        let title = payload.title
        let icon_unicode = payload.icon_unicode
        let ref = payload.ref

        commit('addMainMapExtraObject', {
            object: {
                latLng: center,
                title: title,
                icon_unicode: icon_unicode,
                ref: ref
            },
            type: 'markers'
        })
    },
    async drawCircle({ commit }, payload) {
        let center = payload.center
        let radius = payload.radius
        let ref = payload.ref

        commit('addMainMapExtraObject', {
            object: {
                latLng: center,
                radius: radius,
                ref: ref
            },
            type: 'circles'
        })
    },
    async removeMarker({ state, commit }, payload) {
        let ref = payload.ref

        let marker = state.mainMapExtraObjects.markers.find((m) => m.ref === ref)

        if (marker) {
            commit('removeMainMapExtraObject', { object: marker, type: 'markers' })
        }
    },
    async removeCircle({ state, commit }, payload) {
        let ref = payload.ref

        let circle = state.mainMapExtraObjects.circles.find((c) => c.ref === ref)

        if (circle) {
            commit('removeMainMapExtraObject', { object: circle, type: 'circles' })
        }
    },

    // CONFIG
    async saveConfig({ commit, dispatch, state }, payload) {
        let config = state.config
        let vm = payload.vueInstance

        if (!config) {
            dispatch('loadConfig', { vueInstance: vm })
        }

        let dataToSave = Object.entries(payload).filter(([key, value]) => {
            return key !== 'vueInstance'
        })

        // cycle through payload properties and values
        for (const [prop, value] of dataToSave) {
            if (config && config[prop]) {
                // If config[prop] == value ignore it
                if (config[prop] == value) continue

                //if exists update value
                config[prop] = value
            } else {
                //if not exists add property (with no vueInstance property)
                let noVueInstance = Object.entries(payload).filter(([key, value]) => {
                    return key !== 'vueInstance'
                })
                let newConfig = Object.fromEntries(noVueInstance)

                config = { ...config, ...newConfig }
            }
        }

        let configOptions = {
            type: 'user',
            key: 'geofleet',
            data: config
        }

        vm.$fetch(vm.$env.GOLIA_API + '/store', {
            method: 'POST',
            body: JSON.stringify(configOptions)
        })
            .then((response) => response.json())
            .catch((error) => console.log('error', error))

        //save config to localStorage adding previuos config
        // localStorage.setItem('geoFleet.config', JSON.stringify(config))
        commit('setConfig', config)
    },
    async loadConfig({ commit }, payload) {
        let vm = payload.vueInstance

        //load config from localStorage
        // state.config = JSON.parse(localStorage.getItem('geoFleet.config'))

        vm.$fetch(vm.$env.GOLIA_API + '/store?type=user&key=geofleet')
            .then((response) => response.json())
            .then((result) => {
                commit('setConfig', result.data)
            })
            .catch((error) => console.log('error', error))
    },
    async resetConfig({ commit, dispatch }, payload) {
        let vm = payload.vueInstance

        let configOptions = {
            type: 'user',
            key: 'geofleet',
            data: {}
        }

        vm.$fetch(vm.$env.GOLIA_API + '/store', {
            method: 'POST',
            body: JSON.stringify(configOptions)
        })
            .then((response) => response.json())
            .then(() => {
                commit('setConfig', {})
            })
            .catch((error) => console.log('error', error))

        // localStorage.removeItem('geoFleet.config')
    },

    // TRUCKS
    async loadTrucks({ commit }, payload) {
        let vm = payload.vueInstance

        let trucks = await vm
            .$fetch(
                vm.$env.GOLIA_API +
                    '/trucks?include[]=lastpoint&include[]=details&include[]=category&include[]=tags&filter[VISIBILE][eq]=1'
            )
            .then((res) => {
                return res.json()
            })

        commit('setTrucks', trucks.data)
    },
    async loadTrucksEvents({ commit }, payload) {
        let vm = payload.vueInstance
        let params = {}

        if (payload.update) {
            params.update = payload.update
        }

        let query_string = new URLSearchParams(params).toString()

        // let trucksEvents = await vm.$fetch(vm.$env.GOLIA_API + '/trucks/events').then((res) => {
        let trucksEvents = await vm
            .$fetch(
                'https://run.mocky.io/v3/2de7f863-2d37-4225-8303-7af83ca7fd48' + '?' + query_string
            )
            .then((res) => {
                return res.json()
            })

        commit('setTrucksEvents', trucksEvents)

        // const lastEvent = trucksEvents[trucksEvents.length - 1]
        // commit('setTrucksLastEventId', lastEvent.id)
    },

    // COMPANIES
    async loadCompanies({ commit }, payload) {
        let vm = payload.vueInstance

        let companies_obj = {}

        let companies = await vm.$fetch(vm.$env.GOLIA_API + '/companies').then((res) => {
            return res.json()
        })

        companies_obj[companies.data.company.id] = companies.data.company.business_name

        for (var company of companies.data.master_slave) {
            companies_obj[company.id] = company.business_name
        }

        commit('setCompanies', companies.data)
    },

    // COMPANY TAGS
    async loadCompanyTags({ commit }, vueInstance) {
        let tags = await vueInstance.$fetch(vueInstance.$env.GOLIA_API + '/tags').then((res) => {
            return res.json()
        })

        tags.data.forEach((tag) => {
            tag.loading = false
            tag.error = false
            tag.to_update = false
        })

        commit('setCompanyTags', tags.data)
    },
    async updateCompanyTag({ commit }, payload) {
        let vm = payload.vueInstance
        let tag = payload.item

        let update = await vm
            .$fetch(vm.$env.GOLIA_API + '/tags/' + tag.id, {
                method: 'PUT',
                body: JSON.stringify({ tag: tag.name })
            })
            .then((res) => {
                return res.json()
            })
            .catch((err) => {
                vm.$snotify.error(err, { position: 'centerTop' })
            })

        if (!update.errors) {
            tag.loading = false
            tag.error = false
            tag.to_update = false

            vm.$snotify.success(vm.__('tags.update_tag_success'), { position: 'centerTop' })
        } else {
            tag.error = true
            tag.loading = false
            tag.to_update = false

            vm.$snotify.error(vm.__('tags.update_tag_error'), { position: 'centerTop' })
        }

        commit('updateCompanyTag', tag)
    },
    async addCompanyTag({ commit, dispatch }, payload) {
        let vm = payload.vueInstance
        let tag = payload.item

        let insert = await payload.vueInstance
            .$fetch(payload.vueInstance.$env.GOLIA_API + '/tags', {
                method: 'POST',
                body: JSON.stringify({ tag: tag.name })
            })
            .then((res) => {
                return res.json()
            })
            .catch((err) => {
                vm.$snotify.error(err, { position: 'centerTop' })
            })

        if (!insert.errors) {
            tag.loading = false
            tag.error = false
            tag.to_update = false

            dispatch('loadCompanyTags', vm)
            vm.$snotify.success(vm.__('tags.add_tag_success'), { position: 'centerTop' })
        } else {
            tag.error = true
            tag.loading = false

            vm.$snotify.error(vm.__('tags.add_tag_error'), { position: 'centerTop' })
        }

        commit('addCompanyTag', tag)
    },
    async removeCompanyTag({ commit }, payload) {
        let vm = payload.vueInstance
        let tag = payload.item

        let remove = await vm.$fetch(vm.$env.GOLIA_API + '/tags/' + tag.id, {
            method: 'DELETE'
        })

        if (!remove.errors) {
            tag.loading = false
            tag.error = false

            vm.$snotify.success(vm.__('tags.delete_tag_success'), { position: 'centerTop' })
        } else {
            tag.error = true
            tag.loading = false

            vm.$snotify.error(vm.__('tags.delete_tag_error'), { position: 'centerTop' })
        }

        commit('removeCompanyTag', tag)
    },

    // DRIVERS DATA
    async loadDriversData({ commit }, payload) {
        let vm = payload.vueInstance
        let params = { driving: true }

        if (payload.update) {
            params.update = payload.update
        }

        let query_string = new URLSearchParams(params).toString()

        let drivers = await vm
            .$fetch(vm.$env.GOLIA_API + '/drivers/plafond?' + query_string)
            .then((res) => {
                return res.json()
            })

        if (payload.update) {
            commit('updateDriversData', drivers.data)
        } else {
            commit('setDriversData', drivers.data)
        }
    },
    async loadDrivers({ commit }, payload) {
        let vm = payload.vueInstance

        let drivers = await vm.$fetch(vm.$env.GOLIA_API + '/geofleet/drivers').then((res) => {
            return res.json()
        })

        commit('setDrivers', drivers.data)
    },
    async loadDriverPlafondData({ state, commit }, payload) {
        const vm = payload.vueInstance
        const driverId = payload.driverId

        const cachedPlafondDriver = state.driversPlafondData?.[driverId]
        if (cachedPlafondDriver && moment().diff(cachedPlafondDriver.__cacheTime, 'minutes') < 1) {
            return cachedPlafondDriver
        }

        const translation_language = store.state.loggedUser?.params?.Lingua || 'it-IT'
        const infringement = store.state.loggedUser?.params?.infringment_nation || 'ITA'

        let driverPlafond = await vm
            .$fetch(
                `${vm.$env.GOLIA_API}/drivers/${driverId}/plafond?translation_language=${translation_language}&infringement_nation=${infringement}&timezone=UTC`
            )
            .then((res) => {
                return res.json()
            })

        commit('setDriversPlafondData', { id: driverId, data: driverPlafond })

        return driverPlafond
    },

    // POIS
    async loadPois({ commit }, payload) {
        let vm = payload.vueInstance

        vm.$fetch(vm.$env.GOLIA_API + '/poi')
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                let pois = res.data.map((p) => {
                    p.latLng = [p.lat, p.lng]
                    p.visible = false

                    return p
                })

                commit('setPois', pois)
            })
    },
    async createPoi({ commit, dispatch }, payload) {
        let vm = payload.vueInstance
        let poi = payload.item

        commit('setLoading', true)

        vm.$fetch(vm.$env.GOLIA_API + '/poi', {
            method: 'POST',
            body: JSON.stringify(poi)
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                if (!res.errors) {
                    poi.id = res.data.id
                    poi.visible = false

                    commit('addPoi', poi)
                    vm.$snotify.success(vm.__('manage_poi.messages.add_poi_success'), {
                        position: 'centerTop'
                    })

                    dispatch('loadPois', { vueInstance: vm })
                } else {
                    vm.$snotify.error(vm.__('manage_poi.messages.add_poi_error'), {
                        position: 'centerTop'
                    })
                }

                commit('setLoading', false)
            })
            .catch((err) => {
                vm.$snotify.error(err, { position: 'centerTop' })

                commit('setLoading', false)
            })
    },
    async updatePoi({ commit, dispatch }, payload) {
        let vm = payload.vueInstance
        let poi = payload.item

        let poiToUpdate = {
            color: poi.color,
            description: poi.description,
            lat: poi.lat,
            lng: poi.lng,
            note: poi.note,
            radius: poi.radius,
            type: poi.type
        }

        commit('setLoading', true)

        vm.$fetch(vm.$env.GOLIA_API + '/poi/' + poi.id, {
            method: 'PUT',
            body: JSON.stringify(poiToUpdate)
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                if (!res.errors) {
                    poi.visible = false

                    commit('updatePoi', poi)
                    vm.$snotify.success(vm.__('manage_poi.messages.update_poi_success'), {
                        position: 'centerTop'
                    })

                    dispatch('loadPois', { vueInstance: vm })
                } else {
                    vm.$snotify.error(vm.__('manage_poi.messages.update_poi_error'), {
                        position: 'centerTop'
                    })
                }

                commit('setLoading', false)
            })
            .catch((err) => {
                vm.$snotify.error(err, { position: 'centerTop' })

                commit('setLoading', false)
            })
    },
    async deletePoi({ commit, dispatch }, payload) {
        let vm = payload.vueInstance
        let poi = payload.item

        commit('setLoading', true)

        vm.$fetch(vm.$env.GOLIA_API + '/poi/' + poi.id, {
            method: 'DELETE'
        })
            .then((res) => {
                if (!res.errors) {
                    commit('removePoi', poi)
                    vm.$snotify.success(vm.__('manage_poi.messages.delete_poi_success'), {
                        position: 'centerTop'
                    })

                    dispatch('loadPois', { vueInstance: vm })
                } else {
                    vm.$snotify.error(vm.__('manage_poi.messages.delete_poi_error'), {
                        position: 'centerTop'
                    })
                }

                commit('setLoading', false)
            })
            .catch((err) => {
                vm.$snotify.error(err, { position: 'centerTop' })

                commit('setLoading', false)
            })
    },

    // BLUETOOTH DEVICES
    async loadBluetoothDevices({ commit }, payload) {
        let vm = payload.vueInstance

        let devices = await vm
            // .$fetch('https://run.mocky.io/v3/3021ee38-4b34-4af1-9fbb-6a2e18d238ac')
            .$fetch(vm.$env.GOLIA_API + '/geofleet/bluetooth_accessories')
            .then((res) => {
                return res.json()
            })

        commit('setBluetoothDevices', devices.data)
    }
}

export default actions
