<template>
    <span v-if="truncated">{{ address | truncate(truncated, '...') }}</span>
    <span v-else>{{ address }}</span>
</template>

<script>
export default {
    name: 'ReverseGeocoding',
    props: {
        latLon: {
            type: Array,
            required: true
        },
        full: {
            type: Boolean,
            default: false
        },
        custom_fields: {
            type: Array,
            default: () => []
        },
        truncated: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            address: ''
        }
    },
    watch: {
        latLon() {
            this.reverse(this.latLon)
        }
    },
    methods: {
        async reverse(latLon) {
            let address = await this.$reverseGeocoding({
                lat: latLon[0],
                lng: latLon[1],
                full: this.full,
                custom_fields: this.custom_fields.length ? this.custom_fields : null,
                raw: true
            })

            if (!address) return

            if (this.custom_fields.length) {
                this.address = address
            } else {
                this.address = this.full
                    ? address
                    : this.truncated
                      ? address.reverse().join(', ')
                      : address.join(', ')
            }
        }
    },
    created() {
        this.reverse(this.latLon)
    }
}
</script>

<style scoped>
i {
    font-size: 22px;
}
</style>
