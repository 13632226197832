var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"plafond-info-card plafond-info-card--with_gouge",style:({
        '--gouge-arrow-color': _vm.color
    }),attrs:{"title":_vm.title}},[_c('vue-ellipse-progress',{attrs:{"empty-color":"#324c7e","animation":"reverse  700 1000","fontSize":"0.8rem","dash":"2","color":_vm.color,"font-color":_vm.color,"progress":100 - _vm.progress,"determinate":false,"size":110,"thickness":3,"empty-thickness":3,"loading":false,"no-data":false,"reverse":false,"color-fill":_vm.gradient,"dot":{
            size: 15,
            backgroundColor: 'DarkCyan',
            width: '1px'
        }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i',{class:['fa', _vm.gougeIcon]}),_c('strong',[_vm._v(_vm._s(_vm.gougeText))])]},proxy:true}])}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }