<template>
    <side-panel class="side-panel" id="settings-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('settings') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div class="settings-wrapper">
                <geo-fleet-settings @resetSettings="resetConfig" />
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'

export default {
    name: 'SettingsPanel',
    components: {
        SidePanel
    },
    methods: {
        resetConfig() {
            this.$store.dispatch('resetConfig', { vueInstance: this })
            this.$store.commit('setTimedLoading', 500)
            this.$snotify.success(this.__('reset_settings_success'), { position: 'centerTop' })
        }
    }
}
</script>

<style lang="scss" scoped>
@import './SidePanel.scss';

.settings-wrapper {
    padding: 16px;
}

#settings-panel ::v-deep(.head),
#settings-panel ::v-deep(.body) {
    --side-panels-security-width: 60vw;
    width: var(--side-panels-security-width);
}
</style>
