import mutations from './planner.mutations'
import actions from './planner.actions'

const state = () => ({
    waypoints: [{ id: 1 }]
})

const getters = {
    getUpdatingWaypoint: (state) => {
        return (
            {
                waypoint: state.waypoints.find((t) => t.updatingWaypoint) || null,
                index: state.waypoints.findIndex((t) => t.updatingWaypoint)
            } || null
        )
    },
    getWaypointsErrors: (state) => {
        const errorTypes = {
            empty: 'Empty Waypoint',
            updating: 'Updating Waypoint',
            nullCoordinate: 'Null Coordinate',
            nullReferenceCoordinate: 'Null Reference Coordinate',
            zeroX: 'Zero X Coordinate',
            zeroY: 'Zero Y Coordinate'
        }

        return state.waypoints
            .map((waypoint) => {
                const errors = []

                if (Object.keys(waypoint).length == 0) {
                    errors.push(errorTypes.empty)
                }
                if (waypoint.updatingWaypoint) {
                    errors.push(errorTypes.updating)
                }
                if (waypoint.coordinate == null) {
                    errors.push(errorTypes.nullCoordinate)
                }
                if (waypoint.coordinate && waypoint.coordinate.referenceCoordinate == null) {
                    errors.push(errorTypes.nullReferenceCoordinate)
                }
                if ((waypoint.coordinate?.referenceCoordinate?.x ?? null) == 0) {
                    errors.push(errorTypes.zeroX)
                }
                if ((waypoint.coordinate?.referenceCoordinate?.y ?? null) == 0) {
                    errors.push(errorTypes.zeroY)
                }

                return {
                    ...waypoint,
                    hasErrors: errors.length > 0,
                    errorTypes: errors
                }
            })
            .filter((waypoint) => waypoint.hasErrors)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
