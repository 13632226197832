const actions = {
    async fillDictionaries({ commit }, payload) {
        let vm = payload.vueInstance

        // PARKING FILTERS
        vm.$fetch('https://plannerws.goliaweb.it/service/parking/filters', {
            'X-Company': vm.$authProcess.getSignedTenantToken()
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                commit('setParkingsFiltersDictionary', res.data)
            })
            .catch((error) => {
                console.log('error', error)
            })

        // STATIONS FILTERS
        vm.$fetch('https://plannerws.goliaweb.it/service/station/services', {
            'X-Company': vm.$authProcess.getSignedTenantToken()
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                // if res.data.type == 'FAI' change the label in 'Lumesia'
                res.data.map((item) => {
                    if (item.type == 'FAI') {
                        item.label = 'Lumesia'
                    } else {
                        item.label = item.type
                    }
                })

                commit('setStationsFiltersDictionary', res.data)
            })
            .catch((error) => {
                console.log('error', error)
            })
    },
    searchResource({ dispatch }, payload) {
        let vm = payload.vueInstance
        let waypoint = payload.waypoint
        let lat = payload.lat
        let lng = payload.lng

        var searchInfo = waypoint.searchInfo ? waypoint.searchInfo : waypoint

        let params = {
            lat: lat,
            lng: lng,
            searchInfo: searchInfo,
            vueInstance: vm
        }

        if (Array.isArray(searchInfo.type)) {
            if (searchInfo.type.includes('stations') || searchInfo.type.includes('parkings'))
                dispatch('getPlannerService', params)
        } else {
            this.resources = []

            if (searchInfo.type == 'fuel') dispatch('getPlannerService', params)

            if (searchInfo.type == 'parking') dispatch('getPlannerService', params)

            if (searchInfo.type == 'reachable_areas') dispatch('getReachableAreas', params)

            // if (searchInfo.type == 'poi')
            //     this.getPois(lat , lng, searchInfo)

            // if (searchInfo.type == 'borsa')
            //     this.getBorseCaricoAlpega(lat , lng, searchInfo)

            // if (searchInfo.type == 'borsa-timocom')
            //     this.getBorseCaricoTimocom(lat , lng, searchInfo)
        }
    },
    async getPlannerService({ state, commit }, payload) {
        let vm = payload.vueInstance
        let lat = payload.lat
        let lng = payload.lng
        let searchInfo = payload.searchInfo

        commit('setLoading', true)

        let params = {}

        if (searchInfo.areaUnit == 'min') {
            params = {
                area: searchInfo.isocronaValue
            }
        } else {
            params = {
                lat: lat,
                lng: lng,
                radius: searchInfo.areaValue || 10
            }
        }

        // if type of searchInfo.type is array
        if (Array.isArray(searchInfo.type)) {
            params['services'] = searchInfo.type.join(',')
        } else {
            if (searchInfo.type == 'fuel') {
                params['services'] = 'stations'
            } else if (searchInfo.type == 'parking') {
                params['services'] = 'parkings'
            }
        }

        // search filters
        if (searchInfo.stationServices) {
            let services = searchInfo.stationServices.map((item) => {
                return item.type
            })

            params['filters[services]'] = services
        }
        if (searchInfo.parkingServices) {
            let services = searchInfo.parkingServices.map((item) => {
                return item.id
            })

            params['filters[parking_filters]'] = services
        }
        if (searchInfo.parkingRating && searchInfo.parkingRating > 0) {
            params['filters[reviews_rating][value]'] = searchInfo.parkingRating
        }
        if (searchInfo.parkingSecurityRating && searchInfo.parkingSecurityRating > 0) {
            params['filters[security_rating][value]'] = searchInfo.parkingSecurityRating
        }
        if (searchInfo.parkingVerified) {
            params['filters[verified]'] = 1
        }

        let query_string = new URLSearchParams(params).toString()

        vm.$fetch('https://plannerws.goliaweb.it/service?' + query_string, {
            'X-Company': vm.$authProcess.getSignedTenantToken()
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                let results = []

                // Stazioni servizio
                if (res.data.stations && res.data.stations.length > 0) {
                    results = res.data.stations.map((item) => {
                        return {
                            id: item.id,
                            on_focus: false,
                            coordinate: { y: item.coordinate.y, x: item.coordinate.x },
                            name: item.name,
                            marker_label: item.flag || item.name || item.id + ' - ' + item.city,
                            icon: 'fa-gas-pump',
                            icon_unicode: '#xe01f',
                            type: 'fuel',
                            reference: item
                        }
                    })
                }

                // Parcheggi
                if (res.data.parkings && res.data.parkings.length > 0) {
                    results = res.data.parkings.map((item) => {
                        return {
                            id: item.id,
                            on_focus: false,
                            coordinate: { y: item.coordinate.y, x: item.coordinate.x },
                            name: item.name,
                            marker_label: item.flag || item.name || item.id + ' - ' + item.city,
                            icon: 'fa-square-parking',
                            icon_unicode: '#xf540',
                            type: 'parking',
                            reference: item
                        }
                    })
                }

                if (results.length > 0) {
                    if (!state.resources) {
                        commit('setResources', results)
                    } else {
                        commit('setResources', state.resources.concat(results))
                    }
                }

                commit('toggleActiveSearch', true)
                commit('setLoading', false)
            })
            .catch((error) => {
                console.log('error', error)
                commit('setLoading', false)
            })
    },
    async createReachableAreasBody(state, payload) {
        let reachableAreas = payload.reachableAreas
        let waypoint = payload.waypoint

        let quality =
            reachableAreas.reduce((c, a) => {
                parseInt(a.timing) > c

                c = a.timing

                return c
            }, 0) <= 90

        let routeInfo = this.routeInfo || {}

        return {
            requestProfile: {
                featureLayerProfile: {
                    themes: [
                        { id: 'PTV_SpeedPatterns', enabled: true },
                        { id: 'PTV_TrafficIncidents', enabled: true },
                        { id: 'PTV_TruckAttributes', enabled: true }
                    ]
                },
                userLanguage: 'it-IT'
            },
            reachableAreasOptions: {
                calculationMode: quality ? 'QUALITY' : 'PERFORMANCE',
                drivingDirection: routeInfo.startMode == 'end' ? 'INBOUND' : 'OUTBOUND',
                timeConsideration: {
                    $type:
                        routeInfo.startMode == 'end'
                            ? 'ExactTimeConsiderationAtArrival'
                            : 'ExactTimeConsiderationAtStart',
                    referenceTime:
                        routeInfo?.startTime?.start?.toISOString() || new Date().toISOString()
                },
                horizons: reachableAreas.reduce((c, a) => {
                    if (a.timing)
                        c.push({
                            $type: 'TravelTimeBasedHorizon',
                            travelTime: a.timing * 60
                        })

                    return c
                }, [])
            },
            waypoint: {
                $type: 'OffRoadWaypoint',
                location: {
                    offRoadCoordinate: waypoint,
                    sideOfStreetRestriction: 'ANY_SIDE'
                }
            }
        }
    },
    async getReachableAreas({ commit, dispatch }, payload) {
        let lat = payload.lat
        let lng = payload.lng
        let waypoint = {
            x: lng,
            y: lat
        }
        let searchInfo = payload.searchInfo
        let reachableAreas = searchInfo.reachable_areas

        // let totalArea = 0;

        commit('setLoading', true)

        var myHeaders = new Headers()
        myHeaders.append(
            'Authorization',
            'Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM='
        )
        myHeaders.append('Content-Type', 'application/json')

        if (!waypoint) return false

        //Reorder reachable areas by timing asc
        reachableAreas = reachableAreas.sort((a, b) => a.timing - b.timing)

        let body = await dispatch('createReachableAreasBody', { reachableAreas, waypoint })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(body)
        }

        fetch(
            'https://xserver2-europe.cloud.ptvgroup.com/services/rs/XRoute/calculateReachableAreas',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // state here
                let polygons = result.polygons.map((polygon) => {
                    return polygon.plain.polygonRings.map((p) => {
                        return p.polyline.map((l) => [l.y, l.x])
                    })
                })

                if (polygons.length > 0) {
                    commit('setReachableAreasPolygons', polygons)
                }

                commit('setLoading', false)
            })
            .catch(() => {
                commit('setLoading', false)
            })
    },
    fitBoundsOfMap(state, payload) {
        let map = payload.map
        let bounds = payload.bounds
        let padding = payload.padding

        map.fitBounds(bounds, { padding: padding || [50, 50] })
    }
}

export default actions
