require('dotenv').config()

import Vue from 'vue'
import Vuex from 'vuex'
import VueMq from 'vue-mq'

//LANGS
import IT from './langs/it.js'
import FR from './langs/fr.js'
import EN from './langs/en.js'
import DE from './langs/de.js'
import EL from './langs/el.js'
import ES from './langs/es.js'
import HU from './langs/hu.js'
import PL from './langs/pl.js'
import PT from './langs/pt.js'
import RO from './langs/ro.js'

//PLUGINS
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'
import Snotify from 'vue-snotify'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import Golia from './plugins/Golia.js'
import Caller from './plugins/Caller.js'
import Dater from './plugins/Dater.js'
import Utilities from './plugins/Utilities.js'
import i18n from './plugins/i18n.js'
import VueDarkMode from '@vue-a11y/dark-mode'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

Vue.use(VueMq, {
    breakpoints: {
        xs: 450,
        sm: 992,
        md: 1400,
        lg: 1900,
        xl: Infinity
    }
})

Vue.use(Golia)
Vue.use(Caller)
Vue.use(new Dater('Europe/Rome'))
Vue.use(
    new i18n({
        messages: {
            it: IT,
            en: EN,
            fr: FR,
            de: DE,
            el: EL,
            es: ES,
            hu: HU,
            pl: PL,
            pt: PT,
            ro: RO
        },
        debug: false
    })
)
Vue.use(Utilities)
Vue.use(VueDarkMode)

vSelect.props.components.default = () => ({
    OpenIndicator: {
        render: (createElement) =>
            createElement('i', { class: 'fa-solid fa-chevron-down fa-xs' }, '')
    }
})

Vue.component('v-select', vSelect)
Vue.component('multi-select', Multiselect)
Vue.component('vue-slider', VueSlider)
Vue.use(Snotify)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSocialSharing)

Vue.use(VueSidebarMenu)

const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /\.vue$/i
)

requireComponent.keys().map((key) => {
    Vue.component(
        requireComponent(key).default.name ?? key.split('/').pop().split('.')[0],
        requireComponent(key).default
    )
})

export default Vue
