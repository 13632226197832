import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  /*
   * GEOFLEET
   */
  {
    path: "/",
    name: "geofleet.index",
    component: () => import(/* webpackChunkName: "geofleet" */ "./../views/geofleet/GeoFleet.vue"),
  },

  /*
   * GEOFLEET SHARE LINK
   */
  {
    path: "/view-shared-link/:token",
    name: "viewvehicle.index",
    component: () => import(/* webpackChunkName: "sharedlinks" */ "./../views/viewsharedlink/viewSharedLink.vue"),
  },

  /*
   * PLANNER PORTAL
   */
  {
    path: "/planner/",
    name: "planner.index",
    component: () => import(/* webpackChunkName: "planner" */ "./../views/plannerPortal/plannerPortal.vue"),
  },

  /**
   * Errors/Service pages
   */
  {
    path: "/errors/",
    name: "errors.index",
    component: () => import(/* webpackChunkName: "errors" */ "./../views/errors/ErrorLayout.vue"),
    children: [
      {
        name: "error-403",
        path: "permissions",
        component: () => import(/* webpackChunkName: "errors" */ "./../views/errors/ErrorPermissions.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
