<template>
    <div>
        <div class="head">
            <slot name="head"></slot>
        </div>

        <div class="body" :class="scrollableBody ? 'custom-scrollbar' : ''">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidePanel',
    props: {
        scrollableBody: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    computed: {}
}
</script>

<style scoped>
.head {
    color: var(--main-text-color);
}
.body {
    color: var(--main-text-color);
    overflow-y: hidden;
    flex: 1;

    display: flex;
    flex-direction: column;
    position: relative; /* For loader */
}
.body.custom-scrollbar {
    overflow-y: auto;
}
</style>
