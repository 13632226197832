let moment = require('moment-timezone')

export default class Dater {
    iana = 'UTC'

    constructor(iana) {
        this.iana = iana || this.iana
    }

    parseDateString(value, tz, iana, lang) {
        let date = moment.tz(value, tz || iana || this.iana)

        date.locale(lang ? lang.slice(0, 2) : 'it')

        return date.isValid() ? date.tz(iana || this.iana) : null
    }

    install(Vue) {
        Vue.prototype.moment = (value, tz) => {
            return this.parseDateString(
                value,
                tz,
                Vue.prototype.$authProcess.getParams('tz') || null,
                Vue.prototype.$authProcess.getParams('Lingua') || null
            )
        }

        Vue.moment = Vue.prototype.moment

        Vue.prototype.today = (format) => {
            return moment().format(format || 'DD/MM/YYYY')
        }

        Vue.prototype.unixToDate = (value, format) => {
            return moment.unix(value).format(format || 'DD/MM/YYYY')
        }

        Vue.prototype.$diffDates = (date1, date2, unit) => {
            if (date1 && date2) return moment(date1).diff(moment(date2), unit)
        }

        Vue.filter('formatDate', (value, tz) => {
            let date = this.parseDateString(
                value,
                tz,
                Vue.prototype.$authProcess.getParams('tz') || null,
                Vue.prototype.$authProcess.getParams('Lingua') || null
            )

            if (date) return date.format('L HH:mm:ss')
        })

        Vue.filter('formatDateWithFormat', (value, format, tz) => {
            let date = this.parseDateString(
                value,
                tz,
                Vue.prototype.$authProcess.getParams('tz') || null,
                Vue.prototype.$authProcess.getParams('Lingua') || null
            )

            if (date) return date.format(format)
        })

        Vue.filter('minutesToTime', (value) => {
            let hours = Math.floor(value / 60)
            let min = value % 60

            return (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min)
        })

        Vue.filter('secondsToTime', (value, opts) => {
            opts = opts || {}

            let settings = { h: true, m: true, s: true }

            Object.assign(settings, opts)

            let time = {
                h: Math.floor(value / 3600),
                m: Math.floor((value % 3600) / 60),
                s: (value % 60).toFixed(0)
            }

            return Object.keys(time)
                .reduce((c, k) => {
                    if (settings[k]) c.push(time[k] < 10 ? '0' + time[k] : time[k])

                    return c
                }, [])
                .join(':')
        })
    }
}
