import { render, staticRenderFns } from "./LinksPanel.vue?vue&type=template&id=38af060b&scoped=true"
import script from "./LinksPanel.vue?vue&type=script&lang=js"
export * from "./LinksPanel.vue?vue&type=script&lang=js"
import style0 from "./LinksPanel.vue?vue&type=style&index=0&id=38af060b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38af060b",
  null
  
)

export default component.exports