<template>
    <div>
        <div class="vehicles-list-wrapper">
            <div class="vehicles-list custom-scrollbar">
                <div
                    class="vehicle-list-item"
                    v-for="(vehicle, index) in vehicles"
                    :key="vehicle._id"
                >
                    <golia-list-item v-b-toggle="'vehicle-collapse' + vehicle._id">
                        <template #extra-contents>
                            <div class="d-flex justify-content-between align-items-center">
                                <h3 class="item-title mb-0">
                                    {{ parseVehicleInfo(vehicle._id).plate }}
                                </h3>
                                <div class="item-actions">
                                    <i class="fa fa-chevron-up"></i
                                    ><i class="fa fa-chevron-down"></i>
                                </div>
                            </div>
                            <b-collapse
                                :id="'vehicle-collapse' + vehicle._id"
                                :visible="index == 0"
                            >
                                <div
                                    class="vehicle-events"
                                    v-if="vehicle.grouped_events.length > 0"
                                >
                                    <div
                                        class="single-event d-flex justify-content-between mb-2"
                                        v-for="(event, index) in vehicle.grouped_events"
                                        :key="index"
                                        @click.stop="
                                            openTruckActivity({
                                                id: vehicle._id,
                                                grouped_event: event
                                            })
                                        "
                                    >
                                        <small class="event-date">
                                            <i
                                                class="fa-solid fa-arrow-right-from-arc text-success me-2"
                                            ></i>
                                            {{ eventFormatDate(event.start_timestamp) }}
                                        </small>
                                        <small class="event-date" v-if="event.end_timestamp">
                                            -
                                        </small>
                                        <small class="event-date" v-if="event.end_timestamp">
                                            <i
                                                class="fa-solid fa-arrow-left-from-arc text-danger me-2"
                                            ></i>
                                            {{ eventFormatDate(event.end_timestamp) }}
                                        </small>
                                    </div>
                                </div>
                            </b-collapse>
                        </template>
                    </golia-list-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'VehiclesCrossResults',
    props: {
        vehicles: {
            type: Array,
            default: () => []
        },
        resultsMeta: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {}
    },
    methods: {
        eventFormatDate(timestamp) {
            return this.moment(parseInt(timestamp)).format('L HH:mm')
        },
        openTruckActivity(truck) {
            let meta = {
                start_date: truck?.grouped_event?.start_timestamp || null,
                end_date: truck?.grouped_event?.end_timestamp || null
            }

            meta.start_date = meta.start_date
                ? this.moment(parseInt(meta.start_date))
                : this.moment().startOf('day')
            meta.end_date = meta.end_date
                ? this.moment(parseInt(meta.end_date))
                : this.moment().endOf('day')

            this.setActivityTruck({ truck, on_activity: true })
            this.addPanelMeta({ panel: 'activity', meta: meta })
            this.openPanel('activity')
        },
        parseVehicleInfo(vehicleId) {
            let vehicle = this.trucksRef[vehicleId]

            if (!vehicle) return vehicleId

            return vehicle
        },
        ...mapMutations({
            setActivityTruck: 'setActivityTruck',
            addPanelMeta: 'addPanelMeta',
            openPanel: 'openPanel'
        })
    },
    computed: {
        ...mapState({
            trucksRef: (state) => state.geoFleet.trucksRef
        })
    }
}
</script>

<style scoped lang="scss">
.vehicles-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    position: relative;

    .vehicles-list.custom-scrollbar {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px;
    }

    .vehicle-list-item {
        cursor: pointer;

        .vehicle-events {
            border-top: 1px solid var(--main-gray--darker);
            padding-top: 15px;
            margin-top: 15px;

            .single-event:last-child {
                margin-bottom: 0 !important;
            }
            .single-event:hover {
                color: var(--dark-blue);
            }
        }

        .not-collapsed {
            .fa-chevron-down {
                display: none;
            }
        }
        .collapsed {
            .fa-chevron-up {
                display: none;
            }
        }
    }
}
</style>
