<template>
    <div class="white-cloud" :class="highlighted ? 'highlighted' : ''">
        <!-- TITOLO SEZIONE -->
        <div class="cloud-header d-flex align-items-center">
            <span class="title-wc" v-if="title"
                >{{ __(title).toUpperCase() }}

                <span v-html="icon"></span>
            </span>

            <input
                type="search"
                name="filterTelemetries"
                class="form-control"
                :placeholder="__('filter') + '...'"
                @input="filterTelemetries"
                v-if="searchable"
            />
        </div>

        <hr style="border: 1px solid #aebec6" v-if="title" />

        <div class="empty-cloud-panel text-center" v-if="searchable && Object.keys(data) == 0">
            <small>{{ __('empty_search') }}</small>
        </div>

        <!-- DETTAGLI SEZIONE -->
        <div class="cloud-container">
            <div v-for="(title, key) in Object.keys(data)" :key="key">
                <div
                    class="detail-telemetry-info-row row g-0 justify-content-between align-items-center"
                    :class="Object.keys(data).length > 1 ? 'mb-1' : ''"
                >
                    <div
                        class="pe-2"
                        :class="data[title].type == 'button_group' ? 'col-7' : 'col-5'"
                    >
                        <span class="detail-wc">{{
                            data[title].originalName
                                ? data[title].originalName.toUpperCase()
                                : __(title).toUpperCase()
                        }}</span>
                    </div>
                    <div
                        class="d-flex align-items-center justify-content-between"
                        :class="data[title].type == 'button_group' ? 'col-5' : 'col-7'"
                        v-if="data[title].value !== null"
                    >
                        <!-- Nel caso in cui ci sia data[title].type == 'switch_input' -->
                        <span
                            class="response-wc d-flex align-items-center ms-auto"
                            v-if="data[title].type && data[title].type == 'switch_input'"
                        >
                            <toggle-button
                                :value="data[title].value"
                                :sync="true"
                                class="me-3"
                                @change="(event) => switchInputChange(title, event.value)"
                            />
                        </span>

                        <!-- Nel caso in cui ci sia data[title].type == 'button' -->
                        <span
                            class="response-wc d-flex align-items-center ms-auto mb-2"
                            style="width: 70%"
                            v-else-if="data[title].type && data[title].type == 'button'"
                        >
                            <golia-button
                                class="btn-sm"
                                :class="
                                    data[title].buttonClass
                                        ? data[title].buttonClass
                                        : 'btn-fill dark-blue'
                                "
                                style="font-size: 12px; width: 100%"
                                :label="data[title].buttonLabel"
                                :ref="data[title].buttonRef"
                                @click="buttonClick(title, data[title])"
                            />
                        </span>

                        <template v-else>
                            <!-- In caso di Telemetria GSM Signal inserisco un icona -->
                            <span
                                class="response-wc gsm-signal d-flex align-items-center"
                                v-if="title == 'gsm_signal'"
                            >
                                <i
                                    class="fa-solid me-2"
                                    :class="'fa-' + data[title].value.icon"
                                ></i>
                                <small class="text-muted">({{ data[title].value.value }})</small>
                            </span>

                            <!-- Telemetrie standard -->
                            <span class="response-wc" v-else>
                                {{ data[title].value }}
                                <span v-if="data[title].um">{{ data[title].um }}</span>
                                <span v-if="data[title].additional">
                                    {{ data[title].additional }}
                                </span>
                                <span v-if="rfid && title == 'driver'">
                                    <i
                                        class="fa-solid me-2 fa-info-circle text-dark-blue"
                                        v-b-tooltip.hover
                                        :title="__('no_driver_rfid_info')"
                                    ></i>
                                </span>
                            </span>

                            <i
                                class="fa-solid fa-xs me-2 fa-info-circle text-dark-blue d-none"
                                v-if="data[title].description"
                            ></i>
                        </template>
                    </div>
                </div>

                <!-- if data[title].type = 'button_group' make n button as many as data[title].buttons -->
                <div
                    class="d-flex mt-2 gap-2"
                    v-if="data[title].type && data[title].type == 'button_group'"
                >
                    <golia-button
                        v-for="(button, index) in data[title].buttons"
                        :key="index"
                        class="btn-sm"
                        :class="button.buttonClass ? button.buttonClass : 'btn-fill dark-blue'"
                        style="font-size: 12px; width: 100%"
                        :label="button.buttonLabel"
                        :ref="button.buttonRef"
                        @click="buttonClick(title, data[title], button)"
                    />
                </div>

                <b-collapse
                    :id="'telemetry-collapse-' + key"
                    class="mt-2 d-none"
                    v-if="data[title].description"
                >
                    <small class="text-muted" style="font-size: 11px">{{
                        data[title].description
                    }}</small>
                    <hr />
                </b-collapse>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
export default {
    name: 'CloudPanel',
    props: {
        title: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        rfid: {
            type: Boolean,
            required: false,
            default: false
        },
        ignoreNullValue: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: Object,
            required: true
        },
        searchable: {
            type: Boolean,
            required: false,
            default: false
        },
        highlighted: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ToggleButton
    },
    data() {
        return {}
    },
    computed: {
        values() {
            if (this.ignoreNullValue)
                return Object.keys(this.data).reduce((c, k) => {
                    if (this.data[k].value !== null) c[k] = this.data[k]

                    return c
                }, {})

            return this.data
        }
    },
    methods: {
        switchInputChange(title, value) {
            this.$emit('switchInputChange', title, value)
        },
        buttonClick(title, data, button) {
            this.$emit('buttonClick', {
                eventName: title,
                command: title,
                value: button.value
            })
        },
        filterTelemetries(e) {
            const value = e.target.value.toLowerCase()

            this.$emit('search', value)
        }
    }
}
</script>

<style scoped lang="scss">
.cloud-header {
    gap: 10px;

    .form-control {
        width: 100%;
        height: 30px;
        font-size: 12px;
        padding: 0 5px;
        border-radius: 5px;
        border: 1px solid #aebec6;
        background: rgba(var(--main-bg-color--rgb), 0.8);
        color: var(--main-text-color);
    }
}
.content {
    background: var(--main-inner-bg-color);
    border-radius: 0 0 15px 15px;
    margin: 0;
    padding: 0px 15px 5px 15px;
    width: 100%;

    flex: 1;
    overflow-y: auto;
}

.white-cloud {
    background: rgba(var(--main-bg-color--rgb), 0.8);
    padding: 15px;
    border-radius: 15px;
    margin-top: 10px;

    &.highlighted {
        border: 2px solid #dabf6e;
    }
}
.title-wc {
    font-size: 15px;
}
.detail-wc {
    font-size: 11px;
    color: #90a4ae;
    text-transform: uppercase;
    vertical-align: middle;
}
.response-wc {
    font-size: 14px;
    color: var(--main-text-color);
    vertical-align: middle;
    font-weight: 700;
    line-height: 1.5;
}
.cloud-container > div:last-child .response-wc.mb-2 {
    margin-bottom: 0 !important;
}
.detail-telemetry-info-row:hover,
.detail-telemetry-info-row[aria-expanded='true'] {
    cursor: pointer;

    .detail-wc {
        color: #324047;
    }
    .response-wc {
        color: #4b57b5;
    }
}
</style>
