<template>
    <div>
        <div class="form-group mb-3">
            <label for="address">{{ __('vehicles_cross.fields.address') }}</label>

            <golia-waypoint-search id="address" v-model="localVehiclesCross.address" />
        </div>

        <!-- Km radius input -->
        <div class="form-group mb-3">
            <label for="radius">{{ __('vehicles_cross.fields.radius') }} (Km)</label>
            <golia-input
                id="radius"
                type="number"
                step=".1"
                max="100"
                min="0.1"
                :value="localVehiclesCross.radius"
                v-model.number="localVehiclesCross.radius"
            ></golia-input>
        </div>

        <!-- Time interval (max 24 hours) -->
        <div class="form-group mb-3">
            <label for="time-interval">{{ __('vehicles_cross.fields.time_interval') }}</label>
            <golia-date-picker
                v-model="localVehiclesCross.timeInterval"
                :monthPanels="1"
                :singleDate="false"
                :specificTime="false"
                :maxRange="1"
            ></golia-date-picker>

            <small class="form-text text-muted" :class="isMoreThan24Hours ? 'text-golia' : ''">
                {{ __('vehicles_cross.fields.time_interval_description') }}
            </small>
        </div>

        <golia-button
            class="mt-1"
            variant="btn-success"
            type="button"
            @click="searchVehicleCross"
            :disabled="!validForm || loading"
            :loading="loading"
            :label="loading ? __('loading') : __('search')"
        />
    </div>
</template>

<script>
export default {
    name: 'VehiclesCrossForm',
    props: {
        value: {
            type: Object
        },
        loading: {
            type: Boolean
        },
        isMoreThan24Hours: {
            type: Boolean
        }
    },
    data() {
        return {
            localVehiclesCross: this.value,
            drawCirclesTimeout: null
        }
    },
    methods: {
        searchVehicleCross() {
            this.$emit('searchVehicleCross')
        }
    },
    watch: {
        value: function () {
            this.localVehiclesCross = this.value
        }
    },
    computed: {
        validForm() {
            return (
                this.localVehiclesCross.address &&
                this.localVehiclesCross.timeInterval.start &&
                this.localVehiclesCross.timeInterval.end &&
                !this.isMoreThan24Hours
            )
        }
    }
}
</script>

<style scoped>
.v-select {
    font-size: 14px;
}
</style>
