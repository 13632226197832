<template>
    <b-card
        class="plafond-info-card plafond-info-card--with_gouge"
        :title="title"
        :style="{
            '--gouge-arrow-color': color
        }"
    >
        <vue-ellipse-progress
            empty-color="#324c7e"
            animation="reverse  700 1000"
            fontSize="0.8rem"
            dash="2"
            :color="color"
            :font-color="color"
            :progress="100 - progress"
            :determinate="false"
            :size="110"
            :thickness="3"
            :empty-thickness="3"
            :loading="false"
            :no-data="false"
            :reverse="false"
            :color-fill="gradient"
            :dot="{
                size: 15,
                backgroundColor: 'DarkCyan',
                width: '1px'
            }"
        >
            <template #default>
                <i :class="['fa', gougeIcon]"></i>
                <strong>{{ gougeText }}</strong>
            </template>
        </vue-ellipse-progress>

        <slot></slot>
    </b-card>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress'

export default {
    name: 'PlafondInfoWithGougeCard',
    components: {
        VueEllipseProgress
    },
    props: {
        title: String,
        gougeIcon: String,
        gougeText: String,
        progress: Number,
        color: { type: String, default: '#3A65FF' }
    },
    data: () => ({
        gradient: {
            radial: true,
            colors: [
                {
                    color: '#3260FC',
                    offset: '50',
                    opacity: '0.2'
                },
                {
                    color: '#3260FC',
                    offset: '50',
                    opacity: '0.15'
                },
                {
                    color: '#3260FC',
                    offset: '70',
                    opacity: '0.15'
                },
                {
                    color: '#3260FC',
                    offset: '70',
                    opacity: '0.1'
                },
                {
                    color: '#3260FC',
                    offset: '90',
                    opacity: '0.1'
                },
                {
                    color: 'transparent',
                    offset: '90',
                    opacity: '0.1'
                },
                {
                    color: 'transparent',
                    offset: '95',
                    opacity: '0.1'
                },
                {
                    color: 'transparent',
                    offset: '95',
                    opacity: '0.1'
                }
            ]
        }
    })
}
</script>

<style lang="scss" scoped>
.plafond-info-card--with_gouge {
    --gouge-arrow-color: black;

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

::v-deep(.ep-circle--progress__dot) {
    &:after {
        content: '';
        display: block;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        top: 0px;
        right: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid var(--gouge-arrow-color);
        transform: rotate(180deg);
    }
}

::v-deep(.ep-legend--value__counter) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1rem;
}
</style>
