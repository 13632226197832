<template>
    <b-card class="plafond-info-card">
        <b-card-title>
            <i class="fa-solid fa-bed rest"></i>
            {{ __('plafonds.reduced_weekly_rest') }}
        </b-card-title>
        <template v-if="rests.length > 0">
            <div
                class="d-flex flex-column"
                v-for="(rest, rIndex) in rests"
                :key="`rest_to_get_${rIndex}`"
            >
                <small v-if="rests.length > 1"
                    ><strong>{{ `${__('rest')} ${rIndex + 1}` }}</strong></small
                >
                <dl class="card-data-list card-data-list--border-bottom">
                    <dt>{{ __('start') }}</dt>
                    <dd style="text-align: right">
                        {{ rest.periodStart }}
                    </dd>
                    <dt>{{ __('end') }}</dt>
                    <dd style="text-align: right">
                        {{ rest.periodEnd }}
                    </dd>
                    <dt>{{ __('duration') }}</dt>
                    <dd style="text-align: right">
                        {{ rest.duration }}
                    </dd>
                    <dt>{{ __('plafonds.time_to_compensate') }}</dt>
                    <dd style="text-align: right">
                        {{ rest.recoveryHours }}
                    </dd>
                    <dt>{{ __('plafonds.time_to_compensate_until') }}</dt>
                    <dd style="text-align: right">
                        {{ rest.nextStartPeriod }}
                    </dd>
                </dl>
                <hr v-if="rests.length > 1" />
            </div>
        </template>
        <template v-else>
            <p class="text-center p-5">
                {{ __('none') }}
            </p>
        </template>
    </b-card>
</template>

<script>
export default {
    name: 'LastWeekRestsCard',
    props: {
        rests: Array
    }
}
</script>
