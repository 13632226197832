<template>
    <side-panel id="detail-panel" :class="{ collapsed: localCollapsed }">
        <template #head>
            <div
                @click="localCollapsed = !localCollapsed"
                class="collapse-button"
                v-if="$mq != 'xs' && $mq != 'sm'"
            >
                <i v-if="localCollapsed" class="fa-solid fa-arrow-left-from-line"></i>
                <i v-else class="fa-solid fa-arrow-right-from-line"></i>
            </div>
            <transition name="collapse-fade" mode="in-out">
                <details-head
                    :truck="localTruck"
                    :collapsed="localCollapsed"
                    @close="closePanel"
                    v-if="localTruck && !localCollapsed"
                />
            </transition>

            <div
                class="close-btn"
                @click="closePanel"
                v-if="localCollapsed && $mq != 'xs' && $mq != 'sm'"
            >
                <i class="fa-solid fa-xmark"></i>
            </div>
            <!-- Mobile close button (return to home) -->
            <div
                @click="$router.push({ name: 'geofleet.index' })"
                class="close-btn"
                v-if="$mq == 'xs' || $mq == 'sm'"
            >
                <i class="fa-solid fa-xmark"></i>
            </div>
        </template>

        <template #body>
            <!-- Mobile placeholder if the vehicle single route doesn't exist -->
            <div
                class="d-flex flex-column justify-content-center align-items-center h-100"
                v-if="$route.params.id && !localTruck && ($mq == 'xs' || $mq == 'sm')"
            >
                <p class="text-center px-5">
                    {{ __('vehicle_not_found') }} <br />
                    <br />
                    <golia-button
                        variant="btn-fill golia"
                        @click="$router.push({ name: 'geofleet.index' })"
                        :label="__('back_to_home')"
                    />
                </p>
            </div>

            <div class="collapse-info" v-if="localCollapsed">
                <div class="collapsed-header">
                    <div
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        "
                    >
                        <div
                            class="circle-icon"
                            :style="{ 'background-color': colorStatus, 'background-image': 'none' }"
                        >
                            <truck-icon
                                style="padding: 5px; color: #fff"
                                :category="localTruck.category"
                            />
                        </div>
                        <span class="targa-c">{{
                            (localTruck.details.custom_id || localTruck.plate) | truncate(7)
                        }}</span>
                    </div>

                    <div v-if="localTruck.details.custom_id" class="plate">
                        <small
                            ><b>{{ localTruck.plate }}</b></small
                        >
                    </div>

                    <div class="lastpoint-info">
                        <div>
                            {{ localTruck.lastpoint.date | formatDate('UTC') }}
                        </div>
                        <div
                            v-if="
                                localTruck.lastpoint.provenience &&
                                localTruck.lastpoint.provenience.web
                            "
                            class="p-provdescr"
                        >
                            <i
                                v-if="
                                    localTruck.lastpoint.provenience &&
                                    localTruck.lastpoint.provenience.icon
                                "
                                :class="['fa-solid', localTruck.lastpoint.provenience.icon]"
                            ></i>
                            {{ localTruck.lastpoint.provenience.web }}
                        </div>
                    </div>

                    <div class="localizzazione-f">
                        <reverse-geocoding
                            :full="true"
                            :truncated="80"
                            :latLon="[localTruck.lastpoint.lat, localTruck.lastpoint.lng]"
                        />
                    </div>
                </div>
            </div>

            <transition name="collapse-fade" mode="in-out">
                <details-body
                    :truck="localTruck"
                    :collapsed="localCollapsed"
                    @updateTruckTags="(tags) => $emit('updateTruckTags', tags, localTruck)"
                    class="d-body"
                    :class="
                        $mq == 'xs' || $mq == 'sm' ? 'details-mobile-body' : 'details-desktop-body'
                    "
                    ref="details_body"
                    v-if="!localCollapsed && localTruck"
                />
            </transition>

            <div class="tab-tools d-flex flex-column" style="gap: 8px" v-if="localCollapsed">
                <golia-button
                    @click="expandAndSelect('truck')"
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-solid fa-fw fa-truck"
                />
                <golia-button
                    @click="expandAndSelect('driver')"
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-solid fa-fw fa-user"
                />
            </div>

            <div
                class="panel-tools d-flex"
                :class="localCollapsed ? 'flex-column' : 'flex-row justify-content-center'"
                v-if="$mq !== 'xs' && $mq !== 'sm'"
            >
                <golia-button
                    @click="$emit('activity', localTruck)"
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-solid fa-fw fa-chart-mixed"
                />
                <golia-button
                    v-if="$can('use', 'fuel_stations') || $can('use', 'parkings')"
                    @click="openPanel('searchResources')"
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-fw fa-search-poi"
                    icon-weight="fa-kit"
                />
                <golia-button
                    v-if="$can('use', 'planner')"
                    @click="$emit('plan', localTruck)"
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-solid fa-fw fa-route"
                />
                <golia-button
                    v-if="$can('use', 'share_link')"
                    v-b-modal.modal-share-link
                    variant="btn-fill dark-blue btn-circle"
                    icon="fa-solid fa-fw fa-share"
                />
            </div>

            <!-- Modale crea link -->
            <b-modal
                id="modal-share-link"
                :title="__('sharedlinks.share_single_vehicle') + ' ' + localTruck?.plate"
                size="lg"
                @cancel="closeShareModal"
            >
                <div class="create-link" v-show="!createdLink">
                    <p class="mt-2 mb-3">{{ __('sharedlinks.start_expire_date_description') }}:</p>

                    <div class="formg-roup">
                        <label for="share-start-date">{{
                            __('sharedlinks.select_start_expire_date')
                        }}</label>

                        <golia-date-picker
                            v-model="shareLinkStart"
                            :monthPanels="1"
                            :specificTime="false"
                        ></golia-date-picker>
                    </div>
                </div>

                <div class="preview-link" v-show="createdLink">
                    <p
                        class="my-2 p-2 text-white"
                        :class="createdLink && createdLink.errors ? 'bg-danger' : 'bg-success'"
                    >
                        {{
                            createdLink && createdLink.errors
                                ? __('sharedlinks.created_link_error')
                                : __('sharedlinks.created_link_success')
                        }}
                    </p>
                    <p class="mb-3 share-link-message" v-show="createdLink && !createdLink.errors">
                        {{ __('sharedlinks.service_description') }}:

                        <span
                            class="d-block position-relative"
                            v-if="createdLink && createdLink.data"
                        >
                            <b
                                ><router-link
                                    ref="sharedLink"
                                    target="_blank"
                                    :to="'/view-shared-link/' + createdLink.data.token"
                                    >{{ __('sharedlinks.link_preview') }} ({{
                                        __('click_here')
                                    }})</router-link
                                ></b
                            >
                            <input
                                type="text"
                                v-on:focus="$event.target.select()"
                                ref="sharedLinkInput"
                                readonly
                                :value="computedSharedLink"
                            />
                            <button class="btn btn-sm btn-success" @click="copySharedLink">
                                <i class="fa-regular fa-copy"></i>
                                {{ copiedLink ? __('copied') : __('copy') }}
                            </button>
                        </span>

                        <golia-shareNetwork
                            v-if="computedSharedLink"
                            :sharedLink="computedSharedLink"
                            :truckPlate="localTruck.plate"
                            :bigButton="true"
                        />
                    </p>
                </div>

                <template #modal-footer="{ cancel }">
                    <golia-button
                        variant="btn-fill dark-blue"
                        @click="cancel()"
                        :label="createdLink ? __('close') : __('cancel')"
                    />

                    <golia-button
                        variant="btn-fill success"
                        v-show="createdLink"
                        @click="createdLink = null"
                        :label="
                            createdLink && createdLink.errors
                                ? __('retry')
                                : __('sharedlinks.create_link')
                        "
                    />
                    <golia-button
                        variant="btn-fill success"
                        @click="createShareLink(localTruck.id)"
                        v-show="!createdLink"
                        :label="__('sharedlinks.create_link')"
                        :disabled="shareDifference <= 0"
                        :loading="shareLoading"
                    />
                </template>
            </b-modal>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from '@/components/panels/SidePanel.vue'

//Details Panel
import DetailsHead from './DetailsPanel/DetailsHead.vue'
import DetailsBody from './DetailsPanel/DetailsBody.vue'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'DetailsPanel',
    props: {
        truck: {
            type: Object,
            required: false,
            default: null
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SidePanel,
        DetailsHead,
        DetailsBody
    },
    data() {
        return {
            localTruck: this.focus_truck || this.truck,
            localCollapsed: this.collapsed,
            shareLinkStart: {
                start: this.moment().startOf('day'),
                end: this.moment().endOf('day')
            },
            shareLoading: false,
            createdLink: null,
            copiedLink: false
        }
    },
    created() {
        if (this.focus_truck) {
            this.localTruck = this.focus_truck
        } else {
            this.setCurrentTruck(this.storeTrucks)
        }
    },
    methods: {
        setCurrentTruck(trucks) {
            if (!this.focus_truck && this.$route.params.id) {
                trucks.forEach((truck) => {
                    if (truck.plate == this.$route.params.id) {
                        this.$store.commit('setFocusTruck', { truck: truck, on_focus: true })

                        this.localTruck = truck
                    }
                })
            } else {
                this.localTruck = this.focus_truck
            }
        },
        copySharedLink() {
            this.copiedLink = true

            let copyText = this.$refs.sharedLinkInput
            copyText.select()
            document.execCommand('copy')

            setTimeout(() => {
                this.copiedLink = false
            }, 2000)
        },
        expandAndSelect(type) {
            this.localCollapsed = false

            this.$nextTick(() => {
                this.$refs.details_body.page = type
            })
        },
        createShareLink(truckId) {
            var _this = this
            this.shareLoading = true

            this.$fetch(this.$env.GOLIA_API + '/sharelink', {
                method: 'POST',
                body: JSON.stringify({
                    truck_id: truckId,
                    start_at: _this.startToSend,
                    expire_at: _this.expireToSend,
                    tz: this.$authProcess.getParams('tz')
                })
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.shareLoading = false
                    this.createdLink = res

                    this.$store.commit('addLink', res.data)
                })
        },
        closeShareModal() {
            this.createdLink = null
            this.shareLinkStart = {
                start: this.moment().startOf('day'),
                end: this.moment().endOf('day')
            }
        },
        closePanel() {
            //Pulizia store legato al pannello
            this.$store.commit('cleanDriversPlafondCache')

            this.$emit('lostfocus', this.localTruck)
        },
        ...mapMutations({
            openPanel: 'openPanel'
        }),
        ...mapActions({
            openSearchResourcePanel: 'openSearchResourcePanel'
        })
    },
    watch: {
        collapsed: function (val) {
            this.localCollapsed = val
        },
        truck: function (val) {
            this.localTruck = val
        },
        storeTrucks: function (val) {
            this.setCurrentTruck(val)
        }
    },
    computed: {
        computedSharedLink() {
            if (this.createdLink && this.createdLink.data) {
                return this.$path_join(
                    window.location.origin,
                    this.$router.resolve({
                        name: 'viewvehicle.index',
                        params: { token: this.createdLink.data.token }
                    }).href
                )
            } else {
                return null
            }
        },
        colorStatus() {
            // da migrare con la composable
            if (
                this.moment().diff(this.moment(this.localTruck.lastpoint.date, 'UTC'), 'hours') > 1
            ) {
                return 'var(--default-color)'
            } else {
                if (this.localTruck.activity_type == 'moving') return 'var(--moving-color)'
                else if (this.localTruck.activity_type == 'engine_on')
                    return 'var(--engine-on-color)'
                else if (this.localTruck.activity_type == 'ignition') return 'var(--ignition-color)'
                else {
                    if (
                        this.localTruck.lastpoint.ignition ||
                        this.localTruck.lastpoint.ignition > 0
                    ) {
                        return 'var(--ignition-color)'
                    } else {
                        return 'var(--default-color)'
                    }
                }
            }
        },
        shareDifference() {
            if (this.shareLinkStart) {
                return this.shareLinkStart.end.diff(this.shareLinkStart.start, 'hours')
            } else {
                return 0
            }
        },
        startToSend() {
            return this.shareLinkStart.start.clone().utc().toISOString()
        },
        expireToSend() {
            return this.shareLinkStart.end.clone().utc().toISOString()
        },
        ...mapState({
            storeTrucks: (state) => state.geoFleet.trucks
        }),
        ...mapGetters({
            focus_truck: 'getFocusTruck'
        })
    }
}
</script>

<style scoped lang="scss">
@import './DetailsPanel/styles/DetailsPanel.scss';
</style>

<style lang="scss">
/* COLLAPSED PANEL STYLES */
#detail-panel.collapsed {
    .head,
    .body {
        width: 120px;

        transition-property: width;
        transition-duration: 0.4s;
        transition-timing-function: ease-in;
    }

    .head {
        margin-bottom: 10px;
    }
    .body {
        justify-content: space-between;
        overflow-y: auto;

        --scrollbar-track-bg: transparent;
        --scrollbar-thumb-border: #fff;

        &::-webkit-scrollbar {
            width: 12px; /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-bg); /* color of the tracking area */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a3a3a3; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 3px solid var(--scrollbar-thumb-border); /* creates padding around scroll thumb */
        }
    }
    .collapse-info {
        font-size: 12px;
        text-align: center;
    }
    .panel-tools {
        flex-wrap: nowrap;
        margin-top: 8px;
    }
    .plate {
        margin-top: 0px;
    }
    .p-provdescr {
        margin-top: 10px;
    }
    .btn {
        width: 52px !important;
        padding: 15px;
        border-radius: 50%;
        margin: 0 auto;
    }
    .dati-autisti,
    .lastpoint-info,
    .localizzazione-f {
        max-width: 100px;
        text-align: center;
        display: block;
        margin: 5px auto;
    }

    .close-btn {
        z-index: 999;
        width: 30px;
        height: 30px;
    }
}
</style>
