var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row menu"},[_c('div',{class:{ 'collapsible-title': true, 'is-active': _vm.page == 'maneuver' },on:{"click":function($event){_vm.page = 'maneuver'}}},[_c('i',{staticClass:"fa-solid fa-route"})]),_c('div',{class:{ 'collapsible-title': true, 'is-active': _vm.page == 'emission' },on:{"click":function($event){_vm.page = 'emission'}}},[_c('i',{staticClass:"fa-solid fa-smog"})]),_c('div',{class:{ 'collapsible-title': true, 'is-active': _vm.page == 'segments' },on:{"click":function($event){_vm.page = 'segments'}}},[_c('i',{staticClass:"fa-solid fa-road"})]),(_vm.toll.sections)?_c('div',{class:{ 'collapsible-title': true, 'is-active': _vm.page == 'toll' },on:{"click":function($event){_vm.page = 'toll'}}},[_c('i',{staticClass:"fa-solid fa-route-highway"})]):_vm._e()]),_c('div',{staticClass:"content custom-scrollbar"},[(_vm.page == 'maneuver')?_c('div',{staticStyle:{"margin-bottom":"50px"}},_vm._l((_vm.events),function(event,key){return _c('div',{key:key,staticClass:"box",class:_vm.page,attrs:{"data-index":key}},[(!(event.tourEventTypes || []).includes('SERVICE'))?_c('div',{staticClass:"event-wrapper",class:event.$type == 'TourEvent' &&
                        (event.tourEventTypes.includes('BREAK') ||
                            event.tourEventTypes.includes('DAILY_REST'))
                            ? 'break-info'
                            : ''},[(event.$type != 'ManeuverEvent' && event.$type != 'TourEvent')?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.eventTypeLabel(event.$type))),(event.waypointIndex >= 0)?_c('span',[_vm._v(" - "+_vm._s(_vm.waypointLabelOnCoordinates(event.waypointIndex)))]):_vm._e()]):(event.$type == 'TourEvent')?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(event.tourEventTypes.includes('BREAK') ? _vm.__('break') : _vm.__('daily_rest'))+" ")]):_vm._e(),_c('div',{staticClass:"description"},[(event.country)?_c('div',[_vm._v(_vm._s(event.country))]):_vm._e(),(event.directionDescription)?_c('div',[_c('i',{class:_vm.maneuverIcon(event.maneuverType)}),_vm._v(" "+_vm._s(event.directionDescription)+" ")]):_vm._e(),(event.displayName)?_c('div',[_vm._v(" "+_vm._s(event.displayName)+" "),(event.accessType)?_c('span',[_vm._v("("+_vm._s(event.accessType == 'ENTER' ? _vm.__('input') : _vm.__('output'))+")")]):_vm._e()]):_vm._e()]),(event.$type == 'WaypointEvent')?_c('div',{staticClass:"road-stamp"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.__('finish_point')))]),_vm._v(" "+_vm._s(_vm._f("formatDate")(event.startsAt,'UTC'))+" ")]),(event.travelTimeFromStart > 0)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.__('duration')))]),_vm._v(" "+_vm._s(_vm._f("secondsToTime")(event.travelTimeFromStart))+" ")]):_vm._e(),(event.distanceFromStart > 0)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.__('distance_total')))]),_vm._v(" "+_vm._s(_vm._f("metersToKm")(event.distanceFromStart))+" ")]):_vm._e()]):_vm._e(),(
                            event.$type == 'TourEvent' &&
                            (event.tourEventTypes.includes('BREAK') ||
                                event.tourEventTypes.includes('DAILY_REST'))
                        )?_c('div',{staticClass:"road-stamp"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.__('to_begin_within')))]),_vm._v(" "+_vm._s(_vm._f("formatDate")(event.startsAt,'UTC'))+" ")]),(event.travelTimeFromStart > 0)?_c('div',{staticClass:"d-none"},[_c('b',[_vm._v(_vm._s(_vm.__('after_total_time_travel')))]),_vm._v(" "+_vm._s(_vm._f("secondsToTime")(event.travelTimeFromStart))+" ")]):_vm._e(),(event.distanceFromStart > 0)?_c('div',{staticClass:"d-none"},[_c('b',[_vm._v(_vm._s(_vm.__('after_total_distance_travel')))]),_vm._v(" "+_vm._s(_vm._f("metersToKm")(event.distanceFromStart))+" ")]):_vm._e()]):_vm._e()]):_vm._e()])}),0):(_vm.page == 'emission')?_c('div',{style:(_vm.emissions.length > 1 ? 'margin-bottom: 50px' : '')},_vm._l((_vm.emissions),function(emission,key){return _c('div',{key:key,staticClass:"box",class:_vm.page},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.__(emission.standardType))+" ")]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"row"},_vm._l((Object.keys(emission).filter(
                                (t) =>
                                    t != '$type' &&
                                    t != 'standardType' &&
                                    t != 'valueScenarioType'
                            )),function(title,key){return _c('div',{key:key,staticClass:"col-md-6 mb-1"},[_c('div',{staticClass:"d-flex justify-content-between h-100 pb-1",staticStyle:{"border-bottom":"1px solid #ddd"}},[_c('b',{staticStyle:{"opacity":"0.9"}},[_vm._v(_vm._s(_vm.__(_vm.consumiLabel(title))))]),_c('span',[_vm._v(_vm._s(title == 'numberOfParticles' ? (emission[title] / 1000000000000000).toFixed(3) + ' quadr.' : emission[title].toFixed(3)))])])])}),0)])])}),0):(_vm.page == 'segments')?_c('div',{style:(_vm.legs.length > 3 ? 'margin-bottom: 50px' : '')},_vm._l((_vm.legs),function(leg,key){return _c('div',{key:key,staticClass:"box",class:_vm.page},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.__('segment'))+" "+_vm._s(key + 1))]),_c('div',{staticClass:"description"},[_c('i',[_vm._v(_vm._s(_vm.waypoints[key].coordinate.label)+" - "+_vm._s(_vm.waypoints[key + 1].coordinate.label))]),_c('div',{staticClass:"row mt-2"},_vm._l((Object.keys(leg)),function(title,key){return _c('div',{key:key,staticClass:"col-md-6 mb-1"},[_c('div',{staticClass:"d-flex justify-content-between h-100 pb-1",staticStyle:{"border-bottom":"1px solid #ddd"}},[_c('b',{staticStyle:{"opacity":"0.9"}},[_vm._v(_vm._s(_vm.__(_vm.segmentiLabel(title))))]),_c('span',[_vm._v(_vm._s(_vm.segmentiValue(title, leg[title])))])])])}),0)])])}),0):(_vm.page == 'toll')?_c('div',{style:(_vm.toll.summary.countries.length > 3 || (_vm.toll.sections || {}).length > 3
                    ? 'margin-bottom: 50px'
                    : '')},_vm._l((_vm.toll.summary.countries),function(country,key){return _c('div',{key:key,staticClass:"box",class:_vm.page},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.__('state'))+" "+_vm._s(country.country))]),_c('div',{staticClass:"description"},[_c('table',_vm._l((_vm.toll.sections.filter(
                                (t) => t.country == country.country
                            )),function(toll,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(toll.displayName))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(toll.cost.amount)))])])}),0),_c('hr',{staticClass:"my-2"}),_c('table',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.__('cost')))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(country.cost.amount)+" €")])])])])])}),0):_c('div')])])
}
var staticRenderFns = []

export { render, staticRenderFns }