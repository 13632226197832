import moment from 'moment'

export function isNotValidTachoTimestampFromUpdated(lastpoint) {
    const tachigrafo = lastpoint?.telemetry?.tacho_timestamp
        ? moment(parseInt(lastpoint?.telemetry?.tacho_timestamp?.$date?.$numberLong))
        : null

    const updated_at = moment(lastpoint?.updated_at)

    if (tachigrafo && updated_at.diff(tachigrafo, 'minutes') >= 10) return true

    return false
}

export function isNotValidTachoTimestampFromDevice(lastpoint) {
    const centralina = lastpoint?.telemetry?.device_timestamp
        ? moment(lastpoint?.telemetry?.device_timestamp).utc(true)
        : null
    const tachigrafo = lastpoint?.telemetry?.tacho_timestamp
        ? moment(parseInt(lastpoint?.telemetry?.tacho_timestamp?.$date?.$numberLong))
        : null

    if (centralina && centralina.diff(tachigrafo, 'minutes') >= 10) return true

    return false
}

export function returnToTheFuture(lastpoint) {
    const timestamp = moment(lastpoint?.date).utc(true)
    const now = moment().utc(true)

    if (timestamp.diff(now, 'hours') >= 1) return true
}

export function buildVehicleAlerts(vehicle) {
    let alerts = {}

    let truckSpeed = vehicle?.lastpoint?.tacho_speed || vehicle?.lastpoint?.speed
    let last_date = moment(vehicle?.lastpoint?.date).utc(true)

    if (
        vehicle?.lastpoint?.telemetry?.battery_level &&
        vehicle?.lastpoint?.telemetry?.battery_level <= 20
    ) {
        let icon =
            vehicle?.lastpoint?.telemetry?.battery_level.value <= 5
                ? 'fa-solid fa-battery-slash'
                : 'fa-solid fa-battery-low'
        alerts.low_battery_level = { icon: icon, problem: 'low_battery_level', unicode: '&#xF244;' }
    }
    if (
        vehicle?.lastpoint?.driver &&
        vehicle?.lastpoint?.driver.id == 1 &&
        truckSpeed &&
        vehicle.type != 3
    ) {
        alerts.no_card = {
            icon: 'fak fa-user-nocard',
            problem: 'no_card_driving',
            unicode: '&#xF2C2;'
        }
    }
    if (
        vehicle?.lastpoint?.telemetry?.tacho_overspeed &&
        vehicle?.lastpoint?.telemetry?.tacho_overspeed == 1 &&
        truckSpeed
    ) {
        alerts.tacho_overspeed = {
            icon: 'fa-solid fa-gauge-max',
            problem: 'tacho_overspeed',
            unicode: '&#xF4F5;'
        }
    }

    if (
        vehicle?.lastpoint?.telemetry?.external_voltage !== undefined
    ) {

        if (vehicle.lastpoint.telemetry.external_voltage == 0) {
            alerts.no_power_supply = {
                icon: 'fa-solid fa-plug-circle-xmark',
                problem: 'no_power_supply',
                unicode: '&#xF244;'
            }
        } else if (vehicle.lastpoint.telemetry.external_voltage < 9000) {
            alerts.no_power_supply = {
                icon: 'fa-solid fa-plug-circle-exclamation',
                problem: 'low_power_supply',
                unicode: '&#xF244;'
            }
        }
    }
    if (
        vehicle?.lastpoint?.telemetry?.gnss_status &&
        vehicle?.lastpoint?.telemetry?.gnss_status == 1
    ) {
        alerts.no_gps = {
            icon: 'fa-solid fa-signal-bars-slash',
            problem: 'no_gps',
            unicode: '&#xF7C5;'
        }
    }
    if (isNotValidTachoTimestampFromUpdated(vehicle?.lastpoint)) {
        alerts.tacho_clock_from_updated_error = {
            icon: 'fa-solid fa-reply-clock',
            problem: 'tacho_clock_from_updated_error',
            unicode: '&#xE33E;'
        }
    }
    if (isNotValidTachoTimestampFromDevice(vehicle?.lastpoint)) {
        alerts.tacho_clock_from_device_error = {
            icon: 'fa-solid fa-truck-clock',
            problem: 'tacho_clock_from_device_error',
            unicode: '&#xE33E;'
        }
    }
    if (returnToTheFuture(vehicle?.lastpoint)) {
        alerts.device_timestamp_error = {
            icon: 'fa-solid fa-calendar-circle-exclamation',
            problem: 'device_timestamp_error',
            unicode: '&#xE46E;'
        }
    }
    if (moment().diff(last_date, 'hours') > 24) {
        alerts.no_points = {
            icon: 'fa-solid fa-exclamation-triangle',
            problem: 'no_points',
            unicode: '&#xF071;'
        }
    }

    return alerts
}
