// let jws = require('jws')

const golia = {
    install: function (Vue) {
        let env_overwrite = {}

        if (process.env.NODE_ENV == 'development') {
            env_overwrite = Object.keys(process.env).reduce((c, k) => {
                c[k.replace('VUE_APP_', '')] = process.env[k]

                return c
            }, {})
        }

        var stateCode = {
            production: 'cdnigw02',
            development: 'cdnigw02dev'
        }

        //ENV
        Vue.prototype.$env = Object.assign(
            {
                GOLIA_API: 'https://golia.goliaweb.it/api/v1.0',
                SIMULATOR_SERVER_HOST: '//apisimulator.goliaweb.it',
                AUTH_20_LINK:
                    'https://auth.goliaweb.it/login?client_id=ccd57a1299c3c354&response_type=code&grant_type=authorization_code&redirect_uri=https://bff-geofleet-23lela5wwq-ew.a.run.app/code&state=' +
                    location.origin,

                //PTV
                ptv_user: 'xtok',
                ptv_password: 'D6687876-CAE6-432B-AE07-BA5BFAFF41F3'
            },
            env_overwrite
        )

        Vue.prototype.$reverseGeocoding = async function (options) {
            try {
                let response = await fetch(
                    `https://geo.goliaweb.it/reverse?lat=${options.lat}&lon=${options.lng}&format=json`,
                    {
                        headers: new Headers({
                            Authorization: `Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM=`
                        }),
                        cache: 'force-cache'
                    }
                ).then((response) => response.json())

                if (!response.address) return null

                if (options.full) return response.display_name

                if (options.custom_fields) {
                    let address = options.custom_fields
                        .map((f) => response.address[f])
                        .filter((c) => !!c)

                    return address.join(', ')
                }

                let address = [
                    response.address.road,
                    response.address.village || response.address.county,
                    (response.address.village || response.address.county) == response.address.city
                        ? null
                        : response.address.city,
                    response.address.state,
                    response.address.country
                ]

                address = address.filter((c) => !!c)

                if (options.raw) return address

                return address.join(', ')
            } catch (e) {
                console.error(e)
            }
        }

        Vue.prototype.$path_join = function (...args) {
            var splitted = args.join('/').split(/http(s|):\/\//g)

            var path = splitted.pop().replace(/\/+/g, '/')

            return (splitted.length > 1 ? 'http' + splitted[1] + '://' : '') + path
        }

        Vue.filter('truncate', function (text, length, clamp) {
            clamp = clamp || ''

            return text.length > length ? text.slice(0, length) + clamp : text
        })

        Vue.filter('currency', function (value, currency) {
            currency = currency || 'EUR'
            let lang_code = document.querySelector('html').getAttribute('lang') || 'it-IT'

            return new Intl.NumberFormat(lang_code, {
                style: 'currency',
                currency: currency
            }).format(value)
        })

        //HELPERS
        Vue.prototype.$isBrowserWindowActive = (() => {
            if (document.visibilityState == 'undefined') document.visibilityState = 'visible'

            return () => {
                return document.visibilityState == 'visible'
            }
        })()

        //DIRECTIVES

        Vue.directive('click-outside', {
            bind: function (el, binding) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) binding.value(el)
                }
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            }
        })

        Vue.directive('resized', {
            inserted: function (el, binding, vnode) {
                if (!ResizeObserver) return false

                el.resize_observer = {}

                el.resize_observer.obj = new ResizeObserver(
                    ((el) => {
                        return (ent) => {
                            el.resize_observer.rect = ent[0].contentRect

                            if (el.resize_observer.timer) return false

                            el.resize_observer.timer = setTimeout(() => {
                                binding.value.bind(vnode.context)(el.resize_observer.rect)

                                clearTimeout(el.resize_observer.timer)

                                el.resize_observer.timer = null
                            }, 300)
                        }
                    })(el)
                ).observe(el)
            },
            unbind: function (el) {
                if (el.resize_observer.obj) el.resize_observer.obj.unobserve(el)
            }
        })
    }
}

export default golia
