<template>
    <div>
        <multi-select
            v-bind="$attrs"
            v-on="$listeners"
            :ref="refer"
            @select="
                (option, id) => {
                    $emit('selectOption', option, id)
                }
            "
            @remove="
                (option, id) => {
                    $emit('removeOption', option, id)
                }
            "
            @tag="
                (query, id) => {
                    $emit('tagOption', query, id)
                }
            "
        >
            <template slot="selection" slot-scope="{ values, isOpen }" v-if="customSelectedLabel">
                <span
                    class="multiselect__single"
                    v-if="values.length && !isOpen"
                    @click="toggleMultiselect(isOpen, this.refer)"
                >
                    <b class="text-success">{{ values.length }}</b>
                    <span v-if="customSelectedLabel && !customSelectedLabel.onlyValue">
                        {{
                            values.length > 1
                                ? customSelectedLabel && customSelectedLabel.plural
                                    ? customSelectedLabel.plural
                                    : __('selected_plural')
                                : customSelectedLabel && customSelectedLabel.singular
                                  ? customSelectedLabel.singular
                                  : __('selected')
                        }}
                    </span>
                </span>
            </template>
            <template slot="noOptions">
                <span>{{ emptyLabel ? emptyLabel : __('empty_search') }}</span>
            </template>
        </multi-select>
        <div class="d-flex flex-wrap container-label mt-2" v-if="outsideTagsSelected">
            <div>
                <span v-if="attrsCloned.value.length > 0" class="text-label"
                    >{{ customValueLabels }}:</span
                >
            </div>
            <div v-for="(tag, index) in attrsCloned.value" :key="tag.id">
                <span class="selected-label">
                    {{ tag.label }}{{ attrsCloned.value.length - 1 !== index ? ',' : '' }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoliaMultiselect',
    props: {
        refer: {
            type: [String, Number],
            default: 'multiselect-ref'
        },
        customSelectedLabel: {
            type: [Object, Boolean],
            default: null
        },
        customValueLabel: {
            type: [Object, Boolean],
            default: null
        },
        emptyLabel: {
            type: String,
            default: null
        },
        outsideTagsSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localeForLabels: (this.$authProcess.getParams('Lingua') || '').replace('-', '_')
        }
    },
    computed: {
        customValueLabels() {
            if (this.customValueLabel == null)
                return this.attrsCloned.value.length > 1
                    ? this.__('multiselect_default_value.plural')
                    : this.__('multiselect_default_value.singular')
            return this.attrsCloned.value.length > 1
                ? this.customValueLabel.plural
                : this.customValueLabel.singular
        },
        /**
         * Clona l'attributo e modifica la proprietà "label" in base al valore dell'attributo "label".
         *
         * @return {Object} L'attributo clonato con la proprietà "label" modificata
         **/
        attrsCloned() {
            let attrs = Object.assign({}, this.$attrs)
            let key = attrs.label
            if (attrs.value && attrs.value.length > 0)
                attrs.value.forEach((el) => {
                    el.label = el[key]
                    // nel caso in cui avessimo una label tradotta in più lingue
                    if (typeof el.label === 'object') el.label = el.label[this.localeForLabels]
                })
            return attrs
        }
    },
    watch: {
        waypoint: function (val) {
            this.localWaypoint = val
        }
    },
    methods: {
        /**
         * Refactors della label in base all'opzione fornita.
         *
         * @param {Object} option - l'oggetto option
         * @return {string} la label "refatorizzata".
         */
        labelRefactor(option) {
            let att = this.$attrs
            let key = att.label
            let label = option[key]

            if (typeof label === 'string') return label

            return label[this.localeForLabels]
        },
        toggleMultiselect(isOpen, ref) {
            if (!isOpen) {
                this.$refs[ref].activate()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.container-label {
    .text-label {
        font-size: 12px;
        margin-right: 2px;
        font-weight: 600;
    }
    .selected-label {
        font-size: 12px;
        margin-left: 2px;
        margin-right: 2px;
    }
}

::v-deep(.multiselect__option.multiselect__option--highlight.multiselect__option--selected) {
    .slot-value {
        .icon::before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;

            content: '\f057';
            font-family: 'Font Awesome 6 Pro', 'FontAwesome';
            font-weight: 300;
        }
    }
}
.multiselect__option--selected {
    .slot-value {
        .icon::before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;

            content: '\f336';
            font-family: 'Font Awesome 6 Pro', 'FontAwesome';
            font-weight: 900;
        }
    }
}
</style>
