<template>
    <side-panel class="side-panel" id="links-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('shared_links') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div class="d-flex justify-content-between">
                <!-- Chiude modale / Torna indietro -->
                <golia-button
                    icon="fa-fw fa-arrow-left"
                    variant="btn-fill dark-blue"
                    v-show="createNewLink"
                    @click="createNewLink = false"
                    :label="__('sharedlinks.back_to_links')"
                />

                <!-- Permette di creare un nuovo link mostrando la form -->
                <golia-button
                    icon="fa-fw fa-plus"
                    variant="btn-fill success"
                    :class="!createNewLink ? 'ms-auto' : ''"
                    v-show="!createdLink && !createNewLink"
                    @click="createNewLink = true"
                    :label="__('sharedlinks.create_link')"
                />

                <!-- Pulsante che permette di riprovare -->
                <golia-button
                    icon="fa-fw fa-plus"
                    variant="btn-fill success"
                    v-show="createdLink"
                    @click="createdLink = null"
                    :label="
                        createdLink && createdLink.errors
                            ? __('retry')
                            : __('sharedlinks.create_link')
                    "
                />
            </div>

            <hr />

            <div
                v-if="sharedLinks.length > 0 && !createNewLink"
                class="links-list custom-scrollbar"
            >
                <transition-group name="link-transition" tag="div">
                    <single-link
                        class="single-link"
                        :id="'link-' + link.token"
                        v-for="link in sharedLinks"
                        :link="link"
                        :key="link.token"
                        @loadSharedLinks="loadSharedLinks"
                        @deleteLink="deleteLink"
                    >
                    </single-link>
                </transition-group>
            </div>
            <div v-else-if="sharedLinks.length == 0 && !createNewLink">
                <p class="text-center mb-4">{{ __('sharedlinks.no_shared_links') }}</p>
            </div>
            <div v-else>
                <div v-show="!createdLink">
                    <golia-multiselect
                        v-model="sharedLinksVehicles"
                        :options="trucks"
                        :multiple="true"
                        :close-on-select="false"
                        track-by="plate"
                        label="plate"
                        :placeholder="__('sharedlinks.select_share_vehicles')"
                        :selectLabel="__('select')"
                        :selectedLabel="__('selected')"
                        :deselectLabel="__('deselect')"
                        id="sharedLinksVehicles"
                        ref="sharedLinksVehicles"
                        class="no-input"
                        :show-labels="false"
                        style="margin-top: 3px"
                    >
                    </golia-multiselect>
                    <small class="d-block text-muted mt-1 ms-2">{{
                        __('select_multi_vehicles')
                    }}</small>

                    <golia-date-picker
                        v-model="shareLinkStart"
                        :monthPanels="1"
                        :specificTime="false"
                        class="mt-2"
                    ></golia-date-picker>
                    <small class="d-block ms-2 text-muted">{{
                        __('sharedlinks.select_start_expire_date')
                    }}</small>

                    <!-- Pulsante che permette di creare il link -->
                    <golia-button
                        icon="fa-fw fa-plus"
                        variant="btn-fill success mt-3"
                        @click="createShareLink()"
                        v-show="!createdLink && createNewLink"
                        :label="__('sharedlinks.create_link')"
                        :disabled="shareDifference <= 0 || sharedLinksVehicles.length == 0"
                        :loading="shareLoading"
                    />
                </div>

                <div class="preview-link" v-show="createdLink">
                    <p
                        class="my-2 p-2 text-white"
                        :class="createdLink && createdLink.errors ? 'bg-danger' : 'bg-success'"
                    >
                        {{
                            createdLink && createdLink.errors
                                ? __('sharedlinks.created_link_error')
                                : __('sharedlinks.created_link_success')
                        }}
                    </p>
                    <p class="mb-3 share-link-message" v-show="createdLink && !createdLink.errors">
                        {{ __('sharedlinks.service_description') }}:

                        <span
                            class="d-block position-relative"
                            v-if="createdLink && createdLink.data"
                        >
                            <b
                                ><router-link
                                    ref="sharedLink"
                                    target="_blank"
                                    :to="'/view-shared-link/' + createdLink.data.token"
                                    >{{ __('sharedlinks.link_preview') }} ({{
                                        __('click_here')
                                    }})</router-link
                                ></b
                            >
                            <input
                                type="text"
                                v-on:focus="$event.target.select()"
                                ref="sharedLinkInput"
                                readonly
                                :value="computedSharedLink"
                            />
                            <button class="btn btn-sm btn-success" @click="copySharedLink">
                                <i class="fa-regular fa-copy"></i>
                                {{ copiedLink ? __('copied') : __('copy') }}
                            </button>
                        </span>

                        <golia-shareNetwork
                            v-if="computedSharedLink"
                            :sharedLink="computedSharedLink"
                            :bigButton="true"
                        />
                    </p>
                </div>
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import SingleLink from './LinksPanel/SingleLink.vue'

import { mapState, mapMutations } from 'vuex'

export default {
    name: 'LinksPanel',
    components: {
        SidePanel,
        SingleLink
    },
    props: {
        trucks: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            createNewLink: false,
            sharedLinksVehicles: [],
            shareLoading: false,
            shareLinkStart: {
                start: this.moment().startOf('day'),
                end: this.moment().endOf('day')
            },
            createdLink: null,
            copiedLink: false
        }
    },
    watch: {},
    created() {
        this.loadSharedLinks()
    },
    computed: {
        computedSharedLink() {
            if (this.createdLink && this.createdLink.data) {
                return this.$path_join(
                    window.location.origin,
                    this.$router.resolve({
                        name: 'viewvehicle.index',
                        params: { token: this.createdLink.data.token }
                    }).href
                )
            } else {
                return null
            }
        },
        shareDifference() {
            if (this.shareLinkStart) {
                return this.shareLinkStart.end.diff(this.shareLinkStart.start, 'hours')
            } else {
                return 0
            }
        },
        startToSend() {
            return this.shareLinkStart.start.clone().utc().toISOString()
        },
        expireToSend() {
            return this.shareLinkStart.end.clone().utc().toISOString()
        },
        ...mapState({
            sharedLinks: (state) => state.sharedLinks.links
        })
    },
    methods: {
        loadSharedLinks() {
            var links = []

            this.$fetch(this.$env.GOLIA_API + '/sharelink', {
                method: 'GET'
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    var incomingLinks = res.data

                    for (var company in incomingLinks) {
                        if (incomingLinks[company].length > 0) {
                            for (var link in incomingLinks[company]) {
                                links.push(incomingLinks[company][link])
                            }

                            this.setSharedLinks(links)
                        }
                    }
                })
        },
        createShareLink() {
            var _this = this
            this.shareLoading = true
            let truck_ids = this.sharedLinksVehicles.map((a) => a.id)

            this.$fetch(this.$env.GOLIA_API + '/sharelink', {
                method: 'POST',
                body: JSON.stringify({
                    truck_id: truck_ids,
                    start_at: _this.startToSend,
                    expire_at: _this.expireToSend,
                    tz: this.$authProcess.getParams('tz')
                })
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.shareLoading = false
                    this.createdLink = res

                    this.addLink(res.data)
                })
        },
        toggleMultiselect(isOpen) {
            if (!isOpen) {
                this.$refs.sharedLinksVehicles.activate()
            }
        },
        copySharedLink() {
            this.copiedLink = true

            let copyText = this.$refs.sharedLinkInput
            copyText.select()
            document.execCommand('copy')

            setTimeout(() => {
                this.copiedLink = false
            }, 2000)
        },
        deleteLink() {
            if (this.sharedLinks == 0) {
                this.$bvModal.hide('modal-all-links')
            }
        },
        ...mapMutations({
            setSharedLinks: 'setSharedLinks',
            addLink: 'addLink',
            updateExpireLink: 'updateExpireLink',
            removeLink: 'removeLink'
        })
    }
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';

#links-panel ::v-deep(.head),
#links-panel ::v-deep(.body) {
    --side-panels-security-width: 400px;
    width: var(--side-panels-security-width);
}

.shared-links {
    overflow: hidden;
}

.links-list {
    flex: 1;
    overflow-y: auto;
}

.link-transition-enter-active,
.link-transition-leave-active {
    transition: all 0.5s;
}
.link-transition-enter, .link-transition-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.bg-success,
.bg-danger {
    border-radius: 5px;
}
.bg-success {
    background-color: var(--success) !important;
}
.share-link-message a {
    color: var(--dark-blue);
    display: block;
    word-break: break-all;
    padding: 10px;
    border-radius: 5px;
    background: var(--main-gray);
    margin-top: 10px;
    font-size: 14px;
    position: relative;
    z-index: 1;

    &:hover {
        color: var(--dark-blue--hover);
    }
}
.share-link-message button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
}
.share-link-message input:read-only {
    position: relative;
    top: -35px;
}
</style>
