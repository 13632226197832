<template>
    <side-panel>
        <template #head>
            <div class="a-header">
                <div class="a-h-details">
                    <details-head :truck="truck" :tiny="true" />
                    <div class="a-h-address">
                        <i
                            style="color: #2881c1; font-size: 38px"
                            class="fa-solid fa-location-dot"
                        ></i>
                        <reverse-geocoding :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]" />
                    </div>
                </div>
                <div class="a-h-end">
                    <golia-date-picker
                        class="date-picker"
                        v-model="dateRange"
                        v-if="dateRange.start && dateRange.end"
                        :maxRange="3"
                    >
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </golia-date-picker>
                </div>
                <div class="close-btn" @click="$emit('lostfocus', truck)">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
        </template>

        <template #body>
            <map-route-analizer :truck="truck" :range="dateRange" />
        </template>
    </side-panel>
</template>

<script>
import SidePanel from '@/components/panels/SidePanel.vue'

import MapRouteAnalizer from './ActivityPanel/MapRouteAnalizer.vue'
import DetailsHead from '@/views/geofleet/components/DetailsPanel/DetailsHead.vue'

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'ActivityPanel',
    props: {
        truck: {
            type: Object,
            required: true
        }
    },
    components: {
        SidePanel,
        MapRouteAnalizer,
        DetailsHead
    },
    data() {
        return {
            dateRange: { start: null, end: this.moment().endOf('day') }
        }
    },
    created() {
        this.setDateRangeStart()
    },
    mounted() {
        this.$watch(
            () => this.activityMeta,
            () => {
                this.setDateRangeStart()
            }
        )
    },
    watch: {
        truck() {
            this.setDateRangeStart()
        }
    },
    computed: {
        ...mapState({
            driversData: (state) => state.geoFleet.driversData
        }),
        ...mapGetters({
            activityMeta: 'activityMeta',
            isLoading: 'isLoading'
        })
    },
    methods: {
        setDateRangeStart() {
            let driver = this.truck.lastpoint.driver
                ? this.truck.lastpoint.driver
                : this.truck.lastpoint.last_driver

            if (this.activityMeta && this.activityMeta.start_date && this.activityMeta.end_date) {
                this.dateRange.start = this.activityMeta.start_date
                this.dateRange.end = this.activityMeta.end_date

                return
            }

            if (!(driver && driver.id > 1)) {
                this.dateRange.start = this.moment().startOf('day')
                this.dateRange.end = this.moment().endOf('day')

                return
            }

            if (this.driversData[driver.id]) {
                let start_labor_day = this.driversData[driver.id]?.day?.start
                let today = this.moment().startOf('day')

                // If start_labor_day is older than 5 days, set it to today
                if (start_labor_day && today.diff(start_labor_day, 'days') > 5) {
                    this.dateRange.start = this.moment().startOf('day')
                    this.dateRange.end = this.moment().endOf('day')

                    return
                }

                this.dateRange.start =
                    this.moment(this.driversData[driver.id].day.start, 'UTC') ||
                    this.moment().startOf('day')

                this.dateRange.end = this.moment(this.driversData[driver.id].day.start, 'UTC')
                    ? this.moment(this.driversData[driver.id].day.start, 'UTC').endOf('day')
                    : this.moment().endOf('day')

                return
            }

            this.dateRange.start = this.moment().startOf('day')
            this.dateRange.end = this.moment().endOf('day')
        }
    }
}
</script>

<style scoped lang="scss">
.map {
    padding: 15px;
    height: 100%;
    border-radius: 25px;
}
.date-picker {
    align-self: center;
    min-width: 282px;

    ::v-deep(.form-control),
    ::v-deep(.form-input-g) {
        color: #333;
    }
}
.date-picker :not(.dp_main) {
    font-size: 12px !important;
}
.close-btn {
    top: 7px;
    right: 7px;
    display: flex;
    width: 30px;
    height: 30px;
}
.date-picker ::v-deep(.form-input-g .input) {
    background: #eaeaea !important;
}
</style>
