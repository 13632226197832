<template>
    <b-card class="plafond-info-card">
        <b-card-title>
            <i class="fa-solid fa-bed rest"></i>
            {{ __('plafonds.last_rest') }}
        </b-card-title>
        <dl class="card-data-list">
            <dt>{{ __('start') }}</dt>
            <dd style="text-align: right">
                {{ rest.periodStart }}
            </dd>
            <dt>{{ __('end') }}</dt>
            <dd style="text-align: right">
                {{ rest.periodEnd }}
            </dd>
            <dt>{{ __('duration') }}</dt>
            <dd style="text-align: right">
                {{ rest.duration }}
            </dd>
            <dt>{{ __('plafonds.minimal_duration_next') }}</dt>
            <dd style="text-align: right">
                {{ rest.nextDurationHours }}
            </dd>
            <dt>{{ __('plafonds.top_start_until') }}</dt>
            <dd style="text-align: right">
                {{ rest.nextStartPeriod }}
            </dd>
        </dl>
    </b-card>
</template>

<script>
export default {
    name: 'LastWeekRestCard',
    props: {
        rest: Object
    }
}
</script>
