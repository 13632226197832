<template>
    <div>
        <!-- LANG SETTINGS -->
        <h5 class="section-title mt-4 mb-2">{{ __('options_language') }}</h5>

        <div class="bg-main-inner-bg p-3 rounded-3">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group mb-3">
                        <label class="mb-2">{{ __('change_interface_language') }}</label>
                        <v-select
                            v-model="langs['lingua']"
                            :options="userSettings.languages"
                            label="DESCRIZIONE"
                            :reduce="(option) => option.TRANSLATION_LANGUAGE"
                            :placeholder="__('select')"
                            :clearable="false"
                            :searchable="false"
                        ></v-select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group mb-3">
                        <label class="mb-2">{{ __('change_timezone') }}</label>
                        <v-select
                            v-model="langs['timeZone']"
                            :options="userSettings.timezones"
                            label="timeZone"
                            :reduce="(option) => option.timeZone"
                            :placeholder="__('select')"
                            :clearable="false"
                            :searchable="false"
                        ></v-select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group mb-3">
                        <label class="mb-2">{{ __('change_infringment_language') }}</label>
                        <v-select
                            v-model="langs['infrNation']"
                            :options="userSettings.infringment_nations"
                            label="NAME"
                            :reduce="(option) => option.CODE"
                            :placeholder="__('select')"
                            :clearable="false"
                            :searchable="false"
                        ></v-select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <golia-button
                    :label="__('save_language_settings')"
                    variant="btn-fill success"
                    :loading="loading"
                    @click="changeLanguage()"
                >
                </golia-button>
            </div>
        </div>

        <!-- COLOR MODES -->
        <h5 class="section-title mt-4 mb-2">{{ __('options_general') }}</h5>

        <div class="bg-main-inner-bg p-3 rounded-3">
            <label class="d-block mb-2">{{ __('select_color_mode') }}</label>
            <DarkMode :modes="['light', 'dark']">
                <template v-slot="{ mode }">
                    <golia-button
                        :icon="iconMode(mode)"
                        variant="btn-fill dark-blue"
                    ></golia-button>
                    <span class="text-smaller ms-2">{{ labelMode(mode) }}</span>
                </template>
            </DarkMode>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GeneralSettings',
    data() {
        return {
            userSettings: {},
            langs: {
                infrNation: this.$authProcess.getParams('infringment_nation'),
                lingua: this.$authProcess.getParams('Lingua'),
                timeZone: this.$authProcess.getParams('timezone')
            },
            loading: false
        }
    },
    methods: {
        getLangConfigurations() {
            this.$fetch(this.$env.GOLIA_API + '/golia/config')
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    this.userSettings = data.data
                })

            if (this.langs && Object.entries(this.langs).length === 0) {
                this.langs['infrNation'] = this.$authProcess.getParams('infringment_nation')
                this.langs['lingua'] = this.$authProcess.getParams('Lingua')
                this.langs['timeZone'] = this.$authProcess.getParams('timezone')
            }
        },
        labelMode(mode) {
            return this.__(mode + '_mode')
        },
        iconMode(mode) {
            // modes: light, color_blind, dark, color_blind_dark
            if (mode === 'light') {
                return 'fa-solid fa-sun'
            } else if (mode === 'color_blind') {
                return 'fa-solid fa-low-vision'
            } else if (mode === 'dark') {
                return 'fa-solid fa-moon'
            } else if (mode === 'color_blind_dark') {
                return 'fa-solid fa-low-vision'
            }
        },
        changeLanguage() {
            let lang = this.langs

            if (Object.entries(lang).length === 0) return

            this.loading = true

            // Call in put to change language
            this.$fetch(this.$env.GOLIA_API + '/users/me/config', {
                method: 'PUT',
                body: JSON.stringify(lang)
            })
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        this.$store.commit('updateLoggedUserParams', {})
                        this.$store.commit('updateRulesLoaded', false)

                        setTimeout(() => {
                            this.loading = false
                            window.location.reload()
                        }, 1000)
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                })
        }
    },
    created() {
        this.getLangConfigurations()
    }
}
</script>
