<!-- Vue 2 basic component template -->
<template>
    <side-panel class="side-panel" id="search-resources-panel" :scrollable-body="true">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('geofleet.search_resources.title') }}
                    </h3>
                    <small class="mb-3 d-block text-muted">
                        {{ __('geofleet.search_resources.description') }}
                    </small>

                    <div class="close-btn" @click="closePanel('searchResources')">
                        <i class="fa-solid fa-xmark"></i>
                    </div>

                    <golia-button
                        variant="btn-success w-100 mb-3"
                        :label="visibleForm ? __('close') : __('search')"
                        :icon="visibleForm ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
                        @click="visibleForm = !visibleForm"
                        v-if="resources.length > 0"
                    />

                    <b-collapse v-model="visibleForm">
                        <golia-input type="select" v-model="localResourceType" :options="options" />

                        <!-- Reachable Area Form -->
                        <template v-if="localResourceType == 'reachable_areas'">
                            <div class="waypoint-wrapper aree-wrapper">
                                <hr style="margin-top: 0px" />
                                <div class="d-flex justify-content-between mb-2">
                                    <span class="section-text">{{ __('areas') }}</span>
                                    <div
                                        @click="reachableAreas.push({})"
                                        :class="{ 'add-waypoint': true }"
                                    >
                                        <i class="fa-solid fa-plus"></i>
                                    </div>
                                </div>
                                <div
                                    v-for="(area, area_index) in reachableAreas"
                                    :key="area_index"
                                    class="row align-items-center"
                                >
                                    <div class="col-2 d-flex justify-content-center area-text">
                                        <div class="choose-type">{{ area_index + 1 }}</div>
                                    </div>

                                    <div :class="area_index == 0 ? 'col-10' : 'col-8'">
                                        <golia-input
                                            class="choose-type"
                                            :input-style="{ 'background-color': '#fff' }"
                                            v-model="area.timing"
                                            type="number"
                                            :additionalAttributes="{ min: 1 }"
                                            :placeholder="__('time')"
                                        ></golia-input>
                                    </div>
                                    <div
                                        class="col-2 d-flex justify-content-center area-text"
                                        v-if="area_index != 0"
                                    >
                                        <div class="delete-waypoint">
                                            <i
                                                @click="removeArea(area_index)"
                                                class="fa-solid fa-trash-can"
                                                v-b-tooltip.hover
                                                :title="__('planner.delete_waypoint')"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <golia-button
                                variant="w-100 btn-fill dark-blue btn-rounded mt-3"
                                @click="searchResourceHandle(waypointConstructor)"
                                icon="fa-magnifying-glass"
                                :label="__('cerca')"
                                :loading="loading"
                                :disabled="checkEmptyReachableAreas"
                            ></golia-button>

                            <golia-button
                                variant="w-100 btn-fill dark-blue-1 btn-rounded mt-3"
                                @click="resetReachableAreas()"
                                icon="fa-solid fa-arrow-rotate-left"
                                :label="__('planner.reset_isocrona')"
                                v-if="reachableAreasPolygons.length > 0"
                            ></golia-button>
                        </template>

                        <template v-else>
                            <resources-filters
                                :waypoint="waypointConstructor"
                                :loading="loading"
                                @searchResource="searchResourceHandle"
                            />

                            <golia-button
                                variant="w-100 btn-fill dark-blue-1 btn-rounded mt-3"
                                @click="resetResources()"
                                icon="fa-solid fa-arrow-rotate-left"
                                :label="__('cancel_search')"
                                v-if="resources.length > 0"
                            ></golia-button>
                        </template>
                    </b-collapse>
                </div>
            </div>
        </template>

        <template #body>
            <resources-list
                class="mt-3"
                v-show="resources.length > 0"
                :resources="resources"
                :no-header-buttons="true"
                :no-select-button="true"
                @focusResource="toggleFocusResource"
            />

            <div
                class="mt-3"
                v-show="
                    resources.length == 0 && activeSearch && localResourceType != 'reachable_areas'
                "
            >
                <p class="text-center mt-3">
                    {{ __('planner.resources.empty_search') }}
                </p>
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import ResourcesFilters from '@/components/panels/PlannerPanel/ResourcesFilters.vue'
import ResourcesList from '@/components/panels/PlannerPanel/ResourcesList.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'SearchResourcesPanel',
    props: {},
    components: {
        SidePanel,
        ResourcesFilters,
        ResourcesList
    },
    data() {
        return {
            localResourceType: 'fuel',
            reachableAreas: [{}],
            visibleForm: true
        }
    },
    created() {
        // Filling dictionaries with stations and parking services
        this.fillDictionaries({ vueInstance: this })

        if (this.reachableAreasPolygons.length > 0) this.localResourceType = 'reachable_areas'
    },
    destroyed() {
        this.setResources([])
    },
    methods: {
        searchResourceHandle(waypointInfo) {
            this.resetResources()

            let parameters = {
                waypoint: waypointInfo,
                lat: this.focus_truck.lastpoint.lat,
                lng: this.focus_truck.lastpoint.lng,
                vueInstance: this
            }

            this.searchResource(parameters)
        },
        removeArea(index) {
            this.reachableAreas.splice(index, 1)
        },
        ...mapMutations({
            closePanel: 'closePanel',
            setResources: 'setResources',
            toggleFocusResource: 'toggleFocusResource',
            resetResources: 'resetResources',
            resetReachableAreas: 'resetReachableAreasPolygons'
        }),
        ...mapActions({
            fillDictionaries: 'fillDictionaries',
            searchResource: 'searchResource'
        })
    },
    watch: {
        resources(val) {
            this.visibleForm = val.length > 0 ? false : true
        }
    },
    computed: {
        options() {
            let options = []

            options.push({
                value: 'reachable_areas',
                text: this.__('planner.isocrona')
            })

            if (this.$can('use', 'fuel_stations'))
                options.push({
                    value: 'fuel',
                    text: this.__('planner.search_station')
                })

            if (this.$can('use', 'parkings'))
                options.push({
                    value: 'parking',
                    text: this.__('planner.search_parking')
                })

            return options
        },
        waypointConstructor() {
            return {
                coordinate: {
                    resourceType: this.localResourceType
                },
                searchInfo: {
                    areaValue: null,
                    areaUnit: 'km',
                    type: this.localResourceType,
                    reachable_areas: this.reachableAreas
                }
            }
        },
        checkEmptyReachableAreas() {
            let empty = false

            this.reachableAreas.forEach((area) => {
                if (!area.timing || area.timing == '' || area.timing == 0) {
                    empty = true
                }
            })

            return empty
        },
        ...mapState({
            resources: (state) => state.searchResource.resources,
            activeSearch: (state) => state.searchResource.active_search,
            loading: (state) => state.searchResource.loading,
            reachableAreasPolygons: (state) => state.searchResource.reachable_areas_polygons
        }),
        ...mapGetters({
            focus_truck: 'getFocusTruck'
        })
    }
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';

#search-resources-panel {
    ::v-deep(.form-input-g .input) {
        padding: 8px 14px;
    }
}

.resources-list {
    --scrollbar-thumb-border: var(--main-inner-bg-color);

    flex: 1;
    overflow-y: auto;
    background: var(--main-inner-bg-color);
    border-radius: 12px;
    padding: 15px;
    margin-top: 10px;
}

.waypoint-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    padding: 8px;
    border-radius: 8px;

    &.aree-wrapper {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    &.updating-waypoint {
        background-color: var(--main-inner-bg-color);
        border-radius: 25px;
        padding: 12px;
    }

    ::v-deep(.form-control),
    ::v-deep(.form-select) {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.add-waypoint {
    background-color: #4bcc87;
    border-radius: 50%;

    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}
.delete-waypoint {
    background-color: #f8385b;
    border-radius: 50%;

    min-width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}

.choose-type {
    font-size: 0.8em;

    &.disabled-select select {
        opacity: 0.5 !important;
    }
}
</style>
