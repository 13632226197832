import { ref, unref, watch } from 'vue'
import { buildVehicleAlerts } from '@/functions/checkVehicleAlerts'

export function useVehicleAlerts(vehicle) {
    const alerts = ref({})

    watch(
        vehicle,
        () => {
            alerts.value = { ...alerts.value, ...buildVehicleAlerts(unref(vehicle)) }
        },
        { deep: true, immediate: true }
    )

    return {
        alerts
    }
}
