<template>
    <span
        class="geofleet-wrapper geofleet-mobile print-directives"
        :class="{
            'menu-collapsed': main_menu_collapsed,
            'menu-extended': !main_menu_collapsed,
            'fullscreen-map': fullScreenMap
        }"
    >
        <golia-loader
            v-show="loading || rootLoading"
            :hideable="false"
            :message="loadingTimeout ? __('loading_timeout') : __('loading')"
        ></golia-loader>

        <div class="geofleet-map">
            <router-view name="map"></router-view>
        </div>

        <div class="panel-box">
            <transition name="common-fade">
                <router-view></router-view>
            </transition>
        </div>
    </span>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'GeoFleetMobile',
    props: [],
    data() {
        return {
            loading: false,
            loadingTimeout: false,
            fullScreenMap: false,
            trucks_ref: {},
            drivers: [],
            profiles: [],
            proveniences: [],
            companies: [],
            pois: [],

            activity_panel: null,
            utility_panel: null,

            last_update: null,

            main_menu_collapsed: true
        }
    },
    computed: {
        blurredMap() {
            return (
                this.activity_panel !== null ||
                (this.utility_panel !== null &&
                    this.utility_panel != 'vehicles-passage' &&
                    this.utility_panel != 'trucks-with-alerts') ||
                (this.config?.vehicleListModeView == 'table' &&
                    !this.$store.state.panels.main.collapsed &&
                    !this?.config?.vehicleListFluidTable)
            )
        },
        listActiveTrucks() {
            return this.storeTrucks.filter(
                (truck) => this.truck_filtered_out.indexOf(truck.id) == -1
            )
        },
        ...mapState({
            rootLoading: (state) => state.rootLoading,
            config: (state) => state.geoFleet.config,
            storeTrucks: (state) => state.geoFleet.trucks,
            truck_filtered_out: (state) => state.geoFleet.truckFilteredOut,
            mainMapStopAutocenter: (state) => state.geoFleet.mainMapStopAutocenter
        }),
        ...mapGetters({
            focus_truck: 'getFocusTruck',
            activity_truck: 'getActivityTruck',
            mainCollapsed: 'mainCollapsed',
            activityOpen: 'activityOpen',
            activityMeta: 'activityMeta'
        }),
        menu_items() {
            let menu = []

            menu.push({
                ref: 'tags',
                title: this.__('tags.manage_tags'),
                icon: 'fa-solid fa-tags',
                attributes: {
                    title: this.__('tags.manage_tags')
                }
            })

            if (this.$can('use', 'poi'))
                menu.push({
                    ref: 'manage-poi',
                    title: this.__('manage_poi.title'),
                    icon: 'fak fa-poi',
                    attributes: {
                        title: this.__('manage_poi.title')
                    }
                })

            if (this.$can('use', 'vehicles_cross'))
                menu.push({
                    ref: 'vehicles-passage',
                    title: this.__('vehicles_cross.title'),
                    icon: 'fa-regular fa-radar',
                    attributes: {
                        title: this.__('vehicles_cross.title')
                    }
                })

            if (this.$can('use', 'planner'))
                menu.push({
                    ref: 'planner',
                    title: this.__('planner.plan_route'),
                    icon: 'fa-solid fa-route',
                    attributes: {
                        title: this.__('planner.plan_route')
                    }
                })

            if (this.$can('use', 'share_link'))
                menu.push({
                    ref: 'shared-links',
                    title: this.__('shared_links'),
                    icon: 'fa-solid fa-share-nodes',
                    attributes: {
                        title: this.__('shared_links')
                    }
                })

            menu.push({
                ref: 'trucks-with-alerts',
                title: this.__('trucks_with_alerts'),
                icon: 'fa-solid fa-exclamation-triangle',
                attributes: {
                    title: this.__('trucks_with_alerts')
                }
            })

            return menu
        }
    },
    methods: {
        truckFocus(truck, optional_actions) {
            // Setto la proprità on_focus a true per il truck nello Store
            this.$store.commit('setFocusTruck', {
                truck: truck,
                on_focus: true
            })

            if (optional_actions)
                optional_actions.forEach((action) => {
                    if (action.type == 'action') this.$store.dispatch(action.name, action.payload)
                    else if (action.type == 'mutation')
                        this.$store.commit(action.name, action.payload)
                })

            // Se c'è un truck in attività e non è quello su cui ho cliccato, lo apro con il truck su cui ho cliccato
            if (this.activity_truck && this.activity_truck.id != truck.id)
                this.utilsPanel(truck, this.activity_panel)

            // Se c'è un truck in attività e non c'era il panel aperto, lo apro con il truck su cui ho cliccato
            if (this.activity_panel && !this.activity_truck)
                this.utilsPanel(truck, this.activity_panel)

            // Attivo il focus nel MainPanel
            this.$refs.main_panel.truckFocus(truck)

            // Barbatrucco: se è stato attivato un loader per diluire l'animazione lo disattivo dopo un timer di 0.5 secondi
            if (this.rootLoading)
                setTimeout(() => {
                    this.$store.commit('setLoading', false)
                }, 500)

            // Se è attivo il metodo centralizzato per non far muovere la mappa esco qui (altrimenti si sposterebbe la mappa sul marker)
            if (this.mainMapStopAutocenter) return

            // Attivo il focus sul marker
            this.$refs.map.truckFocus(truck)
        },
        lostFocus() {
            if (!this.focus_truck) return false

            if (this.searchResourcesOpen) this.$store.commit('closePanel', 'searchResources')

            this.$store.commit('setFocusTruck', {
                truck: this.focus_truck,
                on_focus: false
            })
        },
        truckActivity(truck) {
            this.utilsPanel(truck, 'activity')
        },
        truckPlanner(truck) {
            this.utilsPanel(truck || null, 'planner')
        },
        utilsPanel(truck, type) {
            if (this.utility_panel) this.utility_panel = null

            if (this.searchResourcesOpen) this.$store.commit('closePanel', 'searchResources')

            if (this.focus_truck && this.focus_truck.id != truck.id) this.truckFocus(truck)

            if (truck)
                this.$store.commit('setActivityTruck', {
                    truck: truck,
                    on_activity: true
                })

            this.activity_panel = type

            var main_panel = this.$refs.main_panel.$el

            if (main_panel)
                setTimeout(() => {
                    main_panel.classList.add('with-activity-open')
                }, 1000)
        },
        lostUtilsPanel() {
            if (this.activity_truck) {
                this.$store.commit('setActivityTruck', {
                    truck: this.activity_truck,
                    on_activity: false
                })
                this.$store.commit('closePanel', 'activity')
            }

            this.activity_panel = null

            var main_panel = this.$refs.main_panel.$el

            if (main_panel)
                setTimeout(() => {
                    main_panel.classList.remove('with-activity-open')
                }, 1000)
        },
        toggleUtilityPanel(panel) {
            if (this.utility_panel == panel) return

            if (this.activity_panel) this.activity_panel = null

            if (this.searchResourcesPanelOpen) this.$store.commit('toggleSearchResourcePanel')

            if (panel)
                if (this.utility_panel) {
                    this.utility_panel = null

                    setTimeout(() => {
                        this.utility_panel = panel
                    }, 300)
                } else {
                    this.utility_panel = panel
                }
            else this.utility_panel = null
        },
        boundVisibleTruck() {
            let focusVisible = false

            let visibleTrucks = this.listActiveTrucks.map((truck) => {
                if (this.focus_truck && truck.id == this.focus_truck.id) {
                    focusVisible = truck.visible
                }

                return [truck.lastpoint.lat, truck.lastpoint.lng]
            })

            if (focusVisible === false) this.lostFocus()

            if (visibleTrucks.length)
                this.$refs.map.lmap.fitBounds(visibleTrucks, {
                    padding: [50, 50]
                })
        },
        computeActivity(lastpoint) {
            if (lastpoint.speed || lastpoint.tacho_speed) return 'moving'

            if (lastpoint.engine_rpm) return 'engine_on'

            if (lastpoint.ignition) return 'ignition'

            return 'off'
        },
        loadPoisList(pois) {
            this.pois = pois
        },
        loadTrucks(sb) {
            this.last_update = moment.utc().toISOString()

            this.$fetch(
                this.$env.GOLIA_API +
                    '/trucks?include[]=lastpoint&include[]=details&include[]=category&include[]=tags&filter[VISIBILE][eq]=1'
            )
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    sb.done('trucks', res.data)
                })
        },
        loadDrivers(sb) {
            this.$fetch(this.$env.GOLIA_API + '/geofleet/drivers')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    sb.done('drivers', res.data)
                })
        },
        loadProfiles(sb) {
            this.$fetch(this.$env.GOLIA_API + '/geofleet/profiles')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    sb.done('profiles', res.data)
                })
        },
        loadProveniences(sb) {
            this.$fetch(this.$env.GOLIA_API + '/proveniences')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    sb.done('proveniences', res.data)
                })
        },
        loadCompanies(sb) {
            this.$fetch(this.$env.GOLIA_API + '/companies')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    let companies = {}

                    companies[res.data.company.id] = res.data.company.business_name

                    for (var cmpny of res.data.master_slave) {
                        companies[cmpny.id] = cmpny.business_name
                    }

                    this.companies = companies

                    sb.done('companies', companies)
                })
        },
        updateData() {
            let query = new URLSearchParams(
                this.last_update ? { update: this.last_update } : ''
            ).toString()

            this.last_update = moment.utc().toISOString()

            this.$fetch(this.$env.GOLIA_API + '/geofleet/lastpoints' + (query ? '?' + query : ''))
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    res.data.forEach((p) => {
                        if (!this.trucks_ref[p.truck]) location.reload()

                        let truck = this.trucks_ref[p.truck]

                        truck.activity_type = this.computeActivity(p)

                        truck.lastpoint = this.bindLastPointRelations(p)

                        this.$store.commit('updateTruck', { truck: truck })
                    })
                })
        },
        updateTruckTags(tags, truck) {
            this.$store.commit('updateTruckProperty', {
                truck: truck,
                property: 'tags',
                value: tags
            })
        },
        bindLastPointRelations(lastpoint) {
            lastpoint.provenience = lastpoint.provenience
                ? this.proveniences[lastpoint.provenience]
                : null
            lastpoint.driver = lastpoint.driver ? this.drivers[lastpoint.driver] : null
            lastpoint.last_driver = lastpoint.last_driver
                ? this.drivers[lastpoint.last_driver]
                : null
            lastpoint.codriver = lastpoint.codriver ? this.drivers[lastpoint.codriver] : null
            lastpoint.company_name = this.companies[lastpoint.company] || ''

            try {
                lastpoint.telemetry =
                    (typeof lastpoint.telemetry === 'string'
                        ? JSON.parse(lastpoint.telemetry)
                        : lastpoint.telemetry) || {}
            } catch (err) {
                lastpoint.telemetry = {}
            }

            return lastpoint
        },
        ...mapActions({
            fitBoundsOfMap: 'fitBoundsOfMap'
        }),
        ...mapMutations({
            focusResource: 'focusResource',
            collapsePanel: 'collapsePanel',
            expandPanel: 'expandPanel'
        })
    },
    created() {
        let _this = this
        this.loading = true

        let sb = {
            counter: 0,
            processes: 5,
            data: {},

            done: function (key, data) {
                this.counter += 1

                this.data[key] = data

                if (this.counter >= this.processes) this.finally()
            },
            finally: function () {
                _this.loading = false

                _this.drivers = this.data['drivers'].reduce((c, d) => {
                    c[d.id] = d

                    return c
                }, {})

                _this.proveniences = this.data['proveniences'].reduce((c, d) => {
                    c[d.id] = d

                    return c
                }, {})

                _this.profiles = this.data['profiles'].reduce((c, d) => {
                    c[d.id] = d

                    return c
                }, {})

                let truck_index = 0

                _this.trucks = this.data['trucks']
                    .filter((v) => {
                        return v.lastpoint && v.visible
                    })
                    .map((v) => {
                        v.visible = true
                        v.on_focus = false
                        v.pinned = false
                        v.on_activity = false

                        v.index = truck_index

                        v.details = v.details
                            ? v.length == 1
                                ? v.details.shift()
                                : v.details.find((d) => d.id_main_descr == 0)
                            : null

                        v.activity_type = _this.computeActivity(v.lastpoint)
                        v.profile = _this.profiles[v.details ? v.details.profile_id : null]

                        v.lastpoint = _this.bindLastPointRelations(v.lastpoint)

                        _this.trucks_ref[v.id] = v

                        truck_index++

                        return v
                    })

                _this.$store.commit('setTrucks', _this.trucks)
                _this.$store.commit('setTrucksRef', _this.trucks_ref)
                _this.$store.commit('setDrivers', _this.drivers)
                _this.$store.commit('setCompanies', _this.companies)
            }
        }

        this.loadDrivers(sb)
        this.loadProfiles(sb)
        this.loadProveniences(sb)
        this.loadCompanies(sb)
        this.loadTrucks(sb)

        //Load common configs from Vuex
        this.$store.dispatch('loadConfig', { vueInstance: this })

        //Load company tags
        this.$store.dispatch('loadCompanyTags', this)

        //Load drivers data
        this.$store.dispatch('loadDriversData', { vueInstance: this })

        setInterval(() => {
            if (this.$isBrowserWindowActive()) this.updateData()
        }, 20 * 1000)

        setInterval(() => {
            if (this.$isBrowserWindowActive())
                this.$store.dispatch('loadDriversData', {
                    vueInstance: this,
                    update: moment.utc().toISOString()
                })
        }, 300 * 1000)

        setTimeout(() => {
            if (this.loading) this.loadingTimeout = true
        }, 20000)
    },
    watch: {
        $route(to, from) {
            if (to.name == 'geofleet.index') {
                if (this.focus_truck)
                    this.$store.commit('setFocusTruck', {
                        truck: this.focus_truck,
                        on_focus: false
                    })
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import './GeoFleet.scss';
</style>
