var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"geofleet-wrapper print-directives with-sidebar",class:{
        'menu-collapsed': _vm.main_menu_collapsed,
        'menu-extended': !_vm.main_menu_collapsed,
        'fullscreen-map': _vm.fullScreenMap
    }},[_c('golia-sidebar',{attrs:{"collapsed":_vm.main_menu_collapsed,"companies":_vm.companies,"menu":_vm.menu_items},on:{"toggleMenu":(collapsed) => (_vm.main_menu_collapsed = collapsed),"plan":function($event){_vm.focus_truck ? _vm.truckPlanner(_vm.focus_truck) : _vm.truckPlanner()},"toggleUtilityPanel":_vm.toggleUtilityPanel}}),_c('golia-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.rootLoading),expression:"loading || rootLoading"}],attrs:{"hideable":false,"message":_vm.loadingTimeout ? _vm.__('loading_timeout') : _vm.__('loading')}}),_c('geofleet-map',{ref:"map",class:{
            'geofleet-map--focus': _vm.focus_truck,
            'menu-collapsed': _vm.main_menu_collapsed,
            'menu-extended': !_vm.main_menu_collapsed,
            blurred: _vm.blurredMap
        },attrs:{"map":"silica","advanced":true,"cluster":this.config?.activeVehicleClustering,"ptvToken":this.$env.ptv_password,"trucks":_vm.listActiveTrucks,"poi":true,"noAutoCenterMap":_vm.mainMapStopAutocenter},on:{"focus":_vm.truckFocus,"poisLoaded":_vm.loadPoisList,"plan":function($event){_vm.focus_truck ? _vm.truckPlanner(_vm.focus_truck) : _vm.truckPlanner()},"toggleMenu":(collapsed) => (_vm.main_menu_collapsed = collapsed),"fullScreenToggle":(toggle) => (_vm.fullScreenMap = toggle)}},[_vm._l((_vm.resources),function(resource,index){return _c('custom-marker',{key:'searched-resource-' + index,attrs:{"item":resource,"coordinates":[resource.coordinate.y, resource.coordinate.x],"markerTitle":_vm._f("truncate")(resource.marker_label,10, '...'),"iconUnicode":resource.icon_unicode,"customizer":{ tiny: true, color_class: resource.type },"visible":resource.type != 'borsa'},on:{"click":function($event){return _vm.focusResource(resource)}}})}),(_vm.reachableAreasPolygons && _vm.reachableAreasPolygons.length > 0)?_vm._l((_vm.reachableAreasPolygons),function(polygon,index){return _c('l-polygon',{key:index,attrs:{"lat-lngs":polygon}})}):_vm._e(),(
                _vm.mainMapExtraObjects.circles.length > 0 || _vm.mainMapExtraObjects.markers.length > 0
            )?[_vm._l((_vm.mainMapExtraObjects.markers),function(marker,index){return _c('custom-marker',{key:'extra-markers' + index,attrs:{"item":marker,"coordinates":marker.latLng,"markerTitle":marker.title,"iconUnicode":'#xf041',"customizer":{ tiny: true },"visible":true}})}),_vm._l((_vm.mainMapExtraObjects.circles),function(circle,index){return _c('l-circle',{key:'extra-circles' + index,attrs:{"lat-lng":circle.latLng,"radius":circle.radius}})})]:_vm._e()],2),_c('app-panels',{attrs:{"utility_panel":_vm.utility_panel,"activity_panel":_vm.activity_panel,"main_menu_collapsed":_vm.main_menu_collapsed},on:{"focus":_vm.truckFocus,"activity":_vm.truckActivity,"plan":_vm.truckPlanner,"toggleUtilityPanel":_vm.toggleUtilityPanel,"lostfocus":_vm.lostUtilsPanel,"afterEnter":function($event){_vm.collapsed_details_panel = true
            _vm.collapsePanel({
                panel: 'main',
                with_loading: _vm.config?.vehicleListModeView == 'table'
            })},"afterLeave":function($event){_vm.collapsed_details_panel = false
            _vm.expandPanel({ panel: 'main', with_loading: _vm.config?.vehicleListModeView == 'table' })}}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.focus_truck)?_c('details-panel',{ref:"details_panel",staticClass:"tiny-panel details-panel",attrs:{"truck":_vm.focus_truck,"collapsed":_vm.collapsed_details_panel},on:{"lostfocus":_vm.lostFocus,"activity":_vm.truckActivity,"plan":_vm.truckPlanner,"updateTruckTags":(tags, truck) => _vm.updateTruckTags(tags, truck)}}):_vm._e()],1),_c('main-panel',{ref:"main_panel",staticClass:"tiny-panel main-panel",attrs:{"drivers":_vm.drivers,"companies":_vm.companies,"collapsed":_vm.mainCollapsed,"last_update":_vm.last_update,"trucks":_vm.listActiveTrucks},on:{"focus":_vm.truckFocus,"activity":_vm.truckActivity,"plan":_vm.truckPlanner,"searching":_vm.boundVisibleTruck}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }