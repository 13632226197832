<template>
    <div v-resized="resized" class="d-flex flex-row h-100">
        <div class="map-section" :class="selectTriggered != null ? 'select-triggered' : ''">
            <!-- User config modal -->
            <b-modal
                id="modal-user-config"
                :title="__('planner.user_config')"
                size="lg"
                ok-only
                ok-variant="default"
                :ok-title="__('close')"
            >
                <template>
                    <p>{{ __('planner.freight_access') }}</p>

                    <ul class="borse-carico-list list-unstyled">
                        <li class="connect alpega">
                            <div>
                                <span class="fa-stack fa-lg me-4">
                                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                                    <i class="fak fa-fw fa-alpega fa-stack-1x fa-inverse"></i>
                                </span>

                                <b>{{ __('planner.alpega') }}</b>

                                <span v-show="storeUser.loggedToAlpega" class="text-success">
                                    - {{ __('logged') }} <i class="fa-solid fa-circle-check"></i>
                                </span>
                            </div>

                            <golia-button
                                :label="storeUser.loggedToAlpega ? __('manage') : __('connect')"
                                :variant="
                                    storeUser.loggedToAlpega
                                        ? 'btn-primary ms-4'
                                        : 'btn-success ms-4'
                                "
                                :icon="'fa-link'"
                                v-b-modal.modal-service-login
                            />
                        </li>
                    </ul>
                </template>
            </b-modal>

            <!-- Service login modal -->
            <b-modal id="modal-service-login" :title="__('planner.alpega_login')">
                <template #default="{ cancel, ok }">
                    <service-login
                        :title="__('planner.alpega_login')"
                        @login-cancel="cancel()"
                        @login-success="ok()"
                        :in-modal="true"
                    ></service-login>
                </template>
            </b-modal>

            <transition name="common-fade">
                <b-alert :show="selectTriggered != null" :fade="true" variant="success">
                    {{ __('planner.select_on_map') }}
                </b-alert>
            </transition>

            <geofleet-map
                class="geofleet-map smaller-controls"
                map="silica"
                :advanced="true"
                :cluster="true"
                :ptvToken="$env.ptv_password"
                :trucks="trucks"
                :extraControls="{}"
                :noAutoCenterMap="currentWaypoints[0].coordinate != null"
                @readyMap="clickOnMapHandler"
                ref="map"
            >
                <l-polyline
                    :class-name="'main-route-polyline'"
                    :lat-lngs="route.polyline"
                    :dash-array="route?.alternativeRoutesPolylines?.length > 0 ? '10 10' : null"
                >
                    <l-popup
                        :content="'Percorso principale'"
                        v-if="route?.alternativeRoutesPolylines?.length > 0"
                    />
                </l-polyline>

                <template v-if="route.alternativeRoutesPolylines">
                    <l-polyline
                        :lat-lngs="alternative"
                        v-for="(alternative, index) in route.alternativeRoutesPolylines"
                        :color="alternativeRoutersColors[index]"
                        :key="index"
                        :dash-array="'10 10'"
                        :class-name="'alternative-route-polyline-' + index"
                    >
                        <l-popup :content="'Percorso n° ' + index" />
                    </l-polyline>
                </template>

                <template v-if="route.polygons">
                    <l-polygon
                        v-for="(polygon, index) in route.polygons"
                        :key="index"
                        :lat-lngs="polygon"
                    >
                    </l-polygon>
                </template>

                <!-- Waypoints markers quando visualizzo il percorso calcolato -->
                <custom-marker
                    v-for="(waypoint, index) in route.waypoints"
                    :key="'calculated-' + index"
                    :coordinates="[waypoint.coordinate[0], waypoint.coordinate[1]]"
                    :markerTitle="waypoint.label"
                    :markerInnerLabel="
                        waypoint.waypointIndex !== null ? waypoint.waypointIndex : null
                    "
                    :iconUnicode="waypoint.waypointIndex ? null : checkIconUnicode(waypoint)"
                    :customizer="{ tiny: true, color_class: colorClass(waypoint) }"
                />

                <!-- Resources markers quando si cercano risorse -->
                <custom-marker
                    v-for="(resource, index) in resources"
                    :key="'searched-resource-' + index"
                    :item="resource"
                    :coordinates="[resource.coordinate.y, resource.coordinate.x]"
                    :markerTitle="resource.marker_label | truncate(10, '...')"
                    :iconUnicode="resource.icon_unicode"
                    :customizer="{
                        tiny: true,
                        color_class: resource.type,
                        custom_color: resource.custom_color,
                        size_x: resource.type == 'poi' ? 28 : null
                    }"
                    @click="focusResource(resource)"
                />

                <!-- Selected resources markers -->
                <template v-if="(route.waypoints || []).length == 0">
                    <custom-marker
                        v-for="(waypoint, index) in currentWaypoints"
                        :key="'selected-resource-' + index"
                        :referId="'selected-resource-' + index"
                        :draggable="checkDraggableMarker(waypoint)"
                        :visible="checkWaypointMarkerVisibility(waypoint)"
                        :item="
                            ((waypoint.coordinate || {}).resource || {}).reference
                                ? ((waypoint.coordinate || {}).resource || {}).reference
                                : null
                        "
                        :coordinates="[
                            ((waypoint.coordinate || {}).referenceCoordinate || {}).y || 0,
                            ((waypoint.coordinate || {}).referenceCoordinate || {}).x || 0
                        ]"
                        :markerTitle="(waypoint.coordinate || {}).label"
                        :iconUnicode="checkIconUnicode(waypoint)"
                        :customizer="{
                            tiny: true,
                            color_class:
                                waypoint?.coordinate?.type == 'borsa.delivery'
                                    ? 'borsa.delivery'
                                    : colorClass(waypoint),
                            custom_color: ((waypoint.coordinate || {}).resource || {}).custom_color,
                            size_x:
                                ((waypoint.coordinate || {}).resource || {}).type == 'poi'
                                    ? 28
                                    : null
                        }"
                        :highlighted="waypoint.highlighted"
                        @updateByDrag="
                            (LatLng) => {
                                updateResourceCoordinates(LatLng, waypoint)
                            }
                        "
                    />
                </template>

                <!-- Map utils markers / references -->
                <template v-if="(route.waypoints || []).length == 0">
                    <custom-marker
                        v-for="(waypoint, index) in utilsMarkers"
                        :key="'selected-utils-' + index"
                        :draggable="checkDraggableMarker(waypoint)"
                        :visible="checkWaypointMarkerVisibility(waypoint)"
                        :item="
                            ((waypoint.coordinate || {}).resource || {}).reference
                                ? ((waypoint.coordinate || {}).resource || {}).reference
                                : null
                        "
                        :coordinates="[
                            ((waypoint.coordinate || {}).referenceCoordinate || {}).y || 0,
                            ((waypoint.coordinate || {}).referenceCoordinate || {}).x || 0
                        ]"
                        :markerTitle="(waypoint.coordinate || {}).label"
                        :iconUnicode="
                            ((waypoint.coordinate || {}).resource || {}).icon_unicode
                                ? ((waypoint.coordinate || {}).resource || {}).icon_unicode
                                : '#xf041'
                        "
                        :customizer="{
                            tiny: true,
                            color_class: ((waypoint.coordinate || {}).resource || {}).type
                                ? ((waypoint.coordinate || {}).resource || {}).type
                                : null
                        }"
                        @updateByDrag="
                            (LatLng) => {
                                updateResourceCoordinates(LatLng, waypoint)
                            }
                        "
                    />
                </template>
            </geofleet-map>

            <!-- DRIVERS -->
            <DriversSelection :drivers="[current_driver, closed_driver]" v-if="current_driver" />
        </div>
        <div class="route-calculator custom-scrollbar">
            <div class="spinner-wrapper" v-show="loading" style="border-radius: 25px">
                <Spinner></Spinner>
            </div>
            <route-planner
                class="route-planner"
                ref="routePlanner"
                :truck="truck"
                :clickedCoordinates="clickedCoordinates"
                :resources="resources"
                :selectedWaypoints="storeWaypoints"
                :resourceSearched="resourceSearched"
                :errorsInWaypoints="errorsInWaypoints"
                @selectOnMap="selectOnMapHandler"
                @searchResource="searchResource"
                @focusResource="focusResource"
                @placeMarker="placeMarker"
                @resetWaypoint="resetWaypoint"
                @resetUtilsMarkers="resetUtilsMarkers"
                @resetClickMap="resetClickHandler()"
                @resetResource="resetResource"
                @resetIsocrona="resetIsocrona"
                @editRoute="
                    () => {
                        route = { polyline: [], waypoints: [] }
                    }
                "
                @resetRoute="resetRoute"
                @fitBoundsUpdate="fitBounds"
                @setWaypoints="
                    (waypoints) => {
                        currentWaypoints = waypoints
                    }
                "
                @addWaypoint="addWaypoint"
                @setWaypoint="setWaypoint"
                v-model="route"
            />
        </div>
    </div>
</template>

<script>
import { LPolyline, LPolygon, LPopup } from 'vue2-leaflet'
import L from 'leaflet'
import Spinner from 'vue-simple-spinner'

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'PlannerPanelContents',
    props: {
        truck: {
            type: Object,
            required: false,
            default: () => ({ lastpoint: null, details: null })
        }
    },
    components: {
        LPolyline,
        LPolygon,
        LPopup,
        Spinner
    },
    data() {
        return {
            start_position: this.truck.lastpoint
                ? { x: this.truck.lastpoint.lng, y: this.truck.lastpoint.lat }
                : { x: 0, y: 0 },
            clickedCoordinates: [],
            selectTriggered: null,
            resourceSearched: false,
            resources: null,
            resourceOnFocus: null,
            currentWaypoints: [{}],
            utilsMarkers: [],

            route: {
                polyline: [],
                waypoints: []
            },

            current_driver: null,
            closed_driver: null,

            loading: false,
            alternativeRoutersColors: ['#004188', '#e6333f', '#39abcd']
        }
    },
    watch: {
        route() {
            let map = this.$refs.map.lmap

            if (this.route.waypoints && this.route.waypoints.length > 0) {
                map.fitBounds(L.latLngBounds(this.route.waypoints.map((w) => w.coordinate)))
            }
            if (this.route.polygons && this.route.polygons.length > 0) {
                var bounds = []
                this.route.polygons.map((p) => p.map((c) => bounds.push(c)))

                map.fitBounds(L.latLngBounds(bounds))
            }
        },
        truck() {
            this.setDrivers()

            this.start_position.x = this.truck.lastpoint ? this.truck.lastpoint.lng : 0
            this.start_position.y = this.truck.lastpoint ? this.truck.lastpoint.lat : 0
        },
        resources() {
            if (this.resources) {
                this.fitBounds(
                    this.resources
                        .filter((r) => r.type != 'borsa')
                        .map((s) => {
                            return [s.coordinate.y, s.coordinate.x]
                        }) || []
                )
            }
        },
        currentWaypoints: {
            handler() {
                // Adding bounds
                var waypointsBounds = []

                this.currentWaypoints.map((s) => {
                    if (
                        s.coordinate &&
                        (s.coordinate || {}).referenceCoordinate.x != this.start_position.x &&
                        (s.coordinate || {}).referenceCoordinate.y != this.start_position.y &&
                        !s.updatingWaypoint
                    ) {
                        waypointsBounds.push([
                            s.coordinate.referenceCoordinate.y,
                            s.coordinate.referenceCoordinate.x
                        ])
                    }
                })

                if (waypointsBounds && waypointsBounds.length > 0) {
                    this.fitBounds(waypointsBounds)
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.currentWaypoints = this.storeWaypoints

        if (this.storePois.length == 0) this.$store.dispatch('loadPois', { vueInstance: this })

        this.setDrivers()
        this.fillDictionaries()

        this.configBorseCarico()

        if (this.config && this.config.loggedToAlpega)
            this.$store.commit('addUserProperty', { property: 'loggedToAlpega', value: true })
    },
    mounted() {
        if ((this.truck || {}).lastpoint) {
            this.$nextTick(() => {
                let map = this.$refs.map.lmap
                let current_zoom = map.getZoom()
                map.setView(
                    [this.truck.lastpoint.lat, this.truck.lastpoint.lng + 0.0015],
                    current_zoom > 13 ? current_zoom : 13
                )
            })
        }
    },
    destroyed() {
        // reset wayopints
        this.$store.commit('resetWaypoints')
    },
    computed: {
        trucks() {
            return this.truck.lastpoint ? [this.truck] : []
        },
        errorsInWaypoints() {
            return this.getWaypointsErrors.length > 0
        },
        ...mapState({
            storeWaypoints: (state) => state.planner.waypoints,
            storePois: (state) => state.geoFleet.pois,
            storeUser: (state) => state.user,
            config: (state) => state.geoFleet.config
        }),
        ...mapGetters({
            getUpdatingWaypoint: 'getUpdatingWaypoint',
            getWaypointsErrors: 'getWaypointsErrors',
            userHasFreightPermission: 'hasFreightPermission'
        })
    },
    methods: {
        fillDictionaries() {
            // PARKING FILTERS
            this.$fetch('https://plannerws.goliaweb.it/service/parking/filters', {
                'X-Company': this.$authProcess.getSignedTenantToken()
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.$store.commit('setParkingsFiltersDictionary', res.data)
                })
                .catch((error) => {
                    console.log('error', error)
                })

            // STATIONS FILTERS
            this.$fetch('https://plannerws.goliaweb.it/service/station/services', {
                'X-Company': this.$authProcess.getSignedTenantToken()
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    // if res.data.type == 'FAI' change the label in 'Lumesia'
                    res.data.map((item) => {
                        if (item.type == 'FAI') {
                            item.label = 'Lumesia'
                        } else {
                            item.label = item.type
                        }
                    })

                    this.$store.commit('setStationsFiltersDictionary', res.data)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        },
        addWaypoint(waypoint) {
            if (
                this.errorsInWaypoints &&
                !(
                    (waypoint.coordinate || {}).type == 'borsa' ||
                    (waypoint.coordinate || {}).type == 'borsa-timocom'
                )
            ) {
                this.currentWaypoints[this.currentWaypoints.length - 1] = waypoint
            } else {
                this.currentWaypoints.push(waypoint)
            }
        },
        setWaypoint(waypointData, index) {
            this.currentWaypoints[index].coordinate = waypointData
        },
        resetWaypoint(index) {
            this.$nextTick(() => {
                this.$set(this.currentWaypoints, index, {})
            })
        },
        colorClass(waypoint) {
            if (waypoint.violated) return 'violated'

            if (waypoint.resourceType) return waypoint.resourceType

            if (waypoint.coordinate) {
                if (waypoint.coordinate.violated) return 'violated'
                // Resource color
                if (waypoint.coordinate.resourceType) return waypoint.coordinate.resourceType
                else if (waypoint.coordinate.resource)
                    return waypoint.coordinate.resource.type
                        ? waypoint.coordinate.resource.type
                        : null
                else return 'selectonmap'
            } else {
                return 'selectonmap'
            }
        },
        checkWaypointMarkerVisibility(waypoint) {
            if (
                waypoint.coordinate &&
                (waypoint.coordinate || {}).action &&
                (waypoint.coordinate || {}).referenceCoordinate.x != this.start_position.x &&
                (waypoint.coordinate || {}).referenceCoordinate.y != this.start_position.y &&
                !waypoint.updatingWaypoint
            ) {
                return true
            }

            return false
        },
        checkDraggableMarker(waypoint) {
            return (waypoint.coordinate || {}).action === 'selectOnMap'
        },
        checkIconUnicode(waypoint) {
            if (waypoint.icon_unicode) return waypoint.icon_unicode

            if (waypoint.coordinate) {
                if (waypoint.coordinate.icon_unicode) return waypoint.coordinate.icon_unicode
                else if (waypoint.coordinate.resource)
                    return waypoint.coordinate.resource.icon_unicode
                else return '#xf041'
            } else {
                return '#xf041'
            }
        },
        selectOnMapHandler(trigger) {
            this.selectTriggered = trigger
        },
        clickOnMapHandler() {
            this.$refs.map.$refs.map.mapObject.on('click', (e) => {
                if (this.selectTriggered != null) {
                    var coord = e.latlng
                    var lat = coord.lat
                    var lng = coord.lng

                    this.clickedCoordinates = [lat, lng]
                    this.selectTriggered = null
                }
            })
        },
        async updateResourceCoordinates(LatLng, resource) {
            if (
                resource.coordinate &&
                resource.coordinate.action == 'selectOnMap' &&
                !resource.updatingWaypoint
            ) {
                var clickedAddress = await this.$reverseGeocoding({
                    lat: LatLng[0],
                    lng: LatLng[1],
                    full: true
                })

                resource.coordinate.referenceCoordinate.x = LatLng[1]
                resource.coordinate.referenceCoordinate.y = LatLng[0]
                resource.coordinate.label = clickedAddress
            }
        },
        resetClickHandler() {
            if (this.selectTriggered != null) this.selectTriggered = null
        },
        setDrivers() {
            if ((this.truck || {}).lastpoint) {
                this.current_driver = this.truck.lastpoint.driver

                this.current_driver ? (this.current_driver.position = 1) : null

                this.closed_driver = this.truck.lastpoint.codriver

                this.closed_driver ? (this.closed_driver.position = 2) : null
            }
        },
        resized() {
            if (this.$refs.map && this.$refs.map.lmap) this.$refs.map.lmap.invalidateSize(true)
        },
        searchResource(waypoint, lat, lng) {
            var searchInfo = waypoint.searchInfo ? waypoint.searchInfo : waypoint

            if (Array.isArray(searchInfo.type)) {
                if (searchInfo.type.includes('stations') || searchInfo.type.includes('parkings'))
                    this.getPlannerService(lat, lng, searchInfo)
            } else {
                this.resources = []

                if (searchInfo.type == 'fuel') this.getPlannerService(lat, lng, searchInfo)

                if (searchInfo.type == 'parking') this.getPlannerService(lat, lng, searchInfo)

                if (searchInfo.type == 'poi') this.getPois(lat, lng, searchInfo)

                if (searchInfo.type == 'borsa') this.getBorseCaricoAlpega(lat, lng, searchInfo)

                if (searchInfo.type == 'borsa-timocom')
                    this.getBorseCaricoTimocom(lat, lng, searchInfo)
            }
        },
        getPlannerService(lat, lng, searchInfo) {
            this.resourceSearched = false
            this.loading = true

            let params = {}

            if (searchInfo.areaUnit == 'min') {
                params = {
                    area: searchInfo.isocronaValue
                }
            } else {
                params = {
                    lat: lat,
                    lng: lng,
                    radius: searchInfo.areaValue || 10
                }
            }

            // if type of searchInfo.type is array
            if (Array.isArray(searchInfo.type)) {
                params['services'] = searchInfo.type.join(',')
            } else {
                if (searchInfo.type == 'fuel') {
                    params['services'] = 'stations'
                } else if (searchInfo.type == 'parking') {
                    params['services'] = 'parkings'
                }
            }

            // search filters
            if (searchInfo.stationServices) {
                let services = searchInfo.stationServices.map((item) => {
                    return item.type
                })

                params['filters[services]'] = services
            }
            if (searchInfo.parkingServices) {
                let services = searchInfo.parkingServices.map((item) => {
                    return item.id
                })

                params['filters[parking_filters]'] = services
            }
            if (searchInfo.parkingRating && searchInfo.parkingRating > 0) {
                params['filters[reviews_rating][value]'] = searchInfo.parkingRating
            }
            if (searchInfo.parkingSecurityRating && searchInfo.parkingSecurityRating > 0) {
                params['filters[security_rating][value]'] = searchInfo.parkingSecurityRating
            }
            if (searchInfo.parkingVerified) {
                params['filters[verified]'] = 1
            }

            let query_string = new URLSearchParams(params).toString()

            this.$fetch('https://plannerws.goliaweb.it/service?' + query_string, {
                'X-Company': this.$authProcess.getSignedTenantToken()
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.resourceSearched = true

                    // Stazioni servizio
                    if (res.data.stations && res.data.stations.length > 0) {
                        let results = res.data.stations.map((item) => {
                            return {
                                id: item.id,
                                on_focus: false,
                                coordinate: { y: item.coordinate.y, x: item.coordinate.x },
                                name: item.name,
                                marker_label: item.flag || item.name || item.id + ' - ' + item.city,
                                icon: 'fa-gas-pump',
                                icon_unicode: '#xe01f',
                                type: 'fuel',
                                reference: item
                            }
                        })

                        if (!this.resources) {
                            this.resources = results
                        } else {
                            this.resources = this.resources.concat(results)
                        }
                    }

                    // Parcheggi
                    if (res.data.parkings && res.data.parkings.length > 0) {
                        let results = res.data.parkings.map((item) => {
                            return {
                                id: item.id,
                                on_focus: false,
                                coordinate: { y: item.coordinate.y, x: item.coordinate.x },
                                name: item.name,
                                marker_label: item.flag || item.name || item.id + ' - ' + item.city,
                                icon: 'fa-square-parking',
                                icon_unicode: '#xf540',
                                type: 'parking',
                                reference: item
                            }
                        })

                        if (!this.resources) {
                            this.resources = results
                        } else {
                            this.resources = this.resources.concat(results)
                        }
                    }

                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error)
                    this.loading = false
                })
        },
        getPois() {
            if (this.storePois.length == 0) {
                this.resources = []
                return
            }

            let results = this.storePois.map((item) => {
                return {
                    id: item.id,
                    on_focus: false,
                    coordinate: { y: item.lat, x: item.lng },
                    name: item.description,
                    marker_label: item.description,
                    icon: 'fa-building',
                    icon_unicode: item.type_icon ? this.poiIconUnicode(item) : '#xf1ad',
                    custom_color: this.poiColor(item),
                    type: 'poi',
                    reference: item
                }
            })

            if (results.length > 0) {
                this.resourceSearched = true

                if (!this.resources) {
                    this.resources = results
                } else {
                    this.resources = this.resources.concat(results)
                }
            }
        },
        poiIconUnicode(poi) {
            return poi.type_icon.replace('&', '').replace(';', '')
        },
        poiColor(poi) {
            return poi.color || poi.type_color || '#000'
        },
        configBorseCarico() {
            // Fare qui chiamata per verificare il permesso
            // Supponendo che se lo abbiamo già da user o ci torna lo troviamo in permissions
            // this.$fetch('https://run.mocky.io/v3/a0440766-b36d-40f2-8897-2faef4327ce5')
            // .then((res) => {
            //     return res.json()
            // })
            // .then((res) => {
            //     if (res.data.success) {
            //         this.$store.commit('addUserProperty', { property: 'permissions.freight', value: true })
            //     }
            // })
        },
        getBorseCaricoAlpega(lat, lng, searchInfo) {
            this.resourceSearched = false
            this.loading = true

            let params = {}

            if (searchInfo.areaUnit == 'min') {
                params = {
                    area: searchInfo.isocronaValue
                }
            } else {
                params = {
                    lat: lat,
                    lng: lng,
                    radius: searchInfo.areaValue || 10
                }
            }

            let query_string = new URLSearchParams(params).toString()

            fetch('https://634ea906f34e1ed82692aeff.mockapi.io/freight/searches?' + query_string)
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.resourceSearched = true
                    let results = res.content.map((item) => {
                        return {
                            id: item.offerId,
                            on_focus: false,
                            coordinate: {
                                y: item.pickUp.location.coordinates.latitude,
                                x: item.pickUp.location.coordinates.longitude
                            },
                            name: item.company.name + ' - ' + item.freightDescription.type,
                            marker_label:
                                this.__('planner.alpega') +
                                ' - ' +
                                item.company.name +
                                ' - ' +
                                this.__('planner.pickup'),
                            icon: 'fa-suitcase',
                            icon_unicode: '#xe03c',
                            type: 'borsa',
                            reference: item,
                            delivery: {
                                name: item.company.name + ' - ' + item.freightDescription.type,
                                coordinate: {
                                    y: item.delivery.location.coordinates.latitude,
                                    x: item.delivery.location.coordinates.longitude
                                }
                            }
                        }
                    })

                    if (!this.resources) {
                        this.resources = results
                    } else {
                        this.resources = this.resources.concat(results)
                    }

                    // TODO: da aggiornare col totale VERO
                    this.$store.commit('updateWaypointProperty', {
                        waypoint: this.getUpdatingWaypoint.waypoint,
                        property: 'searchInfo.pagination.total',
                        value: 4
                    })

                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error)

                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                })
        },
        getBorseCaricoTimocom(lat, lng, searchInfo) {
            this.resourceSearched = false
            this.loading = true

            let params = {}

            if (searchInfo.areaUnit == 'min') {
                params = {
                    area: searchInfo.isocronaValue
                }
            } else {
                params = {
                    lat: lat,
                    lng: lng,
                    radius: searchInfo.areaValue || 10
                }
            }

            let query_string = new URLSearchParams(params).toString()

            fetch('https://634ea906f34e1ed82692aeff.mockapi.io/freight/searches?' + query_string)
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.resourceSearched = true
                    let results = res.content.map((item) => {
                        return {
                            id: item.offerId,
                            on_focus: false,
                            coordinate: {
                                y: item.pickUp.location.coordinates.latitude,
                                x: item.pickUp.location.coordinates.longitude
                            },
                            name: item.company.name + ' - ' + item.freightDescription.type,
                            marker_label:
                                this.__('planner.timocom') +
                                ' - ' +
                                item.company.name +
                                ' - ' +
                                this.__('planner.pickup'),
                            icon: 'fa-suitcase',
                            icon_unicode: '#xe03d',
                            type: 'borsa-timocom',
                            reference: item,
                            delivery: {
                                name: item.company.name + ' - ' + item.freightDescription.type,
                                coordinate: {
                                    y: item.delivery.location.coordinates.latitude,
                                    x: item.delivery.location.coordinates.longitude
                                }
                            }
                        }
                    })

                    if (!this.resources) {
                        this.resources = results
                    } else {
                        this.resources = this.resources.concat(results)
                    }

                    this.loading = false
                })
                .catch((error) => {
                    console.log('error', error)

                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                })
        },
        focusResource(resource) {
            this.resources.map((r) => (r.on_focus = false))

            resource.on_focus = true

            let cn = document.querySelector('.route-calculator')
            let el = document.getElementById(resource.id)

            setTimeout(() => {
                cn.scrollTo({
                    top: el.offsetTop - 100,
                    behavior: 'smooth'
                })
            }, 300)

            let map = this.$refs.map.lmap
            let current_zoom = map.getZoom()

            if (resource.type != 'borsa')
                map.setView(
                    [resource.coordinate.y, resource.coordinate.x],
                    current_zoom > 13 ? current_zoom : 13
                )
        },
        placeMarker(waypoint) {
            this.utilsMarkers.push(waypoint)
        },
        resetUtilsMarkers() {
            //TODO: find a better way to remove a single marker, passing the test to pass on .filter() function
            this.utilsMarkers = []
        },
        resetResource() {
            this.resources = null
            this.resourceSearched = false
            this.resourceOnFocus = null
        },
        resetIsocrona() {
            this.route.polygons = null
            delete this.route.polygons

            let map = this.$refs.map.lmap
            let current_zoom = map.getZoom()

            if (this.truck.lastpoint)
                map.setView(
                    [this.truck.lastpoint.lat, this.truck.lastpoint.lng + 0.0015],
                    current_zoom > 13 ? current_zoom : 13
                )

            if (this.utilsMarkers.length > 0) this.utilsMarkers = []
        },
        resetRoute(config) {
            if (config) {
                if (config.resources) {
                    this.resources = null
                    this.resourceOnFocus = null
                }
                if (config.waypoints) this.$store.commit('resetWaypoints')
                if (config.route) this.route = {}
                if (config.map) {
                    let map = this.$refs.map.lmap
                    let current_zoom = map.getZoom()

                    if (this.truck.lastpoint)
                        map.setView(
                            [this.truck.lastpoint.lat, this.truck.lastpoint.lng + 0.0015],
                            current_zoom > 13 ? current_zoom : 13
                        )
                }
            } else {
                this.resources = null
                this.$store.commit('resetWaypoints')
                this.route = {}
                this.resourceOnFocus = null

                let map = this.$refs.map.lmap
                let current_zoom = map.getZoom()

                if (this.truck.lastpoint)
                    map.setView(
                        [this.truck.lastpoint.lat, this.truck.lastpoint.lng + 0.0015],
                        current_zoom > 13 ? current_zoom : 13
                    )
            }
        },
        fitBounds(bounds) {
            if (bounds.length > 1) {
                this.$refs.map.lmap.fitBounds(bounds, { padding: [50, 50] })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.map-section {
    padding: 8px;
    height: 100%;
    flex-basis: 70%;

    display: flex;
    flex-direction: column;
}
.geofleet-map {
    border-radius: 25px;
}
.my-saved-trips {
    align-self: flex-end;
}
.route-calculator {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    margin: 8px 12px 8px 8px;
    position: relative;

    overflow: auto;
}
.date-picker {
    align-self: center;

    background-color: #fff;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;

    min-width: 305px;
}
.close-btn {
    background-color: #fff;
}
.circle-icon-small {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-image: linear-gradient(to right, #394a5d, #74869b);

    display: flex;
    align-items: center;
    justify-content: center;

    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 12px;

    margin-top: 3px;
}
.circle-icon i {
    padding: 5px;
    color: rgb(255, 255, 255);
    font-size: 34px;
}
.driver {
    display: flex;
    flex-direction: row;
    gap: 4px;

    padding: 0 18px 0 18px;
}
.d-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.separator {
    background-color: var(--main-gray);
    width: 2px;
}
.truck-info {
    display: flex;
    flex-grow: 2;

    padding-right: 38px;

    gap: 8px;
}

.route-planner {
    display: flex;
    flex-direction: column;
}

.select-triggered .geofleet-map {
    cursor: crosshair;
}
.select-triggered {
    position: relative;
}
.select-triggered .alert {
    position: absolute;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 50px;
}

.select-triggered .alert button:hover {
    text-decoration: underline;
}

.borse-carico-list {
    .connect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--main-gray);
        padding: 8px 12px 8px 8px;
        border-radius: 25px;
    }
}

::v-deep(#modal-service-login) {
    .modal-footer {
        display: none;
    }
    .form-control,
    .form-select {
        border-radius: 25px;
        background-color: var(--main-gray);
    }
    .alert-dismissible .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 2;
        color: inherit;
        background-color: transparent;
        border: 0;
    }
}
</style>
