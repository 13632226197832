<template>
    <div id="router-view">
        <vue-snotify></vue-snotify>
        <router-view />
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'App',
    created: function () {},
    computed: {
        ...mapState({
            loggedUserParams: (state) => state.loggedUser.params
        })
    },
    watch: {
        loggedUserParams: {
            handler() {
                if (this.loggedUserParams?.Lingua)
                    this.$setLocale(this.loggedUserParams.Lingua.slice(0, 2))
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<!-- Components CSS -->
<style lang="scss">
/* VUE Select */
@import './../node_modules/vue-select/dist/vue-select.css';

/* VUE MultiSelect */
@import './../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

/* VUE Snotify */
@import '~vue-snotify/styles/material';

/* Bootstrap */
@import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './../node_modules/bootstrap/dist/css/bootstrap.css';
@import './../node_modules/bootstrap-vue/dist/bootstrap-vue.css';

/* Swiper */
@import './../node_modules/swiper/swiper-bundle.min.css';

/* Animate.css */
@import './../node_modules/animate.css/animate.css';

/* Golia Commons */
@import './assets/scss/main.scss';

/* vue-virtual-scroller */
@import './../node_modules/vue-virtual-scroller/dist/vue-virtual-scroller.css';
</style>
