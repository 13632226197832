import Vue from 'vue'
import moment from 'moment'

const mutations = {
    // TRUCKS
    setTrucks(state, trucks) {
        state.trucks = trucks
    },
    setTrailerHooked(state, trucksHooked) {
        state.truck_hooked_list = trucksHooked
    },
    setTrucksRef(state, trucks) {
        state.trucksRef = trucks
    },
    setFocusTruck(state, payload) {
        state.trucks.forEach((t) => {
            t.on_focus = false
        })

        state.trucks.find((t) => t.id === payload.truck.id).on_focus = payload.on_focus
    },
    setActivityTruck(state, payload) {
        state.trucks.forEach((t) => {
            t.on_activity = false
        })

        state.trucks.find((t) => t.id === payload.truck.id).on_activity = payload.on_activity
    },
    updateTruck(state, payload) {
        let index = state.trucks.findIndex((t) => t.id === payload.truck.id)

        if (index !== -1) {
            state.trucks[index] = payload.truck
        }
    },
    updateTruckProperty(state, payload) {
        let index = state.trucks.findIndex((t) => t.id === payload.truck.id)

        if (index !== -1) {
            state.trucks[index][payload.property] = payload.value
        }
    },
    setTrucksEvents(state, events) {
        let specialVehicleEvents = [
            {
                id: 18,
                event_type: 'door_open',
                position: ['45.4383842', '10.9916215'],
                truck_id: 144101
            },
            {
                id: 19,
                event_type: 'engine_on',
                position: ['45.4384023', '10.9916124'],
                truck_id: 144101
            },
            {
                id: 20,
                event_type: 'engine_off',
                position: ['45.4384204', '10.9916033'],
                truck_id: 144101
            }
        ]

        state.trucksEvents = events.concat(specialVehicleEvents)
    },
    setTrucksLastEventId(state, id) {
        state.trucksLastEventId = id
    },

    // BLUETOOTH DEVICES
    setBluetoothDevices(state, devices) {
        state.bluetooth_devices = devices
    },

    // MAIN MAP
    setMainMapStopAutocenter(state, value) {
        state.mainMapStopAutocenter = value
    },
    setMainMapStopAutoFitBounds(state, value) {
        state.mainMapStopAutoFitBounds = value
    },
    setMainMapInstance(state, map) {
        state.mainMapInstance = map
    },
    addMainMapExtraObject(state, payload) {
        let object = payload.object // object can be a marker, circle, polygon, line
        let type = payload.type // type can be marker, circle, polygon, line
        let ref = object.ref

        // Check if the object already exists in the array, if it does, update it with reactivity, if it doesn't, add it
        let index = state.mainMapExtraObjects[type].findIndex((o) => o.ref === ref)

        if (index !== -1) {
            state.mainMapExtraObjects[type].splice(index, 1, object)
        } else {
            state.mainMapExtraObjects[type].push(object)
        }
    },
    removeMainMapExtraObject(state, payload) {
        let object = payload.object // object can be a markers, circle, polygon, line
        let type = payload.type // type can be marker, circle, polygon, line

        let index = state.mainMapExtraObjects[type].indexOf(object)

        if (index !== -1) {
            state.mainMapExtraObjects[type].splice(index, 1)
        }
    },

    // VEHICLES CROSS
    setVehiclesCrossSearch(state, search) {
        state.vehiclesCrossSearch = search
    },
    resetVehiclesCrossSearch(state) {
        state.vehiclesCrossSearch = {}
    },
    setVehiclesCrossResults(state, results) {
        state.vehiclesCrossResults = results

        // loop through the results and group the events in pairs of two
        state.vehiclesCrossResults.forEach((v) => {
            let events = v.events
            let grouped_events = []
            // events is an array of events, inside of that we can find the timestamp in event.timestamp.$date.$numberLong
            // we need to create a new array containing the events grouped in pairs of two

            for (let i = 0; i < events.length; i++) {
                if (i % 2 == 0) {
                    let event = events[i]
                    let next_event = events[i + 1] ? events[i + 1] : null
                    let start_timestamp = event.timestamp?.$date?.$numberLong
                    let end_timestamp = next_event ? next_event.timestamp?.$date?.$numberLong : null

                    grouped_events.push({
                        start_timestamp,
                        end_timestamp,
                        event,
                        next_event
                    })
                }
            }

            v.grouped_events = grouped_events
        })
    },
    resetVehiclesCrossResults(state) {
        state.vehiclesCrossResults = []
    },

    // DRIVERS DATA
    setDrivers: (state, drivers) => {
        state.drivers = drivers
    },
    setDriversData(state, driversData) {
        state.driversData = driversData
    },
    updateDriversData(state, payload) {
        // loop through the payload object
        for (const [key, value] of Object.entries(payload)) {
            if (state.driversData[key]) {
                // if the driver already exists in the state, update the data
                state.driversData[key] = value
            }
        }
    },

    // COMPANIES
    setCompanies(state, companies) {
        state.companies = companies
    },

    //PLAFONDS DRIVER
    setDriversPlafondData(state, { id, data }) {
        state.driversPlafondData = {
            ...state.driversPlafondData,
            [id]: {
                ...data,
                __cacheTime: moment()
            }
        }
    },
    cleanDriversPlafondCache(state) {
        state.driversPlafondData = {}
    },
    removeDriverFromDriversPlafondData(state, id) {
        state.driversPlafondData[id] = undefined
        Vue.delete(state.driversPlafondData, id)
    },

    // POIS
    setPois(state, pois) {
        state.pois = pois
    },
    addPoi(state, poi) {
        state.pois.push(poi)
    },
    updatePoi(state, poi) {
        let index = state.pois.findIndex((p) => p.id === poi.id)

        if (index !== -1) {
            state.pois[index] = poi
        }
    },
    focusPoi(state, poi) {
        let _pois = [...state.pois]

        _pois.forEach((p) => {
            p.on_focus = false
            if (p.id === poi.id) p.on_focus = true
        })

        state.pois = _pois
    },
    removePoi(state, poi) {
        state.pois.splice(state.pois.indexOf(poi), 1)
    },

    // GMAPS
    setGmaps(state, gmaps) {
        state.gmaps = gmaps
    },

    // COMPANY TAGS
    setCompanyTags(state, tags) {
        state.companyTags = tags
    },
    addCompanyTag(state, tag) {
        state.companyTags.push(tag)
    },
    updateCompanyTag(state, tag) {
        let index = state.companyTags.findIndex((t) => t.id === tag.id)

        if (index !== -1) {
            state.companyTags[index] = tag
        }
    },
    removeCompanyTag(state, tag) {
        state.companyTags.splice(state.companyTags.indexOf(tag), 1)
    },

    // FILTERS DICTIONARY
    setFiltersDictionary(state, filters) {
        state.filtersDictionary = filters
    },
    setStationsFiltersDictionary(state, filters) {
        state.filtersDictionary.stations = filters
    },
    setParkingsFiltersDictionary(state, filters) {
        state.filtersDictionary.parkings = filters
    },

    // CONFIG
    setConfig(state, config) {
        state.config = config
    },
    setConfigProperty(state, payload) {
        state.config[payload.property] = payload.value
    },
    resetConfig(state) {
        state.config = {}
    },
    setTruckFilteredOut(state, ids) {
        state.truckFilteredOut = ids || []
    }
}

export default mutations
