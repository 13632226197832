<template>
    <side-panel>
        <template #head>
            <div class="a-header">
                <div class="a-h-details">
                    <details-head :truck="truck" :tiny="true" v-if="truck.details" />
                    <div class="a-h-address" v-if="truck.lastpoint">
                        <i
                            style="color: #2881c1; font-size: 38px"
                            class="fa-solid fa-location-dot"
                        ></i>
                        <reverse-geocoding :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]" />
                    </div>
                </div>
                <div class="close-btn" @click="$emit('lostfocus', truck)">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
        </template>

        <template #body>
            <PlannerPanelContents :truck="truck" />
        </template>
    </side-panel>
</template>

<script>
import SidePanel from '@/components/panels/SidePanel.vue'
import DetailsHead from '@/views/geofleet/components/DetailsPanel/DetailsHead.vue'

export default {
    name: 'PlannerPanel',
    props: {
        truck: {
            type: Object,
            required: false,
            default: () => ({ lastpoint: null, details: null })
        }
    },
    components: {
        SidePanel,
        DetailsHead
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
.activity-panel ::v-deep(.head) {
    flex-basis: 50px;
}
.close-btn {
    top: 7px;
    right: 7px;
    display: flex;
    width: 30px;
    height: 30px;
}
</style>
