<template>
    <side-panel class="side-panel" id="tags-panel">
        <template #head>
            <div class="d-head">
                <div class="container-fluid">
                    <h3 class="panel-title">
                        {{ __('tags.manage_tags') }}
                    </h3>

                    <div @click="$emit('toggleUtilityPanel')" class="close-btn">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <tags-trucks
                class="tags-list custom-scrollbar"
                :companyTags="companyTags"
                :with-count="true"
            />
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import TagsTrucks from './TagsPanel/TagsTrucks.vue'

import { mapState } from 'vuex'

export default {
    name: 'TagsPanel',
    components: {
        SidePanel,
        TagsTrucks
    },
    data() {
        return {}
    },
    watch: {},
    created() {},
    computed: {
        ...mapState({
            companyTags: (state) => state.geoFleet.companyTags
        })
    },
    methods: {}
}
</script>

<style scoped lang="scss">
@import './SidePanel.scss';
.tags-list {
    --scrollbar-thumb-border: var(--main-inner-bg-color);

    flex: 1;
    overflow-y: auto;
    background: var(--main-inner-bg-color);
    border-radius: 12px;
    padding: 15px;
    margin-top: 10px;
}
</style>
