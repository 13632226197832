<template>
    <div class="print-directives">
        <div class="spinner-wrapper" v-show="loading">
            <Spinner></Spinner>
        </div>

        <a target="_blank" href="https://goliaweb.it" class="golia-logo">
            <img
                :src="$mq == 'xs' || $mq == 'sm' ? goliaMobileLogo : goliaFullLogo"
                alt="Powered by Golia"
            />
        </a>

        <geofleet-map
            class="geofleet-map shared-link-map"
            :class="{ 'geofleet-map--focus': focus_truck }"
            map="osm"
            :advanced="false"
            :cluster="true"
            :ptvToken="'shared'"
            :trucks="trucks"
            :poi="false"
            :menu="false"
            :mode="'shared-link'"
            @focus="truckFocus"
            ref="map"
        >
            <l-marker-cluster :options="clusterOpt" v-if="(focus_truck || {}).showingRoute">
                <l-geo-json
                    @ready="geoJsonLoaded"
                    v-for="opt in geojson"
                    :key="opt.id"
                    :geojson="opt.geojson"
                    :optionsStyle="opt.style || {}"
                    :options="opt.options || {}"
                    :ref="'geojson_' + opt.id"
                />
            </l-marker-cluster>
        </geofleet-map>

        <div class="panel-box">
            <transition name="slide-fade">
                <details-panel
                    v-if="focus_truck"
                    class="details-panel shared-link-panel"
                    :class="{
                        mobile: $mq == 'xs' || $mq == 'sm',
                        'with-trucks': trucks.length > 1,
                        'with-scrollbar': mainPanelHasScrollbar
                    }"
                    :truck="focus_truck"
                    :rangeData="rangeData"
                    :collapsed="$mq == 'xs' || $mq == 'sm'"
                    :closable="trucks.length > 1 ? true : false"
                    @lostfocus="lostFocus"
                    @rangeUpdate="
                        (date) => {
                            rangeData = date
                        }
                    "
                    ref="details_panel"
                />
            </transition>
        </div>

        <main-panel
            v-if="trucks.length > 1"
            class="main-panel shared-link-panel horizontal-variant"
            :class="mainPanelHasScrollbar ? 'has-scrollbar' : ''"
            :trucks="trucks"
            :drivers="{}"
            :companies="{}"
            :collapsed="true"
            :last_update="last_update"
            :direction="'horizontal'"
            @focus="truckFocus"
            @pinTruck="pinMyTruck"
            @searching="boundVisibleTruck"
            ref="main_panel"
        />
    </div>
</template>

<script>
import L from 'leaflet'
import { LGeoJson } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import MainPanel from './../geofleet/components/MainPanel.vue'
import DetailsPanel from './components/DetailsPanel/DetailsPanel.vue'
import moment from 'moment'
import Spinner from 'vue-simple-spinner'

import goliaFullLogo from './../../assets/images/golia-powered-by.png'
import goliaMobileLogo from './../../assets/images/golia-truck.png'

import { mapMutations } from 'vuex'

export default {
    name: 'viewSharedLink',
    components: {
        MainPanel,
        DetailsPanel,
        Spinner,
        LGeoJson,
        'l-marker-cluster': Vue2LeafletMarkerCluster
    },
    data() {
        return {
            //Trucks data
            trucks: [],
            trucks_ref: {},
            error: null,
            focus_truck: null,

            //Map data
            layers: [],
            geojson: [],
            stops: [],
            rangeData: { start: this.moment().startOf('day'), end: this.moment().endOf('day') },
            geoJsoncounter: 0,
            clusterOpt: {
                maxClusterRadius: 50,
                showCoverageOnHover: true,
                zoomToBoundsOnClick: true,

                elementsPlacementStrategy: 'clock',

                spiderLegPolylineOptions: {
                    weight: 0
                },
                clockHelpingCircleOptions: {
                    weight: 0.7,
                    opacity: 1,
                    color: 'black',
                    fillOpacity: 1,
                    dashArray: '10 5'
                },
                helpingCircles: true,

                spiderfyDistanceSurplus: 25,
                spiderfyDistanceMultiplier: 1,

                elementsMultiplier: 1.4,
                firstCircleElements: 8,

                spiderfyOnMaxZoom: true,

                animateAddingMarkers: true,

                spiderfiedClassName: 'spiderfied-marker',
                disableClusteringAtZoom: 18
            },

            //Loading data
            last_update: null,
            collapsed_main_panel: false,
            collapsed_details_panel: false,
            requestsCounter: 0,

            //Token data
            company: null,
            access_token: null,
            link_token: null,
            loading: false,

            //Images
            goliaFullLogo: goliaFullLogo,
            goliaMobileLogo: goliaMobileLogo,

            //Other
            mainPanelHasScrollbar: false
        }
    },
    watch: {
        access_token: function () {
            this.loadTrucks()
        },
        focus_truck: {
            handler(val) {
                if (val.showingRoute && !val.route_lastpoint) {
                    this.draw()
                }
            },
            deep: true
        },
        rangeData: {
            handler: function () {
                if (this.focus_truck.showingRoute) {
                    this.draw()
                }
            },
            deep: true
        },
        error: function () {
            if (this.error) {
                var errorDescr = ''

                if (this.error === 422) {
                    errorDescr = this.__('viewsharedlink.invalid_date_range')
                }
                if (this.error === 425) {
                    errorDescr = this.__('viewsharedlink.too_early_link')
                }
                if (this.error === 404) {
                    errorDescr = this.__('viewsharedlink.too_late_link')
                }

                this.$bvModal
                    .msgBoxOk(errorDescr, {
                        title: this.__('Errore'),
                        headerClass: 'px-3 border-bottom-0 d-block text-center',
                        bodyClass: 'text-center',
                        footerClass:
                            this.error == 422
                                ? 'px-3 border-top-0 text-center'
                                : 'alert-footer px-3 border-top-0 text-center',
                        centered: true,
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true
                    })
                    .then(() => {
                        if (this.error == 422) {
                            //this.rangeData = { start: this.moment().startOf('day'), end: this.moment().endOf('day') }

                            this.loading = false
                            this.resetRoute()
                        }
                    })
            }
        },
        trucks: function () {
            if (this.trucks.length > 0) {
                setTimeout(() => {
                    this.checkMainScrollbar()
                }, 1000)
            }
        }
    },
    methods: {
        checkMainScrollbar() {
            let element = document.querySelector('.truck-section')
            let hasScrollbar = false

            if (element) {
                hasScrollbar = element.scrollWidth > element.clientWidth
                this.mainPanelHasScrollbar = hasScrollbar
            }
        },
        resetRoute() {
            this.focus_truck.showingRoute = false
            this.error = null
            this.layers = []
            this.geojson = []
        },
        truckFocus(truck) {
            if (this.focus_truck) this.focus_truck.on_focus = false

            this.focus_truck = truck

            truck.on_focus = true
            truck.details.custom_id = null

            this.$set(this.focus_truck, 'showingRoute', false)
            this.$set(this.focus_truck, 'route_lastpoint', false)

            this.$refs.map.truckFocus(truck)

            if (this.trucks.length > 1) {
                this.$refs.main_panel.truckFocus(truck)
            }
        },
        lostFocus() {
            if (!this.focus_truck) return false

            this.focus_truck.on_focus = false
            this.focus_truck = null
        },
        pinMyTruck(truck) {
            truck.pinned = !truck.pinned
        },
        boundVisibleTruck() {
            this.lostFocus()
        },
        computeActivity(lastpoint) {
            if (lastpoint.speed || lastpoint.tacho_speed) return 'moving'

            if (lastpoint.engine_rpm) return 'engine_on'

            if (lastpoint.ignition) return 'ignition'

            return 'off'
        },
        loadTrucks() {
            let _this = this

            this.loading = true
            this.last_update = moment.utc().toISOString()

            this.$fetch(
                this.$env.GOLIA_API +
                    '/trucks?include[]=lastpoint&include[]=details&include[]=category&filter[VISIBILE][eq]=1',
                { token: this.access_token }
            )
                .then((res) => {
                    this.loading = false
                    return res.json()
                })
                .then((res) => {
                    this.trucks = res.data
                        .filter((v) => {
                            return v.lastpoint && v.visible
                        })
                        .map((v) => {
                            v.visible = true
                            v.on_focus = false
                            v.pinned = false
                            v.on_activity = false

                            v.details = v.details
                                ? v.length == 1
                                    ? v.details.shift()
                                    : v.details.find((d) => d.id_main_descr == 0)
                                : null

                            v.details.custom_id = null

                            v.activity_type = _this.computeActivity(v.lastpoint)

                            v.lastpoint = _this.bindLastPointRelations(v.lastpoint)

                            _this.trucks_ref[v.id] = v

                            return v
                        })

                    // this.truckFocus(this.trucks[0])
                })
                .catch(() => {
                    this.getAccessToken()
                })
        },
        updateData() {
            let query = new URLSearchParams(
                this.last_update ? { update: this.last_update } : ''
            ).toString()

            this.last_update = moment.utc().toISOString()

            this.$fetch(this.$env.GOLIA_API + '/geofleet/lastpoints' + (query ? '?' + query : ''), {
                token: this.access_token
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    res.data.forEach((p) => {
                        let truck = this.trucks_ref[p.truck]

                        truck.activity_type = this.computeActivity(p)

                        truck.lastpoint = this.bindLastPointRelations(p)

                        if (truck.route_lastpoint) {
                            this.addGeojsonLayer('route_' + truck.lastpoint.lat, {
                                type: 'FeatureCollection',
                                features: [
                                    {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: [
                                                truck.route_lastpoint,
                                                [truck.lastpoint.lng, truck.lastpoint.lat]
                                            ]
                                        },
                                        properties: truck.lastpoint
                                    }
                                ]
                            })
                        }

                        truck.route_lastpoint = [truck.lastpoint.lng, truck.lastpoint.lat]
                    })
                })
                .catch(() => {
                    this.getAccessToken()
                })
        },
        bindLastPointRelations(lastpoint) {
            lastpoint.provenience = null
            lastpoint.driver = null
            lastpoint.last_driver = null
            lastpoint.codriver = null
            lastpoint.company_name = ''

            try {
                lastpoint.telemetry = lastpoint.telemetry ? JSON.parse(lastpoint.telemetry) : {}
            } catch (err) {
                lastpoint.telemetry = {}
            }

            return lastpoint
        },
        draw() {
            this.loading = true
            this.geojson = []
            this.geoJsoncounter = 1

            this.drawRoute()
            //this.drawStops()
        },
        geoJsonLoaded() {
            this.geoJsoncounter -= 1

            if (this.geoJsoncounter <= 0) {
                this.layers = Object.values(this.$refs.geojson_route[0].mapObject._layers)

                //this.stops = Object.values(this.$refs.geojson_stops[0].mapObject._layers)

                // let map_bound = this.$refs.geojson_route[0].mapObject.getBounds()

                // if (map_bound.isValid())
                //     this.$refs.map.lmap.fitBounds(map_bound)
            }
        },
        drawRoute() {
            this.$fetch(
                this.$env.GOLIA_API +
                    '/geofleet/trucks/' +
                    this.focus_truck.id +
                    '/route.json?start_date=' +
                    this.startDateRoute +
                    '&end_date=' +
                    this.endDateRoute +
                    '&company=' +
                    this.company,
                { token: this.access_token }
            )

                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.focus_truck.route_lastpoint = res.features.at(-1).geometry.coordinates[1]

                    this.addGeojsonLayer('route', res)

                    this.loading = false
                })
                .catch(() => {
                    this.error = 422
                })
        },
        drawStops() {
            let _this = this

            this.$fetch(
                this.$env.GOLIA_API +
                    '/geofleet/trucks/' +
                    this.focus_truck.id +
                    '/stops.json?start_date=' +
                    this.startDate +
                    '&end_date=' +
                    this.endDate,
                { token: this.access_token }
            )
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    var stopsCount = 0
                    res.features = res.features.filter((s) => s.properties.DURATION >= 15)

                    _this.geojson.push({
                        id: 'stops',
                        geojson: res,
                        options: {
                            pointToLayer: function (feature, latlng) {
                                stopsCount++

                                return L.marker(latlng, {
                                    icon: L.divIcon({
                                        //html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16"><defs><linearGradient id="myGradient"><stop offset="0%" stop-color="rgba(37,145,251,0.98)" /><stop offset="90%" stop-color="rgba(0,7,128,1)" /></linearGradient></defs><path stroke="white" stroke-width="0.4" d="M14.3,6.5a4.8,4.8,0,0,0-.7-2.3l.5-.5a.7.7,0,0,0,0-.9.7.7,0,0,0-1,0l-.4.4a5.2,5.2,0,0,0-2-.9V1.6H11a.6.6,0,0,0,.6-.6A.7.7,0,0,0,11,.3H9a.7.7,0,0,0-.6.7.6.6,0,0,0,.6.6h.3v.7A4.3,4.3,0,0,0,5.7,6.5a4.8,4.8,0,0,0,.4,1.8h0L10,15.7l3.9-7.4h0A4.8,4.8,0,0,0,14.3,6.5ZM9.5,4.2a.5.5,0,0,1,1,0V6.9a.5.5,0,0,1-1,0Z" fill="url(#myGradient)"/><text fill="#fff" style="font-size: 6px;font-weight: 700;transform: translate(8px, 9px);">' + stopsCount + '</text></svg>',
                                        html:
                                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386 512"><path fill="rgb(247, 59, 94)" stroke="#fff" stroke-width="30" d="M187.733 505.173L112.64 375.467c-3.413-6.827-6.827-10.24-13.653-17.067l-81.92-78.507C6.827 269.653 0 256 0 242.347V143.36c0-13.653 6.827-27.307 17.067-37.547l88.746-88.746C116.053 6.827 129.707 0 143.36 0h95.573c13.654 0 27.307 6.827 37.547 17.067l88.747 88.746c10.24 10.24 17.066 23.894 17.066 37.547v95.573c0 13.654-6.826 27.307-17.066 37.547l-81.92 81.92c-6.827 6.827-10.24 13.653-13.654 20.48L194.56 505.173c3.413 6.827-6.827 6.827-6.827 0z" class=""></path><text fill="#fff" x="' +
                                            (stopsCount > 10 ? '20%' : '35%') +
                                            '">' +
                                            stopsCount +
                                            '</text></svg>',
                                        className: 'stop-icon',
                                        iconSize: [26, 35],
                                        iconAnchor: [13, 35]
                                    })
                                })
                            }
                        }
                    })

                    this.loading = false
                })
        },
        addGeojsonLayer(id, geojson) {
            this.geojson.push({
                id: id,
                geojson: geojson,
                style: (feature) => {
                    let speed = parseInt(feature.properties.tacho_speed || feature.properties.speed)

                    let color = '#fd3030'

                    if (speed <= 30) color = '#5d8dc8' // Blue soft
                    else if (speed <= 50) color = '#5d8dc8' // Blue soft
                    else if (speed <= 70) color = '#5d8dc8' // Blue soft
                    else if (speed <= 90) color = '#335e94' // Blue middle
                    else if (speed <= 100) color = '#335e94' // Blue middle
                    else if (speed <= 130) color = '#fda030' // Orange
                    else color = '#fd3030'

                    return {
                        weight: 7,
                        color: color,
                        opacity: 1
                    }
                },
                options: {
                    onEachFeature: (feature, layer) => {
                        layer.on('mouseover', function () {
                            this.original_style = {
                                weight: this.options.weight,
                                color: this.options.color
                            }

                            this.setStyle({
                                weight: 14,
                                color: '#35bdfc'
                            })
                        })

                        layer.on('mouseout', function () {
                            if (this.original_style) this.setStyle(this.original_style)
                        })

                        let date = this.moment(feature.properties.date, 'UTC')
                        let speed = feature.properties.tacho_speed || feature.properties.speed
                        let heading = feature.properties.heading
                        let altitude = feature.properties.altitude

                        feature.properties.timestamp = date.unix()

                        let tooltip =
                            '<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">'
                        tooltip +=
                            '<div style="display: flex;flex-direction: row;justify-content: center;align-items: center;gap: 5px;">'

                        if (heading)
                            tooltip +=
                                '<div style="flex-grow: 2;"><svg style="height: 22px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595 615"><g style="transform: rotate(' +
                                String(heading) +
                                'deg); transform-origin: center;"><path d="M267.6,83.5,113,499c-17.7,48.1,41.3,85.5,76.5,50.6L310.3,429.5l120,120.9c34.9,35.1,94.2-1.9,76.8-50.1L355.2,83.8C340,46.5,283,46.3,267.6,83.5Z" transform="translate(-10 -25)"/></g></svg></div>'

                        tooltip +=
                            '<div style="display: flex;flex-direction: column;justify-content: flex-start;">'

                        if (speed) tooltip += '<span>' + speed + ' km/h</span>'

                        if (altitude) tooltip += '<span>' + altitude + ' m s.l.m.</span>'

                        tooltip += '</div>'
                        tooltip += '</div>'
                        tooltip += '<span>' + date.format('L HH:mm:ss') + '</span>'
                        tooltip += '</div>'

                        layer.bindTooltip(tooltip, { direction: 'right', offset: L.point(10, -40) })
                    }
                }
            })
        },
        getAccessToken() {
            let _this = this

            this.$fetch(this.$env.GOLIA_API + '/sharelink/token', {
                method: 'POST',
                body: JSON.stringify({
                    token: this.link_token
                })
            })
                .then((res) => {
                    if (res.status >= 400) {
                        _this.requestsCounter += 1
                        throw new Error('http error', { cause: res.status })
                    } else {
                        return res.json()
                    }
                })
                .then((res) => {
                    _this.access_token = res.data.access_token
                    _this.company = res.meta.company
                })
                .catch((error) => {
                    _this.error = error.cause
                })
        },
        ...mapMutations({
            collapsePanel: 'collapsePanel',
            expandPanel: 'expandPanel'
        })
    },
    computed: {
        startDateRoute() {
            return this.rangeData.start.clone().utc().format()
        },
        endDateRoute() {
            return this.rangeData.end.clone().utc().format()
        }
    },
    created() {
        this.collapsePanel({ panel: 'main' })

        this.link_token = this.$route.params.token

        this.getAccessToken()

        setInterval(() => {
            if (this.requestsCounter < 2) {
                if (this.$isBrowserWindowActive()) {
                    this.updateData()
                }
            }
        }, 20 * 1000)
    },
    updated() {
        this.checkMainScrollbar()
    }
}
</script>

<style>
/* .stop-icon text {
        transform: translateY(260px);
        font-size: 190px;
        font-weight: 700;
    } */

.alert-footer .btn {
    display: none;
}

@media (max-width: 768px) {
    .leaflet-control-zoom {
        display: none;
    }
    .details-panel.shared-link-panel .d-head .close-btn {
        display: none;
    }
}
</style>

<style scoped lang="scss">
.slide-fade-enter-active {
    transition: all 0.4s ease;
}
.slide-fade-leave-active {
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
}

.geofleet-map {
    height: 100%;
    width: 100%;
    position: absolute;

    &.shared-link-map {
        ::v-deep(.leaflet-control-container .leaflet-control-fullscreen) {
            margin-top: 90px;
        }
    }
}
.panel-box {
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 20px 20px 20px 20px;

    gap: 20px;
}

.golia-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;

    img {
        width: 140px;
    }
}

@media (max-width: 768px) {
    .geofleet-map.shared-link-map {
        ::v-deep(.leaflet-control-container .leaflet-control-fullscreen) {
            margin-top: 65px;
        }
    }
    .panel-box {
        flex-direction: column;
        position: absolute;
        bottom: 0px;
    }

    .golia-logo {
        left: 5px;

        img {
            width: 55px;
        }
    }
}
.details-panel {
    /*width: 420px;*/
    background: rgba(254, 254, 254, 1);
    border-radius: 25px;

    float: right;
    display: flex;
    flex-flow: column;

    padding: 15px;

    z-index: 1000;

    &.shared-link-panel ::v-deep(.body) {
        overflow-y: initial;
    }
    &.shared-link-panel ::v-deep(.d-head .close-btn) {
        display: none;
    }
}
#main-panel {
    background: rgba(254, 254, 254, 0.96);
    border-radius: 25px;

    float: right;
    display: flex;
    flex-flow: column;

    padding-bottom: 14px;

    z-index: 1000;

    &.shared-link-panel {
        &.horizontal-variant {
            width: auto;
            max-width: calc(100% - 140px);
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            padding: 8px !important;

            ::v-deep(.truck-section) {
                display: flex;
                gap: 10px;
            }
        }

        ::v-deep(.collapse-button) {
            display: none;
        }
        ::v-deep(.pin-icon),
        ::v-deep(.truck-popover) {
            display: none;
        }

        ::v-deep(.vue-recycle-scroller.scroller.ready.direction-horizontal) {
            height: 75px;
        }
        &.has-scrollbar ::v-deep(.vue-recycle-scroller.scroller.ready.direction-horizontal) {
            height: 90px;
        }

        ::v-deep(.truck-list-item) {
            padding: 0px 8px;
        }
    }
}
</style>
