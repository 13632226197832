<template>
    <v-select
        v-model="searchLocation"
        :filterable="false"
        :options="searchResults"
        :placeholder="__('planner.search_location')"
        :noDrop="searchResults.length == 0"
        @search="
            (search, loading) => {
                searchAddress(search, loading)
            }
        "
        @input="
            (option) => {
                optionSelectHandler(option)
            }
        "
    >
        <template slot="no-options">
            {{ __('planner.search_location') }}
        </template>
    </v-select>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
    name: 'GoliaWaypointSearch',
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            searchLocation: '',
            searchResults: []
        }
    },
    metaInfo() {
        return {
            script: [
                {
                    src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDHi20jpKVCTkdIfgWXUQFk56x4FEBh6D4&libraries=places`,
                    async: true,
                    defer: true,
                    callback: () => this.GoogleMapsInit()
                }
            ]
        }
    },
    mounted() {
        this.$can('use', 'google_places') && !this.gmaps ? this.GoogleMapsInit() : null
    },
    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.searchLocation = val.label

                    // per resettare il risultato delle ricerche
                    this.searchResults = []
                }
            },
            deep: true,
            immediate: true
        },
        searchLocation: {
            handler: function (val) {
                if (val == null) {
                    this.$emit('input', null)
                    // per resettare il risultato delle ricerche
                    this.searchResults = []
                }
                if (val && val.label) {
                    this.$emit('input', val)
                }
            },
            deep: true
        }
    },
    methods: {
        lookingForAddress: debounce((loading, search, _this) => {
            var regXCoordinates = /[\d|\-]+[\.|\,]\d+/g // eslint-disable-line
            var coordinatesSearched = []

            // Controllo se la ricerca è una coppia di coordinate
            coordinatesSearched = [...search.matchAll(regXCoordinates)]
                .map((m) => Number((m[0] || '').replace(',', '.')))
                .filter((c) => !isNaN(c))

            if (coordinatesSearched.length == 2) {
                _this.$nextTick(() => {
                    0, 0
                    _this.searchResults = [
                        {
                            label: coordinatesSearched.join(', '),
                            referenceCoordinate: {
                                x: coordinatesSearched[1],
                                y: coordinatesSearched[0]
                            }
                        }
                    ]
                    loading(false)
                })
            } else {
                _this.$can('use', 'google_places')
                    ? _this.gMapLocate(loading, search, _this)
                    : _this.xlocate(loading, search, _this)
            }
        }, 500),
        searchAddress(search, loading) {
            let _this = this

            if (search) {
                loading(true)

                if (search != '') {
                    this.lookingForAddress(loading, search, this)
                } else {
                    this.$nextTick(() => {
                        _this.searchLocation = null
                        _this.searchResults = []
                    })
                }
            } else {
                this.$nextTick(() => {
                    _this.searchResults = []

                    setTimeout(() => {
                        if (search == '') _this.searchResults = []
                    }, 500)
                })
            }
        },
        async optionSelectHandler(option) {
            if (option && option.placeId) {
                option.referenceCoordinate = await this.gMapPlace(option)
            }
        },
        GoogleMapsInit() {
            this.$store.commit('setGmaps', window.google.maps.places)
        },
        gMapLocate: debounce((loading, search, vm) => {
            let autocompleteService = new vm.gmaps.AutocompleteService()

            var sessionToken = new vm.gmaps.AutocompleteSessionToken()

            autocompleteService.getPlacePredictions(
                {
                    input: search,
                    sessionToken: sessionToken,
                    language: vm.$locale()
                },
                (res) => {
                    loading(false)

                    vm.searchResults = res.map((r) => {
                        return {
                            placeId: r.place_id,
                            label: r.description,
                            sessionToken: sessionToken,
                            referenceCoordinate: { x: 0, y: 0 }
                        }
                    })
                }
            )
        }, 250),
        gMapPlace: function (option) {
            return new Promise((resolved, rejected) => {
                let placeService = new this.gmaps.PlacesService(document.createElement('div'))

                placeService.getDetails(
                    {
                        placeId: option.placeId,
                        sessionToken: option.sessionToken,
                        fields: ['geometry.location']
                    },
                    (res, status) => {
                        if (status == 'OK')
                            return resolved({
                                x: res.geometry.location.lng(),
                                y: res.geometry.location.lat()
                            })

                        rejected(status)
                    }
                )
            })
        },
        xlocate: debounce((loading, search, vm) => {
            var myHeaders = new Headers()
            myHeaders.append(
                'Authorization',
                'Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM='
            )
            myHeaders.append('Content-Type', 'application/json')

            var raw = JSON.stringify({
                $type: 'SearchByTextRequest',
                requestProfile: {
                    mapLanguage: 'x-ptv-DFT'
                },
                text: search
            })

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw
            }

            fetch(
                'https://xserver2-europe.cloud.ptvgroup.com/services/rs/XLocate/searchLocations',
                requestOptions
            )
                .then((response) => response.json())
                .then((json) => {
                    loading(false)
                    vm.searchResults = json.results.map((r) => {
                        return {
                            referenceCoordinate: r.location.referenceCoordinate,
                            label: r.location.formattedAddress
                        }
                    })
                })
        }, 250)
    },
    computed: {
        ...mapState({
            gmaps: (state) => state.geoFleet.gmaps
        })
    }
}
</script>
