<template>
    <div class="d-head">
        <div class="container-fluid" :class="collapsed ? 'g-0' : ''">
            <div class="row align-items-center">
                <div class="col-6 col-lg">
                    <div class="targa-f" v-if="!collapsed && !tiny">{{ truck.plate }}</div>
                </div>
                <div class="col-6 d-lg-none lastpoint-info">
                    {{ truck.lastpoint.date | formatDate('UTC') }}
                </div>
            </div>
            <div :class="!collapsed ? 'row' : ''">
                <div
                    class="col-3 d-none d-lg-flex flex-column justify-content-center align-items-center"
                    v-if="!collapsed"
                >
                    <div class="circle-icon big" :style="{ 'background-color': colorStatus }">
                        <truck-icon
                            style="padding: 5px; color: #fff; font-size: 34px"
                            :category="truck.category"
                        />
                    </div>
                    <div
                        class="oval-status d-flex align-items-center justify-content-center d-none"
                        :style="{ 'background-color': colorStatus }"
                    >
                        <span class="status-text">{{ status }}</span>
                    </div>
                </div>

                <div class="collapsed-header" v-else>
                    <div
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        "
                    >
                        <div
                            class="circle-icon"
                            :style="{ 'background-color': colorStatus, 'background-image': 'none' }"
                        >
                            <truck-icon
                                style="padding: 5px; color: #fff"
                                :category="truck.category"
                            />
                        </div>
                        <span class="targa-c">{{ truck.plate }}</span>
                    </div>
                </div>

                <div :class="collapsed ? 'text-center' : 'col-lg-9 d-flex flex-column'">
                    <div class="targa-f fleat-start" v-if="tiny">{{ truck.plate }}</div>

                    <div v-if="truck.details.custom_id && tiny" class="plate">
                        <small>{{ truck.plate }}</small>
                    </div>

                    <div v-if="!tiny" class="lastpoint-info">
                        <div class="d-none d-lg-block">
                            {{ truck.lastpoint.date | formatDate('UTC') }}
                        </div>
                        <div
                            v-if="truck.lastpoint.provenience && truck.lastpoint.provenience.web"
                            class="p-provdescr"
                        >
                            <i
                                v-if="
                                    truck.lastpoint.provenience && truck.lastpoint.provenience.icon
                                "
                                :class="['fa-solid', truck.lastpoint.provenience.icon]"
                            ></i>
                            {{ truck.lastpoint.provenience.web }}
                        </div>
                    </div>

                    <div v-if="!tiny">
                        <span class="localizzazione-f">
                            <reverse-geocoding
                                :full="true"
                                :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="!tiny && !collapsed && closable"
                @click="$emit('close', truck)"
                class="close-btn"
            >
                <button type="button" class="btn-close" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainPanel',
    props: {
        truck: {
            type: Object,
            required: true
        },
        tiny: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {}
    },
    computed: {
        status() {
            return ''
        },
        colorStatus() {
            if (this.moment().diff(this.moment(this.truck.lastpoint.date, 'UTC'), 'hours') > 1)
                return '#ccccce'
            else {
                if (this.truck.activity_type == 'moving') return '#a4d6a8'
                else if (this.truck.activity_type == 'engine_on') return '#f73b5e'
                else if (this.truck.activity_type == 'ignition') return '#fabe67'
                else {
                    if (this.truck.lastpoint.ignition || this.truck.lastpoint.ignition > 0)
                        return '#fabe67'
                    else {
                        return '#ccccce'
                    }
                }
            }
        },
        driver() {
            let driver = this.truck.lastpoint.driver || this.truck.lastpoint.last_driver

            return driver && driver.id > 1
                ? driver.name + ' ' + driver.surname
                : '- ' + this.__('no_card') + ' -'
        }
    },
    methods: {},
    watch: {}
}
</script>

<style scoped>
.d-head {
    position: relative;
}
.icon-geofleet {
    font-size: 70px;
    color: #ffffff;
}
.targa-f {
    color: #515059;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 10px;
}
.icon-status-f {
    float: left;
}
.dati-autisti {
    color: #515059;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 5px;
}
.lastpoint-info {
    color: #515059;
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 5px;

    display: flex;
    flex-direction: column;
    gap: 2px;
}
.localizzazione-f {
    color: #515059;
    font-size: 0.6rem;
    font-weight: 500;
    display: block;
    line-height: 1.5;
}
.localizzazione-f i {
    color: #515059;
}
.close-btn {
    top: -5px;
    right: -5px;
    display: flex;
}
.oval-status {
    margin-top: -6px;
    border-radius: 15px;
    width: 70px;
    height: 20px;
}
.status-text {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 500;
}
small {
    font-size: 0.75em;
}
.plate {
    font-weight: 700;
}
</style>

<style lang="scss">
@media (max-width: 768px) {
    .shared-link-panel {
        .collapsed-header .circle-icon {
            display: none;
        }
        .targa-c {
            font-size: 12px;
            font-weight: 700;
        }
    }
}
</style>
