import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    /*
     * GEOFLEET
     */
    {
        path: '/',
        component: () =>
            import(/* webpackChunkName: "geofleet" */ '@/views/geofleet/GeoFleetMobile'),
        children: [
            {
                path: '/',
                name: 'geofleet.index',
                components: {
                    map: () => import(/* webpackChunkName: "geofleet" */ '@/components/GeofleetMap')
                },
                // Pass the props object to the component using the props option
                props: {
                    default: false,
                    map: {
                        map: 'silica',
                        advanced: true,
                        cluster: true,
                        ptvToken: Vue.prototype.$env.ptv_password,
                        poi: true,
                        noAutoCenterMap: false,
                        extraControls: {
                            vehiclesMenu: true,
                            mobileSettings: true,
                            mobileLogout: true
                        }
                    }
                }
            },
            {
                path: '/vehicles',
                name: 'geofleet.vehicles',
                components: {
                    default: () =>
                        import(
                            /* webpackChunkName: "geofleet" */ '@/views/geofleet/components/MainPanel'
                        )
                }
            },
            {
                path: '/vehicle/:id',
                name: 'geofleet.vehicle_details',
                components: {
                    default: () =>
                        import(
                            /* webpackChunkName: "geofleet" */ '@/views/geofleet/components/DetailsPanel'
                        )
                }
            }
        ]
    },

    /*
     * GEOFLEET SHARE LINK
     */
    {
        path: '/view-shared-link/:token',
        name: 'viewvehicle.index',
        component: () =>
            import(
                /* webpackChunkName: "sharedlinks" */ './../views/viewsharedlink/viewSharedLink.vue'
            )
    },

    /**
     * Errors/Service pages
     */
    {
        path: '/errors/',
        name: 'errors.index',
        component: () =>
            import(/* webpackChunkName: "errors" */ './../views/errors/ErrorLayout.vue'),
        children: [
            {
                name: 'error-403',
                path: 'permissions',
                component: () =>
                    import(
                        /* webpackChunkName: "errors" */ './../views/errors/ErrorPermissions.vue'
                    )
            }
        ]
    }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
