<template>
    <div class="plafond-container" v-if="driverActivities.state">
        <div class="details-wrap">
            <div class="current-activity mb-1">
                <div class="bold-text">
                    <b>{{ __('activity') }}</b>
                </div>
                <div>
                    <i :class="getIconClass(driverActivities.state)"></i>
                </div>
            </div>

            <div class="d-flex table-element">
                <div style="min-width: 20px"><i class="fa-solid fa-user-clock"></i></div>
                <div style="min-width: 136px">{{ __('residual_commitment') }}</div>
                <div class="">{{ residualWorkingDay }}</div>
            </div>
            <div class="d-flex table-element">
                <div style="min-width: 20px"><i class="fa-solid fa-steering-wheel"></i></div>
                <div style="min-width: 136px">{{ __('residual_driving') }}</div>
                <div class="">{{ residualDriveDay }}</div>
            </div>
            <div class="d-flex table-element">
                <div style="min-width: 20px"><i class="fa-solid fa-ticket"></i></div>
                <div style="min-width: 136px">{{ __('residual_bonuses') }}</div>
                <div class="d-flex gap-2">
                    <div>{{ __('driving') }}: {{ driveBonus }}</div>
                    <div>{{ __('rest') }}: {{ restBonus }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toRefs } from 'vue'
import useDriverPlafond from '../composables/useDriverPlafond'

export default {
    name: 'TextPlafond',
    props: {
        driver: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const { driver } = toRefs(props)

        const {
            driverActivities,
            residualWorkingDay,
            residualDriveDay,
            driveBonus,
            restBonus,
            driverPlafond,
            getPlafond,
            getIconClass
        } = useDriverPlafond(driver)

        return {
            driverActivities,
            residualWorkingDay,
            residualDriveDay,
            driveBonus,
            restBonus,
            driverPlafond,
            getPlafond,
            getIconClass
        }
    },
    data() {
        return {
            plafond: {},
            date: null
        }
    },
    watch: {
        driver: {
            immediate: true,
            handler() {
                this.getPlafond({ vueInstance: this })
            }
        }
    }
}
</script>

<style scoped>
.plafond-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    font-size: 14px;
    font-weight: 300;

    border-radius: 15px;
}
.tiny-wrapper {
    display: flex;
    flex-direction: column;
}
.tiny-plafond {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;

    background-color: #d7dce8;
    border-radius: 18px;
    padding: 5px;
}
.tiny-plafond i {
    font-size: 1.1rem;
}
.current {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.p-date {
    font-size: 0.6rem;
    font-weight: 400;

    padding-bottom: 8px;
    padding-left: 8px;
}

.details-wrap {
    display: flex;

    flex-direction: column;
}
.details-wrap i {
    font-size: 0.8rem;
}
.table-element {
    gap: 10px;
}
.tiny-txt {
    padding-bottom: 2px;
}
.tiny-helper {
    font-size: 0.54rem;
    padding-left: 5px;
    padding-top: 5px;
}
.current-activity {
    display: flex;
    gap: 4px;
}
.current-activity div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.current-activity i {
    font-size: 0.9em;
}
.bold-text {
    font-size: 0.8em;
    font-weight: 400;
}
</style>
