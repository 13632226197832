<template>
    <div>
        <div class="mt-3 mb-2 text-smaller">
            <reverse-geocoding :full="true" :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]" />
        </div>

        <div class="d-flex align-items-center mb-3">
            <div class="bg-white d-flex justify-content-between p-1 gap-1 rounded-2 w-100">
                <div
                    class="btn btn-block w-100"
                    :class="{ 'btn-fill dark-blue': showingRoute === false }"
                    @click="showingRoute = false"
                >
                    {{ __('position') }}
                </div>
                <div
                    class="btn btn-block w-100"
                    :class="{ 'btn-fill dark-blue': showingRoute === true }"
                    @click="showingRoute = true"
                >
                    {{ __('route') }}
                </div>
            </div>
            <div class="btn" v-if="showingRoute" v-b-modal.modal-date-filter>
                <i class="fa-regular fa-filter"></i>
            </div>

            <b-modal id="modal-date-filter" :title="__('filter_date')" footer-class="d-none">
                <golia-date-picker
                    class="date-picker mb-4"
                    v-model="dateRange"
                    v-if="dateRange.start && dateRange.end"
                    :maxRange="3"
                >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </golia-date-picker>
            </b-modal>
        </div>

        <div class="map-timeline-wrapper position-relative">
            <div
                class="bg-dark-blue text-tiny py-1 px-2 text-white lastpoint-date position-absolute top-0 end-0"
                style="z-index: 999; border-top-right-radius: 15px; border-bottom-left-radius: 5px"
                v-if="!showingRoute"
            >
                {{ truck.lastpoint.date | formatDate('UTC') }}
            </div>
            <map-route-analizer :truck="truck" :range="dateRange" :showRouteAction="showingRoute" />
        </div>
    </div>
</template>

<script>
import MapRouteAnalizer from '@/components/panels/ActivityPanel/MapRouteAnalizer.vue'
import { mapState } from 'vuex'

export default {
    name: 'ActivityWrapper',
    props: {
        truck: {
            type: Object,
            required: true
        }
    },
    components: {
        MapRouteAnalizer
    },
    data() {
        return {
            dateRange: { start: null, end: this.moment().endOf('day') },
            showingRoute: false
        }
    },
    created() {
        this.setDateRangeStart()
    },
    watch: {
        truck() {
            this.setDateRangeStart()
        },
        dateRange() {
            // Close modal after date range is selected
            this.$nextTick(() => {
                this.$bvModal.hide('modal-date-filter')
            })
        }
    },
    computed: {
        ...mapState({
            driversData: (state) => state.geoFleet.driversData
        })
    },
    methods: {
        setDateRangeStart() {
            let driver = this.truck.lastpoint.driver
                ? this.truck.lastpoint.driver
                : this.truck.lastpoint.last_driver

            if (!(driver && driver.id > 1)) {
                this.dateRange.start = this.moment().startOf('day')

                return
            }

            if (this.driversData[driver.id]) {
                this.dateRange.start =
                    this.moment(this.driversData[driver.id].day.start, 'UTC') ||
                    this.moment().startOf('day')

                return
            }

            this.dateRange.start = this.moment().startOf('day')
        }
    }
}
</script>
