<!-- Vue 2 basic component template -->
<template>
    <div>
        <template v-if="localCompanyTags.length > 0">
            <div class="single-tag d-flex justify-content-between align-items-center">
                <golia-input
                    v-model="newTag.name"
                    :placeholder="__('tags.add_tag')"
                    :errorField="newTag.error"
                    style="min-width: 80%"
                />

                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <golia-button
                            variant="btn-sm btn-circle btn-fill success"
                            @click="createTag"
                            :loading="newTag.loading"
                            :disabled="newTag.name.length < 1"
                            icon="fa-solid fa-fw fa-plus"
                        />
                    </li>
                </ul>
            </div>

            <div
                class="single-tag d-flex justify-content-between align-items-center"
                v-for="(tag, index) in localCompanyTags"
                :key="tag.id"
            >
                <div class="position-relative" style="min-width: 80%">
                    <golia-input
                        v-model="tag.name"
                        :placeholder="__('tags.tag_name')"
                        :errorField="tag.error"
                    />
                    <span class="badge">{{ tag.trucks }}</span>
                </div>

                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <golia-button
                            variant="btn-sm btn-circle btn-fill info"
                            :disabled="tag.loading"
                            @click="updateTag(tag)"
                            icon="fa-solid fa-fw fa-pencil"
                        />
                    </li>
                    <li class="list-inline-item">
                        <golia-button
                            variant="btn-sm btn-circle btn-fill danger"
                            :disabled="tag.loading"
                            @click="deleteTag(tag, index)"
                            icon="fa-solid fa-fw fa-trash"
                        />
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div class="single-tag d-flex justify-content-between align-items-center">
                <golia-input
                    v-model="newTag.name"
                    :placeholder="__('tags.add_tag')"
                    :errorField="newTag.error"
                    style="min-width: 80%"
                />

                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <golia-button
                            variant="btn-sm btn-circle btn-fill success"
                            @click="createTag"
                            :loading="newTag.loading"
                            :disabled="newTag.name.length < 1"
                            icon="fa-solid fa-fw fa-plus"
                        />
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TagsTrucks',
    props: {
        companyTags: {
            type: Array,
            required: true
        },
        withCount: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newTag: { name: '', loading: false, error: false },
            localCompanyTags: this.companyTags
        }
    },
    methods: {
        createTag() {
            this.newTag.loading = true
            this.$store.dispatch('addCompanyTag', { vueInstance: this, item: this.newTag })
        },
        updateTag(tag) {
            tag.loading = true
            this.$store.dispatch('updateCompanyTag', { vueInstance: this, item: tag })
        },
        deleteTag(tag) {
            tag.loading = true
            this.$store.dispatch('removeCompanyTag', { vueInstance: this, item: tag })
        },
        focustTag(tag) {
            this.$set(tag, 'to_update', true)
        }
    },
    watch: {
        companyTags: {
            handler: function () {
                this.newTag = { name: '', loading: false, error: false }
                this.localCompanyTags = this.companyTags
            }
        }
    }
}
</script>

<style scoped lang="scss">
.single-tag {
    .btn {
        padding: 4px 6px;
        font-size: 10px;
    }

    ::v-deep(.input) {
        padding: 8px 10px;
    }

    .badge {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        background-color: var(--dark-blue);
    }

    .to-update,
    &:hover {
        .badge {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }
}
</style>
