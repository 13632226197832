// state.geojson is an array that can hold these three types of objects:
/*
    {
        id: 'route',
        geojson: res,
        ...
    },
    {
        id: 'stops',
        geojson: res,
        ...
    },
    {
        id: 'activities',
        geojson: res,
        ...
    }
*/

const mutations = {
    setCurrentTruck(state, truck) {
        state.currentTruck = truck
    },
    setActivities(state, activities) {
        state.activities = activities
    },
    setLayers(state, layers) {
        state.layers = layers
    },
    setStops(state, stops) {
        state.stops = stops
    },
    setLoader(state, loading) {
        state.loaders = { ...state.loaders, ...loading }
    },
    setRouteGeoJSON(state, geojson) {
        state.geojson = state.geojson.filter((obj) => obj.id !== 'route')
        state.geojson.push(geojson)
    },
    setStopsGeoJSON(state, geojson) {
        state.geojson = state.geojson.filter((obj) => obj.id !== 'stops')
        state.geojson.push(geojson)
    },
    setActivitiesGeoJSON(state, geojson) {
        state.geojson = state.geojson.filter((obj) => obj.id !== 'activities')
        state.geojson.push(geojson)
    },
    resetState(state) {
        state.layers = []
        state.activities = []
        state.stops = []
        state.geojson = []
        state.loaders = {
            activities: false,
            stops: false,
            route: false
        }
        state.currentTruck = null
    }
}

export default mutations
