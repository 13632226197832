<template>
    <div class="waypoint-inner-search mt-2 w-100">
        <hr />

        <template
            v-if="
                (localWaypoint.coordinate || {}).resourceType != 'borsa' &&
                (localWaypoint.coordinate || {}).resourceType != 'poi'
            "
        >
            <!-- Base Search Info -->
            <div class="row mb-2">
                <div class="col-8">
                    <golia-input
                        v-model="(localWaypoint.searchInfo || {}).areaValue"
                        :placeholder="__('planner.resources.area_search')"
                    ></golia-input>
                </div>
                <div class="col-4">
                    <golia-input
                        type="select"
                        v-model="(localWaypoint.searchInfo || {}).areaUnit"
                        :options="[
                            { value: 'km', text: 'Km' },
                            { value: 'min', text: __('minutes') }
                        ]"
                    ></golia-input>
                </div>
            </div>

            <!-- Advanced Search Info -->
            <div class="mb-2">
                <!-- Resource type == 'fuel' -->
                <golia-multiselect
                    v-model="(localWaypoint.searchInfo || {}).stationServices"
                    label="label"
                    track-by="type"
                    class="mb-2"
                    :placeholder="__('planner.resources.station_services')"
                    :options="filtersDictionary.stations"
                    :multiple="true"
                    :close-on-select="false"
                    :taggable="true"
                    :show-labels="false"
                    :customSelectedLabel="true"
                    :refer="'station-services-' + position"
                    v-if="
                        $can('use', 'fuel_partner_services') &&
                        (localWaypoint.coordinate || {}).resourceType == 'fuel'
                    "
                />

                <!-- Resource type == 'parking' -->
                <template v-if="(localWaypoint.coordinate || {}).resourceType == 'parking'">
                    <golia-multiselect
                        v-model="(localWaypoint.searchInfo || {}).parkingServices"
                        track-by="id"
                        class="mb-2"
                        label="name"
                        :customLabel="(option) => option.name[localeForLabels]"
                        :placeholder="__('planner.resources.parking_services')"
                        :options="filtersDictionary.parkings"
                        :multiple="true"
                        :close-on-select="false"
                        :taggable="true"
                        :showLabels="false"
                        :customSelectedLabel="true"
                        :refer="'station-services-' + position"
                    />

                    <div class="row">
                        <div class="col-6">
                            <golia-input
                                type="select"
                                v-model="(localWaypoint.searchInfo || {}).parkingRating"
                                :options="[
                                    { vaue: '', text: __('planner.resources.select_rating') },
                                    { value: 1, text: '1' },
                                    { value: 2, text: '2' },
                                    { value: 3, text: '3' },
                                    { value: 4, text: '4' },
                                    { value: 5, text: '5' }
                                ]"
                            ></golia-input>
                        </div>
                        <div class="col-6">
                            <golia-input
                                type="select"
                                v-model="(localWaypoint.searchInfo || {}).parkingSecurityRating"
                                :options="[
                                    { vaue: '', text: __('planner.resources.select_security') },
                                    { value: 1, text: '1' },
                                    { value: 2, text: '2' },
                                    { value: 3, text: '3' },
                                    { value: 4, text: '4' },
                                    { value: 5, text: '5' }
                                ]"
                            ></golia-input>
                        </div>
                    </div>

                    <div class="form-check mt-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="(localWaypoint.searchInfo || {}).parkingVerified"
                            id="checkVerified"
                        />
                        <label class="form-check-label w-100" for="checkVerified">
                            {{ __('verified') }}
                        </label>
                    </div>
                </template>
            </div>
        </template>

        <!-- Alpega Search Info -->
        <template v-if="(localWaypoint.coordinate || {}).resourceType == 'borsa'">
            <!-- If user has Alpega permissions -->
            <div class="alpega-searches-list mb-3">
                <golia-input
                    v-if="localAlpegaSearches.length > 0"
                    type="select"
                    v-model="localWaypoint.searchInfo.alpegaSearch"
                    :options="localAlpegaSearches"
                    :placeholder="__('planner.alpega_searches')"
                >
                </golia-input>
            </div>
        </template>

        <!-- Timocom Search Info -->
        <template v-if="(localWaypoint.coordinate || {}).resourceType == 'borsa-timocom'">
            <v-select
                v-model="(localWaypoint.searchInfo || {}).delivery_nation"
                :options="delivery_nations"
                :placeholder="__('planner.resources.delivery_nation')"
                :id="'delivery_nation_' + index"
            />

            <div class="row mt-3 mb-2">
                <div class="col-6">
                    <v-select
                        v-model="(localWaypoint.searchInfo || {}).vehicle_type"
                        :options="vehicle_types"
                        :placeholder="__('vehicle_type')"
                        :id="'vehicle_type_' + index"
                    />
                </div>
                <div class="col-6 position-relative">
                    <golia-multiselect
                        v-model="(localWaypoint.searchInfo || {}).vehicle_size"
                        :options="vehicle_sizes"
                        :multiple="true"
                        :show-labels="false"
                        track-by="label"
                        label="label"
                        :placeholder="__('vehicle_body')"
                        :selectLabel="__('select')"
                        :selectedLabel="__('selected')"
                        :deselectLabel="__('deselect')"
                        :id="'vehicle_size_' + index"
                        ref="vehicle_size_ref"
                    >
                        <template slot="selection" slot-scope="{ values, isOpen }"
                            ><span
                                class="multiselect__single"
                                v-if="values.length && !isOpen"
                                @click="toggleMultiselect(isOpen, index)"
                                ><b class="text-success">{{ values.length }}</b>
                                {{ __('selected_plural') }}</span
                            ></template
                        >
                    </golia-multiselect>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-6">
                    <golia-input
                        v-model="(localWaypoint.searchInfo || {}).vehicle_weight"
                        :placeholder="__('vehicle_weight')"
                    ></golia-input>
                </div>
                <div class="col-6">
                    <golia-input
                        v-model="(localWaypoint.searchInfo || {}).vehicle_length"
                        :placeholder="__('vehicle_length')"
                    ></golia-input>
                </div>
            </div>
        </template>

        <golia-button
            variant="w-100 mt-2 btn-fill dark-blue btn-rounded"
            @click="$emit('searchResource', localWaypoint)"
            icon="fa-magnifying-glass"
            :label="__('cerca')"
            :loading="loading"
        ></golia-button>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ResourcesFilters',
    props: {
        waypoint: {
            type: Object,
            default: null
        },
        position: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localWaypoint: this.waypoint,
            localeForLabels: (this.$authProcess.getParams('Lingua') || '').replace('-', '_'),
            localAlpegaSearches: []
        }
    },
    watch: {
        waypoint: function (val) {
            this.localWaypoint = val
        }
    },
    methods: {},
    computed: {
        ...mapState({
            filtersDictionary: (state) => state.geoFleet.filtersDictionary,
            storeUser: (state) => state.user
        })
    },
    created() {
        if (this.storeUser.loggedToAlpega)
            this.$fetch('https://run.mocky.io/v3/d2f60372-8c74-4400-997d-4cc37acbda97')
                .then((response) => response.json())
                .then((response) => {
                    this.localAlpegaSearches = response.searches
                })
                .catch((error) => {
                    console.log(error)
                })
    }
}
</script>

<style scoped lang="scss">
.waypoint-inner-search {
    padding-top: 0;
}
.single-search {
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;

    h3 {
        margin-bottom: 0;
        font-size: 16px;
    }
}
</style>
