<template>
    <div @click="$emit('click')" class="field-wrapper">
        <!-- 1 input -->
        <div class="form-input-g">
            <label
                v-if="icon"
                :for="id"
                :class="{
                    icon: type != 'select',
                    'icon-left': icon.left || !icon.right,
                    'icon-right': icon.right,
                    button: icon.clickable
                }"
            >
                <i :class="icon.class"></i>
            </label>
            <select
                v-if="type == 'select'"
                :style="inputStyle"
                :id="id"
                @input="$emit('input', $event.target.value)"
                class="input form-select"
                :class="{ 'left-space': !icon, 'is-invalid': errorField }"
                :disabled="disabled"
                :readonly="readonly"
            >
                <option v-if="placeholder" value="" disabled :selected="!value">
                    {{ placeholder }}
                </option>
                <option
                    v-for="(opt, index) in options"
                    :key="index"
                    :value="opt.value"
                    :selected="opt.value == value"
                >
                    {{ opt.text }}
                </option>
            </select>
            <input
                v-else
                :style="inputStyle"
                :id="id"
                :type="type"
                class="input form-control"
                :class="{ 'left-space': !icon, 'is-invalid': errorField }"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                v-bind="additionalAttributes"
                :ref="id"
                @click="selectText"
                @input="$emit('input', $event.target.value)"
                :value="value"
            />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'GoliaInput',
    props: {
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        additionalAttributes: {
            type: Object,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false
        },
        errorField: {
            type: Boolean,
            required: false
        },
        id: {
            type: String,
            required: false,
            default: () => {
                return 'input_' + Math.floor(Math.random() * 100000).toString()
            }
        },
        icon: {
            type: Object,
            required: false
        },
        options: {
            type: Array,
            required: false
        },
        inputStyle: {
            type: Object,
            required: false
        },
        value: {}
    },
    components: {},
    data() {
        return {}
    },
    methods: {
        selectText() {
            this.$refs[this.id].select()
        }
    }
}
</script>

<style>
.field-wrapper {
    margin: 4px 0px;
    position: relative;
    min-width: 100%;
}
.form-input-g + .form-input-g {
    margin-top: 0.8em;
}
.form-input-g .input {
    background-color: #fff;
    color: #333;
    border: 1px solid #d2d2d2;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
}
.form-input-g .input.form-select {
    padding-right: 30px !important;
}

.form-input-g .input:focus {
    background-color: var(--main-gray);
    box-shadow: none;
}
.form-input-g .icon-left {
    left: 0;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0.5em;
}
.form-input-g .icon-right {
    right: 0;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 0.5em;
}
.form-input-g .left-space {
    /*padding: 0.4em 0.8em !important;*/
}
.form-input-g .icon ~ .input {
    /*padding-left: 2em;*/
}
.button {
    cursor: pointer;
}
</style>
