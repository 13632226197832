<template>
    <ul :class="bigButton ? 'big-social-share list-inline' : 'social-share list-inline'">
        <li class="list-inline-item" v-for="(network, index) in networks" :key="index">
            <ShareNetwork
                class="test"
                :network="network.network"
                :key="network.key"
                :style="{ backgroundColor: network.color }"
                :url="sharedLink"
                :title="
                    truckPlate
                        ? __('sharedlinks.track_vehicle_position') + ' ' + truckPlate
                        : __('sharedlinks.track_vehicle_position') + ' '
                "
                @open="open"
            >
                <i :class="network.icon"></i>
            </ShareNetwork>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'GoliaShareNetwork',
    props: {
        truckPlate: {
            type: String,
            required: false
        },
        sharedLink: {
            type: String,
            required: true
        },
        bigButton: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            networks: [
                {
                    key: 1,
                    network: 'email',
                    name: 'Email',
                    icon: 'far fah fa-lg fa-envelope',
                    color: '#333333'
                },
                {
                    key: 2,
                    network: 'messenger',
                    name: 'Messenger',
                    icon: 'fab fah fa-lg fa-facebook-messenger',
                    color: '#0084ff'
                },
                {
                    key: 3,
                    network: 'sms',
                    name: 'SMS',
                    icon: 'far fah fa-lg fa-comment-dots',
                    color: '#333333'
                },
                {
                    key: 4,
                    network: 'telegram',
                    name: 'Telegram',
                    icon: 'fab fah fa-lg fa-telegram-plane',
                    color: '#0088cc'
                },
                {
                    key: 5,
                    network: 'whatsapp',
                    name: 'Whatsapp',
                    icon: 'fab fah fa-lg fa-whatsapp',
                    color: '#25d366'
                }
            ]
        }
    },
    methods: {
        /**
         * re-render del pulsante di condivisione per fa sì che si possa ri-cliccare successivamente
         *
         * @param {type} e - il social che è stato selezionato
         * @return {void}
         */
        open(e) {
            const openedNetwork = this.networks.find((n) => {
                return n.network == e
            })
            openedNetwork.key += 1
        }
    }
}
</script>

<style scoped>
.social-share li a {
    font-size: 12px !important;
    margin-top: 0px;
    color: #fff;
}
.big-social-share li a {
    font-size: 15px !important;
    margin-top: 0px;
    color: #fff;
}
a {
    color: var(--dark-blue);
    display: block;
    word-break: break-all;
    padding: 4px;
    padding: 4px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 45px;
    background: var(--main-gray);
    margin-top: 10px;
    font-size: 11px;
    position: relative;
    z-index: 1;

    &:hover {
        color: var(--dark-blue--hover);
    }
}
button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
}
input:read-only {
    position: relative;
    top: -35px;
}
</style>
