<template>
    <div class="drivers-selection" v-show="hasMultiDrivers">
        <golia-button
            :variant="isDriverActive ? 'btn-fill dark-blue' : 'btn-fill gray'"
            class="ml-2"
            label="Autista 1"
            icon="fa fa-user"
            @click="onClick('driver')"
        />
        <golia-button
            :variant="isCodriverActive ? 'btn-fill dark-blue' : 'btn-fill gray'"
            label="Autista 2"
            icon="fa fa-user"
            @click="onClick('codriver')"
        />
    </div>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue'

export default {
    name: 'DriversSelector',
    props: {
        truckLastPoint: {
            type: Object,
            required: true
        },
        value: Object
    },
    setup(props, { emit }) {
        const { truckLastPoint, value: driver } = toRefs(props)

        const active = ref('driver')

        const truckDriver = computed(() => truckLastPoint.value?.driver)
        const truckCodriver = computed(() => truckLastPoint.value?.codriver)
        const hasMultiDrivers = computed(() => truckCodriver.value && truckCodriver.value)
        const isDriverActive = computed(() => active.value === 'driver')
        const isCodriverActive = computed(() => active.value === 'codriver')

        const onClick = (tag) => {
            active.value = tag
            emit('change', {
                type: tag, //driver o codriver
                data: tag === 'driver' ? truckDriver.value : truckCodriver.value
            })
        }

        watch(
            driver,
            (newDriver) => {
                if (newDriver?.id === truckDriver.value?.id) active.value = 'driver'
                else active.value = 'codriver'
            },
            { immediate: true }
        )

        return {
            truckDriver,
            truckCodriver,
            hasMultiDrivers,
            active,
            isDriverActive,
            isCodriverActive,
            onClick
        }
    }
}
</script>

<style lang="scss">
.drivers-selection {
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>
