<template>
    <div>
        <div v-if="value.state != 'BLOCK'" class="tiny-plafond">
            <div class="current">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.state)"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __(`plafonds.driver_in_${value.state}`) }}</b>
                    <template>
                        <span v-if="value.currentText">{{ value.currentText }}</span>
                    </template>
                </div>
            </div>
            <div class="pending" v-if="value.arrowText && value.state == 'DRIVE'">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i class="fa-solid fa-arrow-down-long"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __('residual_driving') }}:</b>
                    <span v-if="value.arrowText">
                        {{ value.arrowText }}
                    </span>
                </div>
            </div>
            <div class="next">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.nextState)"></i>
                </div>
                <div class="tiny-txt align-items-center">
                    <b>{{ __('next_activity') }}:</b>
                    <span v-if="value.nextText">{{ value.nextText }}</span>
                </div>
                <span
                    class="tiny-15-min"
                    v-if="
                        value.nextState == 'REST' &&
                        restInfo?.driveTypeNextRest == 'SESSION' &&
                        restInfo?.driverPlafond?.fifteen
                    "
                    @click="showSessionInfo()"
                    v-b-tooltip.hover.top="__('plafonds.session_tooltip')"
                >
                    <i class="fa-solid fa-circle-quarter-stroke"></i>
                </span>
            </div>
        </div>
        <div v-else class="tiny-plafond">
            <div class="current">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.state)"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __(`plafonds.driver_in_${value.state}`) }}</b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'PlafondDriverActivities',
    props: {
        value: Object,
        restInfo: Object,
        driver: Object
    },
    methods: {
        secondsToHours(seconds) {
            // Convert seconds into this format: 1h 00m
            const hours = Math.floor(seconds / 3600)
            const minutes = Math.floor((seconds % 3600) / 60)

            return `${hours}h ${minutes}m`
        },
        getIconClass(state) {
            if (state == 'DRIVE') return ['fa-solid', 'fa-steering-wheel', 'drive']
            if (state == 'WORK') return ['fak', 'fa-work', 'work']
            if (state == 'AVAILABILITY') return ['fak', 'fa-availability', 'available']
            if (state == 'BLOCK') return ['fak', 'fa-bar-steering', 'drive']

            return ['fa-solid', 'fa-bed', 'rest']
        },
        showSessionInfo() {
            const h = this.$createElement
            const messageVNode = h('div', {
                domProps: {
                    innerHTML: this.infoDescr
                }
            })
            this.$bvModal.msgBoxOk([messageVNode], {
                title: this.__('plafonds.session_title'),
                html: true,
                headerClass: 'px-3 border-bottom-0 d-block text-center',
                bodyClass: 'text-smaller',
                footerClass:
                    this.error == 422
                        ? 'px-3 border-top-0 text-center'
                        : 'alert-footer px-3 border-top-0 text-center',
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                okVariant: 'success'
            })
        }
    },
    computed: {
        infoDescr() {
            return `
                <p><b><i class="fa-solid fa-lg fa-steering-wheel text-golia me-2"></i> ${this.__(
                    'plafonds.drive_occurring'
                )}</b></p>
                <hr class="mt-2">

                <div class="row plafond-modal-mini-grid mt-2 mb-4">
                    <div class="col-6 plafond-label"><b>${this.__('residual_driving')}</b></div>
                    <div class="col-6 plafond-value">${this.value.arrowText}</div>
                </div>

                <p class="mb-0"><i class="fa-solid fa-lg fa-circle-quarter-stroke text-success me-2"></i> <b>${this.__(
                    'plafonds.session_info'
                )}</b></p><hr class="mt-2">

                <div class="row plafond-modal-mini-grid mt-2 mb-4">
                    <div class="col-6 plafond-label"><b>${this.__('plafonds.brake_start')}</b></div>
                    <div class="col-6 plafond-value">${this.$options.filters.formatDate(
                        this.restInfo.driverPlafond?.fifteen?.time[0],
                        'UTC'
                    )}</div>
                    <div class="col-6 plafond-label"><b>${this.__('plafonds.brake_done')}</b></div>
                    <div class="col-6 plafond-value">${
                        this.restInfo.driverPlafond?.fifteen?.length
                    }m</div>
                </div>

                <hr class="mt-2">
                <p class="mb-0 bg-info-light p-2 rounded-lg" style="border-radius: 5px">${this.__(
                    'plafonds.session_choice'
                )}<b>${5}</b</p>
            `
        }
    }
}
</script>

<style scoped>
.tiny-plafond {
    display: flex;
    flex-direction: column;

    /* align-items: center; */
    /* background-color: #d7dce8; */
    /* border-radius: 10px; */
    /* height: 100%; */
    gap: 10px;
}
.tiny-plafond i {
    font-size: 1.1rem;
}
.current,
.pending,
.next {
    /* display: flex;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    background-color: #d7dce8; */
    display: inline-flex;
    min-height: 33px;
    padding: 6px 20px 6px 20px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #d1d7e4;
    gap: 10px;
}

.tiny-txt,
.tiny-icon {
    /* margin-bottom: 2px; */
}
.tiny-icon {
    width: 25px;
}
.tiny-value {
    font-weight: 500;
}
.tiny-txt {
    display: flex;
    gap: 5px;
}
.tiny-15-min {
    /* green bordered circle with 15' text inside */
    display: flex;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: var(--success);
    color: #fff;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-weight: 700;
    &:hover {
        background-color: #333;
        color: white;
        cursor: pointer;
    }

    i {
        font-size: 14px;
    }
}

.rest {
    color: #87cb7e;
}
.available {
    color: #96cee9;
}
.work {
    color: #fc9e42;
}
.drive {
    color: #e83744;
}
</style>
