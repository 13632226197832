<template>
    <div>
        <sidebar-menu
            :menu="local_menu"
            :theme="'white-theme'"
            :collapsed="local_collapsed"
            :widthCollapsed="'55px'"
            :hideToggle="true"
            :disableHover="true"
            @item-click="menuHandler"
        >
            <template v-slot:header>
                <div
                    class="override-header d-flex align-items-center p-3"
                    :class="!local_collapsed ? 'justify-content-between' : 'justify-content-center'"
                >
                    <span class="menu-title">&nbsp;</span>

                    <i
                        class="fa-regular fa-lg fa-bars"
                        @click="$emit('toggleMenu', !local_collapsed)"
                        v-if="local_collapsed"
                    ></i>

                    <div @click="$emit('toggleMenu', !local_collapsed)" class="close-btn" v-else>
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div class="change-company">
                    <v-select
                        v-model="selectedCompany"
                        :options="companyOptions"
                        :placeholder="__('company_select')"
                        :searchable="true"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        :filterBy="customSearchCompany"
                    >
                        <template #no-options>
                            {{ __('no_companies_founded') }}
                        </template>
                    </v-select>
                </div>
            </template>

            <template v-slot:footer>
                <div
                    class="sidebar-button settings-button d-flex align-items-center mb-3"
                    :class="!local_collapsed ? 'ms-1' : ''"
                    @click="$emit('toggleUtilityPanel', 'settings')"
                >
                    <golia-button icon="fa-gear" variant="btn-fill gray mx-2"></golia-button>
                    <span class="ms-2" v-show="!local_collapsed">{{ __('settings') }}</span>
                </div>

                <div
                    class="sidebar-button logout-button d-flex align-items-center mb-3"
                    :class="!local_collapsed ? 'ms-1' : ''"
                    @click="handleLogout"
                >
                    <golia-button icon="fa-power-off" variant="btn-fill gray mx-2"></golia-button>
                    <span class="ms-2" v-show="!local_collapsed">{{ __('logout') }}</span>
                </div>
            </template>
        </sidebar-menu>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'GoliaSidebar',
    props: {
        companies: {
            type: [Object, Array],
            default: () => {}
        },
        menu: {
            type: Array,
            default: () => []
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            local_collapsed: this.collapsed,
            local_menu: this.menu,
            selectedCompany: parseInt(this.$authProcess.getParams('Azienda')) || null,
            localCompanies: []
        }
    },
    methods: {
        customSearchCompany(option, label, search) {
            var content = label.replace(/[#_ .\-/,;:]/g, '') // tolgo i caratteri #_ .-;,:
            var txt = search.replace(/[#_ .\-/,;:]/g, '')
            var re = new RegExp(txt, 'i')
            if (content.match(re)) {
                return true
            }
            return false
        },
        getCompanies() {
            this.$fetch(this.$env.GOLIA_API + '/companies?include[]=permissions')
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    this.localCompanies = data.data.permissions
                })
        },
        // eslint-disable-next-line no-unused-vars
        menuHandler(event, item, node) {
            if (item.ref) {
                if (item.ref === 'planner') {
                    this.$emit('plan')
                } else if (item.ref === 'tags') {
                    this.$emit('toggleUtilityPanel', 'tags')
                } else if (item.ref === 'shared-links') {
                    this.$emit('toggleUtilityPanel', 'shared-links')
                } else if (item.ref === 'manage-poi') {
                    this.$emit('toggleUtilityPanel', 'manage-poi')
                } else if (item.ref === 'vehicles-passage') {
                    this.$emit('toggleUtilityPanel', 'vehicles-passage')
                } else if (item.ref === 'bluethooth-devices') {
                    this.$emit('toggleUtilityPanel', 'bluetooth-devices')
                }
            }

            if (!item.child || item.child.length === 0) {
                this.$emit('toggleMenu', true)
            } else {
                this.$emit('toggleMenu', false)
            }
        },
        changeCompany() {
            this.$fetch(this.$env.GOLIA_API + '/companies/change', {
                method: 'POST',
                body: JSON.stringify({
                    azienda: this.selectedCompany
                })
            }).then((response) => {
                if (response.status === 200) {
                    this.$store.commit('updateLoggedUserParams', {})
                    this.$store.commit('updateRulesLoaded', false)

                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            })
        },
        updateCounters() {
            if (this.local_menu && this.local_menu.length > 0) {
                this.local_menu.map((item) => {
                    let iconSelector = item.icon.split(' ')
                    let icon = document.querySelector('.vsm--icon.' + iconSelector[1])

                    let iconizableItems = [
                        'shared-links',
                        'tags',
                        'manage-poi',
                        'bluethooth-devices'
                    ]

                    if (!iconizableItems.includes(item.ref)) {
                        return
                    }

                    // Create a data-counter attribute on .vsm--icon to show the number of items
                    if (icon) {
                        let counter = 0

                        if (item.ref === 'shared-links') {
                            counter = this.sharedLinks.length
                        } else if (item.ref === 'tags') {
                            counter = this.companyTags.length
                        } else if (item.ref === 'manage-poi') {
                            counter = this.pois.length
                        } else if (item.ref === 'bluethooth-devices') {
                            counter = this.bluetooth_devices.length
                        }

                        if (counter > 0) {
                            icon.setAttribute('data-counter', counter)
                            icon.classList.add('with-counter')
                        } else {
                            icon.removeAttribute('data-counter')
                            icon.classList.remove('with-counter')
                        }
                    }
                })
            }
        },
        handleLogout() {
            this.logout({ vueInstance: this })
        },
        ...mapActions({
            logout: 'logout'
        })
    },
    created() {
        this.$store.dispatch('getSharedLinks', this)

        this.getCompanies()

        this.$store.dispatch('loadPois', { vueInstance: this })
    },
    watch: {
        menu: function (val) {
            this.local_menu = val
        },
        collapsed: function (val) {
            this.local_collapsed = val
        },
        selectedCompany: function (val) {
            if (val) this.changeCompany()
        },
        companyTags: function (val) {
            this.updateCounters()
        },
        sharedLinks: function (val) {
            this.updateCounters()
        },
        pois: function (val) {
            this.updateCounters()
        },
        bluetooth_devices: function (val) {
            this.updateCounters()
        }
    },
    computed: {
        companyOptions() {
            let options = []

            this.localCompanies.map((company) => {
                options.push({
                    value: company.id,
                    label: company.business_name
                })
            })

            return options
        },
        ...mapState({
            companyTags: (state) => state.geoFleet.companyTags,
            sharedLinks: (state) => state.sharedLinks.links,
            pois: (state) => state.geoFleet.pois,
            config: (state) => state.geoFleet.config,
            bluetooth_devices: (state) => state.geoFleet.bluetooth_devices
        })
    }
}
</script>

<style lang="scss" scoped>
.close-btn {
    display: flex;
    width: 30px;
    height: 30px;
}

.sidebar-button {
    cursor: pointer;
}

.logout-button .btn.btn-fill.gray {
    background-color: var(--dark-blue);
    color: #fff;

    &:hover {
        background-color: var(--golia-color);
        color: #fff;
    }
}

.v-sidebar-menu ::v-deep(.vue-dark-mode .btn) {
    padding: 6px 10px;
}

.v-sidebar-menu {
    z-index: 1001;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
    margin: 20px;
    border-radius: 25px;
    background-color: var(--main-bg-color);
}
.with-sidebar .v-sidebar-menu ::v-deep(.vsm--icon) {
    width: calc(var(--sidebar-collapsed) - 20px);
    height: 35px;
    line-height: 35px;
    position: relative;
    background-color: #dde1e7;
    border-radius: 30px;
}
.with-sidebar .v-sidebar-menu ::v-deep(.vsm--link:hover .vsm--icon) {
    background-color: var(--dark-blue);
    color: #fff;
}

.with-sidebar .v-sidebar-menu ::v-deep(.vsm--icon.with-counter::after) {
    content: attr(data-counter);
    position: absolute;
    top: -10px;
    right: -5px;
    background: var(--success);
    padding: 5px;
    line-height: 1;
    font-size: 10px;
    color: #fff;
    border-radius: 4px;
    font-family: 'Font Awesome 6 Pro';
    font-weight: bold;
}

.v-sidebar-menu .override-header i {
    cursor: pointer;
}

.v-sidebar-menu .change-company {
    padding: 0px 10px;
    margin-bottom: 8px;

    ::v-deep(.vs__dropdown-option) {
        font-size: 14px;
    }
}

.v-sidebar-menu {
    .v-select {
        background-color: var(--main-bg-color);
        border-color: var(--main-bg-color);
        color: var(--main-text-color);

        ::v-deep(.vs__selected) {
            position: relative !important;
        }
        &.vs--searching {
            ::v-deep(.vs__selected) {
                opacity: 0;
                display: inline-block;
            }
        }
    }
    ::v-deep(.vsm--link span) {
        color: var(--main-text-color);
    }
}

// Only for collapsed sidebar
.v-sidebar-menu.vsm_collapsed {
    border-radius: 12px;

    .override-header > span {
        width: 0;
    }

    ::v-deep(.vsm--title) {
        display: none;
    }

    @media screen and (min-width: 992px) {
        ::v-deep(.vsm--mobile-item) {
            display: none;
        }
    }

    .v-select {
        --dropdown-security-width: 300px;

        &:not(.vs--open) {
            ::v-deep(.vs__dropdown-toggle) {
                cursor: pointer;
                padding: 0px;
                border-radius: 30px;
                min-height: 35px;
                justify-content: center;
                background-color: #dde1e7;
                border-color: #dde1e7;

                &:hover {
                    background-color: var(--dark-blue);
                    color: #fff;
                }
            }
            ::v-deep(.vs__selected-options),
            ::v-deep(.vs__clear) {
                width: 0;
                opacity: 0;
                padding: 0;
            }
            ::v-deep(.vs__clear),
            ::v-deep(.vs__selected) {
                display: none;
            }
            ::v-deep(.vs__actions) {
                padding: 0 10px;
            }
            ::v-deep(.vs__search) {
                padding: 0;
            }
        }
        &.vs--open {
            ::v-deep(.vs__dropdown-toggle) {
                width: var(--dropdown-security-width);
                transition: width 0.3s ease-in-out;
            }
        }
        ::v-deep(.vs__dropdown-menu) {
            min-width: var(--dropdown-security-width);
            overflow-x: hidden;
        }
    }
}
.v-sidebar-menu ::v-deep(.vsm--arrow:after) {
    font-family: 'Font Awesome 6 Pro';
}
@media (max-width: 768px) {
    .sidebar-button .btn {
        min-width: auto;
    }
}
</style>
